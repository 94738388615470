export default {
    buy: 'Харидан',
    toBuy: 'Гузаштан ба пардохт',
    more: 'Муфассал',
    insuredSum: 'Маблағи суғуртавӣ',
    validaty: 'Муҳлати суғурта',
    insuranceCost: 'Арзиши суғурта',
    insuranceCostCoupon: 'Арзиши суғурта бо купон',
    insuranceCostActiveCoupon: 'Арзиш бо купони истифодашуда:',
    loading: 'Зеркашӣ...',
    activation: 'Фаъолсозии қуттӣ',
    buyBox: 'Харидани қуттӣ',
    boxProducts: 'Маҳсулоти қуттигӣ',
    policyData: 'Маълумоти полис',
    policyNumber: 'Рақами полис',
    policyCode: 'Рамзи полис',
    boxProductBuy: 'Барасмиятдарории маҳсулоти қуттигӣ',
    holderLastName: 'Насаб',
    holderFirstName: 'Ном',
    holderSecondName: 'Номи падар',
    holderLastNamePH: 'Иванов',
    holderFirstNamePH: 'Иван',
    holderSecondNamePH: 'Иванович',
    holderBirthDate: 'Санаи таваллуд',
    holderPhone: 'Телефон',
    holderEmail: 'E-mail ',
    holderGender: 'Ҷинс',
    holderCountry: 'Истиқоматгоҳ',
    couponError: 'Промокод нодуруст ворид карда шуд',
    coupon: 'Промокод',
    couponApply: 'Истифодаи промокод',

    box: 'Қуттӣ',
    holderDocSeries: 'Силсилаи шиносномаҳо',
    holderIssueInstitution: 'Паспорт дода шудааст',
    holderIssueDate: 'Санаи додани паспорт',
    holderDocNumber: 'ID шиноснома',
    periodName: 'мес.',
    offerProducts: 'Маҳсулоти бонкӣ',
    showPassword: 'нишон',
    closePassword: 'пинҳон',
}
