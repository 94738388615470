import React from 'react'
import moment from 'moment'
import changePolicyStatus from '../../utils/policyStatuses'
import Button from '../base/Button'
import sprite from '../../static/img/svg/svg-sprite.svg'

export default function PolicySuccess({ push, hide }) {
    const data = JSON.parse(sessionStorage.getItem('policyInfo'))

    return (
        <div className="popup__content">
            <div className="popup-policy-success">
                <div className={`popup-policy-success__icon-${data.status === 'ACTIVE' ? 'ok' : 'no'}`}>
                    <svg width="296" height="296">
                        <use xlinkHref={`${sprite}#icon-xl-${data.status === 'ACTIVE' ? 'ok' : 'no'}`} />
                    </svg>
                </div>

                <p className="popup-policy-success__title">Полис <span>{data.policyNumber}</span></p>

                <p className="popup-policy-success__text">
                    Статус:
                    <span className={`popup-policy-success__text_${data.status === 'ACTIVE' ? 'ok' : 'no'}`}>
                        {changePolicyStatus(data.status)}
                    </span>
                </p>

                <p className="popup-policy-success__date">
                    с {moment(data.startDate).format('DD.MM.YYYY')} по {moment(data.endDate).format('DD.MM.YYYY')}
                </p>

                <p className="popup-policy-success__text">Застрахован: <span> {data.lifeAssured}</span></p>

                <Button className="popup-policy-success__button"
                    onClick={() => {
                        push('/losses/', { ...data })
                        hide()
                    }}
                    text="Страховой случай" />
            </div>
        </div>
    )
}
