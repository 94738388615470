import React from 'react'
import Layout from '../components/Layout'
import promoArrow from '../static/icons/arrow-promo.svg'

export default function Travel() {
    return (
        <Layout>
            <div className="travel-one">
                <div className="travel-one__bg">
                    <div className="travel-one__container">
                        {/* TODO: Заголовок поставила "из головы", надо уточнить, какой в реале он */}
                        <h3 className="travel-one__title">Оформление страхования</h3>
                        <form action="" className="travel-one__form">
                            <div className="tabs__nav">
                                <button className="tabs__btn tabs__btn_finished">Данные</button>
                                <button className="tabs__btn tabs__btn_active">Опции</button>
                                <button className="tabs__btn">Оформление</button>
                                <button className="tabs__btn">Оплата</button>
                            </div>
                            <section className="step step-1">
                                <div className="travel-one__form-items">
                                    <div className="travel-one__form-item">
                                        <div className="travel-one__form-row">
                                            <div className="select select__auto-order">
                                                <label className="select__label label-valid">Марка <span className="input_error-text" /></label>
                                                <div className="select__current">--------</div>
                                                {/* TODO: В открытом виде для select__list добавить класс select__list_open */}
                                                <div className="select__list">
                                                    <input className="select__search select__search-icon" type="search" />
                                                    <div className="select__item">
                                                        <span className="select__text-value">Audi</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Toyota</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Volkswagen</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="select select__auto-order">
                                                <label className="select__label">Модель <span className="input_error-text" /></label>
                                                <div className="select__current">--------</div>
                                                {/* В открытом виде для select__list добавить класс select__list_open */}
                                                <div className="select__list">
                                                    <input className="select__search select__search-icon" type="search" />
                                                    <div className="select__item">
                                                        <span className="select__text-value">Jetta</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Tiguan</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Touareg</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Transporter</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="travel-one__form-row">
                                            <div className="select select__auto-order">
                                                <label className="select__label">Год выпуска <span className="input_error-text" /></label>
                                                <div className="select__current">--------</div>
                                                {/* В открытом виде для select__list добавить класс select__list_open */}
                                                <div className="select__list">
                                                    <div className="select__item">
                                                        <span className="select__text-value">2018</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">2019</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="select select__auto-order">
                                                <label className="select__label">Страховая сумма <span className="input_error-text" /></label>
                                                <div className="select__current">--------</div>
                                                {/* В открытом виде для select__list добавить класс select__list_open */}
                                                <div className="select__list">
                                                    <div className="select__item">
                                                        <span className="select__text-value">25000
                                                            <span className="auto__currency">TJS</span>
                                                        </span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">30000
                                                            <span className="auto__currency">TJS</span>
                                                        </span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">35000
                                                            <span className="auto__currency">TJS</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="travel-one__form-row">
                                            <div className="travel-order__text checkbox auto-rules__check">
                                                <label className="travel-order__rules">
                                                    <p className="holder-checkbox__text auto-checkbox__text">
                                                        Рассчитать рассрочку
                                                    </p>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span" />
                                                </label>
                                            </div>
                                        </div>
                                        {/* TODO: 05/09/2022 Этот блок должен появляться, если выбрали рассрочку. */}
                                        <div className="travel-one__form-row">
                                            <div className="installment-block">
                                                <div className="installment-block__item first-pay">
                                                    <span className="installment-block__percent">40%</span>
                                                    <span className="installment-block__date">сегодня</span>
                                                    <span className="installment-block__summ">1200 TJS</span>
                                                </div>
                                                <div className="installment-block__item">
                                                    <span className="installment-block__percent">20%</span>
                                                    <span className="installment-block__date">до 12.09.2022</span>
                                                    <span className="installment-block__summ">600 TJS</span>
                                                </div>
                                                <div className="installment-block__item">
                                                    <span className="installment-block__percent">20%</span>
                                                    <span className="installment-block__date">до 12.10.2022</span>
                                                    <span className="installment-block__summ">600 TJS</span>
                                                </div>
                                                <div className="installment-block__item">
                                                    <span className="installment-block__percent">20%</span>
                                                    <span className="installment-block__date">до 12.11.2022</span>
                                                    <span className="installment-block__summ">600 TJS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="travel-one__total_main auto__total_main">
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">Предварительный расчет:</div>
                                            <div className="travel-one__value auto__value">
                                                3000
                                                <span className="travel-option__currency">TJS</span>
                                            </div>
                                            {/* TODO: строка ниже должна появляться, если в основной части поставлена галочка "Рассчитать рассрочку" */}
                                            {/* TODO: 05/09/2022 Обновили формат написания рассрочки */}
                                            <div className="auto__monthly">
                                                В рассрочку <br /><span className="auto__monthly-summ">1200 - 600 - 600 - 600</span>
                                            </div>
                                        </div>
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">На автомобиль</div>
                                            <div className="travel-one__value travel-one__value-mb">
                                                <span className="auto__model-value">Volkswagen Tiguan</span>
                                                <span className="auto__year-value auto__year-text">2020</span>
                                                <span className="auto__year-text">г.в.</span>
                                            </div>
                                        </div>
                                        <div className="travel-order__promo promo">
                                            <p className="promo__subtitle">Промокод</p>
                                            <div className="travel-order__promo-row">
                                                <div className="input input__promo">
                                                    <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                    </label>
                                                    <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                </div>
                                                <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                    <img src={promoArrow} alt="" width="16" height="16" />
                                                </a>
                                            </div>
                                        </div>
                                        <button className="travel-one__form-btn step-button">Далее</button>
                                        <button className="travel-option__description-button auto__description-button">
                                            Что входит в полис
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <section className="step step-2">
                                <div className="travel-one__form-items">
                                    <div className="travel-one__form-item">
                                        {/* TODO: У выбранной опции добавляется класс travel-option__checked
                                        и в инпуте checked="checked".
                                        У неактивной опции этих классов нет. */}
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box crush travel-option__checked">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">ДТП</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" checked="checked" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box crush">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">ДТП только по вине третьих лиц</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box pdtl">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">+ПДТЛ</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box usherb">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">+УЩЕРБ</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box ugon">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">+УГОН</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Дополнительные опции:
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box crush travel-option__checked">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Гражданская ответственность</p>
                                                                </div>
                                                                <span className="select__text-desc">
                                                                    Размер возмещения
                                                                </span>
                                                                <div className="select select__travel-order select__travel-option">
                                                                    <div className="select__current travel-option__select-current">10000
                                                                        <span className="travel-option__currency">TJS</span>
                                                                    </div>
                                                                    {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                    <div className="select__list select__list-option">
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">10000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">20000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">30000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">40000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">50000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" checked="checked" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box hospitalization travel-option__checked">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Несчастный случай</p>
                                                                </div>
                                                                <span className="select__text-desc">
                                                                    Размер возмещения
                                                                </span>
                                                                <div className="select select__travel-order select__travel-option">
                                                                    <div className="select__current travel-option__select-current">10000
                                                                        <span className="travel-option__currency">TJS</span>
                                                                    </div>
                                                                    {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                    <div className="select__list">
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">10000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">20000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">30000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">40000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">50000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" checked="checked" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box bez-gai">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Без участия ГАИ</p>
                                                                </div>
                                                                <span className="select__text-desc">
                                                                    Размер возмещения
                                                                </span>
                                                                <div className="select select__travel-order select__travel-option">
                                                                    <div className="select__current travel-option__select-current">10000
                                                                        <span className="travel-option__currency">TJS</span>
                                                                    </div>
                                                                    {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                    <div className="select__list select__list-option">
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">10000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">20000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">30000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">40000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="select__item travel-option__select-item">
                                                                            <span className="select__text-value">50000
                                                                                <span className="travel-option__currency">TJS</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span travel-option__span" />
                                                </label>
                                            </div>
                                        </div>
                                        {/* Новый тип опции с чекбоксами */}
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box auto-option__box-top green travel-option__checked">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Зеленая карта</p>
                                                                </div>
                                                                <span className="select__text-desc">
                                                                    Расширение территории страхования
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="auto-option__list">
                                                        <div className="auto-option__item">
                                                            узбекистан
                                                        </div>
                                                        <div className="auto-option__check">
                                                            <div className="travel-option__price auto-option__price">
                                                                500 TJS
                                                                <input className="checkbox__input" type="checkbox" checked="checked" />
                                                                <span className="checkbox__span auto-option__span" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="auto-option__list">
                                                        <div className="auto-option__item">
                                                            казахстан
                                                        </div>
                                                        <div className="auto-option__check">
                                                            <div className="travel-option__price auto-option__price">
                                                                500 TJS
                                                                <input className="checkbox__input" type="checkbox" />
                                                                <span className="checkbox__span auto-option__span" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="auto-option__list">
                                                        <div className="auto-option__item">
                                                            кыргызстан
                                                        </div>
                                                        <div className="auto-option__check">
                                                            <div className="travel-option__price auto-option__price">
                                                                500 TJS
                                                                <input className="checkbox__input" type="checkbox" />
                                                                <span className="checkbox__span auto-option__span" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* Тип возмещения */}
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Вид страхового возмещения:
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box compensation">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Компенсация расходов</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="radio" id="auto-radio" />
                                                    <span className="checkbox__span travel-option__span auto-option__radio" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box expertise">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Независимая экспертиза</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="radio" id="auto-radio" />
                                                    <span className="checkbox__span travel-option__span auto-option__radio" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box calculate">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">Оценка Страховщика</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="radio" id="auto-radio" />
                                                    <span className="checkbox__span travel-option__span auto-option__radio" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="auto__option-row">
                                            <div className="checkbox travel-option auto-option__box stoa">
                                                <span className="hint__icon travel-option__hint-icon">
                                                    <span className="hint__icon-simbol">?</span>
                                                    <span className="hint__content travel-option__hint-content">
                                                        Здесь текст подсказки, что собой представляет опция.
                                                    </span>
                                                </span>
                                                <label className="travel-option__rules">
                                                    <div className="travel-option__title-box travel-pl">
                                                        <div className="travel-option__title">
                                                            <div className="travel-option__text-box">
                                                                <div className="travel-option__name auto__name">
                                                                    <p className="auto__text">СТОА Страховщика</p>
                                                                </div>
                                                            </div>
                                                            <div className="travel-option__price">
                                                                435 TJS
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="checkbox__input" type="radio" id="auto-radio" />
                                                    <span className="checkbox__span travel-option__span auto-option__radio" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="travel-one__total_main auto__total_main">
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">Предварительный расчет:</div>
                                            <div className="travel-one__value auto__value">
                                                3000
                                                <span className="travel-option__currency">TJS</span>
                                            </div>
                                            {/* TODO: строка ниже должна появляться, если в основной части поставлена галочка "Рассчитать рассрочку" */}
                                            {/* TODO: 05/09/2022 Обновили формат написания рассрочки */}
                                            <div className="auto__monthly">
                                                В рассрочку <br /><span className="auto__monthly-summ">1200 - 600 - 600 - 600</span>
                                            </div>
                                        </div>
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">На автомобиль</div>
                                            <div className="travel-one__value travel-one__value-mb">
                                                <span className="auto__model-value">Volkswagen Tiguan</span>
                                                <span className="auto__year-value auto__year-text">2020</span>
                                                <span className="auto__year-text">г.в.</span>
                                            </div>
                                        </div>
                                        <div className="auto-option__wrap">
                                            <div className="travel-one__name aside-option__name">Выбранные опции</div>
                                            <p className="aside__option">
                                                Несчастный случай
                                            </p>
                                            <p className="aside__option">
                                                Гражданская ответственность
                                            </p>
                                        </div>
                                        <div className="travel-order__promo promo">
                                            <p className="promo__subtitle">Промокод</p>
                                            <div className="travel-order__promo-row">
                                                <div className="input input__promo">
                                                    <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                    </label>
                                                    <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                </div>
                                                <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                    <img src={promoArrow} alt="" width="16" height="16" />
                                                </a>
                                            </div>
                                        </div>
                                        <button className="travel-one__form-btn step-button">Далее</button>
                                        <button className="travel-option__description-button auto__description-button">
                                            Что входит в полис
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <section className="step step-3">
                                <div className="travel-one__form-items">
                                    <div className="travel-one__form-item">
                                        <div className="travel-one__form-row gender__row">
                                            <div className="input travel-order__input-date input__required">
                                                <label className="input__label">Дата начала страхования<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                            </div>
                                            <div className="travel-currency__wrap">
                                                <div className="travel-currency__box">
                                                    <button className="currency__item currency__item_active">180 дней</button>
                                                    <button className="currency__item">365 дней</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Автомобиль
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="travel-one__form-row travel-step">
                                            <div className="auto__step-info">
                                                <p className="step-info__text auto-info__text">Volkswagen Tiguan;</p>
                                                <p className="step-info__text">2020 <span> г.в.</span></p>
                                            </div>
                                            <a className="change__button">изменить</a>
                                        </div>
                                        {/* TODO: 01/08/2022 Надо уточнять формат номеров VIN, регистрационного знака и свидетельства о регистрации ТС, ВУ
                                        чтобы в плейсхолдеры написать правильную "маску" */}
                                        {/* TODO: 8/8/2022 Добавила галочку для верно заполненного поля
                                        На label у инпутов и селектов должен добавляться класс label-valid,
                                        если из поля данные удалили и оно стало незаполненным - убираем этот класс.
                                        label-valid добавляет паддинг слева у label и ставит псевдоэлемент с галочкой */}
                                        <div className="travel-one__form-row">
                                            <div className="input input__required auto__input">
                                                <label className="input__label">VIN<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="X7LLSRB2HEH728327" />
                                            </div>
                                            <div className="select select__auto-order">
                                                <label className="select__label">Цвет <span className="input_error-text" /></label>
                                                <div className="select__current">--------</div>
                                                {/* В открытом виде для select__list добавить класс select__list_open */}
                                                <div className="select__list">
                                                    <input className="select__search select__search-icon" type="search" />
                                                    <div className="select__item">
                                                        <span className="select__text-value">Черный</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Белый</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Синий</span>
                                                    </div>
                                                    <div className="select__item">
                                                        <span className="select__text-value">Серый</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="travel-one__form-row">
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Государственный номер <span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="1234АА01" />
                                            </div>
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Номер свидетельства о регистрации<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="ФС 123486585" />
                                            </div>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Страхователь
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="input input__required auto__input">
                                            <label className="input__label auto-label__margin">Фамилия Имя Отчество <span className="input_error-text" />
                                            </label>
                                            <input className="input__input" type="text" value="" name="" placeholder="Иванов Иван Иванович" />
                                        </div>
                                        <div className="travel-one__form-row gender__row">
                                            <div className="input travel-order__input-date input__required auto__input">
                                                <label className="input__label">Дата рождения<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                            </div>
                                            <div className="select select__travel-order input__required">
                                                <label className="select__label">Пол<span className="input_error-text" /></label>
                                                <div className="travel-currency__box">
                                                    <button className="currency__item currency__item_active">Мужской</button>
                                                    <button className="currency__item">Женский</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="travel-one__form-row">
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Серия ВУ <span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="AB" />
                                            </div>
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Номер ВУ<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="123486585" />
                                            </div>
                                            <div className="input travel-order__input-date input__required auto__input">
                                                <label className="input__label">Дата выдачи<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                            </div>
                                        </div>
                                        <div className="input input__required auto__input">
                                            <label className="input__label">Адрес проживания <span className="input_error-text" />
                                            </label>
                                            <input className="input__input" type="text" value="" name="" placeholder="Почтовый адрес" />
                                        </div>
                                        <div className="travel-one__form-row">
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Телефон <span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="+992 xxx xx xx" />
                                            </div>
                                            <div className="input input__required auto__input">
                                                <label className="input__label">Электронная почта<span className="input_error-text" />
                                                </label>
                                                <input className="input__input" type="text" value="" name="" placeholder="e-mail" />
                                            </div>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Водители
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                            <div className="travel-order__text checkbox auto-rules__multi">
                                                <label className="travel-order__rules">
                                                    <p className="holder-checkbox__text auto-checkbox__text">
                                                        Мультидрайв
                                                    </p>
                                                    <input className="checkbox__input" type="checkbox" />
                                                    <span className="checkbox__span" />
                                                </label>
                                            </div>
                                        </div>
                                        {/* TODO:: 02/08/2022 Блок auto__drivers-block скрывается, если нажимают галочку Мультидрайв в строке выше,
                                        скрываем добавлением класса drivers-block__hide
                                        Первый блок с водителем содержит кнопку копирования данных страхователя,
                                        остальные уже без нее */}
                                        <div className="auto__drivers-block">
                                            {/* TODO: 07/09/2022 добавила новый класс к обертке и переместила кнопку копрования данных страхователя */}
                                            <div className="driver-card__wrap insurer-card__wrap">
                                                <a className="change__button auto__copy-button driver-button">Скопировать данные страхователя</a>
                                                <div className="input input__required auto__input">
                                                    <label className="input__label auto-label__margin">Фамилия Имя Отчество
                                                        <span className="input_error-text" />
                                                    </label>
                                                    <input className="input__input" type="text" value="" name="" placeholder="Иванов Иван Иванович" />
                                                </div>
                                                <div className="travel-one__form-row gender__row">
                                                    <div className="input travel-order__input-date input__required auto__input">
                                                        <label className="input__label">Дата рождения<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                                    </div>
                                                    <div className="select select__travel-order input__required">
                                                        <label className="select__label">Пол<span className="input_error-text" /></label>
                                                        <div className="travel-currency__box">
                                                            <button className="currency__item currency__item_active">Мужской</button>
                                                            <button className="currency__item">Женский</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travel-one__form-row auto-row__not-margin">
                                                    <div className="input input__required auto__input">
                                                        <label className="input__label">Серия ВУ <span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="text" value="" name="" placeholder="AB" />
                                                    </div>
                                                    <div className="input input__required auto__input">
                                                        <label className="input__label">Номер ВУ<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="text" value="" name="" placeholder="123486585" />
                                                    </div>
                                                    <div className="input travel-order__input-date input__required auto__input">
                                                        <label className="input__label">Дата выдачи<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                                    </div>
                                                </div>
                                                {/* Цифра в блоках с номером увеличивается при добавлении нового водителя */}
                                                <div className="driver-block__number">
                                                    1
                                                </div>
                                            </div>
                                            <div className="driver-card__wrap">
                                                <div className="input input__required auto__input">
                                                    <label className="input__label auto-label__margin">Фамилия Имя Отчество
                                                        <span className="input_error-text" />
                                                    </label>
                                                    <input className="input__input" type="text" value="" name="" placeholder="Иванов Иван Иванович" />
                                                </div>
                                                <div className="travel-one__form-row gender__row">
                                                    <div className="input travel-order__input-date input__required auto__input">
                                                        <label className="input__label">Дата рождения<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                                    </div>
                                                    <div className="select select__travel-order input__required">
                                                        <label className="select__label">Пол<span className="input_error-text" /></label>
                                                        <div className="travel-currency__box">
                                                            <button className="currency__item currency__item_active">Мужской</button>
                                                            <button className="currency__item">Женский</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travel-one__form-row auto-row__not-margin">
                                                    <div className="input input__required auto__input">
                                                        <label className="input__label">Серия ВУ <span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="text" value="" name="" placeholder="AB" />
                                                    </div>
                                                    <div className="input input__required auto__input">
                                                        <label className="input__label">Номер ВУ<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="text" value="" name="" placeholder="123486585" />
                                                    </div>
                                                    <div className="input travel-order__input-date input__required auto__input">
                                                        <label className="input__label">Дата выдачи<span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="date" value="" name="" placeholder="ДД / ММ / ГГГГ" />
                                                    </div>
                                                </div>
                                                {/* Цифра в блоках с номером увеличивается при добавлении нового водителя */}
                                                <div className="driver-block__number">
                                                    2
                                                </div>
                                            </div>
                                            <a className="change__button auto__copy-button auto__add-button ">Добавить водителя</a>
                                        </div>
                                    </div>
                                    <div className="travel-one__total_main auto__total_main">
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">Предварительный расчет:</div>
                                            <div className="travel-one__value auto__value">
                                                3000
                                                <span className="travel-option__currency">TJS</span>
                                            </div>
                                            {/* TODO: строка ниже должна появляться, если в основной части поставлена галочка "Рассчитать рассрочку" */}
                                            {/* TODO: 05/09/2022 Обновили формат написания рассрочки */}
                                            <div className="auto__monthly">
                                                В рассрочку <br /><span className="auto__monthly-summ">1200 - 600 - 600 - 600</span>
                                            </div>
                                        </div>
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">На автомобиль</div>
                                            <div className="travel-one__value travel-one__value-mb">
                                                <span className="auto__model-value">Volkswagen Tiguan</span>
                                                <span className="auto__year-value auto__year-text">2020</span>
                                                <span className="auto__year-text">г.в.</span>
                                            </div>
                                        </div>
                                        <div className="auto-option__wrap">
                                            <div className="travel-one__name aside-option__name">Выбранные опции</div>
                                            <p className="aside__option">
                                                Несчастный случай
                                            </p>
                                            <p className="aside__option">
                                                Гражданская ответственность
                                            </p>
                                        </div>
                                        <div className="auto-option__wrap">
                                            <div className="travel-one__name aside-option__name">Водители</div>
                                            <p className="aside__option">
                                                34, муж;
                                            </p>
                                            <p className="aside__option">
                                                28, жен;
                                            </p>
                                        </div>
                                        <div className="travel-order__promo promo">
                                            <p className="promo__subtitle">Промокод</p>
                                            <div className="travel-order__promo-row">
                                                <div className="input input__promo">
                                                    <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                    </label>
                                                    <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                </div>
                                                <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                    <img src={promoArrow} alt="" width="16" height="16" />
                                                </a>
                                            </div>
                                        </div>
                                        <button className="travel-one__form-btn step-button">Далее</button>
                                        <button className="travel-option__description-button auto__description-button">
                                            Что входит в полис
                                        </button>
                                    </div>
                                </div>
                            </section>
                            <section className="step step-4">
                                <div className="travel-one__form-items">
                                    <div className="travel-one__form-item">
                                        <div className="auto-control__block">
                                            <span className="auto-control__text">Дата начала страхования:</span>
                                            <span className="auto-control__number">01.08.2022,</span>
                                            <span className="auto-control__value">180 дней</span>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Автомобиль
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="auto-control__block">
                                            <span className="auto-control__type">VW Tiguan,</span>
                                            <span className="auto-control__year">2020</span>
                                            <span className="auto-control__text">г.в.</span>
                                        </div>
                                        <div className="auto-control__block">
                                            <span className="auto-control__vin">X7LLSRB2HEH728327,</span>
                                            <span className="auto-control__color">белый,</span>
                                            <span className="auto-control__reg">1234АА01,</span>
                                            <span className="auto-control__pass">ФС 123486585</span>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Страхователь
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="auto-control__block">
                                            <span className="auto-control__name">Иванов Иван Иванович</span>
                                        </div>
                                        <div className="auto__subtitle-box">
                                            <h3 className="auto__subtitle">
                                                Водители
                                            </h3>
                                            <span className="auto__subtitle-decor" />
                                        </div>
                                        <div className="auto-control__block">
                                            <span className="auto-control__name">Иванов Иван Иванович,</span>
                                            <span className="auto-control__age">34,</span>
                                            <span className="auto-control__gender">муж.,</span>
                                            <span className="auto-control__text">стаж: <span className="auto-control__exp">10</span> лет</span>
                                        </div>
                                        <div className="auto-control__block">
                                            <span className="auto-control__name">Иванова Екатерина Анатольевна,</span>
                                            <span className="auto-control__age">28,</span>
                                            <span className="auto-control__gender">жен.,</span>
                                            <span className="auto-control__text">стаж: <span className="auto-control__exp">4</span> года</span>
                                        </div>
                                    </div>
                                    <div className="travel-one__total_main auto__total_main">
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">Предварительный расчет:</div>
                                            <div className="travel-one__value auto__value">
                                                3000
                                                <span className="travel-option__currency">TJS</span>
                                            </div>
                                            {/* TODO: строка ниже должна появляться, если в основной части поставлена галочка "Рассчитать рассрочку" */}
                                            {/* TODO: 05/09/2022 Обновили формат написания рассрочки */}
                                            <div className="auto__monthly">
                                                В рассрочку <br /><span className="auto__monthly-summ">1200 - 600 - 600 - 600</span>
                                            </div>
                                        </div>
                                        <div className="auto-main__block">
                                            <div className="travel-one__name auto__name">На автомобиль</div>
                                            <div className="travel-one__value travel-one__value-mb">
                                                <span className="auto__model-value">Volkswagen Tiguan</span>
                                                <span className="auto__year-value auto__year-text">2020</span>
                                                <span className="auto__year-text">г.в.</span>
                                            </div>
                                        </div>
                                        <div className="auto-option__wrap">
                                            <div className="travel-one__name aside-option__name">Выбранные опции</div>
                                            <p className="aside__option">
                                                Несчастный случай
                                            </p>
                                            <p className="aside__option">
                                                Гражданская ответственность
                                            </p>
                                        </div>
                                        <div className="auto-option__wrap">
                                            <div className="travel-one__name aside-option__name">Водители</div>
                                            <p className="aside__option">
                                                34, муж;
                                            </p>
                                            <p className="aside__option">
                                                28, жен;
                                            </p>
                                        </div>
                                        <div className="travel-order__promo promo">
                                            <p className="promo__subtitle">Промокод</p>
                                            <div className="travel-order__promo-row">
                                                <div className="input input__promo">
                                                    <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                    </label>
                                                    <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                </div>
                                                <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                    <img src={promoArrow} alt="" width="16" height="16" />
                                                </a>
                                            </div>
                                        </div>
                                        <button className="travel-one__form-btn step-button">Оплатить</button>
                                        <button className="travel-option__description-button auto__description-button">
                                            Что входит в полис
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>
                </div>
            </div>
            {/* TODO: Попапы надо вытащить в отдельные компоненты и изменить общий лэйаут,
            поставить компоненты попапов после page__content перед футером
            У page__content стоит ограничение на ширину контента 1280px и из-за этого затемнение страницы у попапа не занимает весь экран
             */}
            {/* TODO: попап для бокового блока, что входит в полис (тексты еще не дали) */}
            <div className="travel-type__hint-popup aside-popup">
                <div className="travel-type__hint-info">
                    <div className="travel-type__hint-head">
                        <h4 className="travel-type__hint-title aside-popup__title">Что входит в полис</h4>
                        <button className="travel-type__hint-close">
                            <span />
                            <span />
                        </button>
                    </div>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item aside-popup__item">Опция 1</li>
                        <li className="travel-type__hint-item aside-popup__item">Опция 2</li>
                        <li className="travel-type__hint-item aside-popup__item">Опция 3</li>
                        <li className="travel-type__hint-item aside-popup__item">Опция 4</li>
                        <li className="travel-type__hint-item aside-popup__item">Опция 5</li>
                        <li className="travel-type__hint-item aside-popup__item">Опция 6</li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
