export default {
    registred: 'Ба қайд гирифта шудааст',
    registerEvent: 'Бақайдгирии ҳодисаи суғуртавӣ',
    fullName: 'Ному насаби Суғурташуда ',
    fullNamePH: 'Ному насаб',
    policyNumber: 'Рақами полис',
    policyNumberPH: 'Рақами полис',
    email: 'E-mail',
    phone: 'Тел.',
    note: 'Ҳолатҳо/рӯйдодҳоро шарҳ диҳед',
    maxFiles: 'На зиёдтар аз 10 файл',
    notePH: 'Шарҳ',
    submit: 'Тасдиқ ',
    ok: 'OK',
}
