import React, {} from 'react'

export default function AutoProduct() {
    return (
        <section className="auto-product">
            <div className="auto-product__container">
                <h2 className="auto-product__title">Спокойствие за рулём онлайн</h2>
                <div className="auto-product__box">
                    <a className="auto-product__card auto-card auto-card-1" href="#">
                        <div className="auto-card__image auto-card__image-1" />
                        <div className="auto-card__wrapper">
                            <div className="auto-card__title">
                                <span className="auto-card__name">Стандарт</span>
                                <span className="auto-card__price">500 TJS</span>
                            </div>
                            <div className="auto-card__desc">
                                <span className="auto-card__txt">
                                    Дорожная ситуация всегда меняется, даже если вы - опытный водитель!
                                    <br />По программе "Стандарт" вы не будете платить за ремонт чужого автомобиля,
                                    пострадавшего в ДТП по вашей вине.
                                    Все расходы покроет ваша страховка.
                                </span>
                                {/* <ul className="auto-card__list">
                                    <li className="auto-card__item">Гражданская ответственность</li>
                                </ul> */}
                            </div>
                            <a className="auto-card__button" href="#">Подробнее</a>
                        </div>
                    </a>
                    <a className="auto-product__card auto-card auto-card-2" href="#">
                        <div className="auto-card__image auto-card__image-2" />
                        <div className="auto-card__wrapper">
                            <div className="auto-card__title">
                                <span className="auto-card__name">Комфорт</span>
                                <span className="auto-card__price">1000 TJS</span>
                            </div>
                            <div className="auto-card__desc">
                                <span className="auto-card__txt">
                                    Непредвиденные события на дороге случаются постоянно!
                                    <br />Программа "Комфорт" - это финансовая "подушка безопасности" при ДТП по вашей вине,
                                    которая обеспечит восстановление вашего и пострадавшего автомобиля.
                                </span>
                                {/* <ul className="auto-card__list">
                                    <li className="auto-card__item">Гражданская ответственность</li>
                                    <li className="auto-card__item">ДТП по вине водителя</li>
                                </ul> */}
                            </div>
                            <a className="auto-card__button" href="#">Подробнее</a>
                        </div>
                    </a>
                    <a className="auto-product__card auto-card auto-card-3" href="#">
                        <div className="auto-card__image auto-card__image-3" />
                        <div className="auto-card__wrapper">
                            <div className="auto-card__title">
                                <span className="auto-card__name">Премиум</span>
                                <span className="auto-card__price">1500 TJS</span>
                            </div>
                            <div className="auto-card__desc">
                                <span className="auto-card__txt">Непредсказуемые происшествия на дороге - обычное дело!
                                    <br />По программе "Премиум", независимо от того, по чьей вине произошло ДТП - вашей или другого водителя,
                                    ремонт автомобилей будет возмещен страховой компанией.
                                </span>
                                {/* <ul className="auto-card__list">
                                    <li className="auto-card__item">Гражданская ответственность</li>
                                    <li className="auto-card__item">ДТП по вине водителя</li>
                                    <li className="auto-card__item">ДТП не по вине водителя</li>
                                </ul> */}
                            </div>
                            <a className="auto-card__button" href="#">Подробнее</a>
                        </div>
                    </a>
                    <a className="auto-product__card auto-card auto-card-4" href="#">
                        <div className="auto-card__image auto-card__image-4" />
                        <div className="auto-card__wrapper">
                            <div className="auto-card__title">
                                <span className="auto-card__name">Индивидуальная</span>
                            </div>
                            <div className="auto-card__desc">
                                <span className="auto-card__txt">
                                    На дороге каждый участник подвержен опасностям - водители, пассажиры, пешеходы!
                                    <br />Программа "Индивидуальный" позволяет вам создать собственную страховую программу,
                                    выбрав все необходимые услуги или подходящие опции.
                                    Полная программа обеспечивает страховую защиту всех участников ДТП и возмещение расходов
                                    на восстановление поврежденных автомобилей с помощью страхового полиса.
                                </span>
                                {/* <span className="auto-card__txt">Защитите свой автомобиль от угона, от ущерба и при ДТП.</span> */}
                                {/* <ul className="auto-card__list">
                                    <li className="auto-card__item">All Inclusive</li>
                                    <li className="auto-card__item">Без участия надзорных органов</li>
                                </ul> */}
                            </div>
                            <a className="auto-card__button" href="#">Подробнее</a>
                        </div>
                    </a>
                </div>
                {/* TODO: Комментарии раскрываются добавлением к auto-comments класса open. В свернутом виде open убираем. */}
                <div className="auto-product__comments auto-comments open">
                    <div className="auto-comments__head">
                        <div className="auto-comments__name">
                            Критерии
                        </div>
                        <button className="auto-comments__btn" />
                    </div>
                    <ul className="auto-comments__list">
                        <li className="auto-comment__item">Вам должно быть не менее 21 года.
                            {/* <span className="hint__icon auto-comment__hint-icon">
                                <span className="hint__icon-simbol">i</span>
                                <span className="hint__content auto-comment__hint-content">
                                    Здесь текст подсказки, что собой представляет опция.
                                </span>
                            </span> */}
                        </li>
                        <li className="auto-comment__item">Минимальный стаж вождения должен составлять не менее 1 года.</li>
                        <li className="auto-comment__item">Мы страхуем автомобили, которые не старше 20 лет.</li>
                        <li className="auto-comment__item">Не предоставляем страхование для такси и юридических лиц.</li>
                        <li className="auto-comment__item">Каждая программа страхования предполагает одну выплату.</li>
                        <li className="auto-comment__item">После первой выплаты полис прекращает действовать только для той программы,
                            по которой произошла выплата. <br />Полис по другим программам страхования остается в силе.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
