import React from 'react'
import prev from '../../static/icons/arrow-left.svg'
import next from '../../static/icons/arrow-right.svg'

export default function Pagination({ countPages, page = 1, setPage }) {
    if (countPages === 1) return null

    return (
        <div className="pagination">
            <div
                onClick={() => {
                    if (page === 1) {
                        return
                    }
                    setPage(page - 1)
                }}
                className="pagination__prev">
                <img src={prev} alt="Prev" />
            </div>

            <div className="pagination__pages">
                <div
                    onClick={() => setPage(1)}
                    className={`pagination__page ${page === 1 ? 'pagination__page_active' : ''}`}>
                    1
                </div>

                {Array(countPages - 2).fill().map((item, index) => (
                    <div
                        onClick={() => setPage(index + 2)}
                        className={`pagination__page ${page === index + 2 ? 'pagination__page_active' : ''}`}>
                        {index + 2}
                    </div>
                ))}

                <div
                    onClick={() => setPage(countPages)}
                    className={`pagination__page ${page === countPages ? 'pagination__page_active' : ''}`}>
                    {countPages}
                </div>
            </div>

            <div
                onClick={() => {
                    if (page === countPages) {
                        return
                    }
                    setPage(page + 1)
                }}
                className="pagination__next">
                <img src={next} alt="Next" />
            </div>
        </div>
    )
}
