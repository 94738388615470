import React, { useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CMS } from '../utils/urls'
import { useLoad } from '../hooks/request'
import Layout from '../components/Layout'
// import BoxesMenu from '../components/boxes/BoxesMenu'
import BoxesProducts from '../components/boxes/BoxesProducts'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import MainPartners from '../components/MainPartners'
import ShortBlog from '../components/ShortBlog'

function Offers() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { state } = useLocation()
    const { t } = useTranslation('box')
    const { response: productsResponse } = useLoad({
        url: CMS.ALL_BOXES_С
            .replace('{page}', '1')
            .replace('{size}', '300'),
    }, [cmsToken, language])
    const products = productsResponse ? productsResponse.data : false
    const activate = useRef(null)
    if (state && state.toActivate) {
        const timer = window.setInterval(() => {
            window.scrollTo({
                top: activate.current.offsetTop - 80,
                left: 0,
                behavior: 'smooth',
            })
            window.clearTimeout(timer)
        }, 1500)
    }

    return (
        <Layout>
            <div className="boxes">
                <div className="boxes__bg">
                    <div className="boxes__container">
                        <h2 className="main-partners__title">{t('offerProducts')}</h2>
                    </div>
                    <BoxesProducts products={products} type="offers" startScroll={() => {
                        if (state && state.toActivate) {
                            window.scrollTo({
                                top: activate.current.offsetTop - 80,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }
                    }} />
                    <MainPartners />
                    <ShortBlog />
                </div>
            </div>
        </Layout>
    )
}

export default Offers
