import React, { createContext, useEffect, useState } from 'react'
import i18n from 'i18next'
import Modal from '../components/base/Modal'

export const Context = createContext()

export default function BaseContextWrapper({ children }) {
    const [modalComponent, setModalComponent] = useState()
    const [modalLoader, setModalLoader] = useState()
    const [category, setCategory] = useState(1)
    const [language, setLanguage] = useState(i18n.language)
    const [utmPartner, setUtmPartner] = useState()
    const [couponFrom, setCouponFrom] = useState('')

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (!utmPartner && params.has('partner')) {
            setUtmPartner(`${params.get('partner')}`)
        }
        if (params.has('coupon')) {
            setCouponFrom(`${params.get('coupon')}`)
        }
    }, [])

    useEffect(() => {
        if (modalComponent) {
            document.querySelector('body').classList.add('body-noscroll')
        } else {
            document.querySelector('body').classList.remove('body-noscroll')
        }
    }, [modalComponent])

    useEffect(() => {
        if (language) localStorage.setItem('language', language)
    }, [language])

    return (
        <Context.Provider value={{
            modalComponent,
            setModalComponent,
            modalLoader,
            setModalLoader,
            category,
            setCategory,
            language,
            setLanguage,
            utmPartner,
            couponFrom,
        }}>
            {children}

            {modalComponent ? (
                <Modal
                    className={modalComponent.props.className}
                    isActive
                    onClose={() => setModalComponent(null)}>
                    {modalComponent}
                </Modal>
            ) : null}
        </Context.Provider>
    )
}
