import React, { useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import PartnerTarget from '../components/PartnerTarget'

export default function Partners() {
    const { cmsToken } = useContext(AuthContext)
    const { state } = useLocation()
    const { id } = useParams()
    const { response } = useLoad({ url: CMS.PARTNER.replace('{id}', state ? state.id : id) }, [cmsToken])

    return (
        <Layout>
            <PartnerTarget partner={response || false} />
        </Layout>
    )
}
