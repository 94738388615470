import React, { useContext, useRef } from 'react'
import { CMS } from '../utils/urls'
import { useLoad } from '../hooks/request'
import Layout from '../components/Layout'
import HomeBannerUr from '../components/HomeBannerUr'
// import BoxesMenuUr from '../components/boxes/BoxesMenuUr'
import BoxesProductsUr from '../components/boxes/BoxesProductsUr'
// import BoxesSeo from '../components/boxes/BoxesSeo'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'

function BoxesUr() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)
    const category = ''
    // const [category, setCategory] = useState('')
    // const { response: cats } = useLoad({ url: CMS.BOX_CATS_UR }, [cmsToken])
    const { response: productsResponse } = useLoad({
        url: CMS.ALL_BOXES_UR
            .replace('{page}', '1')
            .replace('{size}', '300')
            .replace('{category}', category),
    }, [category, cmsToken, language])
    const products = productsResponse ? productsResponse.data : false
    const activate = useRef(null)
    const buy = useRef(null)

    return (
        <Layout>
            <div className="boxes">
                <div className="boxes__bg">
                    <HomeBannerUr />
                    {/* <BoxesMenuUr cats={cats} select={setCategory} selected={category} /> */}
                    <span ref={buy} />
                    <BoxesProductsUr products={products} />
                    <span ref={activate} />
                    {/* <BoxesSeo products={products} /> */}
                </div>
            </div>
        </Layout>
    )
}

export default BoxesUr
