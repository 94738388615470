export default {
    insuranceCase: 'Страховой случай',
    checkPolicy: 'Проверить полис',
    activatePolicy: 'Активировать полис',
    popularProducts: 'Популярные продукты',
    box: 'Коробочный',
    config: 'Конфигурируемый',
    ourPartners: 'Наши партнеры',
    allPartners: 'Все партнеры',
    blogHeader: '#Bima.Блог',
    blogShowAll: 'Смотреть все',
    buy: 'Купить',
    request: 'Оставить заявку',
    more: 'Подробнее',
    corporate: 'корпоративный',
    inputNumber: 'Введите номер полиса',
    checkButton: 'Проверить',
}
