import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Layout from '../components/Layout'
import BoxInsurance from '../components/box/BoxInsurance'
import BoxHead from '../components/box/BoxHead'
import { useModal } from '../hooks/modal'
import BackCall from '../components/modals/BackCall'

function BoxUr() {
    const { slug } = useParams()
    const { t } = useTranslation(['box', 'common', 'home'])
    const [showBackCall] = useModal(
        <BackCall className="popup__policy-check" />,
    )
    const { response: boxes } = useLoad({
        url: CMS.DIRECT_BOX_UR
            .replace('{slug}', slug),
    }, [])

    if (!boxes) {
        return (
            <Layout>
                <div className="box">
                    <div className="box__container">
                        <h3 className="box__title">
                            {t('common:loading')}
                        </h3>
                    </div>
                </div>
            </Layout>
        )
    }

    const box = boxes[0]

    return (
        <Layout>
            <div className="box">
                <BoxHead banner={box} />
                <div className="box__container">
                    <h3 className="box__title">
                        {box.title}
                    </h3>
                    <p className="box__text" dangerouslySetInnerHTML={{ __html: box.fullDesc }} />
                    <div className="main-popular__links" style={{ marginBottom: '5em' }}>
                        <button className="offices__button ur-button"
                            onClick={() => {
                                showBackCall({
                                    note: box.name || box.title,
                                })
                            }}
                            type="button">
                            {t('home:request')}
                        </button>
                    </div>
                </div>
                <BoxInsurance />
            </div>
        </Layout>
    )
}

export default BoxUr
