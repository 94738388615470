export default {
    vacancy: 'Вазифаҳо',
    sendResume: 'Ирсоли маълумотнома',
    sendedResume: 'Ирсоли маълумотнома',
    location: 'Макон',
    salary: 'Маош',
    experience: 'Таҷрибаи зарурӣ',
    dateEnd: 'Муҳлати охирини пешниҳоди маълумотнома',
    experienceOnPosition: 'Таҷрибаи корӣ дар вазифа',
    workSchedule: 'Ҷадвали корӣ',
    candidatesRequirements: 'Талабот ба номзадҳо',
    duties: 'Вазифаҳо',
    conditions: 'Шартҳо',
    fullName: 'Ному насаб',
    fullNamePH: 'Алексей Петрович Иванов',
    phone: 'Тел.',
    email: 'Email',
    message: 'Паём ба Шуъбаи кадрҳо',
    submitResume: 'Посух',
    addFile: 'Замимаи файл',
    addFileText: 'Замимаи файл бо маълумотнома, сертификатҳо ва ғ.(то 5 файл, то 25 мб)',
    title: 'Маълумотнома гузоред',
    subTitle: 'Мо пеш аз ҳама номзадҳоро аз бойгонии худ дида мебароем',
}
