import React from 'react'
import { useHistory } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import Button from '../components/base/Button'
import sprite from '../static/img/svg/svg-sprite.svg'

export default function NotFound() {
    const { t } = useTranslation('common')
    const { push } = useHistory()

    window.setTimeout(() => {
        push('/')
    }, 5000)

    return (
        <Layout>
            <div className={css(styles.body)}>
                <div className="popup-policy-success__icon-no">
                    <svg width="200" height="200">
                        <use xlinkHref={`${sprite}#icon-xl-notfound`} />
                    </svg>
                </div>
                <div className={css(styles.body)}>
                    {/* TODO: 30.01.2023 Здесь должен быть текст "Ошибка" */}
                    <p className={css(styles.string)}>
                        {t('errorText')}
                    </p>
                </div>
                <Button
                    onClick={() => {
                        push('/')
                    }}
                    className="popup-policy-success__button"
                    text="На главную" />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    body: {
        textAlign: 'center',
    },
    image: {
        margin: '5rem auto',
        width: '40rem',
    },
    string: {
        fontSize: '3rem',
    },
})
