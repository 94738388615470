export default {
    vacancy: 'Вакансии',
    sendResume: 'Отправить резюме',
    sendedResume: 'Резюме отправлено',
    location: 'Местоположение',
    salary: 'Заработная плата',
    experience: 'Необходимый опыт',
    dateEnd: 'Крайний срок подачи резюме',
    experienceOnPosition: 'Опыт работы на позиции',
    workSchedule: 'Рабочий график',
    candidatesRequirements: 'Требования к кандидатам',
    duties: 'Обязанности',
    conditions: 'Условия',
    fullName: 'Фамилия, Имя, Отчество',
    fullNamePH: 'Алексей Петрович Иванов',
    phone: 'Телефон',
    email: 'Электронная почта',
    message: 'Сообщение отделу HR',
    submitResume: 'Откликнуться',
    addFile: 'Прикрепить файл',
    addFileText: 'Прикрепите файлы с CV, сертификатами и т.д. (до 5 файлов, до 25 мб)',
    title: 'Оставьте своё резюме',
    subTitle: 'Мы рассматриваем кандидатов из нашего архива в первую очередь',
}
