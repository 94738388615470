import React from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '../base/Button'
import Input from '../base/Input'
import { usePostRequest } from '../../hooks/request'
import { REST } from '../../utils/urls'
import ServerError from '../common/ServerError'
import InputMask from '../base/InputMask'

export default function Login({ showRegistration, onClose }) {
    const login = usePostRequest({ url: REST.LOGIN.AUTH, headers: {} })
    const initialValues = { username: '', password: '' }

    const { t } = useTranslation('common')

    const partnerLink = 'https://tias.bima.tj/'

    async function onSubmit(data) {
        const { success, response } = await login.request({ data })

        if (success) {
            localStorage.setItem('token', JSON.stringify(response))
            onClose()
        }
    }

    return (
        <div className="popup__content">
            <h4 className="popup__title">
                <span className="active">{t('login')} </span>
                {/* /</span> <span className="popup__cursor" onClick={showRegistration}>{t('registerText')} </span> */}
            </h4>
            <div className="popup__partners-block">
                <a className="popup-partners__link" href={partnerLink}>
                    {t('partnerLinkText')}
                </a>
            </div>

            <div style={{ paddingTop: '1em' }}>
                <ServerError error={login.error} />
            </div>

            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                    <div className="popup-login">
                        <Form className="popup-login__form">
                            <InputMask label={t('enterPhone')} name="username" placeholder="(992) 00-000-0000" />
                            <Input name="password" label={t('enterPassword')} type="password" placeholder="********" />
                        </Form>

                        <div className="popup-login__footer">
                            {/* <div className="popup-login__link js-open-popup" data-modal="popup-password"> */}
                            {/*     {t('forgotPassword')} */}
                            {/* </div> */}

                            {false && (
                                <div className="popup-login__link" onClick={showRegistration}>
                                    {t('registerLinkText')}
                                </div>
                            )}
                        </div>

                        <Button
                            onClick={handleSubmit}
                            text={t('enterText')}
                            className="popup-login__button"
                            loading={login.loading} />
                    </div>
                )}
            </Formik>
        </div>
    )
}
