import './static/style.css'
import './static/site.css'
import './static/base-styles.css'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import i18n from 'i18next'
import App from './Router'
import 'moment/locale/ru'
import AuthContextWrapper from './contexts/AuthContext'
import BaseContextWrapper from './contexts/GlobalContext'

moment.locale('ru')

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_TOKEN })

if (localStorage.getItem('language')) i18n.changeLanguage(localStorage.getItem('language'))

ReactDOM.render(
    <AuthContextWrapper>
        <BaseContextWrapper>
            <App />
        </BaseContextWrapper>
    </AuthContextWrapper>,
    document.getElementById('root'),
)
