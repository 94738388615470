import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import AutoContextWrapper from '../contexts/AutoContext'
import { AutoForm } from '../components/auto/AutoForm'

export default function AutoOrder() {
    const { t } = useTranslation('auto')

    return (
        <Layout>
            <AutoContextWrapper>
                <div className="travel-one">
                    <div className="travel-one__bg">
                        <div className="travel-one__container">
                            <h3 className="travel-one__title">
                                {t('header')}
                            </h3>
                            <AutoForm />
                        </div>
                    </div>
                </div>
            </AutoContextWrapper>
        </Layout>
    )
}
