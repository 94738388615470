import React, { Fragment, useContext, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import Input from '../components/base/Input'
import PhoneInput from '../components/base/PhoneInput'
import Select from '../components/base/Select'
import Checkbox from '../components/base/Checkbox'
import Button from '../components/base/Button'
import { useLoad, usePostRequest } from '../hooks/request'
import { CMS, REST } from '../utils/urls'
import Loader from '../components/base/Loader'
import { AuthContext } from '../contexts/AuthContext'
import svg from '../static/img/svg/svg-sprite.svg'
import countries from '../utils/countries.json'
import { DateInput } from '../components/base/DateInput'

const gender = [
    {
        value: 'f',
        label: 'Женский',
    },
    {
        value: 'm',
        label: 'Мужской',
    },
]

function BoxOrder() {
    const { slug } = useParams()
    const { t } = useTranslation(['box', 'common'])
    const formHead = useRef()

    const cmsToken = useContext(AuthContext)

    const boxes = useLoad({
        url: CMS.DIRECT_BOX.replace('{slug}', slug),
    }, [cmsToken])

    const [couponError, setCouponError] = useState(false)
    const [boxPrice, setBoxPrice] = useState(false)
    const [couponValue, setCouponValue] = useState(false)
    const [loading, setLoading] = useState(false)
    const boxAdd = usePostRequest({ url: REST.BOX.ADD })
    const payment = usePostRequest({ url: REST.PAYMENT })
    const boxesCoupon = usePostRequest({ url: REST.BOX.COUPON })

    if (loading || !boxes.response) {
        return (
            <Layout>
                <div className="box">
                    <div className="box__container">
                        <Loader centered />
                    </div>
                </div>
            </Layout>
        )
    }

    const box = boxes.response[0]

    const testCoupon = async (coupon, setErrors) => {
        setCouponError(false)
        if (coupon !== '') {
            const { success, response, error } = await boxesCoupon.request({
                data: {
                    product_code: box.productCode,
                    coupon_code: coupon,
                },
            })

            if (success) {
                // console.log(response)
                setCouponValue(response.valueAmount)
                setBoxPrice(response.sumPrem)
            } else {
                setErrors({ coupon: true })
                setCouponValue(false)
                setBoxPrice(false)
                setCouponError(error.data.couponCode ? error.data.couponCode[0] : error.data.message)
                // console.log(error)
                return { coupon: true }
            }
        }

        return {}
    }

    const initialValues = {
        holderFirstName: '',
        holderLastName: '',
        holderSecondName: '',
        holderGender: '',
        holderCountry: 'Tajikistan',
        holderBirthDate: '',
        holderEmail: '',
        holderPhone: '',
        holderDocSeries: '',
        holderDocNumber: '',
        holderIssueDate: '',
        holderIssueInstitution: '',
        coupon: '',
        agree: false,
    }

    const onSubmit = async (values, { setErrors, setFieldValue }) => {
        let data = {
            last_name: values.holderLastName,
            first_name: values.holderFirstName,
            phone_number: values.holderPhone,
            email: values.holderEmail,
            gender: values.holderGender === 'f' ? 1 : 0,
            patronymic: values.holderSecondName,
            birth_date: values.holderBirthDate,
            resident: values.holderCountry,
            series: values.holderDocSeries,
            doc_number: values.holderDocNumber,
            issue_date: values.holderIssueDate,
            issue_institution: values.holderIssueInstitution,
            product_code: box.productCode,
        }
        testCoupon(values.coupon, setErrors, setFieldValue)

        if (values.coupon) data.coupon = values.coupon
        setLoading(true)
        let answer = await boxAdd.request({ data })
        if (answer.success) {
            const { response } = answer
            data = { policy_id: response.id, policy_type: 'box' }
            answer = await payment.request({ data })
            if (answer.success) window.location = answer.response.redirectUrl
            setLoading(false)
        } else {
            const err = {}
            Object.keys(answer.error.data).forEach((val) => {
                err[val] = true
            })
            setErrors(err)
        }

        setLoading(false)
        return values
    }

    const validate = (values) => {
        const errors = {}
        setCouponError(false)
        const keys = [
            'holderFirstName',
            'holderLastName',
            'holderIssueDate',
            'holderIssueInstitution',
            'holderGender',
            'holderCountry',
            'holderBirthDate',
        ]

        keys.forEach((item) => {
            switch (typeof values[item]) {
            case 'string':
                if (values[item].length === 0 && item !== 'coupon') errors[item] = true
                break
            case 'boolean':
                if (!values[item]) {
                    errors[item] = true
                }
                break
            default:
                errors[item] = false
            }
        })

        window.scrollTo({
            top: formHead.current.offsetTop - 260,
            left: 0,
            behavior: 'smooth',
        })

        return errors
    }

    return (
        <Layout>
            <div className="box-order__container">
                <h1 className="box-order__title" ref={formHead}>
                    {t('boxProductBuy')} - { box.title }
                </h1>
                <Formik initialValues={initialValues} validateOnBlur={false} validateOnChange={false}
                    onSubmit={onSubmit} validate={validate}>
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue, setErrors,
                    }) => (
                        <Form className="box-order">
                            <div className="box-order__row">
                                <Input name="holderLastName"
                                    label={t('holderLastName')} required
                                    error={errors.holderLastName}
                                    placeholder={t('holderLastNamePH')} />
                                <Input name="holderFirstName"
                                    label={t('holderFirstName')} required
                                    error={errors.holderFirstName}
                                    placeholder={t('holderFirstNamePH')} />
                            </div>
                            <div className="box-order__row">
                                <Input name="holderSecondName"
                                    label={t('holderSecondName')}
                                    error={errors.holderSecondName}
                                    placeholder={t('holderSecondNamePH')} />
                                <DateInput label={t('holderBirthDate')}
                                    name="holderBirthDate" required
                                    error={errors.holderBirthDate}
                                    className="input travel-order__input-date"
                                />
                            </div>
                            <div className="box-order__row">
                                <PhoneInput name="holderPhone" error={errors.holderPhone} required
                                    label={t('holderPhone')} placeholder="+992 00-000-00-00" />

                                <Input
                                    name="holderEmail" error={errors.holderEmail}
                                    label={t('holderEmail')} placeholder="mail@mail.ru" />
                            </div>
                            <div className="box-order__row">
                                <Input name="holderDocSeries" error={errors.holderDocSeries} required
                                    label={t('holderDocSeries')} placeholder="A" />

                                <Input
                                    name="holderDocNumber" error={errors.holderDocNumber} required
                                    label={t('holderDocNumber')} placeholder="1234567890" />
                            </div>
                            <div className="box-order__row">
                                <Input name="holderIssueInstitution" error={errors.holderIssueInstitution} required
                                    label={t('holderIssueInstitution')} placeholder="ОВД" />

                                <DateInput
                                    name="holderIssueDate" error={errors.holderDateTo} required
                                    className="input travel-order__input-date"
                                    label={t('holderIssueDate')} />
                            </div>
                            <div className="box-order__row">
                                <Select
                                    name="holderGender"
                                    className="select input input__required"
                                    defValue="----"
                                    required
                                    error={errors.holderGender}
                                    options={gender}
                                    label={t('holderGender')} />

                                <Select
                                    name="holderCountry"
                                    className="select input input__required"
                                    defValue="----"
                                    required
                                    error={errors.holderCountry}
                                    options={countries}
                                    label={t('holderCountry')} />
                            </div>
                            <div className="travel-order__promo">
                                <h3 className="travel-order__subtitle">{t('coupon')}</h3>

                                <div className="travel-order__promo-row">
                                    <div className={cn('input', { input_error: couponError })}>
                                        <label className="input__label">
                                            <span className="input_error-text">
                                                { couponError || t('box:couponError') }
                                            </span>
                                        </label>
                                        <Input
                                            name="coupon"
                                            type="text"
                                            placeholder={t('coupon')}
                                            error={couponError}
                                            onChange={() => setBoxPrice(false)}
                                        />
                                    </div>
                                    <Button className="travel-order__btn" text={t('couponApply')}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            testCoupon(values.coupon, setErrors, setFieldValue)
                                        }} />
                                </div>
                            </div>

                            <div style={{ padding: '10px 5px', margin: '30px 25px' }}>
                                <div className="box__calc-string">
                                    <svg width="16" height="16">
                                        <use xlinkHref={`${svg}#icon-calc-1`} />
                                    </svg>
                                    <div className="box__calc-row">
                                        {t('insuranceCost')}
                                    </div>
                                </div>
                                <div className="box__calc-total">
                                    { box.price } TJS
                                </div>
                            </div>

                            { boxPrice && (
                                <div style={{ padding: '10px 5px', margin: '30px 25px' }}>
                                    <div className="box__calc-string">
                                        <svg width="16" height="16">
                                            <use xlinkHref={`${svg}#icon-calc-1`} />
                                        </svg>
                                        <div className="box__calc-row">
                                            {t('insuranceCostCoupon')}
                                        </div>
                                    </div>
                                    <div className="box__calc-total">
                                        { boxPrice } TJS
                                    </div>
                                </div>
                            )}

                            {
                                couponValue && couponValue !== 0 ? (
                                    <>
                                        <div className="box-order__row" style={{ fontSize: '12pt', fontWidth: 'bold' }}>
                                            {t('insuranceCostActiveCoupon')}{couponValue} TJS
                                        </div>
                                        <hr />
                                    </>
                                ) : null
                            }

                            <div className="box-order__footer">
                                <Checkbox
                                    name="agree"
                                    className="travel-order__checkbox"
                                    error={errors.agree && touched.agree}
                                    label={(
                                        <Fragment>Я согласен(-на) с условиями&nbsp;
                                            <a
                                                className="auto-insurance__rules-link"
                                                href="/rules"
                                                target="_blank"
                                                rel="noreferrer"> обработки персональных данных
                                            </a>&nbsp;и&nbsp;
                                            <a
                                                className="auto-insurance__rules-link"
                                                href="/politics"
                                                target="_blank"
                                                rel="noreferrer"> правилами пользования сайтом
                                            </a>
                                        </Fragment>
                                    )} />

                                <Button
                                    loading={false}
                                    type="submit"
                                    disabled={!values.agree}
                                    className="travel-order__btn js-open-popup"
                                    text={t('toBuy')} />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Layout>
    )
}

export default BoxOrder
