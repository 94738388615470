import React from 'react'
import { useTranslation } from 'react-i18next'

function MainServices({ services, buy, activate }) {
    const { t } = useTranslation('common')

    return (
        <section className="main-services">
            <div className="main-services__container">
                <div className="main-services__grid">
                    {services.map(({ title, onClick, image }, i) => (
                        <button
                            key={title}
                            onClick={(e) => { onClick(e, i === 0 ? buy : activate) }}
                            className="main-services__button"
                            type="button">
                            <img className="main-services__icon" src={image} alt="Icon" />
                            <p className="main-services__text">{t(title)}</p>
                        </button>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default MainServices
