import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { VzrContext } from '../../contexts/VzrContext'

export function VzrHeadStep1Info() {
    const { t } = useTranslation('travel')
    const { values, validateForm, setFieldValue } = useFormikContext()
    const { setStep, step, step3el } = useContext(VzrContext)

    return (
        <div className="travel-one__form-row travel-step">
            <div className="travel__step-info">
                <p className="step-info__text step-info__text-accent">
                    {
                        values.country.map((item) => `${item.label}; `)
                    }
                </p>
                <p className="step-info__text">
                    {t('totalDays', { count: values.dayCount })};
                </p>
                <p className="step-info__text">
                    {t('insuredCount', { count: values.las.length })};
                </p>
            </div>
            <a className="change__button"
                onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setFieldValue('shortValidate', true)
                    window.setTimeout(() => {
                        if (step > 1) {
                            validateForm().then((res) => {
                                setFieldValue('shortValidate', false)
                                if (Object.values(res).length === 0) {
                                    setStep(1)
                                } else if (step3el) {
                                    window.scrollTo({
                                        top: step3el.current[res.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }
                            }).catch(() => setFieldValue('shortValidate', false))
                        }
                    }, 1000)
                }}>
                {t('changeButton')}
            </a>
        </div>
    )
}
