import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { docTypes, filterPassport, gender, hints } from '../../helpers/travel'
import Select from '../base/Select'
import FormTotal from './FormTotal'
import { VzrHeadStep1Info } from './VzrHeadStep1Info'
import Input from '../base/Input'
import { DateInput } from '../base/DateInput'
import SelectSwitch from '../common/SelectSwitch'
import citizenship from '../../utils/citizenship.json'
import Checkbox from '../base/Checkbox'
import InputMask from '../base/InputMask'
import { VzrContext } from '../../contexts/VzrContext'

export default function FormStep3() {
    const { t } = useTranslation('travel')
    const { setFieldValue, errors, values, handleSubmit, validateForm } = useFormikContext()
    const { setStep3el, canCopy, setCanCopy } = useContext(VzrContext)

    const minDate = (date, i) => moment((i >= 0 ? values.las[i][date] : values[date]) || moment().add(-80, 'year')).format('YYYY-MM-DD')
    useEffect(() => {
        if (moment(values.holderBirthday).isAfter(moment(values.holderIssueDate))) {
            setFieldValue('holderIssueDate', values.holderBirthday)
        }
    }, [values.holderBirthday])

    useEffect(() => {
        values.las.forEach((item, i) => {
            if (moment(item.laBirthday).isAfter(moment(item.issueDate))) {
                setFieldValue(`las[${i}]issueDate`, item.laBirthday)
            }
        })
    }, [values.las])

    const timer = useRef()
    const itemsRef = useRef({ })
    useEffect(() => validateForm(), [])
    useEffect(() => setStep3el(itemsRef), [])

    return (
        <section className="step step-3"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    e.stopPropagation()
                    e.preventDefault()
                }
            }}
            onKeyUp={(e) => {
                if (e.keyCode === 13) {
                    e.stopPropagation()
                    e.preventDefault()
                }
            }}
        >
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <VzrHeadStep1Info />
                    <h3 className="travel-order__subtitle subtitle">
                        {t('insured')}
                    </h3>
                    <Input label={t('holderFullName')} refLinlk={(el) => { itemsRef.current.holderFullName = el }}
                        hint={hints.fio} name="holderFullName"
                        placeholder={t('holderFullNamePH')}
                        error={errors.holderFullName || errors.holderFirstname || errors.holderLastname}
                        onChange={(e) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                const str = e.target.value.replace(/[ ]+/gm, ' ')
                                setFieldValue('holderFullName', str)
                                const arr = str.split(' ')
                                setFieldValue('holderLastname', arr[0] || '')
                                setFieldValue('holderFirstname', arr[1] || '')
                                arr.splice(0, 2)
                                setFieldValue('holderPatronymic', arr.join(' ') || '')
                            }, 300)
                        }}
                    />
                    <div className="travel-order__row">
                        <InputMask label={t('holderPhone')} hint={hints.phoneM}
                            refLinlk={(el) => { itemsRef.current.holderPhone = el }}
                            name="holderPhone" placeholder="(992) 00-000-0000"
                            error={errors.holderPhone}
                        />
                        <Input label={t('holderEmail')} hint={hints.email}
                            refLinlk={(el) => { itemsRef.current.holderEmail = el }}
                            name="holderEmail" placeholder="mail@mail.ru"
                            error={errors.holderEmail}
                        />
                    </div>
                    <div className="travel-order__row gender__row">
                        <DateInput
                            label={t('holderBirthday')}
                            name="holderBirthday"
                            inputRef={(el) => { itemsRef.current.holderBirthday = el }}
                            error={errors.holderBirthday}
                            onChange={({ target: { value } }) => {
                                setFieldValue('holderBirthday', value)
                            }}
                            max={moment().add(-18, 'year').format('YYYY-MM-DD')}
                            min={moment().add(-80, 'year').format('YYYY-MM-DD')}
                            className="input travel-order__input-date"
                        />
                        <SelectSwitch options={gender} name="holderGender" label={t('holderGender')}
                            refLinlk={(el) => { itemsRef.current.holderGender = el }}
                            className="select select__travel-order input__required" />
                    </div>
                    <div className="travel-order__row">
                        <Input label={t('holderDocSeries')}
                            name="holderDocSeries"
                            placeholder="АА"
                            refLinlk={(el) => { itemsRef.current.holderDocSeries = el }}
                            error={errors.holderDocSeries}
                            onChange={({ target: { value } }) => {
                                setFieldValue('holderSeries', value)
                            }}
                        />
                        <Input label={t('holderDocNumber')}
                            name="holderDocNumber"
                            placeholder="1234567890"
                            refLinlk={(el) => { itemsRef.current.holderDocNumber = el }}
                            error={errors.holderDocNumber}
                            onChange={({ target: { value } }) => {
                                setFieldValue('holderDocNumber', value)
                            }}
                        />
                    </div>
                    <div className="travel-order__row">
                        <Select
                            label={t('holderDocType')}
                            name="holderDocType"
                            error={errors.holderDocType}
                            options={docTypes}
                            inputRef={(el) => { itemsRef.current.holderDocType = el }}
                            defValue="--------"
                            onChange={(value) => {
                                setFieldValue('holderDocType', value)
                            }}
                            className="select"
                        />
                        <DateInput
                            label={t('holderIssueDate')}
                            name="holderIssueDate"
                            inputRef={(el) => { itemsRef.current.holderIssueDate = el }}
                            error={errors.holderIssueDate}
                            max={moment().add(1, 'day').format('YYYY-MM-DD')}
                            min={minDate('holderBirthday')}
                            onChange={({ target: { value } }) => {
                                setFieldValue('holderIssueDate', value)
                            }}
                            className="input select select__travel-order input__required"
                        />
                    </div>
                    <Input label={t('holderIssueInstitution')}
                        name="holderIssueInstitution"
                        error={errors.holderIssueInstitution}
                        refLinlk={(el) => { itemsRef.current.holderAddressReg = el }}
                    />
                    <Input label={t('holderAddressReg')}
                        name="holderAddressReg" placeholder={t('holderAddressPH')}
                        error={errors.holderAddressReg}
                        refLinlk={(el) => { itemsRef.current.holderAddressReg = el }}
                        onChange={({ target: { value } }) => {
                            setFieldValue('holderAddressReg', value)
                            if (values.holderAddressEqual) {
                                setFieldValue('holderAddressFact', value)
                                setFieldValue('holderAddressFact', value)
                            }
                        }}
                    />
                    {false && (
                        <div className="travel-order__text checkbox travel-order__checkbox holder-checkbox">
                            <label className="travel-order__rules">
                                <p className="holder-checkbox__text">{t('holderAsInsured')}</p>
                                <input className="checkbox__input" type="checkbox" checked={values.holderAsInsured} />
                                <span className="checkbox__span" />
                            </label>
                        </div>
                    )}
                    {
                        values.las.map(({ uid }, i) => (
                            <Fragment key={uid}>
                                <div className="insured-person__title">
                                    <h3 className="travel-order__subtitle subtitle">{t('insuredInfo', { count: i + 1 })}</h3>
                                    {canCopy && (
                                        <a className="change__button copy__button"
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                if (canCopy) {
                                                    const data = {
                                                        holderFullName: `las[${i}]laFullname`,
                                                        holderLastname: `las[${i}]laLastname`,
                                                        holderFirstname: `las[${i}]laFirstname`,
                                                        holderPatronymic: `las[${i}]laPatronic`,
                                                        holderBirthday: `las[${i}]laBirthday`,
                                                        holderIssueInstitution: `las[${i}]issueInstitution`,
                                                        holderGender: `las[${i}]gender`,
                                                    }

                                                    if (filterPassport().map(({ value }) => value).indexOf(values.holderDocType) !== -1) {
                                                        data.holderDocSeries = `las[${i}]series`
                                                        data.holderDocNumber = `las[${i}]docNumber`
                                                        data.holderDocType = `las[${i}]docType`
                                                        data.holderIssueDate = `las[${i}]issueDate`
                                                    }

                                                    Object.keys(data).forEach((item) => {
                                                        setFieldValue(data[item], values[item])
                                                    })

                                                    setCanCopy(false)

                                                    window.setTimeout(() => validateForm(), 500)
                                                }
                                            }}
                                        >
                                            {t('copyHolderData')}
                                        </a>
                                    )}
                                </div>
                                <Input label={t('holderFullName')}
                                    hint={hints.fio} name={`las[${i}]laFullname`}
                                    placeholder={t('holderFullNamePH')}
                                    refLinlk={(el) => { itemsRef.current[`las[${i}]laFullname`] = el }}
                                    error={errors[`las[${i}]laFullname`] || errors[`las[${i}]laFirstname`] || errors[`las[${i}]laLastname`]}
                                    onChange={(e) => {
                                        clearTimeout(timer.current)
                                        timer.current = setTimeout(() => {
                                            const str = e.target.value.replace(/[ ]+/gm, ' ')
                                            setFieldValue(`las[${i}]laFullname`, str)
                                            const arr = str.split(' ')
                                            setFieldValue(`las[${i}]laLastname`, arr[0] || '')
                                            setFieldValue(`las[${i}]laFirstname`, arr[1] || '')
                                            arr.splice(0, 2)
                                            setFieldValue(`las[${i}]laPatronic`, arr.join(' ') || '')
                                        }, 300)
                                        window.setTimeout(() => handleSubmit(), 500)
                                    }}
                                />
                                <Select
                                    name={`las[${i}]laCitizenship`}
                                    error={errors[`las[${i}]laCitizenship`]}
                                    className="select input input__required"
                                    refLinlk={(el) => { itemsRef.current[`las[${i}]laCitizenship`] = el }}
                                    options={citizenship} required filter
                                    label={t('laCitizenship')} />
                                <div className="travel-order__row">
                                    <DateInput label={t('holderBirthday')}
                                        name={`las[${i}]laBirthday`}
                                        refLink={(el) => { itemsRef.current[`las[${i}]laBirthday`] = el }}
                                        error={errors[`las[${i}]laBirthday`] || !values.las[i].laBirthdayTouch}
                                        min={moment().add(-80, 'year').format('YYYY-MM-DD')}
                                        max={moment().format('YYYY-MM-DD')}
                                        onTouch={() => setFieldValue(`las[${i}]laBirthdayTouch`, true)}
                                        onChange={(e) => {
                                            setFieldValue(`las[${i}]laBirthday`, e.target.value)
                                            window.setTimeout(() => {
                                                setFieldValue('shortValidate', true)
                                                validateForm().then((res) => {
                                                    if (!res.haveErrors) {
                                                        handleSubmit()
                                                        window.setTimeout(() => setFieldValue('shortValidate', false), 300)
                                                    } else {
                                                        window.setTimeout(() => setFieldValue('shortValidate', false), 300)
                                                        window.scrollTo({
                                                            top: itemsRef.current[res.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                                            left: 0,
                                                            behavior: 'smooth',
                                                        })
                                                    }
                                                })
                                            }, 500)
                                        }}
                                        className="input travel-order__input-date"
                                    />
                                    <SelectSwitch options={gender} name={`las[${i}]gender`} label={t('holderGender')}
                                        className="select select__travel-order input__required" value={values.las[i].gender}
                                        refLinlk={(el) => { itemsRef.current[`las[${i}]gender`] = el }}
                                        onChange={(value) => setFieldValue(`las[${i}]gender`, value)}
                                    />
                                </div>
                                <div className="travel-order__row">
                                    <Select
                                        label={t('holderDocType')}
                                        name={`las[${i}]docType`}
                                        error={errors[`las[${i}]docType`]}
                                        options={filterPassport()}
                                        inputRef={(el) => { itemsRef.current[`las[${i}]docType`] = el }}
                                        defValue="--------"
                                        onChange={(value) => {
                                            setFieldValue(`las[${i}]docType`, value)
                                        }}
                                        className="select"
                                    />
                                    <DateInput
                                        label={t('holderIssueDate')}
                                        name={`las[${i}]issueDate`}
                                        refLink={(el) => { itemsRef.current[`las[${i}]issueDate`] = el }}
                                        inputRef={(el) => { itemsRef.current[`las[${i}]issueDate`] = el }}
                                        error={errors[`las[${i}]issueDate`]}
                                        max={moment().format('YYYY-MM-DD')}
                                        min={minDate('laBirthday', i)}
                                        onChange={({ target: { value } }) => {
                                            setFieldValue(`las[${i}]issueDate`, value)
                                        }}
                                        className="input select select__travel-order input__required"
                                    />
                                </div>
                                <div className="travel-order__row">
                                    <Input label={t('holderDocSeries')}
                                        name={`las[${i}]series`}
                                        refLinlk={(el) => { itemsRef.current[`las[${i}]series`] = el }}
                                        placeholder="АА"
                                        error={errors[`las[${i}]series`]}
                                        onChange={({ target: { value } }) => {
                                            setFieldValue(`las[${i}]series`, value)
                                        }}
                                    />
                                    <Input label={t('holderDocNumber')}
                                        name={`las[${i}]docNumber`}
                                        placeholder="1234567890"
                                        refLinlk={(el) => { itemsRef.current[`las[${i}]docNumber`] = el }}
                                        error={errors[`las[${i}]docNumber`]}
                                        onChange={({ target: { value } }) => {
                                            setFieldValue(`las[${i}]docNumber`, value)
                                        }}
                                    />
                                </div>
                                <Input label={t('holderIssueInstitution')}
                                    name={`las[${i}]issueInstitution`}
                                    error={errors[`las[${i}]issueInstitution`]}
                                    refLinlk={(el) => { itemsRef.current.issueInstitution = el }}
                                />
                                {
                                    values.las.length > 1 && (
                                        <a className="change__button auto__copy-button auto__add-button"
                                            style={{ paddingTop: '20px' }}
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                setFieldValue('las', values.las.filter((_, index) => index !== i))

                                                window.setTimeout(() => {
                                                    setFieldValue('shortValidate', true)
                                                    handleSubmit()
                                                }, 500)
                                            }}
                                        >
                                            {t('lasRemove')}
                                        </a>
                                    )
                                }

                            </Fragment>
                        ))
                    }
                    {
                        (values.las.length < 5) && (
                            <a className="change__button auto__copy-button auto__add-button"
                                style={{ paddingTop: '20px' }}
                                onClick={async (e) => {
                                    e.preventDefault()
                                    const data = {
                                        uid: values.las[(values.las.length - 1)].uid + 1,
                                        laLastname: '',
                                        laFirstname: '',
                                        laPatronic: '',
                                        laBirthday: '',
                                        laCitizenship: 'TJ',
                                        issueDate: null,
                                        series: '',
                                        docType: '',
                                        docNumber: '',
                                        dateTo: '',
                                        gender: '',
                                    }

                                    const las = [...values.las]
                                    las.push(data)
                                    setFieldValue('las', las)

                                    window.setTimeout(() => validateForm(), 500)
                                }}
                            >
                                {t('lasAdd')}
                            </a>
                        )
                    }
                    {
                        values.sequence.indexOf(10) !== -1
                        && (
                            <>
                                <div className="insured-person__title">
                                    <h3 className="travel-order__subtitle subtitle">{t('optionsHouse')}</h3>
                                </div>
                                <Input label={t('optionsHouseBottom')} placeholder={t('optionsHousePH')}
                                    hint={t('optionsHouseHint')} name="assuredFlatAddress"
                                    refLinlk={(el) => { itemsRef.current.assuredFlatAddress = el }}
                                    error={errors.assuredFlatAddress}
                                />
                            </>
                        )
                    }
                    <Checkbox
                        name="agree"
                        className="travel-order__text checkbox rules__check"
                        error={errors.agree}
                        label={(
                            <>
                                {t('common:agreement1')}&nbsp;
                                <a className="auto-insurance__rules-link"
                                    href="/rules"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement2')}
                                </a>
                                &nbsp;{t('common:agreement3')}&nbsp;
                                <a
                                    className="auto-insurance__rules-link"
                                    href="/politics"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement4')}
                                </a>
                            </>
                        )} />
                    {
                        errors.haveErrors && (
                            <div className="warning-fields">
                                {t('reqFieldsHead')}&nbsp;
                                <button className="warning-fields__button" onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    window.scrollTo({
                                        top: itemsRef.current[errors.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}>
                                    {t('reqFieldsText')}
                                </button>
                            </div>
                        )
                    }
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
