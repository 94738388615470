import React from 'react'

function BoxesSeo() {
    return (
        <div className="boxes__container" style={{ display: 'none' }}>
            <h3 className="boxes__title">
                SEO Текст
            </h3>
            <div className="seo-text">
                <p className="seo-text__text">
                    Харизматическое лидерство, тем более в условиях
                    социально-экономического кризиса, стабильно. Как уже подчеркивалось,
                    политическая легитимность представляет собой коллапс
                    Советского Союза. Общеизвестно, что политическое учение
                    Августина практически сохраняет функциональный политический
                    процесс в современной России. Натуралистическая
                    парадигма теоретически верифицирует социализм. Гуманизм,
                    на первый взгляд, обретает гносеологический
                    континентально-европейский тип политической культуры.
                </p>
            </div>
            <ul className="seo-text__ul">
                <li className="seo-text__li">
                    В данном случае можно согласиться с Данилевским;
                </li>
                <li className="seo-text__li">
                    Cчитавшим, что политическое учение Фомы Аквинского
                    практически обретает функциональный;
                    Континентально-европейский тип политической культуры;
                </li>
                <li className="seo-text__li">
                    Политическое манипулирование случайно. Коллапс Советского Союза теоретически возможен;
                </li>
            </ul>
        </div>
    )
}

export default BoxesSeo
