/* eslint-disable max-len */

export default {
    header: 'Registration of insurance',
    headStep1: 'Data',
    headStep2: 'Programs',
    headStep3: 'Options',
    headStep4: 'Decor',
    headStep5: 'Payment',
    carMark: 'Brand',
    carModel: 'Model',
    carBodyType: 'Body type',
    carBodyTypeLabel: {
        0: 'Sedan',
        1: 'Hatchback',
        2: 'Station wagon',
        3: 'SUV',
        4: 'Crossover',
        5: 'Pickup',
        6: 'Minivan',
        7: 'Van',
        8: 'Convertible',
        9: 'Coupe',
    },
    carIssueYear: 'Year of issue',
    policeSumInsured: 'Sum insured',
    calculateInstallments: 'Calculate installments',
    insuranceCost: 'Estimation:',
    openInfoPopup: 'What is included in the policy?',
    inInstallments: 'In installments',
    toCar: 'per car',
    toCarYear: 'year of age',
    onMonth: 'TJS per month',
    promoCode: 'promo code',
    errorCoupon: 'Error entering promo code',
    coupon: 'Enter promo code',
    nextStep: 'Further',
    optionsMore: 'Additional options',
    compensation: 'Amount of compensation',
    damageOption: 'Damage',
    tfapOption: 'PDTL',
    stealingOption: 'Theft',
    noTraficPoliceOption: 'Without traffic police certificates',
    accSumAssured: 'Accident',
    beginDate: 'the date of the beginning',
    automobile: 'Automobile',
    insured: 'Policyholder',
    holderFullName: 'Full Name',
    holderFullNamePH: 'Alexey Petrovich Ivanov',
    holderAddress: 'Address of residence',
    holderAddressPH: 'Address of residence',
    holderPhone: 'Telephone',
    holderEmail: 'Email',
    drivers: 'Drivers',
    multidrive: 'Multidrive',
    copyHolderData: 'Copy information about the insured',
    addDriver: 'Add driver',
    deleteDriver: 'Delete driver',
    vin: 'VIN',
    color: 'Color',
    gosNomer: 'State number',
    registrSvidet: 'Registration certificate',
    holderDocType: 'Document Type',
    holderDocSeries: 'Passport Series',
    holderDocNumber: 'Passport ID',
    driverDocSeries: 'VU series',
    driverDocNumber: 'VU number',
    driverIssueDate: 'Issue date',
    holderIssueDate: 'Passport issue date',
    citizenship: 'Citizenship',
    holderVuSeries: 'VU series',
    holderVuNumber: 'VU number',
    holderVuIssueDate: 'Date of issue of VU',
    holderBirthday: 'Date of Birth',
    holderGender: 'Floor',
    changeInfo: 'Change',
    beginDateIssued: 'Insurance start date',
    durationIssued: '{{duration}} days',
    holderAgree: 'To registration',
    driving_interval: '(0)[{{count}} years]; (1)[{{count}} year]; (2-4)[{{count}} years]; (5-inf)[{{count}} years]',
    additionalOptions: 'Selected options',
    reqFieldsHead: 'Fill in',
    reqFieldsText: 'required fields',
    damageOptionHint: 'BIMA will indemnify for damage as a result of damage or destruction of the Vehicle, its individual parts, assemblies, assemblies as a result of fire, explosion, natural phenomena, falling or ingestion of foreign objects or damage by animals.',
    stealingOptionHint: 'BIMA compensates for the costs in case of damage or loss of the car as a result of theft (theft, robbery, robbery).',
    tfapOptionHint: 'BIMA compensates for expenses incurred in the event of damage to the Vehicle or destruction of the Vehicle as a result of unlawful actions of third parties and the risk of loss of individual parts, assemblies, assemblies of the Vehicle as a result of their theft.',
    noTraficPoliceOptionHint: 'BIMA will reimburse the costs that will be needed to restore a car that has been damaged in an accident without a traffic police certificate.',
    accSumAssuredHint: 'BIMA will indemnify material damage resulting from injury, permanent disability (disability) or death of passengers and/or driver of the insured vehicle.',
    annually: 'annually',
    monthly: 'monthly',
    taxi: 'Can\'t insure taxi',
    SCI_P1name: 'Standard',
    SCI_P2name: 'Comfort',
    SCI_P3name: 'Premium',
    SCI_P4name: 'Individual',
    SCI_P1description: 'The traffic situation is always changing, even if you are an experienced driver! Under the "Standard" program, you will not pay for the repair of someone else\'s car that was damaged in an accident through your fault. All costs will be covered by your insurance.',
    SCI_P2description: 'foreseen events on the road happen all the time! The "Comfort" program is a financial "airbag" in case of an accident due to your fault, which will ensure the restoration of your and the injured car.',
    SCI_P3description: 'Unpredictable incidents on the road are common! Under the "Premium" program, regardless of whose fault the accident occurred - yours or another driver, car repairs will be reimbursed by the insurance company.',
    SCI_P4description: 'On the road, each participant is exposed to dangers - drivers, passengers, pedestrians! The "Individual" program allows you to create your own insurance program by choosing all the necessary services or suitable options. The full program provides insurance protection for all participants in an accident and reimbursement for the restoration of damaged vehicles using an insurance policy.',
    categories: 'Категории',
    categories_name: [
        'You must be at least 21 years old',
        'Minimum driving experience must be at least 1 year.',
        'We insure cars that are not older than 20 years.',
        'We do not provide insurance for taxis and legal entities.',
        'Each insurance program assumes one payment.',
        'After the first payment, the policy ceases to be valid only for the program for which the payment has been made.<br />The policy under other insurance programs remains valid.',
    ],
    driverSeriesNumber: 'Series and number',
    driverPeriod: 'Driving experience',
    noVin: 'If there is no VIN, fill in only the Body Number field.',
    ECI: 'Select program',
    ECI_P1: 'Full Casco',
    ECI_P2: ' Partial Casco',
    ECI_P3: 'Theft + Total Death',
    ECI_P1_hint: 'Options: “Accident due to the driver’s fault”, “Accident due to the fault of a 3rd party”, “New for old”, “Compensation for expenses”, “Without certificates”',
    ECI_P2_hint: 'Options: “Accident due to the fault of a 3rd party”, “New for old”, “Insurer’s assessment”, “Without certificates”',
    ECI_P3_hint: 'Options: “Accident due to the driver’s fault”, “Accident due to the fault of a 3rd party”, “Old for old” If the vehicle\'s age is more than 20, but less than 30 years inclusive',
    crSumAssured: 'Civil responsibility',
    incidentSumAssured: 'Accident',
    crSumAssuredHint: 'Civil liability is the obligation to compensate for damage to third parties caused by the fault of the insured',
    incidentSumAssuredHint: 'Accident is a sudden, short-term, unforeseen, unintentional external event, the nature, time and place of which can be unambiguously determined, and which resulted in bodily injury, or other impairment of body functions, or death of the Insured Person, not a consequence of a disease that occurred in during the insurance period regardless of the will of the Insured and/or the Insurant and/or the Beneficiary',
    compensation0: 'Compensation of expenses',
    compensation0Hint: 'The Insured repairs the damaged vehicle. The amount of compensation is determined on the basis of the documents provided, taking into account the terms of the insurance contract. Payment is made by the Insurer in favor of the Insured or Beneficiary',
    compensation1: 'Independent examination',
    compensation1Hint: 'The insurance company conducts an independent examination to calculate the cost of repair work on the damaged vehicle. With the consent of the insurer or on his initiative, an examination is carried out, the results of which determine the amount of insurance compensation. This amount includes the cost of the examination and is established in accordance with the terms of the insurance contract and policy conditions',
    compensation2: 'Insurer assessment',
    compensation2Hint: 'The cost of repair and restoration work on a damaged vehicle is calculated by the Insurer\'s experts. The amount of insurance compensation is determined based on the results of this assessment, taking into account the terms of the insurance contract and the Policy conditions. Payment of funds (insurance compensation) is made by the Insurer (cash and/or non-cash) in favor of the Insured or Beneficiary.',
    compensation3: 'Car service station of the insurer',
    compensation3Hint: 'The organization of repair and restoration work of the damaged vehicle is carried out by the Insurer at the service station that has a contractual relationship with it. Payment of funds (insurance compensation) is made by the Insurer (cash and/or non-cash) in favor of the service station that carried out (is carrying out or will carry out) repairs of the damaged vehicle',
    programsMore: 'Additional programs',
    accidentAnyOption: 'RTA due to the fault of the driver',
    accidentAnyOptionHint: 'Road traffic accident that occurred due to the fault of the driver of the insured vehicle (hit on other car, collision, etc.)',
    accident3tpOption: 'RTA caused solely by third parties',
    accident3tpOptionHint: 'Road accident involving an insured vehicle as a result of a collision, overturning, run-over and other actions of third parties, including damage in a parking lot by other cars',
    oldNewOption: 'New for old',
    oldNewOptionHint: 'Reimbursement for spare parts and materials replaced during the repair of the damaged vehicle is made without taking into account wear and tear, that is, in the amount of actual damage',
    noTraficPoliceSumAssured: 'Without the participation of the traffic police',
    noTraficPoliceHint: 'Consideration of an insured event without the participation of the traffic police up to: 1,000 somoni',
    damageCarWashSumAssured: 'Damage at the car wash',
    damageCarWashHint: 'You have the right to receive insurance compensation up to a certain limit (up to 1000 somoni) if car wash employees committed actions that are contrary to the law, without the need to contact special authorities.',
    greenCard: 'Green card',
    greenCardHint: 'Expansion of vehicle insurance territory to other countries',
    greenCardUzbOption: 'Uzbekistan',
    greenCardKazOption: 'Kazakhstan',
    greenCardKgzOption: 'Kyrgyzstan',
    insuranceAreaExpansion: 'Expansion of the insurance territory',
    autoText: 'The vehicle is not in the list',
    amountCompensation: 'Amount of compensation',
}
