import React, { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import whiteLogo from '../static/img/footer/footerLogo.svg'
import footerDecor from '../static/img/footer/footer-decor-line.svg'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import instagram from '../static/img/footer/Instagram.svg'
import ok from '../static/img/footer/ok.svg'
import fb from '../static/img/footer/fb.svg'
import yt from '../static/img/footer/yt.svg'
import lin from '../static/img/footer/in.svg'
import appGooglePlay from '../static/img/app/btn_googleplay_ru.svg'
import appAppStore from '../static/img/app/btn_appstore_ru.svg'
import { Context } from '../contexts/GlobalContext'

export default function Footer() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)

    const [state, setState] = React.useState('')

    const updateState = React.useCallback((newState) => {
        if (newState === state) {
            setState('')
        } else {
            setState(newState)
        }
    }, [state])

    const { t } = useTranslation('common')

    const footer = useLoad({ url: CMS.FOOTERS }, [cmsToken, language])

    if (!footer.response) {
        return (
            <footer className="footer" />
        )
    }

    return (
        <div className="page__footer">
            <footer className="footer">
                <img src={footerDecor} alt="" className="footer__bg" />
                <div className="footer__container">
                    <div className="footer__info">
                        <div className="footer__item">
                            <img src={whiteLogo} alt="" className="footer__image" />
                            {footer.response[0].results.map((item) => (item.type === 'Текст' ? (
                                <span className="footer__text" key={item.id}>
                                    {item.title}
                                </span>
                            ) : (
                                <NavLink className="footer__text" to={item.link} key={item.id}>
                                    {item.title}
                                </NavLink>
                            )))}
                            <div className="footer__icons">
                                <a className="footer__icon"
                                    href="https://instagram.com/bima__insurance" target="_blank" rel="noreferrer">
                                    <img src={instagram} alt="" width="24" height="25" />
                                </a>
                                <a className="footer__icon"
                                    href="https://facebook.com/bima.tj" target="_blank" rel="noreferrer">
                                    <img src={fb} alt="" width="24" height="25" />
                                </a>
                                <a className="footer__icon"
                                    href="https://ok.ru/bima.tj" target="_blank" rel="noreferrer">
                                    <img src={ok} alt="" width="24" height="25" />
                                </a>
                                <a className="footer__icon"
                                    href="https://www.youtube.com/channel/UCil9_39psuG2MXvDBdKqraQ"
                                    target="_blank" rel="noreferrer">
                                    <img src={yt} alt="" width="24" height="25" />
                                </a>
                                <a className="footer__icon"
                                    href="https://www.linkedin.com/company/10004574" target="_blank" rel="noreferrer">
                                    <img src={lin} alt="" width="24" height="25" />
                                </a>
                            </div>
                            <div className="footer__icons" style={{ top: '198px' }}>
                                <a
                                    href="https://apps.apple.com/ru/app/bima/id6446894765"
                                    target="_blank" rel="noopener noreferrer" style={{ paddingRight: '5px' }}
                                >
                                    <img src={appAppStore} alt="ios" />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=tj.bima"
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    <img src={appGooglePlay} alt="play" />
                                </a>
                            </div>
                        </div>
                        <div className="footer__items">
                            <div className="accordion">
                                <div className={`accordion__item accordion__item_${state === 'company' ? 'open' : 'close'}`}
                                    onClick={() => { updateState('company') }}>
                                    <div className="accordion__link">
                                        <span>{t('company')}</span>
                                    </div>

                                    <div className="accordion__panel">
                                        {footer.response[1].results.sort((item) => (item.pos)).map((item) => (
                                            <Link to={`${item.link[0] !== '/' ? '/' : ''}${item.link}`}
                                                key={item.id} className="accordion__panel-link">
                                                {item.title}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="accordion">
                                <div className={`accordion__item accordion__item_${state === 'servicesText' ? 'open' : 'close'}`}
                                    onClick={() => { updateState('servicesText') }}>
                                    <div className="accordion__link">
                                        <span>{t('servicesText')}</span>
                                    </div>

                                    <div className="accordion__panel">
                                        {footer.response[2].results.map((item) => (
                                            item.type === 'Текст' ? (
                                                <p className="accordion__panel-link" key={item.id}>
                                                    {item.title}
                                                </p>
                                            ) : (
                                                <Link to={`${item.link[0] !== '/' ? '/' : ''}${item.link}`}
                                                    className="accordion__panel-link" key={item.id}>
                                                    {item.title}
                                                </Link>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion">
                            <div className={`accordion__item accordion__item_${state === 'communicateText' ? 'open' : 'close'}`}
                                onClick={() => { updateState('communicateText') }}>
                                <div className="accordion__link">
                                    <span>{t('communicateText')}</span>
                                </div>

                                <div className="accordion__panel">
                                    {footer.response[3].results.map((item) => (
                                        <div key={item.id} style={{ display: 'flex', marginBottom: 13 }}>
                                            {
                                                item.link
                                                    ? (
                                                        <a href={item.link} target="_blank"
                                                            /* eslint-disable-next-line max-len */
                                                            className={cn('accordion__panel-link footer__contacts-link', {
                                                                // eslint-disable-next-line max-len
                                                                'footer__contacts-tel': (item.link.indexOf('+99') !== -1),
                                                                // eslint-disable-next-line max-len
                                                                'footer__contacts-email': (item.link.indexOf('@') !== -1),
                                                            })} rel="noreferrer">
                                                            {item.title}
                                                        </a>
                                                    )
                                                    : (
                                                        /* eslint-disable-next-line max-len */
                                                        <NavLink to="/offices" className="accordion__panel-link footer__contacts-link footer__contacts-address">
                                                            {item.title}
                                                        </NavLink>
                                                    )
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom-line">
                    <div className="footer__container bottom-line__container">
                        <div className="copyright">&#169; BIMA Insurance 2021-2022 All rights reserved</div>
                        <div className="dev__info">powered by AISWARE</div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
