import React from 'react'
import { Field } from 'formik'
import hintIcon from '../../static/img/svg/hint-icon.svg'

export default function Checkbox({ name, className, hint, label, value, error, refLinlk, onChange = () => null }) {
    return (
        <div className={`checkbox ${className}`} ref={refLinlk}>
            <Field name={name}>
                {({ form, field }) => (
                    <>
                        <label className="travel-order__rules hint__label" style={{ display: 'flex' }}>
                            <p className="checkbox__text" htmlFor={name}>{label}</p>

                            <input
                                id={name}
                                className="checkbox__input"
                                type="checkbox"
                                checked={field.value}
                                onChange={(e) => {
                                    form.setFieldValue(name, value || e.target.checked)
                                        .then(() => onChange(value || e.target.checked))
                                }} />
                            <span className="checkbox__span" />
                            { (false && error) ? (<div>Обязательно для заполнения</div>) : null }
                            {
                                hint
                                    ? (
                                        <div className="hint__icon">
                                            <img src={hintIcon} alt="" width="16" height="16" />
                                            <span className="hint__content">
                                                {hint}
                                            </span>
                                        </div>
                                    ) : null
                            }
                        </label>
                    </>
                )}
            </Field>
        </div>
    )
}
