/* eslint-disable max-len */

export default {
    header: 'Введите данные для расчета стоимости поездки',
    byuOnline: 'Купить полис онлайн',
    toByuOnline: 'Перейти к оплате',
    wrongInput: 'Неверно заполнено',
    departureDate: 'Дата вылета',
    arrivalDate: 'Дата прилета',
    departureDatePH: '"ДД / ММ / ГГГГ"',
    arrivalDatePH: 'Дата прилета',
    checkPolicy: 'Проверить полис',
    travelProducts: 'Продукты для поездок',
    oneTravel: 'На одну поездку',
    multiTravel: 'На несколько поездок',
    frequencyTravel: 'Частота поездок',
    travelPeriod: 'Период путешествия',
    policeConditions: 'Условия полиса',
    travelCountry: 'Страна путешествия',
    chooseCountry: 'Выберите одну или несколько стран',
    wrongPoliceType: 'Не выбран тип полиса',
    alert90Days: 'При выборе нескольких поездок возможно оформить только на 90 дней',
    dayCount: 'Количество дней',
    dayCountPH: 'Количество дней',
    restTypeChoose: 'Выберите тип отдыха',
    restType: 'Тип отдыха',
    fullCost: 'Итого',
    travelBoxProducts: 'Коробочные продукты для путешественников',
    nullChoose: 'Не выбрано',
    buy: 'Купить',
    toBuy: 'Перейти к оплате',
    more: 'Подробнее',
    insured: 'Страхователь',
    holderFullName: 'Фамилия, Имя, Отчество',
    holderFullNamePH: 'Ivanov Ivan Ivanovich',
    holderPhone: 'Телефон',
    holderEmail: 'Электронная почта',
    holderDocSeries: 'Серия паспорта',
    holderDocNumber: 'Номер паспорта',
    holderIssueInstitution: 'Кем выдан',
    holderBirthday: 'Дата рождения',
    holderGender: 'Пол',
    holderIssueDate: 'Дата выдачи паспорта',
    holderAddressReg: 'Адрес регистрации',
    holderPostalCodeReg: 'Почтовый индекс',
    holderAddressFact: 'Адрес регистрации',
    holderPostalCodeFact: 'Почтовый индекс',
    holderPostalFactEqReg: 'Совпадает с адресом регистарции',
    holderAddressPH: 'Адрес регистрации',
    holderPostalCodePH: '743000',
    holderOblProj: 'Область проживания',
    holderGorodProj: 'Город проживания',
    holderUlProj: 'Улица проживания',
    holderDomProj: 'Дом проживания',
    holderKvProj: 'Квартира проживания',
    holderAgree: 'К оформлению',
    holderData: 'Данные страхователя',
    copyHolderData: 'Скопировать информацию о страхователе',
    lasInfo: 'Застрахованный',
    lasAdd: 'Добавить застрахованного',
    laFullname: 'Фамилия, Имя, Отчество',
    laFullnamePH: 'Алексей Петрович Иванов',
    laBirthday: 'Дата рождения',
    laCitizenship: 'Гражданство',
    series: 'Серия паспорта',
    docNumber: 'Номер паспорта',
    issueDate: 'Дата выдачи паспорта',
    gender: 'Пол',
    sumInsured: 'Страховые суммы',
    currency: 'Валюта',
    medical: 'Страховая сумма',
    mainAvoid: 'Сумма меньше',
    personalSwag: 'Личные вещи',
    houseProtect: 'Защита дома',
    goodNeighbors: 'Добрые соседи',
    accident: 'Несчастный случай',
    optionsMore: 'Дополнительные опции',
    sportOption: 'Спорт',
    covid19Option: 'Короновирусная инфекция',
    pregnantOption: 'Осложнение беременности',
    sunOption: 'Солнечные ожоги',
    alcoholicOption: 'Алкогольное опьянение',
    poisoningOption: 'Пищевое отравление',
    promoCode: 'Промокод',
    errorCoupon: 'Ошибка ввода промокода',
    coupon: 'Введите промокод',
    optionsMore1: '',

    // Подсказки
    hint_fio: 'Впишите ваше ФИО полностью. Например: Ivanov Ivan Ivanovich',
    hint_coutry: 'Выберите страну поездки',
    hint_bd: 'Впишите дату вашего рождения. Например 04.04.1976 г.',
    hint_gender: 'Впишите ваш пол женский или мужской',
    hint_email: 'Адрес электронной почты',
    hint_passport: 'Серия и номер вашего заграничного паспорта, кем и когда выдан',
    hint_regAddr: 'Страна, город, район, улица, номер дома/квартиры указанные в паспорте',
    hint_factAddr: 'Страна, город, район, улица, номер дома/квартиры - где вы фактически проживаете',
    hint_phoneH: 'Впишите номер домашнего телефона (при наличии)',
    hint_inn: 'Впишите Ваш Индивидуальный Номер Налогоплательщика, выданный в налоговых органах РТ.',
    hint_phoneM: 'Впишите Ваш мобильный действующий номер',
    hint_periodSafe: 'Дата начала поездки и дата окончания поездки',
    hint_currency: 'Доллары США, Евро, Российские рубли, Таджикские сомони',
    hint_optSport: 'Занятия спортом с повышенной опастностью (включая катание на горных лыжах на специально оборудованных трассах), профессиональным спортом, участие на соревнованиях и турнирах, прыжки с парашютом, езда на моторных транспортных средствах. ',
    hint_optCovid: 'БИМА возместит медицинские и транспортные расходы, возникшие при заболевании Covid-19. Страховое покрытие до 5000 долларов США.',
    hint_flightDelaOption: 'БИМА компенсирует материальный ущерб, связанный с задержкой, начала поездки, превышающей 8 часов.   ',
    hint_baggageDelayOption: 'БИМА компенсирует расходы, связанные с ожиданием багажа, задержка которого составляет более 48 часов. ',
    hint_baggageLossOption: ' БИМА компенсирует расходы, связанные с полной гибелью или утратой багажа, сданного под ответственность перевозчика.',
    hint_optPregnant: 'Бима компенсирует расходы, связанные с оказанием экстренной и неотложной медицинской помощи при внезапном осложнении беременности.',
    hint_optSun: 'БИМА возместит медицинские расходы, возникшие результате солнечных ожогов.',
    hint_optAlcohol: 'БИМА компенсирует медицинские и экстренные расходы, при заболеваниях, травмах, произошедших в связи с употреблением алкоголя.   ',
    hint_optFood: 'Медицинские и транспортные расходы, возникшие при пищевом отравлении',
    hint_promocode: 'Введите код для получения скидки',
    hint_medical: 'БИМА возместит расходы, связанные с экстренной госпитализацией или транспортировкой в период путешествия',
    hint_personalSwag: 'БИМА возместит стоимость личных вещей — мобильной техники, спортинвентаря, верхней одежды и ручной клади (в пределах установленного лимита).',
    hint_houseProtect: 'БИМА возместит стоимость затрат на ремонт дома / квартиры, пострадавшей от заливов или задымления и т.д., произошедших в период вашего путешествия. ',
    hint_goodNeighbors: 'БИМА возместит расходы, связанные с причинением вреда имуществу ваших соседей по Вашей вине в период путешествия',
    hint_seaCreature: 'БИМА возместит расходы на экстренную госпитализацию и лечение за рубежом, связанные с контактом в путешествии с морскими животными (укусы, ядовитые ожоги, травмы при столкновении с морскими обитателями).',
    hint_accident: 'БИМА возместит расходы, связанные с получением тяжких телесных повреждений Застрахованным в результате несчастного случая или ухода из жизни.',
    hint_oneTrip: 'Полис действует на одну поездку',
    hint_moreTrip: 'Полис, который позволяет путешествовать несколько раз в течении года (количество дней на каждую поездку 90 дней)',
    hint_travelType: 'Спокойный отдых, спорт, экстремальный спорт, семейный отдых, гастрономический тур',

    // новые
    discount: 'Скидка',
    options: 'Опции',
    amount: 'Сумма',
    fullPrice: 'Итого',
    fillFullName: 'Не заполнено ФИО',
    fillName: 'Не заполнено ИМЯ',
    accidentManual: 'Страхование от несчастных случаев - вручную',
    lasRemove: 'Удалить застрахованного',
    insuredInfo: 'Застрахованный {{count}}',
    headNavData: 'Предрасчет',
    holderDocType: 'Тип документа',
    chooseLimit: 'Выберите размер',
    headNavOptions: 'Выбор Опций',
    headNavInsurer: 'Оформление',
    headNavInsured: 'Оплата',
    travelingWho: 'Кто поедет',
    travelingPeriod0: '4 - 69 лет',
    travelingPeriod1: '0 - 12 месяцев',
    travelingPeriod2: '1 - 3 года',
    travelingPeriod3: '70 - 74 лет',
    travelingPeriod4: 'До 80 лет',
    headNavBuy: 'Оформить',
    headNavPrograms: 'Программы',
    fullPolicyCost: 'Итого',
    mainPolicyCost: 'Полис',
    optionsPolicyCost: 'Опции',
    nextStep: 'Далее',
    totalDays: 'Дней: {{count}}',
    insuredCount: 'Застрахованные: {{count}}',
    insured0: '{{count}} путешественник',
    insured1: '{{count}} путешественника',
    insured2: '{{count}} путешественников',
    additionalOptions: 'Дополнительные опции',
    sumOptions: 'Итого',
    changeButton: 'Изменить',
    addButton: 'Добавить',
    deleteButton: 'Удалить',
    flightDelaOption: 'Задержка рейса',
    baggageDelayOption: 'Задержка багажа',
    baggageLossOption: 'Утрата багажа',
    traveling: 'Путешествуют',
    spaType0: 'Спокойный',
    spaType0Hint: 'Включает экскурсии, пляжный отдых, велосипедные прогулки, катание на животных.',
    spaType1: 'Экстримальный',
    spaType1Hint: 'Занятия видами спорта с повышенной опасностью и профессиональным спортом.',
    holderAsInsured: 'Страхователь является застрахованным.',
    insuranceCost: 'Стоимость: {{price}}',
    mainSumPrem: 'Сумма покрытия',
    seacreatureOption: 'Контакты с морскими гадами',
    optionsHouse: 'Дом:',
    optionsHouseBottom: 'Адрес застрахованной недвижимости',
    optionsHousePH: 'Адрес дома',
    optionsHouseHint: 'Полный адрес дома, включая номер, который будет застрахован на время отпуска',
    policeAfterPayment: 'После оплаты полис будет направлен на указанный адрес электронной почты.',
    openInfoPopup: 'Полное описание покрытия',
    spaTypeHint: 'Подробнее о типах отдыха',
    reqFieldsHead: 'Заполните',
    reqFieldsText: 'обязательные поля',
    checkData: 'Проверьте данные',
    payType: 'Cпособ оплаты',
    paySystem1: 'Online Pay',
    paySystem2: 'Vasl Pay',
    paySystem3: 'DC Pay',
}
