import React, { useContext, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Layout from '../components/Layout'
import HomeBanner from '../components/HomeBanner'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import SwiperSlider from '../components/common/SwiperSlider'

export default function Auto() {
    const { t } = useTranslation(['auto', 'home'])
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { response: productsResponse } = useLoad({
        url: CMS.ALL_BOXES_ALL
            .replace('{page}', '0')
            .replace('{size}', '300')
            .replace('{category}', 'sci'),
    }, [cmsToken, language])

    const filteredResponse = useMemo(() => {
        if (productsResponse) {
            return productsResponse.data.filter((item) => !('seo' in item)).sort((itemA, itemB) => {
                if (itemA.price === '0') return 1
                if (itemB.price === '0') return -1
                return itemA.price - itemB.price
            })
        }
        return false
    }, [productsResponse])
    const slides = useMemo(() => {
        if (!filteredResponse) return null

        const sdata = []
        filteredResponse.forEach((item) => {
            sdata.push(
                <NavLink className="auto-product__card auto-card auto-card-1" to="/auto">
                    <div className="auto-card__image">
                        <img src={item.shortImg} alt={item.title} />
                    </div>
                    <div className="auto-card__wrapper">
                        <div className="auto-card__title">
                            <span className="auto-card__name">
                                {item.title}
                            </span>
                            {item.price !== '0' && <span className="auto-card__price">{item.price} TJS</span>}
                        </div>
                        <div className="auto-card__desc">
                            <span className="auto-card__txt" dangerouslySetInnerHTML={{ __html: item.shortDesc }} />
                            <ul className="auto-card__list">
                                {
                                    false && item.options && item.options.map((option) => (
                                        <li className="auto-card__item">
                                            {t(`programOption${option}`)}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            item.price !== '0' ? (
                                <div className="slider-products__links" style={{ margin: '10px auto 0' }}>
                                    <NavLink className="slider-products__link request-link"
                                        to={`/auto/order/${item.productCode}`} style={{ width: '90px' }}>
                                        {t('home:buy')}
                                    </NavLink>
                                    <NavLink className="slider-products__link info-link"
                                        to={`/auto/${item.productCode}`} style={{ width: '110px', marginLeft: '20px', padding: '0 10px' }}>
                                        {t('home:more')}
                                    </NavLink>
                                </div>
                            ) : (
                                <div className="slider-products__links" style={{ margin: '10px auto 0', width: '100%' }}>
                                    <NavLink className="slider-products__link request-link"
                                        to="/offices" style={{ width: '100%' }}>
                                        {t('home:buy')}
                                    </NavLink>
                                    <NavLink className="slider-products__link info-link"
                                        to={`/auto/${item.productCode}`} style={{ width: '110px', marginLeft: '20px', padding: '0 10px' }}>
                                        {t('home:more')}
                                    </NavLink>
                                </div>
                            )
                        }

                    </div>
                </NavLink>,
            )
        })

        return sdata
    }, [filteredResponse])

    return (
        <Layout>
            <HomeBanner />
            <section className="auto-product">
                <div className="auto-product__container">
                    <h2 className="auto-product__title">{t('homeHeader')}</h2>
                    <div className="auto-product__box hide-on-mobile">
                        {
                            filteredResponse && filteredResponse.map((item) => (
                                <NavLink className="auto-product__card auto-card auto-card-1" to="/auto">
                                    <div className="auto-card__image">
                                        <img src={item.shortImg} alt={item.title} />
                                    </div>
                                    <div className="auto-card__wrapper">
                                        <div className="auto-card__title">
                                            <span className="auto-card__name">
                                                {item.title}
                                            </span>
                                            {item.price !== '0' && <span className="auto-card__price">{item.price} TJS</span>}
                                        </div>
                                        <div className="auto-card__desc">
                                            <span className="auto-card__txt" dangerouslySetInnerHTML={{ __html: item.shortDesc }} />
                                            <ul className="auto-card__list">
                                                {
                                                    false && item.options && item.options.map((option) => (
                                                        <li className="auto-card__item">
                                                            {t(`programOption${option}`)}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        {
                                            item.price !== '0' ? (
                                                <div className="slider-products__links" style={{ margin: '10px auto 0' }}>
                                                    <NavLink className="slider-products__link request-link"
                                                        to={`/auto/order/${item.productCode}`} style={{ width: '90px' }}>
                                                        {t('home:buy')}
                                                    </NavLink>
                                                    <NavLink className="slider-products__link info-link"
                                                        to={`/auto/${item.productCode}`} style={{ width: '110px', marginLeft: '20px', padding: '0 10px' }}>
                                                        {t('home:more')}
                                                    </NavLink>
                                                </div>
                                            ) : (
                                                <div className="slider-products__links" style={{ margin: '10px auto 0', width: '100%' }}>
                                                    <NavLink className="slider-products__link request-link"
                                                        to="/offices" style={{ width: '100%' }}>
                                                        {t('home:buy')}
                                                    </NavLink>
                                                    <NavLink className="slider-products__link info-link"
                                                        to={`/auto/${item.productCode}`} style={{ width: '110px', marginLeft: '20px', padding: '0 10px' }}>
                                                        {t('home:more')}
                                                    </NavLink>
                                                </div>
                                            )
                                        }

                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                    {slides && (
                        <SwiperSlider
                            className="main-popular-mobile"
                            loop
                            centeredSlides
                            items={slides}
                        />
                    )}
                    <div className="auto-product__comments auto-comments open">
                        <div className="auto-comments__head">
                            <div className="auto-comments__name">
                                {t('categories')}
                            </div>
                            <button className="auto-comments__btn" />
                        </div>
                        <ul className="auto-comments__list">
                            {
                                [0, 1, 2, 3, 4, 5].map((i) => (
                                    <li className="auto-comment__item">
                                        <Trans t={t} i18nKey={`categories_name.${i}`} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
