import React from 'react'
import cn from 'classnames'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask/dist/reactTextMask'
import hintIcon from '../../static/img/svg/hint-icon.svg'

export default function InputMask({
    name,
    className,
    divClass,
    type,
    label,
    error,
    hint,
    placeholder,
    errTxt,
    onChange = () => null,
    onKeyDown = () => null,
    onBlur = () => null,
    isDisabled,
    required,
    value,
    mask,
    upper,
    refLinlk,
    ...attributes
}) {
    const { t } = useTranslation('common')

    return (
        <div className={cn('input', { input_error: error }, divClass)} ref={refLinlk}>
            {label ? (
                <label className={cn({ hint__label: hint, 'label-valid': !error }, 'input__label')} htmlFor={name}>
                    {label}
                    { required ? <span style={{ color: 'red', paddingLeft: '4px' }}>*</span> : null }
                    {
                        hint
                            ? (
                                <span className="hint__icon">
                                    <img src={hintIcon} alt="" width="16" height="16" />
                                    <span className="hint__content">
                                        {hint}
                                    </span>
                                </span>
                            ) : null
                    }
                    {
                        error
                            ? (
                                <div className="input_error">
                                    <span
                                        className="input_error-text">
                                        { errTxt || t('wrongInput') }
                                    </span>
                                </div>
                            ) : null
                    }
                </label>
            ) : null}

            <Field name={name}>
                {({ form, field }) => (
                    <MaskedInput
                        mask={['(', /[9]/, /[9]/, /[2]/, ')', ' ', /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        id={name}
                        value={field.value || value}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        onBlur={onBlur}
                        className={cn('input__input', className)}
                        onChange={(e) => {
                            let val = e.target.value
                            if (type === 'number') {
                                val = Number(val)
                            } else {
                                if (upper) val = val.toUpperCase()
                                if (mask) val = val.replace(mask, '')
                            }
                            form.setFieldValue(name, val).then(() => onChange(e))
                        }}
                        type={type || 'text'}
                        onKeyDown={onKeyDown}
                        {...attributes} />
                )}
            </Field>
        </div>
    )
}
