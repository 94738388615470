import React, { useContext } from 'react'
import cn from 'classnames'
import Header from './Header'
import FooterOld from './Footer'
import { Context } from '../contexts/GlobalContext'

export default function Layout({ children }) {
    const { modalComponent, modalLoader } = useContext(Context)

    return (
        <div className="page">
            <div className="page__container">
                <Header />

                <div className="page__content">
                    {children}

                    <div className={cn('overlay js-overlay-modal', { active: modalComponent || modalLoader })} />
                </div>

                <FooterOld />
            </div>
        </div>
    )
}
