export default {
    buy: 'Купить',
    box: 'коробочный',
    toBuy: 'Перейти к оплате',
    more: 'Подробнее',
    insuredSum: 'Страховая сумма',
    validaty: 'Срок страхования',
    insuranceCost: 'Стоимость страхования',
    insuranceCostCoupon: 'Стоимость страхования с учетом купона',
    insuranceCostActiveCoupon: 'Стоимость с примененным купоном: ',
    loading: 'Загрузка...',
    activation: 'Активация коробки',
    buyBox: 'Купить коробку',
    boxProducts: 'Коробочные продукты',
    policyData: 'Активация полиса',
    policyNumber: 'Номер полиса',
    policyCode: 'Код полиса',
    boxProductBuy: 'Оформление коробочного продукта',
    holderLastName: 'Фамилия',
    holderFirstName: 'Имя',
    holderSecondName: 'Отчество',
    holderLastNamePH: 'Иванов',
    holderFirstNamePH: 'Иван',
    holderSecondNamePH: 'Иванович',
    holderBirthDate: 'Дата рождения',
    holderPhone: 'Телефон',
    holderEmail: 'Адрес электронной почты',
    holderGender: 'Пол',
    holderCountry: 'Резиденство',
    holderDocSeries: 'Серия паспорта',
    holderDocNumber: 'Номер паспорта',
    holderIssueInstitution: 'Кем выдан',
    holderIssueDate: 'Дата выдачи паспорта',
    couponError: 'Введен неверный промокод',
    coupon: 'Промокод',
    couponApply: 'Применить промокод',
    periodName: 'мес.',
    offerProducts: 'Банковские продукты',
    showPassword: 'показать',
    closePassword: 'скрыть',
}
