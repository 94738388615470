export const secondSite = [
    'SAA', 'ZAA',
    'SMA', 'ZMA',
    'SAP', 'ZAP',
    'SHP', 'ZHP',
    'SAF', 'ZAF',
    'SMF', 'ZMF',
    'SES', 'ZES',
    'SEP', 'ZEP',
    'SMS', 'ZMS',
    'SMP', 'ZMP',
    'SAC', 'ZAC',
    'STP', 'ZTP',
    'SCU', 'ZCU',
    'SCI', 'ZCU',
]

export const salesSite = process.env.REACT_APP_SALES_SITE || 'https://activation.bima.tj'

const decimal = 0
const separator = ' '
const decpoint = '.'
const formatString = '#'

export function formatPrice(_number) {
    let r = parseFloat(_number)

    // eslint-disable-next-line no-restricted-properties
    const exp10 = Math.pow(10, decimal)// приводим к правильному множителю
    r = Math.round(r * exp10) / exp10// округляем до необходимого числа знаков после запятой

    const rr = Number(r).toFixed(decimal).toString().split('.')

    const b = rr[0].replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, `$1${separator}`)

    r = (rr[1] ? b + decpoint + rr[1] : b)
    return formatString.replace('#', r)
}

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean)
    if (!filteredRefs.length) return null
    if (filteredRefs.length === 0) return filteredRefs[0]
    return (inst) => {
        filteredRefs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(inst)
            } else if (ref) {
                // eslint-disable-next-line no-param-reassign
                ref.current = inst
            }
        })
    }
}
