import React, { createContext, useState } from 'react'

export const AuthContext = createContext()

export default function AuthContextWrapper({ children }) {
    const [cmsToken, setCmsToken] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [refreshToken, setRefreshToken] = useState('')

    return (
        <AuthContext.Provider value={{
            cmsToken,
            setCmsToken,
            accessToken,
            setAccessToken,
            refreshToken,
            setRefreshToken,
        }}>
            {children}
        </AuthContext.Provider>
    )
}
