import React from 'react'
import Button from '../base/Button'
import sprite from '../../static/img/svg/svg-sprite.svg'

export default function PolicyNotFound({ onClose, push }) {
    return (
        <div className="popup__content">
            <div className="popup-policy-success">
                <div className="popup-policy-success__icon-no">
                    <svg width="296" height="296">
                        <use xlinkHref={`${sprite}#icon-xl-notfound`} />
                    </svg>
                </div>

                <p className="popup-policy-success__title">Полис не найден</p>

                <p className="popup-policy-success__text popup-policy-success__text_notice">
                    Возможно вы совершили ошибку<br />при вводе информации.<br />Попробуйте еще раз.
                </p>

                <Button
                    onClick={() => {
                        onClose()
                        push('/')
                    }}
                    className="popup-policy-success__button"
                    text="На главную" />
            </div>
        </div>
    )
}
