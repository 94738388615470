import React, { useContext, useMemo } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep5 from './FormStep5'
import { VzrContext } from '../../contexts/VzrContext'

export function VzrForm() {
    const { step, setStep, errorMsg, step3el } = useContext(VzrContext)
    const { t } = useTranslation('travel')
    const { validateForm, setFieldValue } = useFormikContext()

    const stepForm = useMemo(() => {
        switch (step) {
        case 1:
            return (
                <FormStep1 key="formStep1" />
            )
        case 2:
            return (
                <FormStep2 key="formStep2" />
            )
        case 3:
            return (
                <FormStep3 key="formStep3" />
            )
        // case 4:
        //     return (
        //         <FormStep4 key="formStep4" />
        //     )
        default:
            return (
                <FormStep5 key="formStep5" />
            )
        }
    }, [step])
    return (
        <>
            <form className="travel-one__form">
                <div className="tabs__nav">
                    <button className={cn('tabs__btn', {
                        tabs__btn_active: step === 1,
                        tabs__btn_finished: step > 1,
                    })} onClick={(event) => {
                        event.preventDefault()
                        setFieldValue('shortValidate', true)
                        window.setTimeout(() => {
                            if (step > 1) {
                                validateForm().then((res) => {
                                    if (Object.values(res).length === 0) {
                                        setStep(1)
                                    } else if (step3el) {
                                        window.scrollTo({
                                            top: step3el.current[res.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }
                                    setFieldValue('shortValidate', false)
                                }).catch(() => setFieldValue('shortValidate', false))
                            }
                        }, 0)
                    }}>
                        {t('headNavData')}
                    </button>
                    <button className={cn('tabs__btn', {
                        tabs__btn_active: step === 2,
                        tabs__btn_finished: step > 2,
                    })} onClick={(event) => {
                        event.preventDefault()
                        setFieldValue('shortValidate', true)
                        window.setTimeout(() => {
                            if (step > 2) {
                                validateForm().then((res) => {
                                    setFieldValue('shortValidate', false)
                                    if (Object.values(res).length === 0) {
                                        setStep(2)
                                    } else if (step3el) {
                                        window.scrollTo({
                                            top: step3el.current[res.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                            left: 0,
                                            behavior: 'smooth',
                                        })
                                    }
                                }).catch(() => setFieldValue('shortValidate', false))
                            }
                        }, 0)
                    }}>
                        {t('headNavOptions')}
                    </button>
                    <button className={cn('tabs__btn', {
                        tabs__btn_active: step === 3,
                        tabs__btn_finished: step > 3,
                    })} onClick={(event) => {
                        event.preventDefault()
                        if (step > 3) {
                            setStep(3)
                        }
                    }}>
                        {t('headNavInsurer')}
                    </button>
                    <button className={cn('tabs__btn', {
                        tabs__btn_active: step === 4,
                        tabs__btn_finished: step > 4,
                    })} onClick={(event) => {
                        event.preventDefault()
                        if (step > 4) {
                            setStep(4)
                        }
                    }}>
                        {t('headNavInsured')}
                    </button>
                </div>
                <div className="travel__error" style={{ color: 'darkred', paddingBottom: '10px' }}>{errorMsg}</div>
                {stepForm}
            </form>
        </>
    )
}
