import React, { useEffect, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import cn from 'classnames'
import { isNumber } from 'lodash'
import { formatPrice } from '../../helpers/globals'

export default function CheckboxOptions({ name, hint, label, onChange = () => null, options = [], prem, icon, selectLabel, radio = false }) {
    const { values } = useFormikContext()
    // eslint-disable-next-line no-empty-pattern
    const [{ value }, {}, { setValue }] = useField(name)
    const [open, setOpen] = useState(false)
    const refSelect = useRef()

    const handleClickOutside = (event) => {
        if (open && refSelect.current && !refSelect.current.contains(event.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return (
        <div className="auto__option-row">
            <div className={cn('checkbox travel-option auto-option__box', { 'travel-option__checked': value > 0 }, icon)}>
                {
                    hint !== '' && (
                        <span className="hint__icon travel-option__hint-icon">
                            <span className="hint__icon-simbol">?</span>
                            <span className="hint__content travel-option__hint-content">
                                {hint}
                            </span>
                        </span>
                    )
                }
                <label className="travel-option__rules">
                    <div className="travel-option__title-box travel-pl">
                        <div className="travel-option__title">
                            <div className="travel-option__text-box">
                                <div className="travel-option__name auto__name">
                                    <p className="travel-option__text">
                                        {label}
                                    </p>
                                </div>
                                {(value > 0 && options.length > 0) ? (
                                    <>
                                        {selectLabel && <span className="select__text-desc">{selectLabel}</span>}
                                        <div className="select select__travel-order select__travel-option">
                                            <div className="select__current travel-option__select-current"
                                                ref={refSelect}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    setOpen((val) => !val)
                                                }}
                                            >
                                                {formatPrice(value)}
                                                <span className="travel-option__currency">
                                                    {values.currency}
                                                </span>
                                            </div>
                                            <div className={cn('select__list select__list-option', { select__list_open: open })}>
                                                {options.map((item) => (
                                                    <div className="select__item travel-option__select-item"
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setValue(item.value)
                                                            setOpen(false)
                                                            if (onChange) onChange(item.value)
                                                        }}
                                                    >
                                                        <span className="select__text-value">
                                                            {item.label || item.name}
                                                            <span className="travel-option__currency">
                                                                {values.currency}
                                                            </span>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            {
                                (isNumber(values[prem]) || (name === 'covid19SumAssured' && value > 0))
                                    ? <div className="travel-option__price">{formatPrice(values[prem])} TJS</div>
                                    : null
                            }
                        </div>
                    </div>
                    <input className="checkbox__input" type="checkbox" checked={value > 0} onChange={() => { setOpen(false) }} />
                    <span className={cn('checkbox__span travel-option__span', { 'auto-option__radio': radio })}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (value) {
                                setValue(0)
                                if (onChange) onChange(false)
                            } else {
                                const middle = Math.floor(options.length / 2)
                                setValue(options.length !== 0 ? options[middle].value : 1)
                                if (onChange) onChange(options.length !== 0 ? options[0].value : 1)
                            }
                        }}
                    />
                </label>
            </div>
        </div>
    )
}
