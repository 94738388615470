import React, { useState } from 'react'
import cn from 'classnames'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import hintIcon from '../../static/img/svg/hint-icon.svg'

export default function PhoneInput({
    name,
    className,
    divClass,
    type,
    label,
    error,
    hint,
    placeholder,
    errTxt,
    onChange = () => null,
    onKeyDown = () => null,
    onBlur = () => null,
    isDisabled,
    required,
    value,
    mask,
    upper,
    ...attributes
}) {
    const { t } = useTranslation('common')
    const [danger, setDanger] = useState(false)

    return (
        <div className={cn('input', { input_error: error }, divClass)}>
            {label ? (
                <label className={cn({ hint__label: hint }, 'input__label')} htmlFor={name}>
                    {label}
                    { required ? <span style={{ color: 'red', paddingLeft: '4px' }}>*</span> : null }
                    {
                        hint
                            ? (
                                <span className="hint__icon">
                                    <img src={hintIcon} alt="" width="16" height="16" />
                                    <span className="hint__content">
                                        {hint}
                                    </span>
                                </span>
                            ) : null
                    }
                    {
                        error
                            ? (
                                <div className="input_error">
                                    <span
                                        className="input_error-text">
                                        { errTxt || t('wrongInput') }
                                    </span>
                                </div>
                            ) : null
                    }
                </label>
            ) : null}
            <div className="phone-input">
                <div className={cn({ danger })} style={{ backgroundColor: '#f6f6f6', borderRadius: '4px', height: '56px', marginRight: '4px' }}>+ 992</div>
                <Field name={name}>
                    {({ form, field }) => (
                        <input
                            id={name}
                            value={field.value || value}
                            placeholder="00-000-00-00"
                            disabled={isDisabled}
                            onBlur={onBlur}
                            className={cn('input__input', className)}
                            onChange={async (e) => {
                                let val = e.target.value
                                if (val === '992' || val === '+992') {
                                    await setDanger(true)
                                    let count = 1
                                    await form.setFieldValue(name, '')
                                    e.target.value = ''
                                    const flash = window.setInterval(async () => {
                                        if (count > 10) window.clearInterval(flash)
                                        await setDanger((dangerState) => !dangerState)
                                        count += 1
                                    }, 300)
                                } else {
                                    if (type === 'number') {
                                        val = Number(val)
                                    } else {
                                        if (upper) val = val.toUpperCase()
                                        if (mask) val = val.replace(mask, '')
                                    }
                                    await form.setFieldValue(name, val)
                                    await onChange(e)
                                }
                            }}
                            type={type || 'text'}
                            onKeyDown={onKeyDown}
                            {...attributes} />
                    )}
                </Field>
            </div>
        </div>
    )
}
