import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwiperSlider from './common/SwiperSlider'
import { useLoad } from '../hooks/request'
import { CMS, saleUrl } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import Loader from './base/Loader'
import { Context } from '../contexts/GlobalContext'
import { secondSite } from '../helpers/globals'

export default function HomeProducts() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)
    const { response } = useLoad({
        url: `${CMS.ALL_BOXES
            .replace('{page}', '1')
            .replace('{size}', '7')
            .replace('{category}', 0)
        }&popular=1`,
    }, [cmsToken, language])

    const { t } = useTranslation('home')
    if (!response) {
        return (
            <Loader centered />
        )
    }
    const { data } = response
    const slides = data.reduce((reuslt, item) => {
        const slide = (
            <div className="main-popular__item">
                <div className="main-popular__image main-popular__image_vertical">
                    <img
                        className="main-popular__img main-popular__img_vertical"
                        src={item.mainImg}
                        alt="" />
                </div>
                <div className="main-popular__info main-popular__info_vertical">
                    <h3 className="main-popular__subtitle">{item.title}</h3>
                    <span
                        className="main-popular__text">
                        {item.shortDesc}
                    </span>
                    <div className="main-popular__links">
                        {(parseInt(item.isSale, 10) === 1)
                            && (
                                secondSite.indexOf(item.productCode) !== -1
                                    ? (
                                        <a href={saleUrl(item.slug, language)} className="slider-products__link request-link">
                                            {t('buy')}
                                        </a>
                                    )
                                    : (
                                        <NavLink href={`/boxes/order/${item.slug}`} className="slider-products__link request-link">
                                            {t('buy')}
                                        </NavLink>
                                    )
                            )}
                        <NavLink to={`/boxes/${item.slug}`}
                            className="slider-products__link info-link">
                            {t('more')}
                        </NavLink>
                    </div>
                </div>
            </div>
        )
        if (!item.seo) reuslt.push(slide)
        return reuslt
    }, [])

    return (
        <section className="main-popular">
            <div className="main-popular__container">
                <NavLink to="/boxes">
                    <h2 className="main-popular__title">{t('popularProducts')}</h2>
                </NavLink>

                <div className="main-popular__grid main-popular__grid_desktop">
                    {data.map((item, i) => {
                        if (item.seo) return false
                        if (i === 0) {
                            return (
                                <div className="main-popular__item main-popular__item_1" key={item.id}>
                                    <span className="main-popular__label main-popular__label_1">
                                        {t('box')}
                                    </span>
                                    <div className="main-popular__info main-popular__info_horizontal">
                                        <div className="main-popular__info-wrapper extra-margin">
                                            <h3 className="main-popular__subtitle main-popular__subtitle_mb">
                                                {item.title}
                                            </h3>
                                            <span
                                                className="main-popular__text">
                                                {item.shortDesc}
                                            </span>
                                        </div>
                                        <div className="main-popular__links">
                                            {(parseInt(item.isSale, 10) === 1)
                                                && (
                                                    secondSite.indexOf(item.productCode) !== -1
                                                        ? (
                                                            <a href={saleUrl(item.slug, language)} className="slider-products__link request-link">
                                                                {t('buy')}
                                                            </a>
                                                        )
                                                        : (
                                                            <NavLink href={`/boxes/order/${item.slug}`} className="slider-products__link request-link">
                                                                {t('buy')}
                                                            </NavLink>
                                                        )
                                                )}
                                            <NavLink to={`/boxes/${item.slug}`}
                                                className="main-popular__link info-link">
                                                {t('more')}
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="main-popular__image main-popular__image_horizontal">
                                        <img className="main-popular__img main-popular__img_horizontal"
                                            src={item.mainImg}
                                            alt="" />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className={`main-popular__item main-popular__item_${(i + 1)}`} key={item.id}>
                                <span className="main-popular__label">{t('box')}</span>
                                <div className="main-popular__image main-popular__image_vertical">
                                    <img
                                        className="main-popular__img main-popular__img_vertical"
                                        src={item.mainImg}
                                        alt="" />
                                </div>
                                <div className="main-popular__info main-popular__info_vertical">
                                    <h3 className="main-popular__subtitle">{item.title}</h3>
                                    <span
                                        className="main-popular__text">
                                        {item.shortDesc}
                                    </span>
                                    <div className="main-popular__links">
                                        {(parseInt(item.isSale, 10) === 1)
                                            && (
                                                secondSite.indexOf(item.productCode) !== -1
                                                    ? (
                                                        <a href={saleUrl(item.slug, language)} className="slider-products__link request-link">
                                                            {t('buy')}
                                                        </a>
                                                    )
                                                    : (
                                                        <NavLink href={`/boxes/order/${item.slug}`} className="slider-products__link request-link">
                                                            {t('buy')}
                                                        </NavLink>
                                                    )
                                            )}
                                        <NavLink to={`/boxes/${item.slug}`}
                                            className="slider-products__link info-link">
                                            {t('more')}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <SwiperSlider
                className="main-popular-mobile"
                loop
                centeredSlides
                items={slides}
            />

        </section>
    )
}
