import React from 'react'
import pdfIcon from '../static/img/icon-pdf.svg'
import jpgIcon from '../static/img/icon-jpg.svg'
// import { useHistory } from 'react-router-dom'
import Loader from './base/Loader'

export default function DocsList({ docsList }) {
    // const { push } = useHistory()

    if (!docsList) return <Loader centered />

    return (
        <div className="partners">
            <div className="partners__container">
                <h1 className="partners__title">Документация</h1>
                <div className="docs__items">
                    {
                        docsList.map(({ filetype, title, description, link }) => (
                            <>
                                <a href={link} target="_blank" rel="noreferrer">
                                    <div className="docs__item">
                                        <div className="registration-losses__img">
                                            <img
                                                src={filetype === 'pdf' ? pdfIcon : jpgIcon} alt="" />
                                        </div>
                                        <div className="docs__title">
                                            {title}
                                            <div className="docs__item-desc">
                                                {description}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </>
                        ))
                    }

                </div>
            </div>
        </div>
    // <div className="partners">
    //     <div className="partners__container">
    //         <h1 className="partners__title">Документация</h1>
    //         <div class="partners__items">

    //         </div>
    //     </div>
    // </div>
    )
}
