import React, { Fragment, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import wheel from '../static/img/services/wheel-icon.svg'
import cartIcon from '../static/img/services/cart-icon.svg'
import travel from '../static/img/services/travel-icon.svg'
import Select from '../components/base/Select'
import Button from '../components/common/Button'
import countries from '../utils/countries.json'
import Benefits from '../components/Benefits'
import { Radio, RadioGroup } from '../components/base/Radio'
import { DateInput } from '../components/base/DateInput'
import Input from '../components/base/Input'
import { changeDate } from '../utils/date'
import TravelProducts from '../components/travel/TravelProducts'
import TravelBanner from '../components/travel/TravelBanner'
import { useModal } from '../hooks/modal'
import PolicyCheck from '../components/modals/PolicyCheck'
import PolicyNotFound from '../components/modals/PolicyNotFound'
import PolicySuccess from '../components/modals/PolicySuccess'
import { usePostRequest } from '../hooks/request'
import { REST } from '../utils/urls'
import { calcMulti, gender, hints, spaType } from '../helpers/travel'
import MultiSelectField from '../components/base/MultiSelectField'

export default function Travel() {
    const { push } = useHistory()
    const { t } = useTranslation('travel')
    const calc = usePostRequest({ url: REST.TRAVEL.CALC })
    const products = useRef(null)
    const buy = useRef(null)
    const [cost, setCost] = useState(false)
    const [disabledInput, setDisabledInput] = useState(false)

    const travelType = {
        one: t('oneTravel'),
        multi: t('multiTravel'),
    }
    const [showPolicyNotFound, hidePolicyNotFound] = useModal(
        <PolicyNotFound className="popup__popup-policy-success" push={push} onClose={() => hidePolicyNotFound()} />,
    )

    const [showPolicySuccessModal, hidePolicySuccessModal] = useModal(
        <PolicySuccess className="popup__popup-policy-success" push={push} hide={() => hidePolicySuccessModal()} />,
    )

    const [showPolicyCheckModal] = useModal(
        <PolicyCheck
            className="popup__policy-check"
            onOpenSuccess={showPolicySuccessModal}
            onOpenError={showPolicyNotFound} />,
    )

    const benefits = [
        { onClick: showPolicyCheckModal, image: wheel, title: 'travel:checkPolicy' },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: buy.current.offsetTop - 260,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: cartIcon,
            title: 'travel:byuOnline',
        },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: products.current.offsetTop - 220,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: travel,
            title: 'travel:travelProducts',
        },
    ]

    const initialValues = {
        country: '',
        howOften: '',
        beginDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        spaType: null,
        dayCount: 2,
        sportOption: 0,
        holderBirthday: '',
        holderGender: null,
        multi: null,
        sequence: [],
    }

    function onSubmit(data) {
        window.setTimeout(() => {
            window.scrollTo({
                top: 600,
                left: 0,
                behavior: 'smooth',
            })
        }, 0)
        push('/travel-order', { data })
    }

    let timer = null

    function calculate(values) {
        setCost(false)
        let countryList = ''
        if (values.country) {
            countryList = values.country.reduce((result, val) => {
                result.push(val.value)
                return result
            }, []).join(', ')
        }

        const error = {}

        Object.keys(initialValues).forEach((item) => {
            // console.log(values[item])
            if ((!values[item] || values[item] === '') && values[item] !== '0' && values[item] !== 0) error[item] = true
        })

        if (Object.keys(error).length !== 0) return error

        window.clearTimeout(timer)
        timer = window.setTimeout(async () => {
            const data = {
                holderLastname: '',
                holderFirstname: '',
                holderPatronymic: '',
                holderPhone: '',
                holderEmail: '',
                holderBirthday: values.holderBirthday,
                country: (countryList) || '',
                beginDate: values.beginDate || '',
                endDate: values.endDate || '',
                spaType: 0,
                status: 0,
                multi: values.multi || 0,
                las: [
                    {
                        laLastname: '',
                        laFirstname: '',
                        laPatronic: '',
                        laBirthday: values.holderBirthday || '',
                        laCitizenship: 'TJ',
                        issueDate: values.beginDate || '',
                        issueInstitution: '',
                        series: '',
                        docNumber: '',
                        dateTo: values.beginDate || '',
                        gender: values.holderGender,
                        sportOption: values.sportOption || 0,
                        sportSumPrem: 0,
                        pregnantOption: 0,
                        pregnantSumPrem: 0,
                        sunOption: 0,
                        sunSumPrem: 0,
                        alcoholicOption: 0,
                        alcoholicSumPrem: 0,
                        poisoningOption: 0,
                        poisoningSumPrem: 0,
                        sequence: values.sequence,
                    },
                ],
                mainSumAssured: 30000,
                mainSumPrem: 30000,
                thingsSumAssured: 0,
                thingsSumPrem: 0,
                flatSumAssured: 0,
                flatSumPrem: 0,
                neighborSumAssured: 0,
                neighborSumPrem: 0,
                accSumAssured: 0,
                accSumPrem: 0,

                covid19Option: 0,
                covid19SumPrem: 0,
                sumPrem: 0,
                sequence: [],

                agree: false,
                dayCount: values.dayCount || '',

                currency: 'USD',
                coupon: '',

                flightDelaySign: 0,
                baggageDelaySign: 0,
                baggageLossSign: 0,
            }
            data.sportOption = values.sportOption || 0
            const { response, success } = await calc.request({ data })
            if (success) {
                setCost(response.sumPrem)
                if (response.errorMsg) {
                    setCost(false)
                }
            } else {
                setCost(false)
            }
        }, 500)

        return {}
    }

    return (
        <Layout>
            <div className="travel-one">
                <div className="travel-one__bg">

                    <TravelBanner />
                    <Benefits benefits={benefits} />

                    <div className="travel-one__container" ref={buy}>
                        <h3 className="travel-one__title">{t('header')}</h3>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur
                            validate={calculate} validateOnChange>
                            {({ values, handleSubmit, setFieldValue, errors, validateForm }) => (
                                <Form className="travel-one__form">
                                    <h3 className="travel-one__form-title">{t('policeConditions')}</h3>

                                    <div className="travel-one__form-items">
                                        <div className="travel-one__form-item">
                                            <div className="travel-one__form-row">
                                                <MultiSelectField error={errors.country}
                                                    hint={hints.coutry} required
                                                    options={countries} name="country"
                                                    label={t('travelCountry')}
                                                    errTxt={t('chooseCountry')}
                                                />
                                            </div>
                                            <RadioGroup name="howOften" className="travel-one__form-ranges">
                                                <div className="travel-one__ranges-block">
                                                    <div className="ranges__left">
                                                        <Radio
                                                            label={t('oneTravel')}
                                                            value="one"
                                                            hint={hints.oneTrip}
                                                            onChange={() => {
                                                                setFieldValue('multi', 0)
                                                                setDisabledInput(calcMulti('one', setFieldValue))
                                                            }}
                                                            className="travel-one__form-range" />
                                                    </div>
                                                    <div className="ranges__right">
                                                        <Radio
                                                            label={t('multiTravel')}
                                                            value="multi"
                                                            hint={hints.moreTrip}
                                                            onChange={() => {
                                                                setFieldValue('multi', 1)
                                                                setDisabledInput(calcMulti('multi', setFieldValue))
                                                            }}
                                                            className="travel-one__form-range" />
                                                    </div>
                                                </div>
                                                <div className="ranges__error-block">
                                                    {
                                                        errors.multi && (
                                                            <span style={{ color: '#eb5757', fontSize: '12px' }}>
                                                                {t('wrongPoliceType')}
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </RadioGroup>

                                            <div className="travel-one__form-row">
                                                <DateInput label={t('holderBirthday')} error={errors.holderBirthday}
                                                    name="holderBirthday" required
                                                    className="input travel-one__input-date"
                                                />
                                                <Select
                                                    label={t('holderGender')} required
                                                    name="holderGender" error={errors.holderGender}
                                                    options={gender}
                                                    defValue="--------"
                                                    className="input travel-one__input-date"
                                                />
                                            </div>

                                            <div className="travel-one__form-row">
                                                <DateInput
                                                    className="input travel-one__input-date"
                                                    name="beginDate" required error={errors.beginDate}
                                                    min={moment().format('YYYY-MM-DD')}
                                                    max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        const begin = moment(e.target.value)
                                                        let end = moment(values.endDate)
                                                        let delta = end.diff(begin, 'days')
                                                        if (values.multi && values.multi !== 0) {
                                                            end = moment.unix(begin.unix()).add(1, 'year')
                                                                .add('-1', 'day')
                                                            setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                            delta = 89
                                                        } else if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                            end = moment.unix(begin.unix()).add(2, 'day')
                                                                .add('-1', 'day')
                                                            setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                            delta = 1
                                                        }
                                                        setFieldValue('dayCount', delta + 1)
                                                    }}
                                                    label={t('departureDate')}
                                                    placeholder="ДД / ММ / ГГГГ" />

                                                <DateInput
                                                    className="input travel-one__input-date"
                                                    min={moment().format('YYYY-MM-DD')}
                                                    max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                                    name="endDate" required error={errors.endDate}
                                                    isDisabled={disabledInput}
                                                    label={t('arrivalDate')}
                                                    onChange={(e) => {
                                                        const begin = moment(values.beginDate)
                                                        let end = moment(e.target.value)
                                                        if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                            end = moment.unix(begin.unix()).add(2, 'day')
                                                                .add('-1', 'day')
                                                            setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                        }
                                                        const delta = end.diff(begin, 'days')
                                                        setFieldValue('dayCount', delta + 1)
                                                    }}
                                                    placeholder="ДД / ММ / ГГГГ" />

                                            </div>

                                            <div className="travel-one__form-row">
                                                <div className="ranges__right">
                                                    <Input
                                                        isDisabled={disabledInput} required error={errors.dayCount}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            window.setTimeout(() => {
                                                                validateForm(values)
                                                                setFieldValue('endDate',
                                                                    changeDate(new Date(values.beginDate),
                                                                        e.target.value))
                                                                setFieldValue('dayCount', e.target.value)
                                                                setCost(false)
                                                            }, 0)
                                                        }}
                                                        name="dayCount"
                                                        label={t('dayCount')}
                                                        placeholder={t('dayCountPH')} />
                                                </div>
                                                <div className="ranges__right">
                                                    {
                                                        disabledInput && (
                                                            <div className="alert__label">
                                                                {t('alert90Days')}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="travel-one__form-row">
                                                <Select
                                                    name="spaType" required error={errors.spaType}
                                                    className="select select__travel-one travel-one__input_ful"
                                                    options={spaType}
                                                    hint={hints.travelType}
                                                    defValue="-----------"
                                                    onChange={(e) => {
                                                        setFieldValue('spaType', e)
                                                        setFieldValue('sportOption', e === 0 ? 0 : 1)
                                                        if (e === 0) setFieldValue('sequence', [])
                                                        else setFieldValue('sequence', [0])
                                                        window.setTimeout(() => setFieldValue('spaType', e), 1000)
                                                    }}
                                                    label={t('restTypeChoose')} />
                                            </div>
                                        </div>

                                        <div className="travel-one__total_main">
                                            <div className="travel-one__name">{t('travelCountry')}</div>

                                            <div className="travel-one__value">
                                                {!isEmpty(values.country) ? (
                                                    <Fragment>
                                                        {values.country.map((item, index) => {
                                                            if (values.country.length === 1) {
                                                                return item.label
                                                            }
                                                            return index === 0 ? item.label : `, ${item.label}`
                                                        })}
                                                    </Fragment>
                                                ) : t('nullChoose')}
                                            </div>

                                            <div className="travel-one__name">
                                                {t('frequencyTravel')}
                                            </div>
                                            <div className="travel-one__value">
                                                {!isEmpty(values.howOften)
                                                    ? travelType[values.howOften] : t('nullChoose')}

                                            </div>

                                            <div className="travel-one__name">{t('travelPeriod')}</div>
                                            <div className="travel-one__value">
                                                {!isEmpty(values.beginDate || values.endDate) ? (
                                                    <Fragment>
                                                        {!isEmpty(values.beginDate)
                                                            ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                        &nbsp;-&nbsp;
                                                        {!isEmpty(values.endDate)
                                                            ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                    </Fragment>
                                                ) : t('nullChoose')}
                                            </div>

                                            <div className="travel-one__name">{t('restType')}</div>
                                            <div className="travel-one__value">
                                                {
                                                    (!isEmpty(spaType[values.spaType]))
                                                        ? spaType[values.spaType].name
                                                        : t('nullChoose')
                                                }
                                            </div>

                                            {
                                                cost ? (
                                                    <>
                                                        <div
                                                            className="travel-one__name">
                                                            {t('fullCost')}
                                                        </div>
                                                        <div
                                                            className="travel-one__value">
                                                            {cost} TJS
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                            <Button
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                                disabled={values.agree}
                                                text={t('holderAgree')}
                                                className="travel-one__form-btn" />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

                <div className="travel-one__container">
                    <h3 className="travel-one__title">{t('travelBoxProducts')}</h3>
                </div>

                <div ref={products} />
                <TravelProducts />

                <div className="travel-one__container" style={{ display: 'none' }}>
                    <h3 className="travel-one__title">Внимание SEO текст!</h3>
                    <div className="seo-text">
                        <p className="seo-text__text">
                            Харизматическое лидерство, тем более в условиях социально-экономического кризиса, стабильно.
                            Как
                            уже подчеркивалось,
                            политическая легитимность представляет собой коллапс Советского Союза. Общеизвестно, что
                            политическое учение
                            Августина практически сохраняет функциональный политический процесс в современной России.
                            Натуралистическая
                            парадигма теоретически верифицирует социализм. Гуманизм, на первый взгляд, обретает
                            гносеологический
                            континентально-европейский тип политической культуры.
                        </p>
                    </div>
                    <ul className="seo-text__ul">
                        <li className="seo-text__li">В данном случае можно согласиться с Данилевским;</li>
                        <li className="seo-text__li">
                            Cчитавшим, что политическое учение Фомы Аквинского практически обретает функциональный;
                            Континентально-европейский
                            тип политической культуры;
                        </li>
                        <li className="seo-text__li">Политическое манипулирование случайно. Коллапс Советского Союза
                            теоретически возможен;
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
