import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '../base/Button'
import { usePostRequest } from '../../hooks/request'
import { REST } from '../../utils/urls'
import { Context } from '../../contexts/GlobalContext'
import InputMask from '../base/InputMask'

export default function BackCall({ note }) {
    const { setModalComponent } = useContext(Context)
    const [show, setShow] = useState(false)
    const [done, setDone] = useState(null)
    const callMe = usePostRequest({ url: REST.BACK_CALL })

    const { t } = useTranslation('common')

    async function onCheckPolicy({ phoneNumber }) {
        const data = {
            phone: phoneNumber,
            type: 'back_call',
            note,
        }
        const { success } = await callMe.request({ data })

        if (success) {
            setDone(true)
        } else {
            setDone(false)
        }

        setTimeout(() => setModalComponent(null), 3000)
    }

    setTimeout(() => setShow(!show), 300000)

    if (done === true) {
        return (
            <div className="popup__content">
                <div className="popup__finish">
                    <h4 className="popup__title">{t('requestRegistred')}</h4>
                </div>
            </div>
        )
    }

    if (done === false) {
        return (
            <div className="popup__content">
                <div className="popup__finish">
                    <h4 className="popup__title">{t('errorExist')}</h4>
                </div>
            </div>
        )
    }

    return (
        <div className="popup__content">
            <h4 className="popup__title">{t('backcallTitle')}</h4>

            <Formik initialValues={{ phoneNumber: '' }} onSubmit={onCheckPolicy}>
                {({ handleSubmit }) => (
                    <div className="popup-policy-check">

                        <div className="popup-policy-check__tab-content current">
                            <div className="popup-policy-check__text">{t('phoneInput')}</div>
                            <InputMask name="phoneNumber" className="popup-policy-check__input" placeholder="(992) 99-999-9999" />
                        </div>

                        <Button
                            loading={callMe.loading}
                            onClick={handleSubmit}
                            className="popup-policy-check__button"
                            text={t('sendRequest')} />
                    </div>
                )}
            </Formik>
        </div>
    )
}
