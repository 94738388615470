import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FormTotal from './FormTotal'

export default function FormStep5() {
    const { t } = useTranslation('travel')
    const { values } = useFormikContext()

    return (
        <section className="step step-5">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <h3 className="travel-order__subtitle subtitle">
                        {t('checkData')}
                    </h3>
                    <div className="contacts__name">{t('insured')}</div>
                    <p className="contacts__data">{values.holderFullName}</p>
                    <p className="contacts__data">{moment(values.holderBirthday).format('DD.MM.YYYY')}</p>
                    <p className="contacts__data">{values.holderEmail}</p>
                    <div className="contacts__name">{t('insuredCount', { count: values.las.length })}</div>
                    {
                        values.las.map((item) => (
                            <div className="contacts__name_wrap">
                                <p className="contacts__data">
                                    {item.laFullname}
                                </p>
                                <p className="contacts__data">
                                    {moment(item.laBirthday).format('DD.MM.YYYY')}
                                </p>
                            </div>
                        ))
                    }
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
