import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './common/Button'
import Input from './base/Input'
import ServerError from './common/ServerError'
import { useLoad } from '../hooks/request'
import { REST } from '../utils/urls'
import Password from './Password'

export default function Login() {
    const history = useHistory()

    const signIn = useLoad({ url: REST.TEST })

    async function onSubmit(data) {
        const { response, success } = await signIn.request({ data })
        if (success) {
            signIn(response, history)
        }
    }

    return (
        <div className="main-popular-mobile pl-1 pr-1">
            <div style={{ display: 'flex' }}>
                <h2 className={cn('is-size-4 has-text-weight-bold mr-3')}>Вход /</h2>
                <h2 className={cn('is-size-4 has-text-weight-bold', css(styles.hasTextPrimary))}>Регистрация</h2>
            </div>
            <br />
            <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
                <Form className="mr-2">
                    <ServerError error={signIn.error} />
                    <Input
                        name="email"
                        icon="ion-md-mail"
                        label="Введите номер телефона"
                        placeholder="+992 00 000 00 00" />
                    <Password
                        name="password"
                        label="Введите пароль"
                        placeholder="**************************" />

                    <div className={cn('columns', css(styles.titles))}>
                        <div className={cn('column', css(styles.forgetPassword))}>Забыли пароль?</div>
                        <div className={cn('column', css(styles.registration))}>Зарегистрироваься</div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.loading}
                                className={cn('button is-link is-medium is-fullwidth', css(styles.button))}
                                text="Войти"
                                type="submit" />
                        </div>
                    </div>

                </Form>
            </Formik>
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: '1.25rem',
    },
    img: {
        width: '10rem',
    },
    roundedButton: {
        borderRadius: '50%',
    },
    input: {
        color: '#4a4a4a',
    },
    onlyMobile: {
        // marginRight: 10,
        // textAlign: 'center',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    forgotPassword: {
        color: '#999',
        fontSize: '.9rem',
        ':hover': {
            color: '#0062ff',
        },
    },
    forgetPassword: {
        color: '#FBBE07',
        fontSize: 14,
        textAlign: 'left',
    },
    registration: {
        color: '#FBBE07',
        fontSize: 14,
        textAlign: 'right',

    },
    titles: {
        display: 'flex',
        marginBottom: 30,
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#FBBE07',
    },
    hasTextPrimary: {
        color: '#f1f0f0',
    },
})
