export default {
    email: 'Адрес электронной почты',
    subscribe: 'Подписаться',
    success: 'Вы успешно подписались',
    errorEmail: 'Неверно заполнен email',
    text1: 'Нажимая на кнопку ',
    text2: 'Подписаться',
    text3: ' я соглашаюсь с ',
    text4: 'условиями обработки персональных данных',
    text5: ' и ',
    text6: 'правилами пользования сайтом',
}
