/* eslint max-len: 0 */
export default {
    header: 'Тартиби полис барои сафар',
    byuOnline: ' Оnline-хариди полис',
    toByuOnline: 'Гузаштан ба пардохт',
    wrongInput: 'Нодуруст пур карда шудааст',
    departureDate: 'Санаи рафтан',
    arrivalDate: 'Санаи расидан',
    departureDatePH: 'Рӯз /Моҳ /Сол',
    arrivalDatePH: 'Санаи расидан',
    checkPolicy: 'Тафтиши полис',
    travelProducts: 'Маҳсулот барои сафар',
    oneTravel: 'Барои як сафар',
    multiTravel: 'Барои якчанд сафар',
    frequencyTravel: 'Басомади сафар',
    travelPeriod: 'Давраи сафар',
    policeConditions: 'Шартҳои полис',
    travelCountry: 'Кишвари сафар',
    chooseCountry: 'Интихоби кишвар',
    wrongPoliceType: 'Навъи полис интихоб нашудааст',
    alert90Days: 'Ҳангоми интихоби якчанд сафар, танҳо барои 90 рӯз тартиб додан мумкин аст',
    dayCount: 'Шумораи рӯзҳо',
    dayCountPH: 'Шумораи рӯзҳо',
    restTypeChoose: 'Навъи истироҳатро интихоб кунед',
    restType: 'Навъи истироҳат',
    fullCost: 'Ҳамагӣ',
    travelBoxProducts: 'Маҳсулоти қуттигӣ барои сафаркунандагон',
    nullChoose: 'Интихоб нашудааст',
    buy: 'Харидан',
    toBuy: 'Гузаштан ба пардохт',
    more: 'Муфассал',
    insured: 'Суғурташаванда',
    holderFullName: 'Ному насаб',
    holderFullNamePH: 'Ivanov Ivan Ivanovich',
    holderPhone: 'Телефон',
    holderEmail: 'Email',
    holderDocSeries: 'Силсилаи шиноснома',
    holderDocNumber: 'Рақами шиноснома',
    holderBirthday: 'Санаи таваллуд',
    holderGender: 'Ҷинс',
    holderIssueDate: 'Санаи додани шиноснома',
    holderAddressReg: 'Суроғаи бақайдгирӣ',
    holderPostalCodeReg: 'Индекси почта',
    holderAddressFact: 'Суроғаи бақайдгирӣ',
    holderPostalCodeFact: 'Индекси почта',
    holderPostalFactEqReg: 'Ба суроғаи бақайдгирӣ мувофиқат мекунад',
    holderAddressPH: 'Суроғаи бақайдгирӣ',
    holderPostalCodePH: '743000',
    holderAgree: 'Тасдиқ',
    holderOblProj: 'Область проживания',
    holderGorodProj: 'Город проживания',
    holderUlProj: 'Улица проживания',
    holderDomProj: 'Дом проживания',
    holderKvProj: 'Квартира проживания',
    holderData: 'Оиди Суғурташаванда',
    copyHolderData: 'Нусхабардории маълумоти Суғурташуда',
    lasInfo: 'Маълумот дар бораи Суғурташуда',
    lasAdd: 'Илова кардани Суғурташуда',
    laFullname: 'Ному насаб',
    laFullnamePH: 'Алексей Петрович Иванов',
    laBirthday: 'Санаи таввалуд',
    laCitizenship: 'Шаҳрвандӣ',
    series: 'Силсидаи шиноснома',
    docNumber: 'Рақами шиноснома',
    issueDate: 'Санаи додани шиноснома',
    gender: 'Ҷинс',
    sumInsured: 'Маблағҳои суғуртавӣ',
    currency: 'Асъор',
    medical: 'Хароҷоти тиббӣ ва таъҷилӣ',
    mainAvoid: 'Маблағи камтар',
    personalSwag: 'Ашёҳои шахсӣ',
    houseProtect: 'Муҳофизати хона',
    goodNeighbors: 'Ҳамсояҳои хуб',
    accident: 'Суғурта аз ҳодисаҳои нохуш',
    optionsMore: 'Опсияҳои иловагӣ',
    sportOption: 'Варзиш',
    covid19Option: 'Ковид-19',
    pregnantOption: 'Мушкилоти ҳомиладорӣ',
    sunOption: 'Сӯхтан аз офтоб',
    alcoholicOption: 'Хароҷоти тиббӣ ва таъҷилӣ, дар ҳолати мастии майзадагӣ',
    poisoningOption: 'Заҳролудшавӣ аз ғизо',
    promoCode: 'Промокод',
    errorCoupon: 'Хатогии воридкунии купон',
    coupon: 'Купон',
    optionsMore1: '',
    holderIssueInstitution: 'Паспорт дода шудааст',
    discount: 'Тахфиф',
    options: 'Имконот',
    amount: 'сум',
    fullPrice: 'Ҳамагӣ',
    fillFullName: 'Ном пур нашудааст',
    fillName: 'NAME пур нашудааст',
    accidentManual: 'Суғуртаи садама - дастӣ',
    lasRemove: 'Суғурташударо хориҷ кунед',
    insuredInfo: 'Суғурташуда {{count}}',
    headNavData: 'Ҳисобкунии пешакӣ',
    holderDocType: 'Навъи ҳуҷҷат',
    chooseLimit: 'Андозаро интихоб кунед',
    headNavOptions: 'Интихоб кунед',
    headNavInsurer: 'Ороиш',
    headNavInsured: 'Пардохт',
    travelingWho: 'Кӣ меравад',
    travelingPeriod0: '4 - 69 сола',
    travelingPeriod1: '0 - 12 моҳ',
    travelingPeriod2: '1 - 3 сол',
    travelingPeriod3: '70 - 74 сола',
    travelingPeriod4: 'То 80 сола',
    headNavBuy: 'Тарҳрезӣ',
    headNavPrograms: 'Барномаҳо',
    fullPolicyCost: 'Ҳамагӣ',
    mainPolicyCost: 'Сиёсат',
    optionsPolicyCost: 'Имконот',
    nextStep: 'Минбаъд',
    totalDays: 'Рӯзҳо: {{count}}',
    insuredCount: 'Суғурташуда: {{count}}',
    insured0: '{{count}} мусофир',
    insured1: '{{count}} мусофир',
    insured2: '{{count}} сайёҳон',
    additionalOptions: 'Вариантҳои иловагӣ',
    sumOptions: 'Ҳамагӣ',
    changeButton: 'Тағйир',
    addButton: 'Илова',
    deleteButton: 'Нобуд кунед',
    flightDelaOption: 'Таъхири парвоз',
    baggageDelayOption: 'Таъхири бағоҷ',
    baggageLossOption: 'Бағоҷи гумшуда',
    traveling: 'Сафар',
    spaType0: 'Ором',
    spaType0Hint: 'Экскурсияҳо, истироҳатҳои соҳилӣ, велосипедронӣ, савори ҳайвонотро дар бар мегирад.',
    spaType1: 'шадид',
    spaType1Hint: 'шадид',
    holderAsInsured: 'Соҳиби полис суғурта шудааст.',
    insuranceCost: 'Нарх: {{price}}',
    mainSumPrem: 'Маблағи пӯшиш',
    seacreatureOption: 'Тамос бо хазандаҳои баҳрӣ',
    optionsHouse: 'Хона:',
    optionsHousePH: 'Суроға',
    optionsHouseHint: 'Адреси пурраи хона/квартира, ки дар вакти таътил сугурта карда шудааст, дохил кунед.',
    policeAfterPayment: 'Пас аз пардохт, сиёсат ба суроғаи почтаи электронӣ фиристода мешавад.',
    openInfoPopup: 'Тавсифи пурраи фарогирӣ',
    hint_fio: 'Номи пурраи худро ворид кунед. Масалан, Иванов Иван Иванович',
    hint_coutry: ' Кишвари сафарро интихоб кунед',
    hint_bd: ' Санаи таваллуди худро ворид кунед. Масалан, 04/04/1976.',
    hint_gender: ' Ҷинси худро зан ё мард ворид кунед',
    hint_email: ' Суроғаи имэйл',
    hint_passport: ' Серия ва рақами шиносномаи хориҷии шумо, аз ҷониби кӣ ва кай дода шудааст',
    hint_regAddr: ' ки дар паспорт нишон дода шудааст, кишвар, шахр, район, куча, хона/квартира',
    hint_factAddr: ' Кишвар, шаҳр, ноҳия, кӯча, хона/рақами квартира – дар куҷо зиндагӣ мекунед',
    hint_phoneH: ' Рақами телефони хонагии худро ворид кунед (агар дастрас бошад)',
    hint_inn: ' Рақами шахсии андозсупорандаи худро ворид кунед, ки аз ҷониби мақомоти андози Ҷумҳурии Тоҷикистон дода шудааст.',
    hint_phoneM: ' Рақами мобилии худро ворид кунед',
    hint_periodSafe: ' Санаи оғози сафар ва санаи анҷоми сафар',
    hint_currency: ' доллари ИМА, евро, рубли русӣ, сомони тоҷикӣ',
    hint_optSport: ' Машқҳои варзишии дорои хавфи зиёд (аз ҷумла лижаронӣ дар роҳҳои махсус муҷаҳҳазшуда), варзиши касбӣ, иштирок дар мусобиқаҳо ва мусобиқаҳо, парашютӣ, рондани воситаҳои нақлиёт.',
    hint_optCovid: 'BIMA хароҷоти табобатӣ ва сафарро, ки бар асари Ковид-19 сурат гирифтааст, ҷуброн мекунад. Сарпӯши суғурта то $ 5,000.',
    hint_flightDelaOption: 'BIMA зарари моддиеро, ки бо таъхир дар оғози сафар беш аз 8 соат алоқаманд аст, ҷуброн мекунад.',
    hint_baggageDelayOption: 'BIMA хароҷоти марбут ба интизории бағоҷро, ки таъхири онҳо зиёда аз 48 соат аст, ҷуброн мекунад.',
    hint_baggageLossOption: ' BIMA хароҷоти марбут ба нобудшавӣ ё гум шудани бағоҷи таҳти масъулияти интиқолдиҳанда расонидашударо ҷуброн мекунад.',
    hint_optPregnant: 'Бима харочотро, ки бо расондани ёрии таъчилй ва ёрии таъчилии тиббй дар сурати ногахонй мушкил шудани хомиладор аст, чуброн мекунад.',
    hint_optSun: 'BIMA хароҷоти тиббии дар натиҷаи сӯхтани офтобро ҷуброн мекунад.',
    hint_optAlcohol: 'BIMA хароҷоти табобат ва ҳолати фавқулодда, дар ҳолати бемориҳо, ҷароҳатҳое, ки дар робита ба истеъмоли машрубот рух додаанд, ҷуброн мекунад.',
    hint_optFood: 'Хароҷоти тиббӣ ва сафар дар натиҷаи заҳролудшавӣ аз ғизо',
    hint_promocode: 'Барои гирифтани тахфиф кодро ворид кунед',
    hint_medical: 'BIMA хароҷоти марбут ба беморхонаи изтирорӣ ё интиқол дар давоми сафарро ҷуброн мекунад',
    hint_personalSwag: 'BIMA арзиши ашёи шахсӣ - таҷҳизоти мобилӣ, таҷҳизоти варзишӣ, либоси берунӣ ва бағоҷи дастиро (дар ҳудуди муқарраршуда) ҷуброн мекунад.',
    hint_houseProtect: 'BIMA хароҷоти таъмири хона/квартираеро, ки дар натиҷаи обхезӣ ё дуд ва ғайра зарар дидаанд, ҷуброн мекунад.',
    hint_goodNeighbors: 'BIMA хароҷоти марбут ба расонидани зарар ба амволи ҳамсоягонро бо айби шумо ҳангоми сафар ҷуброн мекунад.',
    hint_accident: 'BIMA хароҷоти марбут ба осеби вазнини ҷисмонӣ ба Суғурташавандаро дар натиҷаи садама ё марг ҷуброн мекунад.',
    hint_oneTrip: ' Сиёсат барои як сафар эътибор дорад',
    hint_moreTrip: ' Сиёсат, ки ба шумо имкон медиҳад, ки дар давоми сол якчанд маротиба сафар кунед (шумораи рӯзҳо барои ҳар як сафар 90 рӯз аст)',
    hint_travelType: ' Истироҳати ором, варзиш, варзиши экстремалӣ, истироҳати оилавӣ, тури гастрономӣ',
    checkData: 'Тафсилотро тафтиш кунед ва усули',
    payType: 'Пардохтро интихоб кунед',
    paySystem1: 'Online Pay',
    paySystem2: 'Vasl Pay',
    paySystem3: 'DC Pay',
}
