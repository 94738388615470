export default {
    email: 'E-mail ',
    subscribe: 'Обунашавӣ',
    success: 'Шумо бомуваффақият обуна шудед',
    errorEmail: 'E-mail нодуруст пур карда шудааст',
    text1: 'Бо пахши тугмаи ',
    text2: 'Обунашавӣ',
    text3: ' Розиям бо',
    text4: 'шартҳои коркарди маълумоти шахсӣ',
    text5: ' ва ',
    text6: 'шартҳои истифодаи сомона',
}
