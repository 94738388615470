import React, { useContext, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Layout from '../components/Layout'
import Loader from '../components/base/Loader'
import { AuthContext } from '../contexts/AuthContext'
import VacancyForm from '../components/vacancy/VacancyForm'
import { Context } from '../contexts/GlobalContext'

export default function VacancyDetail() {
    const { slug } = useParams()
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { state } = useLocation()
    const form = useRef(null)

    if (state && state.sendForm) {
        window.setTimeout(() => {
            window.scrollTo({
                top: form.current.offsetTop,
                left: 0,
                behavior: 'smooth',
            })
        }, 500)
    }
    const { t } = useTranslation(['vacancy', 'common'])

    const vacancy = useLoad({ url: CMS.VACANCY.replace('{slug}', slug) }, [slug, cmsToken, language])

    if (!vacancy) {
        return (
            <Layout>
                <div className="vacancy">{t('common:loading')}</div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="vacancy">
                {!vacancy.loading ? (
                    <div className="vacancy__bg">
                        {vacancy.response ? (
                            <div className="vacancy__container">
                                <h1 className="vacancy__title">
                                    {vacancy.response.title}
                                </h1>

                                <div className="vacancy__head">
                                    <div className="vacancy__head-item">
                                        {t('location')}: {vacancy.response.location}
                                    </div>

                                    <div className="vacancy__head-item">
                                        {t('salary')}: {vacancy.response.salary}
                                    </div>

                                    <div className="vacancy__head-item">
                                        {t('experienceOnPosition')}: {vacancy.response.exp}
                                    </div>
                                </div>

                                <div className="vacancy__items">
                                    <div className="vacancy__item">
                                        <picture className="vacancy__img">
                                            <source media="(min-width: 1280px)" srcSet={vacancy.response.mainImg} />
                                            <img src={vacancy.response.mainImg} alt="" />
                                        </picture>

                                        <p className="vacancy__name">{t('dateEnd')}</p>
                                        <p className="vacancy__value">{vacancy.response.dateEnd}</p>

                                        <p className="vacancy__name">{t('salary')}</p>
                                        <p className="vacancy__value">{vacancy.response.salary}</p>

                                        <p className="vacancy__name">{t('experienceOnPosition')}</p>
                                        <p className="vacancy__value">{vacancy.response.exp}</p>

                                        <p className="vacancy__name">{t('workSchedule')}</p>
                                        <p className="vacancy__value">{vacancy.response.workStart}</p>

                                        {/* <label className="vacancy__button" type="button" onClick={() => {
                                            window.scrollTo({
                                                top: form.current.offsetTop,
                                                left: 0,
                                                behavior: 'smooth',
                                            })
                                        }}>
                                            Отправить резюме
                                        </label> */}
                                    </div>

                                    <div className="vacancy__item">
                                        <h3 className="vacancy__subtitle">{t('candidatesRequirements')}:</h3>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={{ __html: vacancy.response.req }} />

                                        <h3 className="vacancy__subtitle">{t('duties')}:</h3>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={{ __html: vacancy.response.resp }} />

                                        <h3 className="vacancy__subtitle">{t('conditions')}:</h3>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <div dangerouslySetInnerHTML={{ __html: vacancy.response.conditions }} />
                                    </div>
                                </div>
                                <div ref={form} />
                                <VacancyForm vacId={vacancy.response.id} />
                            </div>
                        ) : null}
                    </div>
                ) : <Loader centered /> }
            </div>
        </Layout>
    )
}
