import React, { createContext, useContext } from 'react'
import { Field } from 'formik'
import cn from 'classnames'
import hintIcon from '../../static/img/svg/hint-icon.svg'

const Context = createContext()

export function Radio({ label = null, value, className, onChange = () => null, hint, refLinlk }) {
    const { name } = useContext(Context)

    return (
        <div className={className} ref={refLinlk}>
            <label className={cn({ hint__label: hint }, 'radiobutton__label')}>
                {label}
                {
                    hint
                        ? (
                            <span className="hint__icon">
                                <img src={hintIcon} alt="" width="16" height="16" />
                                <span className="hint__content">
                                    {hint}
                                </span>
                            </span>
                        ) : null
                }
                <Field name={name}>
                    {({ form, field }) => (
                        <div>
                            <input
                                onChange={async () => {
                                    await form.setFieldValue(name, value)
                                    await onChange()
                                }}
                                type="radio"
                                value={value}
                                checked={field.value === value} />
                            <span className="radiobutton__span" />
                        </div>
                    )}
                </Field>
            </label>
        </div>
    )
}

export function RadioGroup({ name, children, className, ref }) {
    return (
        <div className={className} ref={ref}>
            <Context.Provider value={{ name }}>
                {children}
            </Context.Provider>
        </div>
    )
}
