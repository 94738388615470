export default {
    incorrectlyFilled: 'Неверно заполнено',
    continue: 'Продолжить',
    loading: 'Загрузка...',
    wrongInput: 'Неверно заполнено',
    addFile: 'Прикрепить файл',
    maxFiles: 'Максимум 10 файлов',
    fileHeader: 'Добавить фото с места события и/или сканы документов',
    agreement1: 'Я согласен(-на) с условиями',
    agreement2: 'обработки персональных данных',
    agreement3: 'и',
    agreement4: 'правилами пользования сайтом',
    notFound: 'Страница не найдена или находится в разработке',
    internalError: 'Внутрення ошибка сервера',
    clients: 'Клиентам',
    business: 'Бизнесу',
    partners: 'Партнерам',
    offices: 'Офисы',
    registerTask: 'Страховой случай',
    enterText: 'Войти',
    successText: 'Спасибо за заказ!',
    errorText: 'Произошла ошибка.',
    company: 'Компания',
    servicesText: 'Услуги',
    communicateText: 'Свяжитесь с нами',
    login: 'Вход',
    registerText: 'Регистрация',
    partnerLinkText: 'Партнёрам',
    enterPhone: 'Введите номер телефона',
    enterPassword: 'Введите пароль',
    forgotPassword: 'забыли пароль?',
    enterSMS: 'Введите код из СМС',
    repeatSMS: 'Отправить код повторно через ',
    seconds: ' сек.',
    newPassword: 'Придумайте пароль',
    repeatPassword: 'Повторите пароль',
    continueText: 'Продолжить',
    registerLinkText: 'Зарегистрироваться',
    rulesText1: 'Нажимая на кнопку «Продолжить», я соглашаюсь с ',
    rulesText2: 'условиями обработки персональных данных',
    rulesText3: ' и ',
    rulesText4: 'правилами пользования сайтом',
    rus: 'Русский',
    tjs: 'Таджикский',
    eng: 'Английский',
    goHome: 'На главную',
    languageSelect: 'Выберите язык: ',
    requestRegistred: 'Заявка зарегистрирована',
    errorExist: 'Произошла ошибка, повторите позднее',
    backcallTitle: 'Обратный звонок',
    phoneInput: 'Введите номер телефона',
    sendRequest: 'Отправить',
    sendPay: 'Оплатить',
}
