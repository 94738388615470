import React, { useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import svg from '../static/img/svg/svg-sprite.svg'
import Layout from '../components/Layout'
import BoxInsurance from '../components/box/BoxInsurance'
import BoxHead from '../components/box/BoxHead'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import Loader from '../components/base/Loader'

const codes = {
    SCI_P1: 'standart',
    SCI_P2: 'komfort',
    SCI_P3: 'premium',
    SCI_P4: 'individualnyy',
}

function Box() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { t } = useTranslation(['box', 'common'])
    const { slug } = useParams()
    const { push } = useHistory()
    const { response: boxes } = useLoad({
        url: CMS.DIRECT_BOX
            .replace('{slug}', codes[slug.toUpperCase()]),
    }, [cmsToken, language])

    if (!boxes) {
        return (
            <Layout>
                <Loader />
            </Layout>
        )
    }

    const box = boxes[0]

    return (
        <Layout>
            <div className="box">
                <BoxHead banner={box} />
                <div className="box__container">
                    <p className="box__text" dangerouslySetInnerHTML={{ __html: box.fullDesc }} />
                </div>
                { parseInt(box.isSale, 10) === 1
                    && (
                        <div className="box__calc">
                            <div className="box__container">
                                <div className="box__calc-items">
                                    <div className="box__calc-item">
                                        <div className="box__calc-string">
                                            <svg width="16" height="16">
                                                <use xlinkHref={`${svg}#icon-calc-1`} />
                                            </svg>
                                            <div className="box__calc-row">
                                                {t('insuranceCost')}
                                            </div>
                                        </div>
                                        <div className="box__calc-total">
                                            {parseInt(box.price, 10) > 0 ? `${box.price} TJS` : '-'}
                                        </div>
                                    </div>
                                    <div className="box__calc-item">
                                        <div className="box__calc-string">
                                            <svg width="16" height="16">
                                                <use xlinkHref={`${svg}#icon-calc-2`} />
                                            </svg>
                                            <div className="box__calc-row">
                                                {t('insuredSum')}
                                            </div>
                                        </div>
                                        <div className="box__calc-total">
                                            {parseInt(box.prepaid, 10) > 0 ? `${box.prepaid} TJS` : '-'}
                                        </div>
                                    </div>
                                    <div className="box__calc-item">
                                        <div className="box__calc-string">
                                            <svg width="16" height="16">
                                                <use xlinkHref={`${svg}#icon-calc-3`} />
                                            </svg>
                                            <div className="box__calc-row">
                                                {t('validaty')}
                                            </div>
                                        </div>
                                        <div className="box__calc-total">
                                            {box.activeMonths} {t('periodName')}
                                        </div>
                                    </div>
                                    <button className="box__button" type="button"
                                        onClick={() => {
                                            if (parseInt(box.price, 10) > 0) push(`/auto/order/${slug}`)
                                            else push('/offices')
                                        }}>
                                        { t('buy') }
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                <BoxInsurance />
            </div>
        </Layout>
    )
}

export default Box
