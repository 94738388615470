import React from 'react'
import cn from 'classnames'
import { Field } from 'formik'

export default function Input({
    name,
    className,
    divClass,
    type,
    label,
    error,
    hint,
    placeholder,
    errTxt,
    onChange = () => null,
    onKeyDown = () => null,
    onBlur = () => null,
    isDisabled,
    required,
    value,
    mask,
    upper,
    refLinlk,
    ...attributes
}) {
    return (
        <Field name={name}>
            {({ form, field }) => (
                <input
                    id={name}
                    value={field.value || value}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    onBlur={onBlur}
                    className={cn('input__input', className)}
                    ref={refLinlk}
                    onChange={(e) => {
                        let val = e.target.value
                        if (type === 'number') {
                            val = Number(val)
                        } else {
                            if (upper) val = val.toUpperCase()
                            if (mask) val = val.replace(mask, '')
                        }
                        form.setFieldValue(name, val).then(() => onChange(e))
                    }}
                    type={type || 'text'}
                    onKeyDown={onKeyDown}
                    {...attributes} />
            )}
        </Field>
    )
}
