import React from 'react'
import Slider from 'react-slick'
import { NavLink } from 'react-router-dom'

const AutoCarousel = ({ partners }) => {
    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: partners.length > 6,
        pauseOnHover: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 4000,
        draggable: true,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 4,
                    infinite: partners.length > 4,
                },
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 3,
                    infinite: partners.length > 3,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 2,
                    infinite: partners.length > 2,
                },
            },
        ],
    }

    if (partners.length === 0) return null

    return (
        <Slider {...settings}>
            {partners.map((item) => (
                <NavLink to={`/partners/${item.id}`}
                    key={item.id} className="main-partners__block">
                    <img className="main-partners__image" src={item.img} alt={item.title} />
                    {/* <span>555</span> */}
                </NavLink>
            ))}
        </Slider>
    )
}

export default AutoCarousel
