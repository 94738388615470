import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import CheckboxOptions from './CheckboxOptions'
import { AutoContext } from '../../contexts/AutoContext'
import FormTotal from './FormTotal'
import { createPremSum } from '../../helpers/travel'

export default function FormStep1() {
    const { t } = useTranslation('auto')
    const { setFieldValue, handleSubmit, validateForm, values } = useFormikContext()
    const { eciCodes, debouceHandleSubmit, setFirstScreen } = useContext(AutoContext)
    const [opened, setOpened] = useState('')

    const selectRefs = useRef({})

    const setProgram = useMemo(() => (program) => {
        setFieldValue('program', program)
        eciCodes.forEach((item) => {
            if (item !== program) {
                setFieldValue(item, false)
            }
        })
    })

    const options = useMemo(() => ([
        // eslint-disable-next-line max-len
        {
            hint: 'ECI_P1_hint',
            name: 'ECI_P1',
            range: [],
            prem: 'totalLossPrem',
            icon: 'crush',
            handler: () => setProgram('ECI_P1'),
            selectLabel: 'amountCompensation',
            radio: true,
        },
        // eslint-disable-next-line max-len
        {
            hint: 'ECI_P2_hint',
            name: 'ECI_P2',
            range: [],
            prem: 'partialDamagePrem',
            icon: 'pdtl',
            handler: () => setProgram('ECI_P2'),
            selectLabel: 'amountCompensation',
            radio: true,
        },
        // eslint-disable-next-line max-len
        {
            hint: 'ECI_P3_hint',
            name: 'ECI_P3',
            range: [],
            prem: 'theftPrem',
            icon: 'ugon',
            handler: () => setProgram('ECI_P3'),
            selectLabel: 'amountCompensation',
            radio: true,
        },
    ].filter((item) => !(item.name === 'ECI_P2' && values.carIssueYearDelta > 20))), [values.carIssueYearDelta])

    const features = [
        { hint: 'crSumAssuredHint', name: 'crSumAssured', prem: 'crSumPrem', icon: 'crush', range: createPremSum(33) },
        { hint: 'accSumAssuredHint', name: 'incidentSumAssured', prem: 'incidentPrem', icon: 'hospitalization', range: createPremSum(32) },
        // { hint: 'noTraficPoliceOptionHint', name: 'noTraficPoliceOption', prem: 'noTraficPolicePrem', range: [5000, 10000] },
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (opened !== '' && selectRefs.current[opened] && !selectRefs.current[opened].contains(event.target)) setOpened('')
        }

        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [opened])
    useEffect(() => validateForm(), [])
    useEffect(() => setFirstScreen(false), [])

    return (
        <section className="step step-3">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item" onClick={() => handleSubmit()}>
                    {
                        options.map(({ hint, name, prem, icon, range, handler, selectLabel, radio }) => (
                            <CheckboxOptions name={name} hint={t(hint)} prem={prem} icon={icon} label={t(name)} onChange={() => {
                                if (handler) handler()
                                debouceHandleSubmit()
                            }} options={range} selectLabel={t(selectLabel)} radio={radio} />
                        ))
                    }
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('programsMore')}:</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    {
                        features.map(({ hint, name, range, prem, icon, handler, selectLabel }) => (
                            <CheckboxOptions name={name} hint={t(hint)} prem={prem} icon={icon} label={t(name)} options={range} onChange={() => {
                                if (handler) handler()
                                debouceHandleSubmit()
                            }} selectLabel={t(selectLabel)} />
                        ))
                    }
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
