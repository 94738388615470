import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import math from 'lodash'
import cn from 'classnames'
import { CMS } from '../../utils/urls'
import { hints } from '../../helpers/travel'
import Input from '../base/Input'
import { usePostRequest } from '../../hooks/request'
import TextArea from '../base/TextArea'
import Checkbox from '../base/Checkbox'
import Button from '../base/Button'
import addIcon from '../../static/icons/icon-add.svg'
import pdfIcon from '../../static/img/icon-pdf.svg'
import jpgIcon from '../../static/img/icon-jpg.svg'
import cross from '../../static/icons/cross.svg'

const trueFile = 'application/pdf'

export default function VacancyForm({ vacId }) {
    const sendResume = usePostRequest({ url: CMS.SEND_RESUME }, [])
    const { t } = useTranslation(['vacancy', 'common'])
    const [error, setError] = useState(false)
    const [files, setFiles] = useState([])
    const [sended, setSended] = useState(false)

    const addFile = ({ target }) => {
        const filesArray = [...files]
        Array.from(target.files).some((file) => {
            if (filesArray.length >= 5) return true
            filesArray.push(file)
            return false
        })
        setFiles(filesArray)
    }

    const deleteFile = (i) => {
        const newFiles = files.filter((_, index) => i !== index)
        setFiles(newFiles)
    }

    const validate = ({ email, phone }) => {
        const errors = {}

        if (!email || email.length > 50 || (!email.match(/^[\w-.]+@[\w-]+\.[a-z]{2,}$/i))) errors.email = true
        if (!phone || phone.length > 13) errors.phone = true

        return errors
    }

    const sendData = async (values) => {
        const data = new FormData()
        data.append('vac_id', vacId)
        data.append('fio', values.fullName)
        data.append('phone', values.phone)
        data.append('email', values.email)
        data.append('desc', values.message)
        for (let i = 0; i < files.length; i += 1) {
            data.append(`file${(i + 1)}`, files[i])
        }
        const result = await sendResume.request({ data })
        if (!result.success) {
            if (result.error.status === 422) {
                Object.keys(result.error.data.errors).forEach((key) => {
                    setError(result.error.data.errors[key].join(', '))
                })
            }

            if (result.error.status === 500) {
                setError(t('common:internalError'))
            }
        } else {
            setError(t('sendedResume'))
            setSended(true)
        }
    }

    const initVal = {
        fullName: '',
        phone: '',
        email: '',
        message: '',
        agree: false,
    }

    if (sended) {
        return (
            <div className="vacancy__container">
                <h2 className="vacancy__title">{t('sendedResume')}</h2>
            </div>
        )
    }

    return (
        <div className="vacancy__container">
            <h2 className="vacancy__title">{t('title')}</h2>
            <p className="vacancy__text">{t('subTitle')}</p>
            <div style={{ color: '#AA5555', fontSize: '22pt', marginBottom: '20px' }}>{error}</div>
            <Formik onSubmit={sendData} initialValues={initVal} validate={validate}>
                {(({ errors, handleSubmit, values }) => (
                    <>
                        <div className="vacancy__form-items">
                            <Form className="vacancy__form vacancy__form-item">
                                <div className="vacancy__form-inputs">
                                    <div className="vacancy__form-row1">
                                        <Input label={t('fullName')}
                                            divClass="vacancy__input vacancy__input_full"
                                            hint={hints.fio} name="fullName"
                                            placeholder={t('fullNamePH')}
                                            error={errors.fio}
                                        />
                                    </div>

                                    <div className="vacancy__form-row">
                                        <Input label={t('phone')}
                                            divClass="vacancy__input"
                                            hint={hints.phoneM} name="phone"
                                            placeholder="+992 00-000-00-00"
                                            error={errors.fio}
                                        />
                                        <Input label={t('email')}
                                            divClass="vacancy__input"
                                            hint={hints.email} name="email"
                                            placeholder="mail@mail.ru"
                                            error={errors.fio}
                                        />
                                    </div>

                                    <TextArea label={t('message')} name="message" />
                                </div>
                            </Form>
                            <div className="vacancy__form-files">
                                {files.map((fileItem, i) => (
                                    <div className="vacancy__pdf">
                                        <a className="vacancy__pdf-item" href="#">
                                            <div className="input_img_title">
                                                <div className="registration-losses__img">
                                                    <img
                                                        src={fileItem.type === trueFile ? pdfIcon : jpgIcon} alt="" />
                                                </div>
                                                <div className="vacancy__link">
                                                    {fileItem.name}
                                                    <div className="vacancy__link_thin">
                                                        {math.round(fileItem.size / 1024)} Кб
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="registration-losses__btn-remove"
                                                onClick={() => deleteFile(i)}
                                            >
                                                <img src={cross} alt="" />
                                            </div>
                                        </a>
                                    </div>
                                ))}
                                <div className="vacancy__btn-block">

                                    <label className="vacancy__btn-add">
                                        <img src={addIcon} alt="" />

                                        <input type="file" multiple onChange={addFile} />{t('addFile')}
                                    </label>

                                    <p className="vacancy__add-text">
                                        {t('addFileText')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="vacancy__form-footer">
                            <Checkbox
                                name="agree"
                                className="travel-order__checkbox"
                                label={(
                                    <>
                                        {cn(t('common:agreement1'), ' ')}
                                        <a className="auto-insurance__rules-link"
                                            href="/politics"
                                            target="_blank"
                                            rel="noreferrer">
                                            {t('common:agreement2')}
                                        </a>
                                        &nbsp;{t('common:agreement3')}&nbsp;
                                        <a
                                            className="auto-insurance__rules-link"
                                            href="/rules"
                                            target="_blank"
                                            rel="noreferrer">
                                            {t('common:agreement4')}
                                        </a>
                                    </>
                                )} />
                            <Button
                                onClick={handleSubmit}
                                disabled={!values.agree}
                                className="vacancy__form-btn"
                                text={t('submitResume')} />
                        </div>
                    </>
                ))}
            </Formik>

        </div>
    )
}
