import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

// import ru from '../../static/img/language/ru.svg'
// import en from '../../static/img/language/en.svg'
// import tj from '../../static/img/language/tj.svg'
import { Context } from '../../contexts/GlobalContext'

export default function SelectLanguageMob() {
    const { setLanguage } = useContext(Context)
    const { t } = useTranslation('common')
    // const langFlags = { en, ru, tj }

    const languageSelect = (code) => {
        setLanguage(code)
        i18n.changeLanguage(code)
    }

    return (
        <div className="header__flags_mob">
            {/* <div className="dropbtn">
                <div className={`flag__wrapper flag__wrapper_${i18n.language}`} />
            </div> */}

            <div className="select-lang__text">{t('languageSelect')}</div>

            <div className="header__flags_case_mob">
                <div className={`header__flags-string_mob ${i18n.language === 'ru' ? 'active-lang' : ''}`} onClick={() => languageSelect('ru')}>
                    <span className="header__lang-name_mob">RU&nbsp;</span>
                    <div className="flag__wrapper flag__wrapper_ru" />
                </div>
                <div className={`header__flags-string_mob ${i18n.language === 'tj' ? 'active-lang' : ''}`} onClick={() => languageSelect('tj')}>
                    <span className="header__lang-name_mob">TJ&nbsp;</span>
                    <div className="flag__wrapper flag__wrapper_tj" />
                </div>
                <div className={`header__flags-string_mob ${i18n.language === 'en' ? 'active-lang' : ''}`} onClick={() => languageSelect('en')}>
                    <span className="header__lang-name_mob">EN&nbsp;</span>
                    <div className="flag__wrapper flag__wrapper_en" />
                </div>
            </div>
        </div>
    )
}
