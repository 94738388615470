import { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import baseAxios from '../utils/request'
import { cmsDomain } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'

export function useGetRequest(options = {}) {
    return useRequest({ method: 'GET', ...options })
}

export function usePostRequest(options = {}) {
    return useRequest({ method: 'POST', ...options })
}

export function usePutRequest(options = {}) {
    return useRequest({ method: 'PUT', ...options })
}

export function useDeleteRequest(options = {}) {
    return useRequest({ method: 'DELETE', ...options })
}

export function useRequest(options = {}) {
    const [response, setResponse] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    const { cmsToken, accessToken } = useContext(AuthContext)

    let headers = {}
    if (options.url.startsWith(cmsDomain) && !isEmpty(cmsToken)) {
        headers = { Authorization: `Bearer ${cmsToken}` }
    } else if (!isEmpty(accessToken)) {
        headers = { Authorization: `Bearer ${accessToken}` }
    }
    const { i18n } = useTranslation()
    headers['Accept-Language'] = i18n.language

    async function request(overrideOptions = {}, sync = false) {
        setLoading(true)

        try {
            const { data } = await baseAxios({
                ...options,
                headers,
                ...overrideOptions,
            })
            if (!sync) setResponse(data)
            return { response: data, success: true }
        } catch (e) {
            setError(e.response || {})
            return { error: e.response, success: false }
        } finally {
            if (!sync) setLoading(false)
        }
    }

    return { loading, request, error, response, setResponse, setError, setLoading }
}

export function useLoad(options, dependencies = []) {
    const request = useRequest({ method: 'GET', ...options })

    useEffect(() => {
        request.request()
    }, dependencies)

    return request
}
