/* eslint max-len: 0 */
export default {
    header: 'Travel policy arrangement',
    byuOnline: 'Buy policy online',
    toByuOnline: 'Go to checkout',
    wrongInput: 'Incorrectly filled',
    departureDate: 'Departure date',
    arrivalDate: 'Arrival date',
    departureDatePH: 'DD / MM / YYYY',
    arrivalDatePH: 'Arrival date',
    checkPolicy: 'Check policy',
    travelProducts: 'Travel Products',
    oneTravel: 'For one trip',
    multiTravel: 'For multiple trips',
    frequencyTravel: 'Frequency of travel',
    travelPeriod: 'Travel period',
    policeConditions: 'Policy terms',
    travelCountry: 'Travel country',
    chooseCountry: 'Choose the country',
    wrongPoliceType: 'Policy type is not selected',
    alert90Days: 'When choosing several trips, it is possible to issue only for 90 days',
    dayCount: 'Number of days',
    dayCountPH: 'Number of days',
    restTypeChoose: 'Choose the type of vacation',
    restType: 'Vocation type',
    fullCost: 'Total',
    travelBoxProducts: 'Boxed Products for travellers',
    nullChoose: 'Not chosen',
    buy: 'Buy',
    toBuy: 'Go to checkout',
    more: 'Learn more',
    insured: 'The insured',
    holderFullName: 'Full Name',
    holderFullNamePH: 'Alexey Petrovich Ivanov',
    holderPhone: 'Telephone',
    holderEmail: 'Email',
    holderDocSeries: 'Passport Series',
    holderDocNumber: 'Passport ID',
    holderBirthday: 'Date of Birth',
    holderGender: 'Gender',
    holderOblProj: 'Область проживания',
    holderGorodProj: 'Город проживания',
    holderUlProj: 'Улица проживания',
    holderDomProj: 'Дом проживания',
    holderKvProj: 'Квартира проживания',
    holderIssueDate: 'Date of issue of passport',
    holderAddressReg: 'Registration address',
    holderPostalCodeReg: 'Postcode',
    holderAddressFact: 'Registration address',
    holderPostalCodeFact: 'Postcode',
    holderPostalFactEqReg: 'Matches the registration address',
    holderAddressPH: 'Registration address',
    holderPostalCodePH: '743000',
    holderAgree: 'Confirm',
    holderData: 'Data of Insured',
    copyHolderData: 'Copy data of Insured',
    lasInfo: 'Data of Insured',
    lasAdd: 'Add insured',
    laFullname: 'Full Name',
    laFullnamePH: 'Ivanov Ivan Ivanovich',
    laBirthday: 'Date of Birth',
    laCitizenship: 'Citizenship',
    series: 'Passport Series',
    docNumber: 'Passport ID',
    issueDate: 'Date of issue of passport',
    gender: 'Gender',
    sumInsured: 'Sum insured',
    currency: 'Currency',
    medical: 'Medical and emergency expenses',
    mainAvoid: 'Amount less',
    personalSwag: 'Personal belongings',
    houseProtect: 'Apartment protection',
    goodNeighbors: 'Good neighbors',
    accident: 'Accident insurance',
    optionsMore: 'Additional options',
    sportOption: 'Sport',
    covid19Option: 'Covid-19',
    pregnantOption: 'Complication of pregnancy',
    sunOption: 'Sunburn',
    alcoholicOption: 'Medical and emergency expenses, in case of alcoholic intoxication',
    poisoningOption: 'Food poisoning',
    promoCode: 'Promo code',
    errorCoupon: 'Coupon entry error',
    coupon: 'Coupon',
    optionsMore1: '',
    holderIssueInstitution: 'Passport issued',
    discount: 'Discount',
    options: 'Options',
    amount: 'Sum',
    fullPrice: 'Total',
    fillFullName: 'Name not filled',
    fillName: 'NAME not filled',
    accidentManual: 'Accident insurance - manually',
    lasRemove: 'Remove insured',
    insuredInfo: 'Insured {{count}}',
    headNavData: 'Precalculation',
    holderDocType: 'Document type',
    chooseLimit: 'Select size',
    headNavOptions: 'Select Options',
    headNavInsurer: 'Decor',
    headNavInsured: 'Payment',
    travelingWho: 'Who will go',
    travelingPeriod0: '4 - 69 years',
    travelingPeriod1: '0 - 12 months',
    travelingPeriod2: '1 - 3 years',
    travelingPeriod3: '70 - 74 years old',
    travelingPeriod4: 'Up to 80 years old',
    headNavBuy: 'Design',
    headNavPrograms: 'Programs',
    fullPolicyCost: 'Total',
    mainPolicyCost: 'Policy',
    optionsPolicyCost: 'Options',
    nextStep: 'Further',
    totalDays: 'Days: {{count}}',
    insuredCount: 'Insured: {{count}}',
    insured0: '{{count}} traveler',
    insured1: '{{count}} traveler',
    insured2: '{{count}} travelers',
    additionalOptions: 'Additional options',
    sumOptions: 'Total',
    changeButton: 'Change',
    addButton: 'Add',
    deleteButton: 'Delete',
    flightDelaOption: 'Flight delay',
    baggageDelayOption: 'Baggage delay',
    baggageLossOption: 'Lost luggage',
    traveling: 'Travel',
    spaType0: 'Calm',
    spaType0Hint: 'Includes excursions, beach holidays, cycling, animal riding.',
    spaType1: 'extreme',
    spaType1Hint: 'extreme',
    holderAsInsured: 'The policyholder is insured.',
    insuranceCost: 'Cost: {{price}}',
    mainSumPrem: 'Cover amount',
    seacreatureOption: 'Contacts with marine reptiles',
    optionsHouse: 'House:',
    optionsHousePH: 'Address',
    optionsHouseHint: 'Enter the full address of the house/apartment insured during your holiday.',
    policeAfterPayment: 'After payment, the policy will be sent to the specified email address.',
    openInfoPopup: 'Full coverage description',
    hint_fio: 'Enter your full name. For example Ivanov Ivan Ivanovich',
    hint_coutry: ' Select country of travel',
    hint_bd: ' Enter your date of birth. For example, 04/04/1976.',
    hint_gender: ' Enter your gender female or male',
    hint_email: ' E-mail address',
    hint_passport: ' Series and number of your foreign passport, by whom and when issued',
    hint_regAddr: ' Country, city, district, street, house/apartment number indicated in the passport',
    hint_factAddr: ' Country, city, district, street, house/apartment number - where you actually live',
    hint_phoneH: ' Enter your home phone number (if available)',
    hint_inn: ' Enter your Individual Taxpayer Number issued by the tax authorities of the Republic of Tajikistan.',
    hint_phoneM: ' Enter your valid mobile number',
    hint_periodSafe: ' Trip start date and trip end date',
    hint_currency: ' US dollars, Euros, Russian rubles, Tajik somoni',
    hint_optSport: ' Sports activities with increased risk (including skiing on specially equipped tracks), professional sports, participation in competitions and tournaments, parachuting, driving motor vehicles.',
    hint_optCovid: 'BIMA will reimburse medical and travel expenses incurred due to Covid-19. Insurance coverage up to $5,000.',
    hint_flightDelaOption: 'BIMA compensates for the material damage associated with a delay in the start of the trip exceeding 8 hours.',
    hint_baggageDelayOption: 'BIMA compensates for the costs associated with waiting for baggage, the delay of which is more than 48 hours.',
    hint_baggageLossOption: ' BIMA compensates for the costs associated with the total destruction or loss of baggage delivered under the responsibility of the carrier.',
    hint_optPregnant: 'Bima compensates for the costs associated with the provision of emergency and emergency medical care in case of a sudden complication of pregnancy.',
    hint_optSun: 'BIMA will reimburse medical expenses incurred as a result of sunburn.',
    hint_optAlcohol: 'BIMA compensates for medical and emergency expenses, in case of illnesses, injuries that have occurred in connection with the use of alcohol.',
    hint_optFood: 'Medical and travel expenses resulting from food poisoning',
    hint_promocode: 'Enter the code to receive a discount',
    hint_medical: 'BIMA will reimburse expenses related to emergency hospitalization or transportation during the trip',
    hint_personalSwag: 'BIMA will reimburse the cost of personal belongings - mobile equipment, sports equipment, outerwear and hand luggage (within the established limit).',
    hint_houseProtect: 'BIMA will reimburse the cost of repairing a house / apartment damaged by floods or smoke, etc. that occurred during your trip.',
    hint_goodNeighbors: 'BIMA will reimburse the costs associated with causing damage to the property of your neighbors through your fault during the trip',
    hint_accident: 'BIMA will indemnify expenses related to grievous bodily injury to the Insured as a result of an accident or death.',
    hint_oneTrip: ' The policy is valid for one trip',
    hint_moreTrip: ' A policy that allows you to travel several times during the year (the number of days for each trip is 90 days)',
    hint_travelType: ' Quiet rest, sports, extreme sports, family vacation, gastronomic tour',
    checkData: 'Check details',
    payType: 'Select payment method',
    paySystem1: 'Online Pay',
    paySystem2: 'Vasl Pay',
    paySystem3: 'DC Pay',
}
