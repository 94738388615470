import React, { useContext, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { optionsTravel } from '../../helpers/travel'
import FormTotal from './FormTotal'
import { VzrContext } from '../../contexts/VzrContext'
import { VzrHeadStep1Info } from './VzrHeadStep1Info'
import CheckboxOptions from './CheckboxOptions'

export default function FormStep2() {
    const { changeSequence, autocompleteOptions } = useContext(VzrContext)
    const { t } = useTranslation('travel')
    const { values, handleSubmit, validateForm } = useFormikContext()

    useEffect(() => validateForm(), [])
    const showPregnant = useMemo(() => values.las.some(({ laBirthday }) => {
        const diff = moment().diff(moment(laBirthday), 'year')
        if (diff <= 69 && diff >= 4) return true
        return false
    }), [])

    useEffect(() => {
        autocompleteOptions().then(() => handleSubmit())
    }, [])

    return (
        <section className="step step-2">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <VzrHeadStep1Info />
                    <h3 className="travel-order__subtitle">
                        {t('additionalOptions')}:
                    </h3>
                    {
                        optionsTravel.map(({
                            name,
                            seq,
                            range,
                            prem,
                            trans,
                            hint,
                            icon,
                        }) => {
                            if ((!showPregnant && name === 'pregnantOption') || name === 'sportOption') return null

                            return (
                                <CheckboxOptions key={seq} icon={icon}
                                    name={name} options={range} prem={prem} hint={t(hint)} label={t(trans)}
                                    onChange={(val) => {
                                        changeSequence(seq, values, val)
                                        window.setTimeout(() => handleSubmit(), 200)
                                    }}
                                />
                            )
                        })
                    }
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
