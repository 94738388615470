import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function PaymentSelector({ hideModal, onCreateTravel, setPaymentOper, paymentOper }) {
    const { t } = useTranslation('travel')
    const [selectedOper, setSelectedOper] = useState(paymentOper)

    return (
        <div className="payment-wrapper">
            <h3 className="travel-order__subtitle subtitle payments__subtitle">
                {t('payType')}
            </h3>
            <div className="payments">
                <div className="payments__button" onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setSelectedOper('dccity')
                    setPaymentOper('dccity')
                }}>
                    <input className="payments__input" type="radio" name="payment"
                        id="payment-card" checked={selectedOper === 'dccity'} />
                    <label className="payments__label" htmlFor="payment-card">
                        <div className="payments__image payments__image--card" />
                        <span className="payments__name">Visa/MasterCard/Мир</span>
                    </label>
                </div>
                <div className="payments__button" onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setSelectedOper('vaslpay')
                    setPaymentOper('vaslpay')
                }}>
                    <input className="payments__input" type="radio" name="payment"
                        id="payment-corti" checked={selectedOper === 'vaslpay'} />
                    <label className="payments__label" htmlFor="payment-corti">
                        <div className="payments__image payments__image--corti" />
                        <span className="payments__name">КОРТИ МИЛЛИ</span>
                    </label>
                </div>
                <div className="payments__button" onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setSelectedOper('dc')
                    setPaymentOper('dc')
                }}>
                    <input className="payments__input" type="radio" name="payment"
                        id="payment-dccity" checked={selectedOper === 'dc'} />
                    <label className="payments__label" htmlFor="payment-dccity">
                        <div className="payments__image payments__image--dccity" />
                        <span className="payments__name">DCCity</span>
                    </label>
                </div>
                <div className="payments__button" onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setSelectedOper('payler')
                    setPaymentOper('payler')
                }}>
                    <input className="payments__input" type="radio" name="payment"
                        id="payment-payler" checked={selectedOper === 'payler'} />
                    <label className="payments__label" htmlFor="payment-payler">
                        <div className="payments__image payments__image--payler" />
                        <span className="payments__name">IBT</span>
                    </label>
                </div>
            </div>
            <button className="button button__submit payments__submit" onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                onCreateTravel()
                if (hideModal) hideModal()
            }}>
                {t('common:sendPay')}
            </button>
        </div>
    )
}
