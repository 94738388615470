import React, { useContext, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import tumbler from '../static/img/services/tumbler-icon.svg'
import cart from '../static/img/services/cart-icon.svg'
import { CMS } from '../utils/urls'
import { useLoad } from '../hooks/request'
import Layout from '../components/Layout'
import HomeBanner from '../components/HomeBanner'
import MainServices from '../components/common/MainServices'
import BoxesMenu from '../components/boxes/BoxesMenu'
import BoxesProducts from '../components/boxes/BoxesProducts'
import BoxesActivate from '../components/boxes/BoxesActivate'
import BoxesSeo from '../components/boxes/BoxesSeo'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'

const services = [
    {
        onClick: (el, ref) => {
            el.preventDefault()
            window.scrollTo({
                top: ref.current.offsetTop - 260,
                left: 0,
                behavior: 'smooth',
            })
        },
        image: cart,
        title: 'box:buyBox',
    },
    {
        onClick: (el, ref) => {
            el.preventDefault()
            window.scrollTo({
                top: ref.current.offsetTop - 80,
                left: 0,
                behavior: 'smooth',
            })
        // window.location = 'https://tias.bima.tj/box/'
        },
        image: tumbler,
        title: 'box:activation',
    },
]

function Boxes() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { state } = useLocation()
    const [category, setCategory] = useState('')
    const { response: cats } = useLoad({ url: CMS.BOX_CATS }, [cmsToken, language])
    const { response: productsResponse } = useLoad({
        url: CMS.ALL_BOXES
            .replace('{page}', '1')
            .replace('{size}', '300')
            .replace('{category}', category),
    }, [category, cmsToken, language])
    const products = productsResponse ? productsResponse.data : false
    const activate = useRef(null)
    const buy = useRef(null)
    if (state && state.toActivate) {
        const timer = window.setInterval(() => {
            window.scrollTo({
                top: activate.current.offsetTop - 80,
                left: 0,
                behavior: 'smooth',
            })
            window.clearTimeout(timer)
        }, 1500)
    }

    return (
        <Layout>
            <div className="boxes">
                <div className="boxes__bg">
                    <HomeBanner />
                    <MainServices services={services} buy={buy} activate={activate} />
                    <BoxesMenu cats={cats} select={setCategory} selected={category} />
                    <span ref={buy} />
                    <BoxesProducts products={products} startScroll={() => {
                        if (state && state.toActivate) {
                            window.scrollTo({
                                top: activate.current.offsetTop - 80,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }
                    }} />
                    <span ref={activate} />
                    <BoxesActivate />
                    <BoxesSeo products={products} />
                </div>
            </div>
        </Layout>
    )
}

export default Boxes
