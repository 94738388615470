import React from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import HomeBanner from '../components/HomeBanner'
import Benefits from '../components/Benefits'
import HomeProducts from '../components/HomeProducts'
import MainOffer from '../components/MainOffer'
import MainPartners from '../components/MainPartners'
import ShortBlog from '../components/ShortBlog'
import shieldIcon from '../static/img/services/shield-icon.svg'
import wheelIcon from '../static/img/services/wheel-icon.svg'
import tumblerIcon from '../static/img/services/tumbler-icon.svg'
import { useModal } from '../hooks/modal'
import PolicyCheck from '../components/modals/PolicyCheck'
import PolicySuccess from '../components/modals/PolicySuccess'
import PolicyNotFound from '../components/modals/PolicyNotFound'

export default function Home() {
    const { push } = useHistory()

    const [showPolicyNotFound, hidePolicyNotFound] = useModal(
        <PolicyNotFound className="popup__popup-policy-success" push={push} onClose={() => hidePolicyNotFound()} />,
    )

    const [showPolicySuccessModal, hidePolicySuccessModal] = useModal(
        <PolicySuccess className="popup__popup-policy-success" push={push} hide={() => hidePolicySuccessModal()} />,
    )

    const [showPolicyCheckModal] = useModal(
        <PolicyCheck
            className="popup__policy-check"
            onOpenSuccess={showPolicySuccessModal}
            onOpenError={showPolicyNotFound} />,
    )

    const benefits = [
        { onClick: () => { push('/losses') }, image: shieldIcon, title: 'home:insuranceCase' },
        { onClick: showPolicyCheckModal, image: wheelIcon, title: 'home:checkPolicy' },
        {
            onClick: () => {
                push('/boxes', { toActivate: true })
            },
            image: tumblerIcon,
            title: 'home:activatePolicy',
        },
    ]

    return (
        <Layout>
            <HomeBanner />
            <Benefits benefits={benefits} />
            <HomeProducts />
            <MainOffer />
            <MainPartners />
            <ShortBlog />
        </Layout>
    )
}
