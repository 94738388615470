import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
// import { NavLink } from 'react-router-dom'
import InputBare from '../base/InputBare'
import InputMask from '../base/InputMask'
// import { NavLink } from 'react-router-dom'

export default function Registration() {
    const { t } = useTranslation('common')
    const initialValues = { username: '', digit0: 0, digit: [] }
    const smsRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ]

    const nextInput = (next) => (el) => {
        console.log(el.target.value, next)
        if (el.target.value.length > 0 && next) {
            smsRefs[next].focus()
        }
    }

    return (
        <div className="popup__content">
            <h4 className="popup__title">
                <span className="active">{t('registerText')}</span>
            </h4>

            <Formik initialValues={initialValues}>
                {() => (
                    <div className="popup-registration">
                        <Form className="popup-registration__form">
                            <InputMask label={t('enterPhone')} name="username" placeholder="+(992) 00-000-0000" />

                            <div className="input">
                                <label className="input__label">
                                    {t('enterSMS')}<span className="input_error-text" />
                                </label>
                                <div className="input__row">
                                    <InputBare name="digit[0]" divClass="input__input" onChange={nextInput(1)} refLinlk={smsRefs[0]} />
                                    <InputBare name="digit[1]" divClass="input__input" onChange={nextInput(0)} refLinlk={smsRefs[1]} />
                                    <InputBare name="digit[2]" divClass="input__input" />
                                    <InputBare name="digit[3]" divClass="input__input" />
                                    <InputBare name="digit[4]" divClass="input__input" />
                                    <InputBare name="digit[5]" divClass="input__input" />
                                </div>
                            </div>

                            <div className="popup-registration__counter">
                                {t('repeatSMS')} <span>45</span> {t('seconds')}
                            </div>
                            <div className="input popup-registration__input-pass">
                                <label className="input__label">{t('newPassword')}
                                    <span className="input_error-text" />
                                </label>
                                <input className="input__input" type="password" value="" name=""
                                    placeholder="Tcoyzhiv90!1234" />
                            </div>
                            <div className="input popup-registration__input-pass">
                                <label className="input__label">{t('repeatPassword')}<span
                                    className="input_error-text" />
                                </label>
                                <input className="input__input" type="password" value="" name=""
                                    placeholder="Tcoyzhiv90!1234" />
                            </div>
                        </Form>

                        {/* <div className="popup-registration__rules">
                            {t('rulesText1')}
                            <div className="popup-registration__rules-link"
                                to="/" target="_blank">
                                {t('rulesText2')}
                            </div> {t('rulesText3')}
                            <div className="popup-registration__rules-link"
                                to="/" target="_blank">
                                {t('rulesText4')}
                            </div>
                        </div> */}
                        <button className="popup-registration__button" type="button">{t('continueText')}</button>
                    </div>
                )}
            </Formik>
        </div>
    )
}
