import React, { Fragment, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import wheel from '../static/img/services/wheel-icon.svg'
import cartIcon from '../static/img/services/cart-icon.svg'
import promoArrow from '../static/icons/arrow-promo.svg'
import travel from '../static/img/services/travel-icon.svg'
import Select from '../components/base/Select'
import Button from '../components/common/Button'
import countries from '../utils/countries.json'
import Benefits from '../components/Benefits'
import { Radio, RadioGroup } from '../components/base/Radio'
import { DateInput } from '../components/base/DateInput'
import Input from '../components/base/Input'
import { changeDate } from '../utils/date'
import TravelProducts from '../components/travel/TravelProducts'
import TravelBanner from '../components/travel/TravelBanner'
import { useModal } from '../hooks/modal'
import PolicyCheck from '../components/modals/PolicyCheck'
import PolicyNotFound from '../components/modals/PolicyNotFound'
import PolicySuccess from '../components/modals/PolicySuccess'
import { usePostRequest } from '../hooks/request'
import { REST } from '../utils/urls'
import { calcMulti, gender, hints, spaType, docTypes } from '../helpers/travel'
import MultiSelectField from '../components/base/MultiSelectField'
import Checkbox from '../components/base/Checkbox'

export default function Travel({ setFieldValueOrig }) {
    const { push } = useHistory()
    const { t } = useTranslation('travel')
    const calc = usePostRequest({ url: REST.TRAVEL.CALC })
    const products = useRef(null)
    const buy = useRef(null)
    const [cost, setCost] = useState(false)
    const [disabledInput, setDisabledInput] = useState(false)

    function changeSequence(val, data, type) {
        const index = data.sequence.indexOf(val)
        if (!type) {
            data.sequence.splice(index, 1)
        } else if (index === -1) {
            data.sequence.push(val)
        }
    }
    let getSubmit
    const [showPolicyNotFound, hidePolicyNotFound] = useModal(
        <PolicyNotFound className="popup__popup-policy-success" push={push} onClose={() => hidePolicyNotFound()} />,
    )

    const [showPolicySuccessModal, hidePolicySuccessModal] = useModal(
        <PolicySuccess className="popup__popup-policy-success" push={push} hide={() => hidePolicySuccessModal()} />,
    )

    const [showPolicyCheckModal] = useModal(
        <PolicyCheck
            className="popup__policy-check"
            onOpenSuccess={showPolicySuccessModal}
            onOpenError={showPolicyNotFound} />,
    )

    const benefits = [
        { onClick: showPolicyCheckModal, image: wheel, title: 'travel:checkPolicy' },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: buy.current.offsetTop - 260,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: cartIcon,
            title: 'travel:byuOnline',
        },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: products.current.offsetTop - 220,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: travel,
            title: 'travel:travelProducts',
        },
    ]

    const initialValues = {
        country: '',
        howOften: '',
        beginDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        spaType: null,
        dayCount: 2,
        sportOption: 0,
        holderBirthday: '',
        holderGender: null,
        multi: null,
        sequence: [],
    }

    function onSubmit(data) {
        window.setTimeout(() => {
            window.scrollTo({
                top: 600,
                left: 0,
                behavior: 'smooth',
            })
        }, 0)
        push('/travel-order', { data })
    }

    let timer = null

    function calculate(values) {
        setCost(false)
        let countryList = ''
        if (values.country) {
            countryList = values.country.reduce((result, val) => {
                result.push(val.value)
                return result
            }, []).join(', ')
        }

        const error = {}

        Object.keys(initialValues).forEach((item) => {
            // console.log(values[item])
            if ((!values[item] || values[item] === '') && values[item] !== '0' && values[item] !== 0) error[item] = true
        })

        if (Object.keys(error).length !== 0) return error

        window.clearTimeout(timer)
        timer = window.setTimeout(async () => {
            const data = {
                holderLastname: '',
                holderFirstname: '',
                holderPatronymic: '',
                holderPhone: '',
                holderEmail: '',
                holderBirthday: values.holderBirthday,
                country: (countryList) || '',
                beginDate: values.beginDate || '',
                endDate: values.endDate || '',
                spaType: 0,
                status: 0,
                multi: values.multi || 0,
                las: [
                    {
                        laLastname: '',
                        laFirstname: '',
                        laPatronic: '',
                        laBirthday: values.holderBirthday || '',
                        laCitizenship: 'TJ',
                        issueDate: values.beginDate || '',
                        issueInstitution: '',
                        series: '',
                        docNumber: '',
                        dateTo: values.beginDate || '',
                        gender: values.holderGender,
                        sportOption: values.sportOption || 0,
                        sportSumPrem: 0,
                        pregnantOption: 0,
                        pregnantSumPrem: 0,
                        sunOption: 0,
                        sunSumPrem: 0,
                        alcoholicOption: 0,
                        alcoholicSumPrem: 0,
                        poisoningOption: 0,
                        poisoningSumPrem: 0,
                        sequence: values.sequence,
                    },
                ],
                mainSumAssured: 30000,
                mainSumPrem: 30000,
                thingsSumAssured: 0,
                thingsSumPrem: 0,
                flatSumAssured: 0,
                flatSumPrem: 0,
                neighborSumAssured: 0,
                neighborSumPrem: 0,
                accSumAssured: 0,
                accSumPrem: 0,

                covid19Option: 0,
                covid19SumPrem: 0,
                sumPrem: 0,
                sequence: [],

                agree: false,
                dayCount: values.dayCount || '',

                currency: 'USD',
                coupon: '',
            }
            data.sportOption = values.sportOption || 0
            const { response, success } = await calc.request({ data })
            if (success) {
                setCost(response.sumPrem)
                if (response.errorMsg) {
                    setCost(false)
                }
            } else {
                setCost(false)
            }
        }, 500)

        return {}
    }

    return (
        <Layout>
            <div className="travel-one">
                <div className="travel-one__bg">

                    <TravelBanner />
                    <Benefits benefits={benefits} />

                    <div className="travel-one__container" ref={buy}>
                        <h3 className="travel-one__title">{t('header')}</h3>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur
                            validate={calculate} validateOnChange>
                            {({ values, handleSubmit, setFieldValue, errors, validateForm }) => (
                                <Form className="travel-one__form">
                                    <div className="tabs__nav">
                                        <button className="tabs__btn tabs__btn_finished">Данные</button>
                                        <button className="tabs__btn tabs__btn_active">Опции</button>
                                        <button className="tabs__btn">Оформление</button>
                                        <button className="tabs__btn">Оплата</button>
                                    </div>
                                    <section className="step step-1">
                                        <div className="travel-one__form-items">
                                            <div className="travel-one__form-item">
                                                <div className="travel-one__form-row">
                                                    <MultiSelectField error={errors.country}
                                                        hint={hints.coutry} required
                                                        options={countries} name="country"
                                                        label={t('travelCountry')}
                                                        errTxt={t('chooseCountry')}
                                                    />
                                                </div>
                                                {/* Селект выбора типа отдыха надо будет убрать */}
                                                <div className="travel-one__form-row">
                                                    <Select
                                                        name="spaType" required error={errors.spaType}
                                                        className="select select__travel-one travel-one__input_ful"
                                                        options={spaType}
                                                        hint={hints.travelType}
                                                        defValue="-----------"
                                                        onChange={(e) => {
                                                            setFieldValue('spaType', e)
                                                            setFieldValue('sportOption', e === 0 ? 0 : 1)
                                                            if (e === 0) setFieldValue('sequence', [])
                                                            else setFieldValue('sequence', [0])
                                                            window.setTimeout(() => setFieldValue('spaType', e), 1000)
                                                        }}
                                                        label={t('restTypeChoose')} />
                                                </div>
                                                <RadioGroup name="howOften" className="travel-one__form-ranges">
                                                    <div className="travel-one__ranges-block">
                                                        <div className="ranges__right">
                                                            <Radio
                                                                label={t('multiTravel')}
                                                                value="multi"
                                                                hint={hints.moreTrip}
                                                                onChange={() => {
                                                                    setFieldValue('multi', 1)
                                                                    setDisabledInput(calcMulti('multi', setFieldValue))
                                                                }}
                                                                className="travel-one__form-range" />
                                                        </div>
                                                    </div>
                                                    <div className="ranges__error-block">
                                                        {
                                                            errors.multi && (
                                                                <span style={{ color: '#eb5757', fontSize: '12px' }}>
                                                                    {t('wrongPoliceType')}
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                </RadioGroup>
                                                <div className="travel-one__form-row">
                                                    <DateInput
                                                        className="input travel-one__input-date"
                                                        name="beginDate" required error={errors.beginDate}
                                                        min={moment().format('YYYY-MM-DD')}
                                                        max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            const begin = moment(e.target.value)
                                                            let end = moment(values.endDate)
                                                            let delta = end.diff(begin, 'days')
                                                            if (values.multi && values.multi !== 0) {
                                                                end = moment.unix(begin.unix()).add(1, 'year')
                                                                    .add('-1', 'day')
                                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                                delta = 89
                                                            } else if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                                end = moment.unix(begin.unix()).add(2, 'day')
                                                                    .add('-1', 'day')
                                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                                delta = 1
                                                            }
                                                            setFieldValue('dayCount', delta + 1)
                                                        }}
                                                        label={t('departureDate')}
                                                        placeholder="ДД / ММ / ГГГГ" />

                                                    <DateInput
                                                        className="input travel-one__input-date"
                                                        min={moment().format('YYYY-MM-DD')}
                                                        max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                                        name="endDate" required error={errors.endDate}
                                                        isDisabled={disabledInput}
                                                        label={t('arrivalDate')}
                                                        onChange={(e) => {
                                                            const begin = moment(values.beginDate)
                                                            let end = moment(e.target.value)
                                                            if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                                end = moment.unix(begin.unix()).add(2, 'day')
                                                                    .add('-1', 'day')
                                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                            }
                                                            const delta = end.diff(begin, 'days')
                                                            setFieldValue('dayCount', delta + 1)
                                                        }}
                                                        placeholder="ДД / ММ / ГГГГ" />
                                                </div>
                                                {/* Ниже строка про количество дней, ее нужно удалить.
                                                Не могу правильно удалить - реакт ругается на объявленные, но не используемые переменные */}
                                                <div className="travel-one__form-row">
                                                    <div className="ranges__right">
                                                        <Input
                                                            isDisabled={disabledInput} required error={errors.dayCount}
                                                            onChange={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                window.setTimeout(() => {
                                                                    validateForm(values)
                                                                    setFieldValue('endDate',
                                                                        changeDate(new Date(values.beginDate),
                                                                            e.target.value))
                                                                    setFieldValue('dayCount', e.target.value)
                                                                    setCost(false)
                                                                }, 0)
                                                            }}
                                                            name="dayCount"
                                                            label={t('dayCount')}
                                                            placeholder={t('dayCountPH')} />
                                                    </div>
                                                </div>
                                                {/* TODO: Если выбрана мультипоездка - показывать этот блок и скрыть блок выше
                                                (где дата вылета и дата прилета с количеством дней)
                                                В левом столбце меняем дата вылета на "Дата начала"
                                                в правом столбце селект с выбором
                                                либо 30 дней, либо 90 дней
                                                Плейсхолдер "Количество дней поездки" */}
                                                <div className="travel-one__form-row">
                                                    <DateInput
                                                        className="input travel-one__input-date"
                                                        name="beginDate" required error={errors.beginDate}
                                                        min={moment().format('YYYY-MM-DD')}
                                                        max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            const begin = moment(e.target.value)
                                                            let end = moment(values.endDate)
                                                            let delta = end.diff(begin, 'days')
                                                            if (values.multi && values.multi !== 0) {
                                                                end = moment.unix(begin.unix()).add(1, 'year')
                                                                    .add('-1', 'day')
                                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                                delta = 89
                                                            } else if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                                end = moment.unix(begin.unix()).add(2, 'day')
                                                                    .add('-1', 'day')
                                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                                                delta = 1
                                                            }
                                                            setFieldValue('dayCount', delta + 1)
                                                        }}
                                                        label={t('departureDate')}
                                                        placeholder="ДД / ММ / ГГГГ" />
                                                    <Select
                                                        label={t('holderGender')} required
                                                        name="holderGender" error={errors.holderGender}
                                                        options={gender}
                                                        defValue="--------"
                                                        className="input travel-one__input-date"
                                                    />
                                                </div>
                                                <h3 className="travel-order__persons">Условия страхования:</h3>
                                                <div className="travel-one__form-row travel-one__person">
                                                    <div className="select select__travel-order travel-order__input_ful select__field">
                                                        <label className="select__label">Кто поедет</label>
                                                        <div className="select__current">1 путешественник
                                                        </div>
                                                        {/* В открытом виде для select__list добавить класс select__list_open */}
                                                        <div className="select__list select__list-travel">
                                                            <div className="select__item select__item-travel">
                                                                <span className="select__text-value">11 - 60 лет</span>
                                                                <div className="select__counter">
                                                                    <button className="counter-button counter__decrease">-</button>
                                                                    <span className="counter__value">1</span>
                                                                    <button className="counter-button counter__increase">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="select__item select__item-travel">
                                                                <span className="select__text-value">0 - 5 лет</span>
                                                                <div className="select__counter">
                                                                    <button className="counter-button counter__decrease counter__decrease-inactive">-</button>
                                                                    <span className="counter__value">0</span>
                                                                    <button className="counter-button counter__increase">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="select__item select__item-travel">
                                                                <span className="select__text-value">6 - 10 лет</span>
                                                                <div className="select__counter">
                                                                    <button className="counter-button counter__decrease counter__decrease-inactive">-</button>
                                                                    <span className="counter__value">0</span>
                                                                    <button className="counter-button counter__increase">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="select__item select__item-travel">
                                                                <span className="select__text-value">61 - 79 лет</span>
                                                                <div className="select__counter">
                                                                    <button className="counter-button counter__decrease counter__decrease-inactive">-</button>
                                                                    <span className="counter__value">0</span>
                                                                    <button className="counter-button counter__increase">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="select__item select__item-travel">
                                                                <span className="select__text-value">От 80 лет</span>
                                                                <div className="select__counter">
                                                                    <button className="counter-button counter__decrease counter__decrease-inactive">-</button>
                                                                    <span className="counter__value">0</span>
                                                                    <button className="counter-button counter__increase">+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travel-one__form-row">
                                                    <div className="select select__travel-order">
                                                        <label className="select__label">Страховая сумма</label>
                                                        <div className="select__current">40000
                                                        </div>
                                                        {/* В открытом виде для select__list добавить класс select__list_open */}
                                                        {/* TODO: 09/09/2022 К селект-лист добавила новый класс, чтобы убрать скролл и добавить рамку */}
                                                        <div className="select__list travel__select-summ">
                                                            <div className="select__item">
                                                                <span className="select__text-value">40000</span>
                                                            </div>
                                                            <div className="select__item">
                                                                <span className="select__text-value">50000</span>
                                                            </div>
                                                            <div className="select__item">
                                                                <span className="select__text-value">75000</span>
                                                            </div>
                                                            <div className="select__item">
                                                                <span className="select__text-value">100000</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="travel-currency__box">
                                                        <button className="currency__item currency__item_active">USD</button>
                                                        <button className="currency__item">EUR</button>
                                                    </div>
                                                </div>
                                                <div className="travel-one__form-row">
                                                    <div className="travel-type__wrapp">
                                                        <div className="travel-currency__box travel-type__box">
                                                            <button className="currency__item currency__item_active">Спокойный отдых</button>
                                                            <button className="currency__item">Экстремальный отдых</button>
                                                        </div>
                                                        {/* Текст подсказки дожен меняться в зависимости
                                                        от выбранного типа отдыха (спокойный или экстремальный)
                                                        Попап с расшифровкой поместила вниз страницы,
                                                        перед закрывающим тегом layout */}
                                                        <div className="travel-type__hint relax-hint">
                                                            <span className="travel-type__hint-text">
                                                                Включает экскурсии, пляжный отдых, велосипедные прогулки, катание на животных.
                                                            </span>
                                                            <button className="travel-type__hint-link">Подробнее о типах отдыха</button>
                                                        </div>
                                                        <div className="travel-type__hint sport-hint">
                                                            <span className="travel-type__hint-text">
                                                                Занятия видами спорта с повышенной опасностью и профессиональным спортом.
                                                            </span>
                                                            <button className="travel-type__hint-link">Подробнее о типах отдыха</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="travel-one__total_main">
                                                <div className="travel-order__total travel-order__summ">
                                                    Стоимость: 3000
                                                    <span className="travel-option__currency">tjs</span>
                                                </div>
                                                <div className="travel-one__name">{t('travelCountry')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.country) ? (
                                                        <Fragment>
                                                            {values.country.map((item, index) => {
                                                                if (values.country.length === 1) {
                                                                    return item.label
                                                                }
                                                                return index === 0 ? item.label : `, ${item.label}`
                                                            })}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                </div>
                                                <div className="travel-one__name">{t('travelPeriod')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.beginDate || values.endDate) ? (
                                                        <Fragment>
                                                            {!isEmpty(values.beginDate)
                                                                ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                            &nbsp;-&nbsp;
                                                            {!isEmpty(values.endDate)
                                                                ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                    {/* Если выбрана мультипоездка, отображать уведомление ниже */}
                                                    <p className="aside__hint">
                                                        Каждая поездка: не более 90 дней.
                                                    </p>
                                                </div>
                                                {
                                                    cost ? (
                                                        <>
                                                            <div
                                                                className="travel-one__name">
                                                                {t('fullCost')}
                                                            </div>
                                                            <div
                                                                className="travel-one__value">
                                                                {cost} TJS
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className="travel-one__name">Сумма покрытия</div>
                                                <div className="travel-one__value travel-one__value-mb">
                                                    30000
                                                    <span className="travel-option__currency">eur</span>
                                                </div>
                                                <div className="travel-option__description">
                                                    <div className="travel-option__description-text">
                                                        Лечение, консультации врачей и диагностика,
                                                        покупка лекарств, транспортные расходы.
                                                    </div>
                                                    {/* При нажатии на кнопку должен открываться попап aside-popup */}
                                                    <button className="travel-option__description-button">
                                                        Полное описание покрытия
                                                    </button>
                                                </div>
                                                <div className="travel-order__promo promo">
                                                    <p className="promo__subtitle">Промокод</p>
                                                    <div className="travel-order__promo-row">
                                                        <div className="input input__promo">
                                                            <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                            </label>
                                                            <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                        </div>
                                                        <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                            <img src={promoArrow} alt="" width="16" height="16" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <Button
                                                    onClick={() => {
                                                        handleSubmit()
                                                    }}
                                                    disabled={values.agree}
                                                    text={t('holderAgree')}
                                                    className="travel-one__form-btn" />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="step step-2">
                                        <div className="travel-one__form-items">
                                            <div className="travel-one__form-item">
                                                <div className="travel-one__form-row travel-step">
                                                    <div className="travel__step-info">
                                                        <p className="step-info__text step-info__text-accent">
                                                            Германия, Испания, Италия; Португалия, ОАЭ, Мексика;
                                                        </p>
                                                        <p className="step-info__text">20 дней;</p>
                                                        <p className="step-info__text">2 человека;</p>
                                                        <p className="step-info__text">спокойный</p>
                                                    </div>
                                                    <a className="change__button">изменить</a>
                                                </div>
                                                <h3 className="travel-order__subtitle">
                                                    Дополнительные опции:
                                                </h3>
                                                {/* У выбранной опции добавляется класс travel-option__checked
                                                и в инпуте checked="checked".
                                                У неактивной опции этих классов нет. */}
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Личные вещи</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">500
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option select__list_open">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">750
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">1000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Личные вещи</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">500
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option ">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">750
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">1000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Защита дома</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">30000
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option ">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">30000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">50000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">75000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* Опция без выбора суммы страховки */}
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Личные вещи</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Личные вещи</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Короновирусная инфекция</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">5000
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option select__list_open">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">5000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">7500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">10000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Короновирусная инфекция</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">5000
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">5000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">7500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">10000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        4235 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Короновирусная инфекция</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">5000
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">5000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">7500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">10000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        4235 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* TODO: 12/09/2022 Новый вид опций, картинки разные у каждой опции */}
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box coronavirus">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Коронавирусная инфекция</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">500
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">750
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">1000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box airbus">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Задержка рейса</p>
                                                                        </div>
                                                                        <div className="select select__travel-order select__travel-option">
                                                                            {/* Валюта должна подставляться в зависимости
                                                                            от выбранной на первом шаге */}
                                                                            <div className="select__current travel-option__select-current">500
                                                                                <span className="travel-option__currency">eur</span>
                                                                            </div>
                                                                            {/* В открытом виде для select__list добавить класс select__list_open */}
                                                                            <div className="select__list select__list-option">
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">500
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">750
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="select__item travel-option__select-item">
                                                                                    <span className="select__text-value">1000
                                                                                        <span className="travel-option__currency">eur</span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__box baggage">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Задержка багажа</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box house">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Защита дома</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box accident">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Несчастный случай</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box crush">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">ДТП</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* TODO: 19/09/2022 добавила картинки к остальным опциям */}
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box pregnance">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Осложнение беременности</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box sun">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Солнечные ожоги</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box alco">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Алкогольное опьянение</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box sea">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Контакты с морскими гадами</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="travel-one__option-row">
                                                    <div className="checkbox travel-option travel-option__checked travel-option__box bag">
                                                        <span className="hint__icon travel-option__hint-icon">
                                                            <span className="hint__icon-simbol">?</span>
                                                            <span className="hint__content travel-option__hint-content">
                                                                Здесь текст подсказки, что собой представляет опция.
                                                            </span>
                                                        </span>
                                                        <label className="travel-option__rules">
                                                            <div className="travel-option__title-box travel-pl">
                                                                <div className="travel-option__title">
                                                                    <div className="travel-option__text-box">
                                                                        <div className="travel-option__name">
                                                                            <p className="travel-option__text">Личные вещи</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="travel-option__price">
                                                                        435 TJS
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <input className="checkbox__input" type="checkbox" checked="checked" />
                                                            <span className="checkbox__span travel-option__span" />
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* TODO:Блок ниже со старым вариантом опций надо убрать */}
                                                <div className="travel-one__option-row">
                                                    <Checkbox
                                                        name="covid19Option"
                                                        className="travel-order__checkbox"
                                                        onChange={(e) => {
                                                            values.las.forEach((item) => {
                                                                changeSequence(1, item, e)
                                                            })
                                                            setFieldValue('covid19Option', e ? 1 : 0).then(getSubmit)
                                                        }}
                                                        hint={hints.optCovid}
                                                        label={(
                                                            <Fragment>
                                                                {t('covid19Option')} {values.covid19SumPrem
                                                                    ? (
                                                                        <span>- {values.covid19SumPrem} TJS</span>
                                                                    )
                                                                    : null}
                                                            </Fragment>
                                                        )} />
                                                    <Select
                                                        name="spaType" required error={errors.spaType}
                                                        className="select select__travel-one travel-one__input_ful"
                                                        options={spaType}
                                                        hint={hints.optSport}
                                                        defValue="-----------"
                                                        onChange={(e) => {
                                                            setFieldValue('spaType', e)
                                                            setFieldValue('sportOption', e === 0 ? 0 : 1)
                                                            if (e === 0) setFieldValue('sequence', [])
                                                            else setFieldValue('sequence', [0])
                                                            window.setTimeout(() => setFieldValue('spaType', e), 1000)
                                                        }}
                                                        label={t('restTypeChoose')} />
                                                </div>
                                            </div>
                                            <div className="travel-one__total_main">
                                                <div className="travel-order__total travel-order__summ">
                                                    Стоимость: 3000
                                                    <span className="travel-option__currency">tjs</span>
                                                </div>
                                                <div className="travel-one__name">{t('travelCountry')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.country) ? (
                                                        <Fragment>
                                                            {values.country.map((item, index) => {
                                                                if (values.country.length === 1) {
                                                                    return item.label
                                                                }
                                                                return index === 0 ? item.label : `, ${item.label}`
                                                            })}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                </div>
                                                <div className="travel-one__name">{t('travelPeriod')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.beginDate || values.endDate) ? (
                                                        <Fragment>
                                                            {!isEmpty(values.beginDate)
                                                                ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                            &nbsp;-&nbsp;
                                                            {!isEmpty(values.endDate)
                                                                ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                    {/* Если выбрана мультипоездка, отображать уведомление ниже */}
                                                    <p className="aside__hint">
                                                        Каждая поездка: не более 90 дней.
                                                    </p>
                                                </div>
                                                {
                                                    cost ? (
                                                        <>
                                                            <div
                                                                className="travel-one__name">
                                                                {t('fullCost')}
                                                            </div>
                                                            <div
                                                                className="travel-one__value">
                                                                {cost} TJS
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className="travel-one__name">Сумма покрытия</div>
                                                <div className="travel-one__value travel-one__value-mb">
                                                    30000
                                                    <span className="travel-option__currency">eur</span>
                                                </div>
                                                <div className="travel-option__description">
                                                    <div className="travel-option__description-text">
                                                        Лечение, консультации врачей и диагностика,
                                                        покупка лекарств, транспортные расходы.
                                                    </div>
                                                    <button className="travel-option__description-button">
                                                        Полное описание покрытия
                                                    </button>
                                                </div>
                                                <div className="travel-one__name aside-option__name">Дополнительные опции</div>
                                                <p className="aside__option">
                                                    Личные вещи
                                                </p>
                                                <p className="aside__option">
                                                    Защита дома
                                                </p>
                                                <div className="travel-order__promo promo">
                                                    <p className="promo__subtitle">Промокод</p>
                                                    <div className="travel-order__promo-row">
                                                        <div className="input input__promo">
                                                            <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                            </label>
                                                            <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                        </div>
                                                        <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                            <img src={promoArrow} alt="" width="16" height="16" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <button className="travel-one__form-btn step-button">Далее</button>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="step step-3">
                                        <div className="travel-one__form-items">
                                            <div className="travel-one__form-item">
                                                <div className="travel-one__form-row travel-step">
                                                    <div className="travel__step-info">
                                                        <p className="step-info__text step-info__text-accent">
                                                            Германия, Испания, Италия; Португалия, ОАЭ, Мексика;
                                                        </p>
                                                        <p className="step-info__text">20 дней;</p>
                                                        <p className="step-info__text">2 человека;</p>
                                                        <p className="step-info__text">спокойный</p>
                                                    </div>
                                                    <a className="change__button">изменить</a>
                                                </div>
                                                <h3 className="travel-order__subtitle subtitle">
                                                    Страхователь
                                                </h3>
                                                <Input label={t('holderFullName')}
                                                    hint={hints.fio} name="holderFullName"
                                                    placeholder={t('holderFullNamePH')}
                                                    error={errors.holderFullName}
                                                    onChange={() => {
                                                        clearTimeout(timer)
                                                        timer = setTimeout(() => {
                                                            const arr = values.holderFullName.split(' ')
                                                            setFieldValueOrig('holderLastname', arr[0] || '')
                                                            setFieldValueOrig('holderFirstname', arr[1] || '')
                                                            arr.splice(0, 2)
                                                            setFieldValueOrig('holderPatronymic', arr.join(' ') || '')
                                                        }, 300)
                                                    }}
                                                />
                                                <div className="travel-order__row">
                                                    <Input label={t('holderPhone')} hint={hints.phoneM}
                                                        name="holderPhone" placeholder="+992 00-000-00-00"
                                                        error={errors.holderPhone}
                                                    />
                                                    <Input label={t('holderEmail')} hint={hints.email}
                                                        name="holderEmail" placeholder="mail@mail.ru"
                                                        error={errors.holderEmail}
                                                    />
                                                </div>
                                                <div className="travel-order__row gender__row">
                                                    <DateInput label={t('holderBirthday')}
                                                        name="holderBirthday"
                                                        error={errors.holderBirthday}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderBirthday', value)
                                                        }}
                                                        className="input travel-order__input-date"
                                                    />
                                                    <div className="select select__travel-order input__required">
                                                        <label className="select__label">Пол<span className="input_error-text" /></label>
                                                        <div className="travel-currency__box gender-box">
                                                            <button className="currency__item currency__item_active">Мужской</button>
                                                            <button className="currency__item">Женский</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="travel-order__row">
                                                    <Select
                                                        label={t('holderDocType')}
                                                        name="holderDocType"
                                                        error={errors.holderDocType}
                                                        options={docTypes}
                                                        defValue="--------"
                                                        onChange={(value) => {
                                                            setFieldValueOrig('holderDocType', value)
                                                        }}
                                                        className="select select__travel-order input__required"
                                                    />
                                                    {/* Здесь текст я бы заменила
                                                    на "Дата выдачи документа" */}
                                                    <DateInput
                                                        label={t('holderIssueDate')}
                                                        name="holderIssueDate"
                                                        error={errors.holderIssueDate}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderIssueDate', value)
                                                        }}
                                                        className="input select select__travel-order input__required"
                                                    />
                                                </div>
                                                <div className="travel-order__row">
                                                    <Input label={t('holderDocSeries')}
                                                        name="holderDocSeries"
                                                        placeholder="АА"
                                                        mask={/[^+\d[A-Z]/g}
                                                        error={errors.holderDocSeries}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderSeries', value)
                                                        }}
                                                    />
                                                    <Input label={t('holderDocNumber')}
                                                        name="holderDocNumber"
                                                        placeholder="1234567890"
                                                        error={errors.holderDocNumber}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderDocNumber', value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="travel-order__row">
                                                    <Input label={t('holderPostalCodeReg')}
                                                        name="holderPostalCodeReg" placeholder={t('holderPostalCodePH')}
                                                        error={errors.holderPostalCodeReg}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderPostalCodeReg', value)
                                                            if (values.holderAddressEqual) {
                                                                setFieldValueOrig('holderPostalCodeFact', value)
                                                                setFieldValue('holderPostalCodeFact', value)
                                                            }
                                                        }}
                                                    />
                                                    <Input label={t('holderAddressReg')}
                                                        name="holderAddressReg" placeholder={t('holderAddressPH')}
                                                        error={errors.holderAddressReg}
                                                        onChange={({ target: { value } }) => {
                                                            setFieldValueOrig('holderAddressReg', value)
                                                            if (values.holderAddressEqual) {
                                                                setFieldValueOrig('holderAddressFact', value)
                                                                setFieldValue('holderAddressFact', value)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="travel-order__text checkbox travel-order__checkbox holder-checkbox">
                                                    <label className="travel-order__rules">
                                                        <p className="holder-checkbox__text">Страхователь является застрахованным.</p>
                                                        <input className="checkbox__input" type="checkbox" checked="checked" />
                                                        <span className="checkbox__span" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="travel-one__total_main">
                                                <div className="travel-order__total travel-order__summ">
                                                    Стоимость: 3000
                                                    <span className="travel-option__currency">tjs</span>
                                                </div>
                                                <div className="travel-one__name">{t('travelCountry')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.country) ? (
                                                        <Fragment>
                                                            {values.country.map((item, index) => {
                                                                if (values.country.length === 1) {
                                                                    return item.label
                                                                }
                                                                return index === 0 ? item.label : `, ${item.label}`
                                                            })}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                </div>
                                                <div className="travel-one__name">{t('travelPeriod')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.beginDate || values.endDate) ? (
                                                        <Fragment>
                                                            {!isEmpty(values.beginDate)
                                                                ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                            &nbsp;-&nbsp;
                                                            {!isEmpty(values.endDate)
                                                                ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                    {/* Если выбрана мультипоездка, отображать уведомление ниже */}
                                                    <p className="aside__hint">
                                                        Каждая поездка: не более 90 дней.
                                                    </p>
                                                </div>
                                                {
                                                    cost ? (
                                                        <>
                                                            <div
                                                                className="travel-one__name">
                                                                {t('fullCost')}
                                                            </div>
                                                            <div
                                                                className="travel-one__value">
                                                                {cost} TJS
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className="travel-one__name">Сумма покрытия</div>
                                                <div className="travel-one__value travel-one__value-mb">
                                                    30000
                                                    <span className="travel-option__currency">eur</span>
                                                </div>
                                                <div className="travel-option__description">
                                                    <div className="travel-option__description-text">
                                                        Лечение, консультации врачей и диагностика,
                                                        покупка лекарств, транспортные расходы.
                                                    </div>
                                                    <button className="travel-option__description-button">
                                                        Полное описание покрытия
                                                    </button>
                                                </div>
                                                <div className="travel-one__name aside-option__name">Дополнительные опции</div>
                                                <p className="aside__option">
                                                    Личные вещи
                                                </p>
                                                <p className="aside__option">
                                                    Защита дома
                                                </p>
                                                <div className="travel-order__promo promo">
                                                    <p className="promo__subtitle">Промокод</p>
                                                    <div className="travel-order__promo-row">
                                                        <div className="input input__promo">
                                                            <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                            </label>
                                                            <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                        </div>
                                                        <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                            <img src={promoArrow} alt="" width="16" height="16" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <button className="travel-one__form-btn step-button" disabled>Далее</button>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="step step-4">
                                        <div className="travel-one__form-items">
                                            <div className="travel-one__form-item">
                                                <div className="travel-one__form-row travel-step">
                                                    <div className="travel__step-info">
                                                        <p className="step-info__text step-info__text-accent">
                                                            Германия, Испания, Италия; Португалия, ОАЭ, Мексика;
                                                        </p>
                                                        <p className="step-info__text">20 дней;</p>
                                                        <p className="step-info__text">2 человека;</p>
                                                        <p className="step-info__text">спокойный</p>
                                                    </div>
                                                    <a className="change__button">изменить</a>
                                                </div>
                                                <div className="travel-one__form-row travel-step">
                                                    <div className="travel__step-info">
                                                        <p className="step-info__text step-info__text-accent">
                                                            Страхователь:
                                                        </p>
                                                        <p className="step-info__text">Фамилия  Имя Отчество</p>
                                                    </div>
                                                    <a className="change__button">изменить</a>
                                                </div>
                                                <div className="insured-person__block">
                                                    <div className="insured-person__title">
                                                        <h3 className="travel-order__subtitle subtitle">
                                                            Застрахованный 1:
                                                        </h3>
                                                        <a className="change__button copy__button">Удалить</a>
                                                    </div>
                                                    <Input label={t('holderFullName')}
                                                        hint={hints.fio} name="holderFullName"
                                                        placeholder={t('holderFullNamePH')}
                                                        error={errors.holderFullName}
                                                        onChange={() => {
                                                            clearTimeout(timer)
                                                            timer = setTimeout(() => {
                                                                const arr = values.holderFullName.split(' ')
                                                                setFieldValueOrig('holderLastname', arr[0] || '')
                                                                setFieldValueOrig('holderFirstname', arr[1] || '')
                                                                arr.splice(0, 2)
                                                                setFieldValueOrig('holderPatronymic', arr.join(' ') || '')
                                                            }, 300)
                                                        }}
                                                    />
                                                    {/* TODO:01/08/2022 Добавила поиск в селект */}
                                                    <div className="select select__travel-one travel-one__input_ful">
                                                        <label className="select__label">Гражданство</label>
                                                        <div className="select__current">--------</div>
                                                        {/* TODO:В открытом виде для select__list добавить класс select__list_open */}
                                                        <div className="select__list select__list_open">
                                                            <input className="select__search select__search-icon" type="search" />
                                                            <div className="select__item">
                                                                <span className="select__text-value">Таджикистан</span>
                                                            </div>
                                                            <div className="select__item">
                                                                <span className="select__text-value">Казахстан</span>
                                                            </div>
                                                            <div className="select__item">
                                                                <span className="select__text-value">Армения</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderPhone')} hint={hints.phoneM}
                                                            name="holderPhone" placeholder="+992 00-000-00-00"
                                                            error={errors.holderPhone}
                                                        />
                                                        <Input label={t('holderEmail')} hint={hints.email}
                                                            name="holderEmail" placeholder="mail@mail.ru"
                                                            error={errors.holderEmail}
                                                        />
                                                    </div>
                                                    <div className="travel-order__row gender__row">
                                                        <DateInput label={t('holderBirthday')}
                                                            name="holderBirthday"
                                                            error={errors.holderBirthday}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderBirthday', value)
                                                            }}
                                                            className="input travel-order__input-date"
                                                        />
                                                        <div className="select select__travel-order input__required">
                                                            <label className="select__label">Пол<span className="input_error-text" /></label>
                                                            <div className="travel-currency__box gender-box">
                                                                <button className="currency__item currency__item_active">Мужской</button>
                                                                <button className="currency__item">Женский</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Select
                                                            label={t('holderDocType')}
                                                            name="holderDocType"
                                                            error={errors.holderDocType}
                                                            options={docTypes}
                                                            defValue="--------"
                                                            onChange={(value) => {
                                                                setFieldValueOrig('holderDocType', value)
                                                            }}
                                                            className="select select__travel-order input__required"
                                                        />
                                                        {/* Здесь текст я бы заменила
                                                        на "Дата выдачи документа" */}
                                                        <DateInput
                                                            label={t('holderIssueDate')}
                                                            name="holderIssueDate"
                                                            error={errors.holderIssueDate}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderIssueDate', value)
                                                            }}
                                                            className="input select select__travel-order input__required"
                                                        />
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderDocSeries')}
                                                            name="holderDocSeries"
                                                            placeholder="АА"
                                                            mask={/[^+\d[A-Z]/g}
                                                            error={errors.holderDocSeries}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderSeries', value)
                                                            }}
                                                        />
                                                        <Input label={t('holderDocNumber')}
                                                            name="holderDocNumber"
                                                            placeholder="1234567890"
                                                            error={errors.holderDocNumber}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderDocNumber', value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderPostalCodeReg')}
                                                            name="holderPostalCodeReg" placeholder={t('holderPostalCodePH')}
                                                            error={errors.holderPostalCodeReg}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderPostalCodeReg', value)
                                                                if (values.holderAddressEqual) {
                                                                    setFieldValueOrig('holderPostalCodeFact', value)
                                                                    setFieldValue('holderPostalCodeFact', value)
                                                                }
                                                            }}
                                                        />
                                                        <Input label={t('holderAddressReg')}
                                                            name="holderAddressReg" placeholder={t('holderAddressPH')}
                                                            error={errors.holderAddressReg}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderAddressReg', value)
                                                                if (values.holderAddressEqual) {
                                                                    setFieldValueOrig('holderAddressFact', value)
                                                                    setFieldValue('holderAddressFact', value)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <a className="add-person__button">Добавить</a>
                                                </div>
                                                <div className="insured-person__block">
                                                    <div className="insured-person__title">
                                                        <h3 className="travel-order__subtitle subtitle">
                                                            Застрахованный 2:
                                                        </h3>
                                                        <a className="change__button copy__button">Удалить</a>
                                                    </div>
                                                    <Input label={t('holderFullName')}
                                                        hint={hints.fio} name="holderFullName"
                                                        placeholder={t('holderFullNamePH')}
                                                        error={errors.holderFullName}
                                                        onChange={() => {
                                                            clearTimeout(timer)
                                                            timer = setTimeout(() => {
                                                                const arr = values.holderFullName.split(' ')
                                                                setFieldValueOrig('holderLastname', arr[0] || '')
                                                                setFieldValueOrig('holderFirstname', arr[1] || '')
                                                                arr.splice(0, 2)
                                                                setFieldValueOrig('holderPatronymic', arr.join(' ') || '')
                                                            }, 300)
                                                        }}
                                                    />
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderPhone')} hint={hints.phoneM}
                                                            name="holderPhone" placeholder="+992 00-000-00-00"
                                                            error={errors.holderPhone}
                                                        />
                                                        <Input label={t('holderEmail')} hint={hints.email}
                                                            name="holderEmail" placeholder="mail@mail.ru"
                                                            error={errors.holderEmail}
                                                        />
                                                    </div>
                                                    <div className="travel-order__row gender__row">
                                                        <DateInput label={t('holderBirthday')}
                                                            name="holderBirthday"
                                                            error={errors.holderBirthday}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderBirthday', value)
                                                            }}
                                                            className="input travel-order__input-date"
                                                        />
                                                        <div className="select select__travel-order input__required">
                                                            <label className="select__label">Пол<span className="input_error-text" /></label>
                                                            <div className="travel-currency__box gender-box">
                                                                <button className="currency__item currency__item_active">Мужской</button>
                                                                <button className="currency__item">Женский</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Select
                                                            label={t('holderDocType')}
                                                            name="holderDocType"
                                                            error={errors.holderDocType}
                                                            options={docTypes}
                                                            defValue="--------"
                                                            onChange={(value) => {
                                                                setFieldValueOrig('holderDocType', value)
                                                            }}
                                                            className="select select__travel-order input__required"
                                                        />
                                                        {/* Здесь текст я бы заменила
                                                        на "Дата выдачи документа" */}
                                                        <DateInput
                                                            label={t('holderIssueDate')}
                                                            name="holderIssueDate"
                                                            error={errors.holderIssueDate}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderIssueDate', value)
                                                            }}
                                                            className="input select select__travel-order input__required"
                                                        />
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderDocSeries')}
                                                            name="holderDocSeries"
                                                            placeholder="АА"
                                                            mask={/[^+\d[A-Z]/g}
                                                            error={errors.holderDocSeries}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderSeries', value)
                                                            }}
                                                        />
                                                        <Input label={t('holderDocNumber')}
                                                            name="holderDocNumber"
                                                            placeholder="1234567890"
                                                            error={errors.holderDocNumber}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderDocNumber', value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="travel-order__row">
                                                        <Input label={t('holderPostalCodeReg')}
                                                            name="holderPostalCodeReg" placeholder={t('holderPostalCodePH')}
                                                            error={errors.holderPostalCodeReg}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderPostalCodeReg', value)
                                                                if (values.holderAddressEqual) {
                                                                    setFieldValueOrig('holderPostalCodeFact', value)
                                                                    setFieldValue('holderPostalCodeFact', value)
                                                                }
                                                            }}
                                                        />
                                                        <Input label={t('holderAddressReg')}
                                                            name="holderAddressReg" placeholder={t('holderAddressPH')}
                                                            error={errors.holderAddressReg}
                                                            onChange={({ target: { value } }) => {
                                                                setFieldValueOrig('holderAddressReg', value)
                                                                if (values.holderAddressEqual) {
                                                                    setFieldValueOrig('holderAddressFact', value)
                                                                    setFieldValue('holderAddressFact', value)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <a className="add-person__button">Добавить</a>
                                                </div>
                                                <div className="insured-person__block estate-adress__block">
                                                    <div className="insured-person__title">
                                                        <h3 className="travel-order__subtitle subtitle">
                                                            Дом:
                                                        </h3>
                                                    </div>
                                                    <div className="input">
                                                        <label className="input__label hint__label">Адрес застрахованной недвижимости
                                                            <span className="hint__icon">
                                                                <span className="hint__content">
                                                                    Укажите полный адрес застрахованного на время Вашего отпуска дома/квартиры.
                                                                </span>
                                                            </span>
                                                            <span className="input_error-text" />
                                                        </label>
                                                        <input className="input__input" type="text" value="" name="" placeholder="Адрес" />
                                                    </div>
                                                </div>
                                                <div className="travel-order__text checkbox rules__check">
                                                    <label className="travel-order__rules">
                                                        <p className="holder-checkbox__text">
                                                            Даю согласие <a href="" className="privacy__rules">на обработку персональных данных</a>.
                                                            Подтверждаю, что с <a href="" className="privacy__rules">Правилами страхования</a>,
                                                            <a href="" className="privacy__rules"> Условиями страхования</a> ознакомлен и согласен.
                                                        </p>
                                                        <input className="checkbox__input" type="checkbox" checked="checked" />
                                                        <span className="checkbox__span" />
                                                    </label>
                                                </div>
                                                {/* TODO: 12/08/2022 добавила кнопку-ссылку с уведомлением заполнить обязательные поля */}
                                                <div className="warning-fields">
                                                    Заполните <button className="warning-fields__button">обязательные поля</button>
                                                </div>
                                            </div>
                                            <div className="travel-one__total_main">
                                                <div className="travel-order__total travel-order__summ">
                                                    Стоимость: 3000
                                                    <span className="travel-option__currency">tjs</span>
                                                </div>
                                                <div className="travel-one__name">{t('travelCountry')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.country) ? (
                                                        <Fragment>
                                                            {values.country.map((item, index) => {
                                                                if (values.country.length === 1) {
                                                                    return item.label
                                                                }
                                                                return index === 0 ? item.label : `, ${item.label}`
                                                            })}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                </div>
                                                <div className="travel-one__name">{t('travelPeriod')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.beginDate || values.endDate) ? (
                                                        <Fragment>
                                                            {!isEmpty(values.beginDate)
                                                                ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                            &nbsp;-&nbsp;
                                                            {!isEmpty(values.endDate)
                                                                ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                    {/* Если выбрана мультипоездка, отображать уведомление ниже */}
                                                    <p className="aside__hint">
                                                        Каждая поездка: не более 90 дней.
                                                    </p>
                                                </div>
                                                {
                                                    cost ? (
                                                        <>
                                                            <div
                                                                className="travel-one__name">
                                                                {t('fullCost')}
                                                            </div>
                                                            <div
                                                                className="travel-one__value">
                                                                {cost} TJS
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className="travel-one__name">Сумма покрытия</div>
                                                <div className="travel-one__value travel-one__value-mb">
                                                    30000
                                                    <span className="travel-option__currency">eur</span>
                                                </div>
                                                <div className="travel-option__description">
                                                    <div className="travel-option__description-text">
                                                        Лечение, консультации врачей и диагностика,
                                                        покупка лекарств, транспортные расходы.
                                                    </div>
                                                    <button className="travel-option__description-button">
                                                        Полное описание покрытия
                                                    </button>
                                                </div>
                                                <div className="travel-one__name aside-option__name">Дополнительные опции</div>
                                                <p className="aside__option">
                                                    Личные вещи
                                                </p>
                                                <p className="aside__option">
                                                    Защита дома
                                                </p>
                                                <div className="travel-order__promo promo">
                                                    <p className="promo__subtitle">Промокод</p>
                                                    <div className="travel-order__promo-row">
                                                        <div className="input input__promo">
                                                            <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                            </label>
                                                            <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                        </div>
                                                        <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                            <img src={promoArrow} alt="" width="16" height="16" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <button className="travel-one__form-btn step-button">Далее</button>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="step step-5">
                                        <div className="travel-one__form-items">
                                            <div className="contacts__item">
                                                <h3 className="travel-order__subtitle subtitle">
                                                    Проверьте данные
                                                </h3>
                                                <div className="contacts__name">Страхователь</div>
                                                <p className="contacts__data">Фамилия Имя Отчество</p>
                                                <p className="contacts__data">14.01.1980</p>
                                                <p className="contacts__data">mail@mail.ru</p>
                                                {/* Если страхователь застрахован - в списке застрахованных тоже должен быть */}
                                                <div className="contacts__name">Застрахованные</div>
                                                <div className="contacts__name_wrap">
                                                    <p className="contacts__data">Фамилия Имя Отчество</p>
                                                    <p className="contacts__data">14.01.1980</p>
                                                </div>
                                                <div className="contacts__name_wrap">
                                                    <p className="contacts__data">Фамилия Имя Отчество</p>
                                                    <p className="contacts__data">14.01.1980</p>
                                                </div>
                                                <div className="contacts__name_wrap">
                                                    <p className="contacts__data">Фамилия Имя Отчество</p>
                                                    <p className="contacts__data">14.01.1986</p>
                                                </div>
                                                <div className="contacts__info_box">
                                                    <p className="contacts__info">После оплаты полис будет направлен на указанный адрес электронной почты.</p>
                                                    <p className="contacts__info">
                                                        Номер полиса:
                                                        <span className="contacts__info_number">124545/455-20</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="travel-one__total_main">
                                                <div className="travel-order__total travel-order__summ">
                                                    Стоимость: 3000
                                                    <span className="travel-option__currency">tjs</span>
                                                </div>
                                                <div className="travel-one__name">{t('travelCountry')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.country) ? (
                                                        <Fragment>
                                                            {values.country.map((item, index) => {
                                                                if (values.country.length === 1) {
                                                                    return item.label
                                                                }
                                                                return index === 0 ? item.label : `, ${item.label}`
                                                            })}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                </div>
                                                <div className="travel-one__name">{t('travelPeriod')}</div>
                                                <div className="travel-one__value">
                                                    {!isEmpty(values.beginDate || values.endDate) ? (
                                                        <Fragment>
                                                            {!isEmpty(values.beginDate)
                                                                ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                                                            &nbsp;-&nbsp;
                                                            {!isEmpty(values.endDate)
                                                                ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                                                        </Fragment>
                                                    ) : t('nullChoose')}
                                                    {/* Если выбрана мультипоездка, отображать уведомление ниже */}
                                                    <p className="aside__hint">
                                                        Каждая поездка: не более 90 дней.
                                                    </p>
                                                </div>
                                                {
                                                    cost ? (
                                                        <>
                                                            <div
                                                                className="travel-one__name">
                                                                {t('fullCost')}
                                                            </div>
                                                            <div
                                                                className="travel-one__value">
                                                                {cost} TJS
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                                <div className="travel-one__name">Сумма покрытия</div>
                                                <div className="travel-one__value travel-one__value-mb">
                                                    30000
                                                    <span className="travel-option__currency">eur</span>
                                                </div>
                                                <div className="travel-option__description">
                                                    <div className="travel-option__description-text">
                                                        Лечение, консультации врачей и диагностика,
                                                        покупка лекарств, транспортные расходы.
                                                    </div>
                                                    <button className="travel-option__description-button">
                                                        Полное описание покрытия
                                                    </button>
                                                </div>
                                                <div className="travel-one__name aside-option__name">Дополнительные опции</div>
                                                <p className="aside__option">
                                                    Личные вещи
                                                </p>
                                                <p className="aside__option">
                                                    Защита дома
                                                </p>
                                                <div className="travel-order__promo promo">
                                                    <p className="promo__subtitle">Промокод</p>
                                                    <div className="travel-order__promo-row">
                                                        <div className="input input__promo">
                                                            <label className="input__label"><span className="input_error-text">Ошибка</span>
                                                            </label>
                                                            <input className="input__input promo__input" type="text" value="" name="" placeholder="" />
                                                        </div>
                                                        <a className="travel-order__btn promo__btn" href="javascript:void(0)">
                                                            <img src={promoArrow} alt="" width="16" height="16" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <button className="travel-one__form-btn step-button">Купить онлайн</button>
                                            </div>
                                        </div>
                                    </section>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

                <div className="travel-one__container">
                    <h3 className="travel-one__title">{t('travelBoxProducts')}</h3>
                </div>

                <div ref={products} />
                <TravelProducts />

                <div className="travel-one__container" style={{ display: 'none' }}>
                    <h3 className="travel-one__title">Внимание SEO текст!</h3>
                    <div className="seo-text">
                        <p className="seo-text__text">
                            Харизматическое лидерство, тем более в условиях социально-экономического кризиса, стабильно.
                            Как
                            уже подчеркивалось,
                            политическая легитимность представляет собой коллапс Советского Союза. Общеизвестно, что
                            политическое учение
                            Августина практически сохраняет функциональный политический процесс в современной России.
                            Натуралистическая
                            парадигма теоретически верифицирует социализм. Гуманизм, на первый взгляд, обретает
                            гносеологический
                            континентально-европейский тип политической культуры.
                        </p>
                    </div>
                    <ul className="seo-text__ul">
                        <li className="seo-text__li">В данном случае можно согласиться с Данилевским;</li>
                        <li className="seo-text__li">
                            Cчитавшим, что политическое учение Фомы Аквинского практически обретает функциональный;
                            Континентально-европейский
                            тип политической культуры;
                        </li>
                        <li className="seo-text__li">Политическое манипулирование случайно. Коллапс Советского Союза
                            теоретически возможен;
                        </li>
                    </ul>
                </div>
            </div>
            {/* TODO: 08.08.22 Попапы надо вытащить в отдельные компоненты и изменить общий лэйаут,
            поставить компоненты попапов после page__content перед футером
            У page__content стоит ограничение на ширину контента 1280px и из-за этого затемнение страницы у попапа не занимает весь экран
             */}
            {/* TODO: 08.08.22 окошко о типах отдыха, появляется при нажатии на кнопку в хинте
            "Подробнее о типах отдыха",
            нужно еще на body навешивать класс noscroll при открытии попап,
            чтобы содержимое боди не двигалось.
            За показ попап отвечает класс travel-popup-active у travel-type__hint-popup */}
            <div className="travel-type__hint-popup">
                <div className="travel-type__hint-info">
                    <div className="travel-type__hint-head">
                        <h4 className="travel-type__hint-title">Типы отдыха</h4>
                        <button className="travel-type__hint-close">
                            <span />
                            <span />
                        </button>
                    </div>
                    <span className="travel-type__hint-type">Спокойный отдых</span>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item">Пляжный</li>
                        <li className="travel-type__hint-item">Экскурсионный</li>
                        <li className="travel-type__hint-item">Посещение аквапарков, бассейнов</li>
                        <li className="travel-type__hint-item">Велосипедные прогулки</li>
                    </ul>
                    <span className="travel-type__hint-type">Спортивный отдых</span>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item">
                            Сноубординг/горные лыжи на подготовленных трассах
                        </li>
                        <li className="travel-type__hint-item">Серфинг</li>
                        <li className="travel-type__hint-item">Рафтинг</li>
                        <li className="travel-type__hint-item">Парашют</li>
                        <li className="travel-type__hint-item">
                            Полеты на моторных и безмоторных летательных аппаратах
                        </li>
                        <li className="travel-type__hint-item">Автоспорт</li>
                        <li className="travel-type__hint-item">Мотоспорт</li>
                        <li className="travel-type__hint-item">Мотороллеры, мопеды и т.п.</li>
                    </ul>
                </div>
            </div>
            {/* попап для бокового блока, что входит в полис */}
            <div className="travel-type__hint-popup aside-popup">
                <div className="travel-type__hint-info">
                    <div className="travel-type__hint-head">
                        <h4 className="travel-type__hint-title aside-popup__title">Что входит в полис</h4>
                        <button className="travel-type__hint-close">
                            <span />
                            <span />
                        </button>
                    </div>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item aside-popup__item">Коронавирус и стихийные бедствия</li>
                        <li className="travel-type__hint-item aside-popup__item">Амбулаторное лечение</li>
                        <li className="travel-type__hint-item aside-popup__item">Вызов врача на дом</li>
                        <li className="travel-type__hint-item aside-popup__item">Вызов санитарно-медицинской помощи</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы на услуги, назначенные лечащим врачом
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Диагностические и лабораторные исследования</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Приобретение медикаментов, назначенных лечащим врачом
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы на перевязочные средства и средства фиксации (гипс, бандаж)
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Пребывание и лечение в стационаре
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Консультации узких специалистов</li>
                        <li className="travel-type__hint-item aside-popup__item">Проведение экстренных операций</li>
                        <li className="travel-type__hint-item aside-popup__item">Экстренная стоматология</li>
                        <li className="travel-type__hint-item aside-popup__item">Транспортировка к врачу или в медицинское учреждение</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Транспортировка до места проживания при условии отсутствия возможностей
                            для предоставления экстренной медицинской помощи на территории страхования
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Транспортировка до места проживания при условии отсутствия возможности
                            у Застрахованного по медицинским показаниям самостоятельно вернуться
                            к месту проживания по причине страхового случая
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы по подготовке к репатриации и репатриация останков
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Проезд до места жительства после лечения в стационаре
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Наблюдение в стационаре</li>
                        <li className="travel-type__hint-item aside-popup__item">Возвращение несовершеннолетних детей</li>
                        <li className="travel-type__hint-item aside-popup__item">Визит третьего лица</li>
                    </ul>
                </div>
            </div>
            {/* TODO: 10/01/2023 попап для кнопки Купить онлайн с выбором способа оплаты */}
            <div className="travel-type__hint-popup aside-popup travel-popup-active">
                <div className="travel-type__hint-info travel-type__payments">
                    <button type="button" className="popup__close">x</button>
                    <h3 className="travel-order__subtitle subtitle payments__subtitle">
                        Способ оплаты
                    </h3>
                    <div className="payments">
                        <div className="payments__button">
                            <input className="payments__input" type="radio" name="payment" id="payment-vasl" checked />
                            <label className="payments__label" htmlFor="payment-vasl">
                                <div className="payments__image payments__image--vasl" />
                                <span className="payments__name">Vasl</span>
                            </label>
                        </div>
                        <div className="payments__button">
                            <input className="payments__input" type="radio" name="payment" id="payment-corti" />
                            <label className="payments__label" htmlFor="payment-corti">
                                <div className="payments__image payments__image--corti" />
                                <span className="payments__name">Corti Milli</span>
                            </label>
                        </div>
                        <div className="payments__button">
                            <input className="payments__input" type="radio" name="payment" id="payment-card" />
                            <label className="payments__label" htmlFor="payment-card">
                                <div className="payments__image payments__image--card" />
                                <span className="payments__name">Visa/MasterCard</span>
                            </label>
                        </div>
                        <div className="payments__button">
                            <input className="payments__input" type="radio" name="payment" id="payment-dccity" />
                            <label className="payments__label" htmlFor="payment-dccity">
                                <div className="payments__image payments__image--dccity" />
                                <span className="payments__name">DCCity</span>
                            </label>
                        </div>
                    </div>
                    <button className="button button__submit payments__submit">Оплатить</button>
                </div>
            </div>
        </Layout>
    )
}
