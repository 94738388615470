import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import { mergeRefs } from '../../helpers/globals'

export default function SelectList({
    name,
    label,
    options,
    onChange,
    classNames,
    itemRef,
}) {
    const { setFieldValue, values } = useFormikContext()
    const [open, setOpen] = useState(false)

    const ref = useRef(null)
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return (
        <div className={cn(classNames || 'select select__travel-order')} ref={mergeRefs(ref, itemRef)}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setOpen((value) => !value)
            }}
        >
            <label className={cn('select__label', { 'label-valid': values[name] })}>{label}</label>
            <div className="select__current">
                {options.map((item) => (item.value === values[name] ? item.name || item.label : null))}
            </div>
            <div className={cn('select__list travel__select-summ', { select__list_open: open })}>
                {
                    options.map((item) => (
                        <div className="select__item" key={item.name}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setFieldValue(name, item.value)
                                setOpen(false)
                                if (onChange) onChange(e)
                            }}>
                            <span className="select__text-value">
                                {item.name}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
