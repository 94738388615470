import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Carousel from '../common/Carousel'
import { useLoad } from '../../hooks/request'
import { CMS } from '../../utils/urls'
import { AuthContext } from '../../contexts/AuthContext'
import Loader from '../base/Loader'
import baggage from '../../static/img/baggage.png'
import { Context } from '../../contexts/GlobalContext'

export default function TravelProducts() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)
    const { response } = useLoad({
        url: `${CMS.ALL_BOXES
            .replace('{page}', '1')
            .replace('{size}', '3')
            .replace('{category}', 'dlya-puteshestvennikov')
        }`,
    }, [cmsToken, language])
    const { t } = useTranslation('travel')

    if (!response) {
        return (
            <Loader centered />
        )
    }
    const data = response.data.filter((item) => (!item.seo))

    return (
        <Carousel
            autoplay
            dots
            slidesToShow={data.length > 3 ? 3 : data.length}
            arrows={false}
            centerMode
            autoplaySpeed={4000}
            focusOnSelect={false}
            className="slider-products">
            {data.map((item) => (
                <div className="mr-4">
                    <div key={item.id}
                        className="slider-products__item">
                        <span
                            className="slider-products__label">{item.title}
                        </span>
                        <div className="slider-products__image">
                            <img className="slider-products__img"
                                src={baggage} alt="" />
                        </div>
                        <div className="slider-products__info">
                            <h3 className="slider-products__subtitle">{item.title}</h3>
                            <p className="slider-products__text">{item.shortDesc}</p>
                            <div className="slider-products__links">
                                <NavLink to={`/boxes/order/${item.slug}/`}
                                    className="slider-products__link request-link">
                                    {t('buy')}
                                </NavLink>
                                <NavLink to={`/boxes/${item.slug}`} className="slider-products__link info-link">
                                    {t('more')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    )
}
