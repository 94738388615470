import React, { Fragment, useContext } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { optionsTravel, spaType } from '../../helpers/travel'
import promoArrow from '../../static/icons/arrow-promo.svg'
import Button from '../common/Button'
import { VzrContext } from '../../contexts/VzrContext'
import Checkbox from '../base/Checkbox'
import { formatPrice } from '../../helpers/globals'

export default function FormTotal() {
    const { t } = useTranslation('travel')
    const { values, validateForm, setFieldValue, handleSubmit, errors } = useFormikContext()
    const { step, nextStep, cost, travelType, errorCoupon, toggleInfoPopup, showPayment } = useContext(VzrContext)

    return (
        <div className="travel-one__total_main">
            {
                cost
                && (
                    <div className="travel-order__total travel-order__summ">
                        {t('insuranceCost', { price: formatPrice(cost) })}<span className="travel-option__currency">tjs</span>
                    </div>
                )
            }

            <div className="travel-one__name">{t('travelCountry')}</div>

            <div className="travel-one__value">
                {!isEmpty(values.country) ? (
                    <Fragment>
                        {values.country.map((item, index) => {
                            if (values.country.length === 1) {
                                return item.label
                            }
                            return index === 0 ? item.label : `, ${item.label}`
                        })}
                    </Fragment>
                ) : t('nullChoose')}
            </div>

            {
                false && (
                    <>
                        <div className="travel-one__name">
                            {t('frequencyTravel')}
                        </div>
                        <div className="travel-one__value">
                            {travelType[values.multi]}
                        </div>
                    </>
                )
            }

            <div className="travel-one__name">{t('travelPeriod')}</div>
            <div className="travel-one__value">
                {!isEmpty(values.beginDate || values.endDate) ? (
                    <Fragment>
                        {!isEmpty(values.beginDate)
                            ? moment(values.beginDate).format('DD.MM.YYYY') : ''}
                        &nbsp;-&nbsp;
                        {!isEmpty(values.endDate)
                            ? moment(values.endDate).format('DD.MM.YYYY') : ''}
                    </Fragment>
                ) : t('nullChoose')}
            </div>
            <div className="travel-one__value">
                {(values.beginDate && values.endDate)
                    ? t('totalDays', { count: values.dayCount })
                    : ''}
            </div>
            {
                false && (
                    <>
                        <div className="travel-one__name">{t('restType')}</div>
                        <div className="travel-one__value">
                            {
                                (!isEmpty(spaType[values.sportOption]))
                                    ? spaType[values.sportOption].name
                                    : t('nullChoose')
                            }
                        </div>
                    </>
                )
            }

            <div className="travel-one__name">{t('mainSumPrem')}</div>
            <div className="travel-one__value">
                {formatPrice(values.mainSumAssured)}
                <span className="travel-option__currency">{values.currency}</span>
            </div>
            {(values.sequence.filter((item) => item !== 0).length > 0) && <div className="travel-one__name">{t('additionalOptions')}</div>}
            {optionsTravel.map((item) => (
                values.sequence.indexOf(item.seq) !== -1 && item.seq !== 0
                    ? <p className="aside__option">{t(item.trans)}</p>
                    : null
            ))}
            <button
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    toggleInfoPopup()
                }}
                className="travel-option__description-button">
                {t('openInfoPopup')}
            </button>
            <div className="travel-order__promo promo">
                <p className="promo__subtitle">{t('promoCode')}</p>
                <div className="travel-order__promo-row">
                    <div className="input input__promo">
                        <label className="input__label">
                            <span className="input_error-text">{t('errorCoupon')}</span>
                        </label>
                        <input className="input__input promo__input" type="text" value={values.coupon} name="coupon" placeholder=""
                            onChange={(e) => setFieldValue('coupon', e.target.value)} />
                    </div>
                    <a className="travel-order__btn promo__btn" onClick={() => handleSubmit()}>
                        <img src={promoArrow} alt="" width="16" height="16" />
                    </a>
                </div>
                <div className="travel__error" style={{ color: 'darkred', paddingBottom: '10px' }}>{errorCoupon}</div>
            </div>
            <Button
                onClick={() => {
                    validateForm().then((e) => {
                        setFieldValue('shortValidate', false)
                        if (!e.haveErrors) {
                            if (step < 4) {
                                nextStep()
                            } else if (values.agree) {
                                showPayment()
                            }
                        }
                    })
                }}
                disabled={(!values.agree || errors.haveErrors || values.short) && step === 3}
                text={step > 3 ? t('holderAgree') : t('nextStep')}
                className="travel-one__form-btn step-button" />

            { step > 16 && (
                <Checkbox
                    name="agree"
                    className="travel-order__checkbox"
                    error={errors.agree}
                    label={(
                        <>
                            {t('common:agreement1')}&nbsp;
                            <a className="auto-insurance__rules-link"
                                href="/rules"
                                target="_blank"
                                rel="noreferrer">
                                {t('common:agreement2')}
                            </a>
                            {t('common:agreement3')}&nbsp;
                            <a
                                className="auto-insurance__rules-link"
                                href="/politics"
                                target="_blank"
                                rel="noreferrer">
                                {t('common:agreement4')}
                            </a>
                        </>
                    )} />
            )}
        </div>
    )
}
