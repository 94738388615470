import React from 'react'
import cn from 'classnames'
import Loader from './Loader'

export default function Button({ onClick, type = 'button', className, text, loading, disabled }) {
    return (
        <button onClick={onClick} disabled={disabled} type={type}
            className={cn(className, { 'disabled-button': disabled })} style={{ border: 'none' }}>
            {loading ? <Loader centered /> : text}
        </button>
    )
}
