import React from 'react'
import { NavLink } from 'react-router-dom'

export default function VacancyItem({ item }) {
    return (
        <div className="vacancy-item">
            <picture className="vacancy-item__img">
                <source media="(min-width: 1280px)" srcSet={item.mainImg} />
                <img src={item.mainImg} alt="" style={{ height: '100%', objectFit: 'cover' }} />
            </picture>

            <div className="vacancy-item__info">
                <h3 className="vacancy-item__title">{item.title}</h3>

                <ul className="vacancy-item__ul">
                    <li className="vacancy-item__li">{item.location}</li>
                    <li className="vacancy-item__li">{item.salary}</li>
                    <li className="vacancy-item__li">{item.exp}</li>
                </ul>

                <div className="vacancy-item__footer">
                    {/* <label onClick={() => push(`/vacancy/${item.slug}`, { sendForm: true })}
                        className="vacancy-item__button">
                        Отправить резюме
                    </label> */}
                    <NavLink to={`/vacancy/${item.slug}`} className="vacancy-item__link">
                        Подробнее
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
