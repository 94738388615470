import React, { useContext } from 'react'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import PartnersList from '../components/PartnersList'
import Loader from '../components/base/Loader'
import { Context } from '../contexts/GlobalContext'

export default function Partners() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { response } = useLoad({ url: CMS.PARTNERS_CAT }, [cmsToken, language])

    if (!response) {
        return (
            <Layout>
                <Loader centered />
            </Layout>
        )
    }

    const partners = response.filter((item) => (!item.seo))
    return (
        <Layout>
            <PartnersList partnersList={partners} />
        </Layout>
    )
}
