import React from 'react'
import { NavLink } from 'react-router-dom'
// import { getDate } from '../utils/date'
// import calendar from '../static/icons/calendar.svg'

export default function BlogItem({ item }) {
    return (
        <NavLink to={`/blog/${item.slug}`} className="news-list__item">
            <div className="news-item">
                <picture>
                    <img className="news-item__img lazy" src={item.mainImg} alt="" />
                </picture>

                <div className="news-item__content">
                    {/* <div className="news-item__tags">
                        {index === 1 ? (
                            <Fragment>
                                {item.categories.map((cat) => (
                                    <span key={cat.catId} className="news-item__tags-links">
                                        {cat.catName}
                                    </span>
                                ))}
                            </Fragment>
                        ) : null}
                    </div> */}

                    <div className="news-item__wrapper">
                        <div className="news-item__text">{item.title}</div>

                        {/* <div className="news-item__footer">
                            <div className="news-item__date">
                                <div className="news-item__date-icon">
                                    <img src={calendar} alt="Calendar" />
                                </div>

                                {getDate(item.createdAt)}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </NavLink>
    )
}
