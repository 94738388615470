import React, { Fragment, useContext, useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Layout from '../components/Layout'
import Loader from '../components/base/Loader'
import VacancyItem from '../components/vacancy/VacancyItem'
import Pagination from '../components/base/Pagination'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'

export default function Vacancies() {
    const [page, setPage] = useState(1)
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { t } = useTranslation('vacancy')

    const vacancies = useLoad({ url: CMS.VACANCIES_ALL, params: { page, pageSize: 8 } }, [page, cmsToken, language])

    return (
        <Layout>
            <div className="vacancies">
                <div className="vacancies__container">
                    <h3 className="vacancies__title">{t('vacancy')}</h3>

                    {!vacancies.loading ? (
                        <Fragment>
                            {!isEmpty(vacancies.response ? vacancies.response.data : []) ? (
                                <Fragment>
                                    <div className="vacancies__items">
                                        {vacancies.response.data.map((item) => (
                                            <VacancyItem key={item.id} item={item} />
                                        ))}
                                    </div>

                                    <Pagination
                                        countPages={vacancies.response.lastPage}
                                        page={page}
                                        setPage={setPage} />
                                </Fragment>
                            ) : null}
                        </Fragment>
                    ) : <Loader centered />}
                </div>
            </div>
        </Layout>
    )
}
