import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { usePostRequest } from '../hooks/request'
import { CMS } from '../utils/urls'
import Button from './base/Button'
import Input from './base/Input'

export default function MailSubs() {
    const subs = usePostRequest({ url: CMS.MAIL_SUBS })
    const [result, setResult] = useState()
    const { t } = useTranslation('subs')

    async function onSend(data, actions) {
        const { response } = await subs.request({ data })
        if (!response) {
            setResult(t('errorEmail'))
        } else if (response.success === 'true') setResult(t('success'))
        else setResult(response.text)
        window.setTimeout(() => {
            actions.resetForm()
            setResult(null)
        }, 10000)
    }

    return (
        <div className="blog__bg">
            <div className="blog__container">
                <Formik onSubmit={onSend} placeholder="mail@example.tj" initialValues={{ email: '' }}>
                    {({ handleSubmit }) => {
                        if (result) {
                            return (
                                <div>{result}</div>
                            )
                        }
                        return (
                            <div className="subscribe-form">
                                <Form className="subscribe-form__form">
                                    <div className="input subscribe-form__input">
                                        <label className="input__label">
                                            {t('email')}
                                        </label>

                                        <Input
                                            name="email"
                                            className="input__input"
                                            type="email"
                                            placeholder="mail@example.tj" />
                                    </div>
                                </Form>

                                <p className="subscribe-form__rules">
                                    {t('text1')} <b><q>{t('text2')}</q></b> {t('text3')}
                                    <NavLink to="/politics" target="_blank"> {t('text4')}</NavLink>
                                    {t('text5')}<NavLink to="/rules" target="_blank">{t('text6')}</NavLink>
                                </p>

                                <Button
                                    text={t('subscribe')}
                                    onClick={handleSubmit}
                                    className="subscribe-form__button"
                                    loading={subs.loading} />
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}
