export default {
    incorrectlyFilled: 'Incorrectly filled',
    continue: 'Continue',
    loading: 'Loading...',
    wrongInput: 'Incorrectly filled',
    addFile: 'Attach file',
    fileHeader: 'Add photos from the event and / or scaned documents',
    agreement1: 'I agree with the terms of',
    agreement2: 'processing of personal data',
    agreement3: 'and',
    agreement4: 'website terms of use',
    internalError: 'Internal server error',
    enterText: 'Enter',
    company: 'Company',
    servicesText: 'Services',
    communicateText: 'Contact us',
    notFound: 'Page not found or under construction',
    login: 'Log in',
    registerText: 'Registration',
    partnerLinkText: 'Visit partners portal',
    enterPhone: 'Phone number',
    enterPassword: 'Password',
    forgotPassword: 'Forgot your password?',
    registerLinkText: 'Register',
    enterSMS: 'Enter SMS code',
    repeatSMS: 'Resend SMS code in',
    seconds: 'sec.',
    newPassword: 'Your new password',
    repeatPassword: 'Repeat your password',
    continueText: 'Continue',
    rulesText1: 'By clicking on "Continue" I agree with',
    rulesText2: 'terms of personal data processing',
    rulesText3: 'and',
    rulesText4: 'Website usage terms and conditions',
    notFound_OLD: 'Oops! Page is not found',
    registerTask: 'Insurance case',
    goHome: 'Go to Home page',
    offices: 'Offices',
    rus: 'Russian',
    tjs: 'Tajik',
    eng: 'English',
    languageSelect: 'Language: ',
    clients: 'Clients',
    business: 'Business',
    partners: 'Partners',
    requestRegistred: 'Your request registred',
    errorExist: 'Error, try later',
    backcallTitle: 'Call me back',
    phoneInput: 'Provide phone number',
    sendRequest: 'Send request',
    sendPay: 'Pay',
    errorText: 'An error has occurred',
}
