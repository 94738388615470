export default {
    email: 'E-mail ',
    subscribe: 'Subscribe',
    success: 'You have successfully subscribed',
    errorEmail: 'Incorrectly filled email',
    text1: 'By clicking on the button ',
    text2: 'Subscribe',
    text3: ' I agree with',
    text4: 'the terms of personal data processing ',
    text5: ' and ',
    text6: 'website terms of use',
}
