import React from 'react'
import bannerBg from '../../static/img/banner-bg.svg'

function BoxHead({ banner }) {
    return (
        <div className="box__container">
            <div className="box-banner">
                <div className="banner__info">
                    <h2 className="banner__title">{banner.title}</h2>
                    {/* <p className="banner__text">{banner.text}</p> */}
                </div>
                <div className="banner__img">
                    <picture className="banner__image">
                        <source media="(min-width: 1340px)" srcSet={banner.mainImg} />
                        <img src={banner.mainImg} alt="" />
                    </picture>
                </div>
                <img className="banner__bg" src={bannerBg} alt="" />
            </div>
        </div>
    )
}

export default BoxHead
