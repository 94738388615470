import React from 'react'
import slider2 from '../static/img/slider-2.png'
import offerBgImage from '../static/img/offer-bg-image.png'
import Carousel from './common/Carousel'

const data = [
    {
        id: 1,
        title: 'Все включено',
        text: 'Путешествуй застрахованным всего за 100 TJS',
        requestLink: 'Оставить заявку',
        infoLink: 'Оставить заявку',
    },
    {
        id: 2,
        title: 'Все включено',
        text: 'Путешествуй застрахованным всего за 100 TJS',
        requestLink: 'Оставить заявку',
        infoLink: 'Оставить заявку',
    },
]

export default function MainOffer({ show }) {
    if (!show || !data) return null
    return (
        <section className="main-offer">
            <div className="main-offer__container">

                <Carousel
                    autoplay
                    slidesToShow
                    arrows={false}
                    centerMode
                    autoplaySpeed={4000}
                    focusOnSelect={false}>
                    {data.map((item) => (
                        <div key={item.id} className="main-offer__slider swiper-container">
                            <div className="swiper-wrapper">
                                <div className="main-offer__wrapper swiper-slide">
                                    <div className="main-offer__info">
                                        <h2 className="main-offer__title">{item.title}</h2>
                                        <p className="main-offer__text">{item.text}
                                        </p>
                                        <div className="main-offer__links">
                                            <p className="main-offer__link request-link request-link--red">
                                                {item.requestLink}
                                            </p>
                                            <p className="main-offer__link info-link">{item.infoLink}</p>
                                        </div>
                                    </div>
                                    <picture className="main-offer__image">
                                        <source media="(min-width: 1280px)" srcSet={offerBgImage} />
                                        <img src={slider2} alt="w" />
                                    </picture>
                                </div>
                            </div>
                            <div className="swiper-pagination swiper-pagination-bullets" />
                        </div>
                    ))}
                </Carousel>
            </div>

        </section>
    )
}
