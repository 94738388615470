const statuses = [
    { name: 'INACTIVE', value: 'Не вступил в силу' },
    { name: 'ACTIVE', value: 'Действующий' },
    { name: 'COMPLETED', value: 'Завершен' },
]

export default function changePolicyStatus(name, revert = false) {
    if (revert) return statuses.find((item) => item.value === name).name
    return statuses.find((item) => item.name === name).value
}
