import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from './base/Loader'

export default function PartnersList({ partnersList }) {
    const { push } = useHistory()
    const { t } = useTranslation('common')

    if (!partnersList) return <Loader centered />

    return (
        <div className="partners">
            <div className="partners__container">
                <h1 className="partners__title">{t('partners')}</h1>
                <p className="partners__text" />
                {
                    partnersList.map(({ partners = [], title }) => (
                        <>
                            <h3 className="partners__subtitle">{title}</h3>
                            <div className="partners__items">
                                {
                                    partners.map(({ img, id }) => (
                                        <picture className="partners__item" onClick={() => {
                                            push(`/partners/${id}`, { id })
                                        }}>
                                            <source media="(min-width: 1280px)"
                                                srcSet={img} />
                                            <img src={img}
                                                alt="" />
                                        </picture>
                                    ))
                                }
                            </div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}
