import React, { useContext } from 'react'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Loader from '../components/base/Loader'
import { AuthContext } from '../contexts/AuthContext'
// import Layout from '../components/Layout'
import { Context } from '../contexts/GlobalContext'

export default function Politics() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { response, loading } = useLoad({ url: CMS.POLITICS }, [cmsToken, language])

    if (loading || !response) {
        return (

            <Loader centered />

        )
    }

    return (

        <div className="box">
            <div className="box__container">
                <h1 className="box__title">{response.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: response.desc }} />
            </div>
        </div>

    )
}
