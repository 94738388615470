import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { getDate } from '../utils/date'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import SwiperSlider from './common/SwiperSlider'

export default function ShortBlog() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)

    const [slides, setSlides] = useState([])

    const { t } = useTranslation('home')
    const { response } = useLoad({ url: CMS.BLOG_POSTS, params: { pageSize: 12 } }, [cmsToken, language])

    useEffect(() => {
        if (!isEmpty(response)) {
            const posts = response.data
            setSlides(posts.map((item) => (
                <NavLink to={`/blog/${item.slug}`} key={item.id} className="main-blog__article">
                    <div className="main-blog__article-img">
                        <img src={item.mainImg} alt="Main" />
                    </div>
                    <span className="main-blog__date">{getDate(item.createdAt, false)}</span>
                    <h4 className="main-blog__subtitle">{item.title}</h4>
                </NavLink>
            )))
        }
    }, [response])

    return (
        <section className="main-blog">
            <div className="main-blog__container">
                <NavLink to="/blog">
                    <h2 className="main-blog__title">{t('blogHeader')}</h2>
                </NavLink>
            </div>

            {!isEmpty(slides) && (
                <div className="main-blog__wrapper">
                    <SwiperSlider
                        loop
                        centeredSlides
                        className="slick-list draggable"
                        items={slides}
                    />
                </div>
            )}

        </section>
    )
}
