import moment from 'moment'
import * as yup from 'yup'
import { formatPrice } from './globals'

import i18n from '../i18n'

function translate(code) {
    return i18n.t(`travel:hint_${code}`)
}

export const hints = {
    fio: translate('fio'),
    bd: translate('bd'),
    gender: translate('gender'),
    email: translate('email'),
    passport: translate('passport'),
    regAddr: translate('regAddr'),
    factAddr: translate('factAddr'),
    phoneH: translate('phoneH'),
    inn: translate('inn'),
    phoneM: translate('phoneM'),
    periodSafe: translate('periodSafe'),
    coutry: translate('coutry'),
    currency: translate('currency'),
    // eslint-disable-next-line max-len
    optSport: translate('optSport'),
    optCovid: translate('optCovid'),
    flightDelaOption: translate('flightDelaOption'),
    baggageDelayOption: translate('baggageDelayOption'),
    baggageLossOption: translate('baggageLossOption'),
    // eslint-disable-next-line max-len
    optPregnant: translate('optPregnant'),
    // eslint-disable-next-line max-len
    optSun: translate('optSun'),
    // eslint-disable-next-line max-len
    optAlcohol: translate('optAlcohol'),
    optFood: translate('optFood'),
    promocode: translate('promocode'),
    medical: translate('medical'),
    // eslint-disable-next-line max-len
    personalSwag: translate('personalSwag'),
    // eslint-disable-next-line max-len
    houseProtect: translate('houseProtect'),
    // eslint-disable-next-line max-len
    goodNeighbors: translate('goodNeighbors'),
    // eslint-disable-next-line max-len
    accident: translate('accident'),
    oneTrip: translate('oneTrip'),
    // eslint-disable-next-line max-len
    moreTrip: translate('moreTrip'),
    travelType: translate('travelType'),
}

export const often = {
    one: 'На одну поездку',
    multi: 'На несколько поездок',
}

export function createPremSum(type) {
    let template

    switch (type) {
    case 0:
        template = [1000, 3000, 5000, 7000, 10000]
        break
    case 3:
        template = [5000, 10000, 20000, 30000]
        break
    case 2:
        template = [50, 75, 100]
        break
    case 4:
        template = [10000, 20000]
        break
    case 32:
        template = [10, 25, 50, 75, 100].map((el) => el * 1000)
        break
    case 33:
        template = [10, 50, 100, 150, 200, 250, 500].map((el) => el * 1000)
        break
    case 34:
        template = [1000]
        break
    case 31:
        template = Array(20).fill(0).map((_, i) => (i + 1) * 10000)
        break
    default:
        template = [500, 750, 1000]
    }

    const arr = []
    template.forEach((i) => {
        arr.push({ value: i, name: formatPrice(i) })
    })

    return arr
}

export const spaType = [
    { value: 0, name: 'Спокойный отдых' },
    { value: 1, name: 'Спортивный отдых' },
]

export const calcMulti = (type, setFieldValue) => {
    const disabledInput = type === 'multi'
    localStorage.setItem('disablePeriodTravelInput', disabledInput ? '1' : '0')
    if (disabledInput) {
        const begin = moment()
        const end = moment().add('1', 'year').add('-1', 'day')
        setFieldValue('beginDate', begin.format('YYYY-MM-DD'))
        setFieldValue('endDate', end.format('YYYY-MM-DD'))
        setFieldValue('dayCount', 90)
    } else {
        const begin = moment()
        const end = moment().add('1', 'day')
        setFieldValue('beginDate', begin.format('YYYY-MM-DD'))
        setFieldValue('endDate', end.format('YYYY-MM-DD'))
        setFieldValue('dayCount', 2)
    }
    return disabledInput
}

let timer = null

export const fields = {
    buyer: [
        {
            label: 'Фамилия, Имя, Отчество',
            hint: hints.fio,
            name: 'holderFullName',
            placeholder: 'Алексей Петрович Иванов',
            onChange: (values, name, setFieldValue) => {
                clearTimeout(timer)
                if (values[name]) {
                    timer = setTimeout(() => {
                        const value = values[name].split(' ')
                        setFieldValue('holderLastname', value[0] || '')
                        setFieldValue('holderFirstname', value[1] || '')
                        value.splice(0, 2)
                        setFieldValue('holderPatronymic', value.join(' ') || '')
                    }, 300)
                }
            },
        },
        {
            label: 'Телефон',
            hint: hints.phoneM,
            name: 'holderPhone',
            placeholder: '+992 00-000-00-00',
            onChange: () => null,
        },
        {
            label: 'Электронная почта',
            hint: hints.phoneM,
            name: 'holderEmail',
            placeholder: 'mail@mail.ru',
            onChange: () => null,
        },
    ],
}

let scroll = false

export function setScroll(val) {
    scroll = val
}

const validateRules = {
    holderFullName: yup.string().required().matches(/^([a-zA-Z- ]*)$/).min(1).max(150),
    holderLastname: yup.string().required().matches(/^([a-zA-Z-]*)$/).min(1),
    holderFirstname: yup.string().required().matches(/^([a-zA-Z-]*)$/).min(1),
    holderPhone: yup.string().required().matches(/^[+(]*992[)]*[ \d-]{12}/),
    holderEmail: yup.string().required().email(),
    holderGender: yup.string().required(),
    holderBirthday: yup.date().required().min(moment().add(-80, 'years').toDate()).max(moment().toDate()),
    holderDocType: yup.string().required(),
    holderDocSeries: yup.string().required(),
    holderDocNumber: yup.string().required(),
    holderIssueDate: yup.string().required().min(moment().add(-70, 'years').toDate()).max(moment().toDate()),
    holderAddressReg: yup.string().optional().max(150),
    holderIssueInstitution: yup.string().required().max(150),
    // holderPostalCodeReg: yup.string().required().matches(/^[0-9]{5,}$/),

    laFullname: yup.string().required().matches(/^([a-zA-Z- ]*)$/).min(1).max(150),
    laLastname: yup.string().required().matches(/^([a-zA-Z-]*)$/).min(1),
    laFirstname: yup.string().required().matches(/^([a-zA-Z-]*)$/).min(1),
    issueInstitution: yup.string().required().max(150),
    gender: yup.string().required(),
    laBirthday: yup.date().required().min(moment().add(-80, 'years').toDate()).max(moment().toDate()),
    docType: yup.string().required(),
    series: yup.string().required(),
    docNumber: yup.string().required(),
    issueDate: yup.string().required(),
    laCitizenship: yup.string().required(),
    assuredFlatAddress: yup.string().required().min(1).max(150),
}

const validatePassport = {
    holderDocSeries: yup.string().required().matches(/^[a-zA-Z]$/),
    holderDocNumber: yup.string().required().matches(/^[0-9№]{8,15}$/),
    series: yup.string().required().matches(/^[a-zA-Z]$/),
    docNumber: yup.string().required().matches(/^[0-9№]{8,15}$/),
}

const validateForeignPassport = {
    holderDocSeries: yup.string().required().matches(/^[0-9a-zA-Z]{1,15}$/),
    holderDocNumber: yup.string().required().matches(/^[0-9a-zA-Z ]{1,15}$/),
    series: yup.string().required().matches(/^[0-9a-zA-Z]{1,15}$/),
    docNumber: yup.string().required().matches(/^[0-9a-zA-Z ]{1,15}$/),
}

const validateInternationalPassport = {
    holderDocSeries: yup.string().required(),
    holderDocNumber: yup.string().required().matches(/^[0-9 ]{1,15}$/),
    series: yup.string().required(),
    docNumber: yup.string().required().matches(/^[0-9 ]{1,15}$/),
}

export function validate(top, step) {
    const bdFirst = false

    return async (values) => {
        const errors = {}

        if (values.shortValidate === true) {
            switch (step) {
            case 3:
                if (validateRules.holderBirthday) {
                    if (!validateRules.holderBirthday.isValidSync(values.holderBirthday)) {
                        errors.holderBirthday = true
                    }
                }
                values.las.forEach((item, i) => {
                    if (validateRules.laBirthday) {
                        if (!validateRules.laBirthday.isValidSync(item.laBirthday)) {
                            errors[`las[${i}]${'laBirthday'}`] = true
                        }
                    }
                })
                break
            default:
                return {}
            }
        } else {
            const validatesLa = [
                'laFullname',
                'laBirthday',
                'laLastname',
                'laFirstname',
                'gender',
                'series',
                'docType',
                'issueDate',
                'docNumber',
                'laBirthday',
                'issueInstitution',
                'laCitizenship',
            ]

            const check3 = [
                'holderFullName',
                'holderLastname',
                'holderFirstname',
                'holderPhone',
                'holderEmail',
                'holderGender',
                'holderBirthday',
                'holderDocType',
                'holderDocSeries',
                'holderDocNumber',
                'holderIssueInstitution',
                'holderIssueDate',
                'holderAddressReg',
                'holderPostalCodeReg',
            ]

            // eslint-disable-next-line default-case
            switch (step) {
            case 1:
                if (!values.beginDate) {
                    errors.beginDate = true
                }

                if (!values.endDate) {
                    errors.endDate = true
                }

                if (values.country.length === 0) {
                    errors.country = true
                }

                if (values.currency.length === 0) {
                    errors.currency = true
                }

                if (values.mainSumAssured < 30000) {
                    errors.mainSumAssured = true
                }

                if (values.endDate && values.beginDate) {
                    const days = values.dayCount
                    const policeEnd = moment(values.endDate)
                    const policeStart = moment(values.beginDate)
                    const delta = policeEnd.diff(policeStart, 'days')
                    if ((delta > 364 || delta < 2) && (days > 364 || days < 2)) {
                        errors.beginDate = true
                        errors.endDate = true
                    }
                }

                // values.las.forEach(({ laBirthday }, i) => {
                //     if (!laBirthday) errors[`las[${i}]laBirthday`] = true
                // })

                break
            case 3:
                check3.forEach((item) => {
                    if (validateRules[item]) {
                        if (!validateRules[item].isValidSync(values[item])) {
                            errors[item] = true
                        }
                    }
                })

                switch (parseInt(values.holderDocType, 10)) {
                case 0:
                    (['holderDocSeries', 'holderDocNumber']).forEach((code) => {
                        if (validatePassport[code]) {
                            if (!validatePassport[code].isValidSync(values[code])) {
                                errors[code] = true
                            } else {
                                delete errors[code]
                            }
                        }
                    })
                    break
                case 2:
                    (['holderDocSeries', 'holderDocNumber']).forEach((code) => {
                        if (validateForeignPassport[code]) {
                            if (!validateForeignPassport[code].isValidSync(values[code])) {
                                errors[code] = true
                            } else {
                                delete errors[code]
                            }
                        }
                    })
                    break
                default:
                    (['holderDocSeries', 'holderDocNumber']).forEach((code) => {
                        if (validateInternationalPassport[code]) {
                            if (!validateInternationalPassport[code].isValidSync(values[code])) {
                                errors[code] = true
                            } else {
                                delete errors[code]
                            }
                        }
                    })
                }

                // eslint-disable-next-line no-case-declarations
                const passports = []
                values.las.forEach((item, i) => {
                    if (!item.laBirthday) {
                        errors[`las[${i}]laBirthday`] = true
                    }

                    if (item.laBirthday && values.beginDate) {
                        const birthDate = moment(values.las[0].laBirthday)
                        const policeStart = moment(values.beginDate)
                        const delta = policeStart.diff(birthDate, 'year')
                        if (delta >= 80 && delta <= -80) {
                            errors.old = true
                        }
                    }

                    const passport = `${item.series} ${item.docNumber}`
                    if (passports.indexOf(passport) !== -1) {
                        errors[`las[${i}]series`] = true
                        errors[`las[${i}]docNumber`] = true
                    } else {
                        passports.push(passport)
                    }

                    validatesLa.forEach((code) => {
                        if (validateRules[code]) {
                            if (!validateRules[code].isValidSync(item[code])) {
                                errors[`las[${i}]${code}`] = true
                            }
                        }

                        (['series', 'docNumber']).forEach((codeVal) => {
                            switch (parseInt(item.docType, 10)) {
                            case 0:
                                if (validatePassport[codeVal]) {
                                    if (!validatePassport[codeVal].isValidSync(item[codeVal])) {
                                        errors[`las[${i}]${codeVal}`] = true
                                    }
                                }
                                break
                            case 2:
                                if (validateForeignPassport[codeVal]) {
                                    if (!validateForeignPassport[codeVal].isValidSync(item[codeVal])) {
                                        errors[`las[${i}]${codeVal}`] = true
                                    }
                                }
                                break
                            default:
                                if (validateInternationalPassport[codeVal]) {
                                    if (!validateInternationalPassport[codeVal].isValidSync(item[codeVal])) {
                                        errors[`las[${i}]${codeVal}`] = true
                                    }
                                }
                            }
                        })
                    })
                })

                if (values.sequence.indexOf(10) !== -1) {
                    if (!validateRules.assuredFlatAddress.isValidSync(values.assuredFlatAddress)) {
                        errors.assuredFlatAddress = true
                    }
                }

                if (!values.agree) errors.agree = true
            }
        }

        if (Object.keys(errors).length !== 0) {
            errors.haveErrors = true
            // eslint-disable-next-line prefer-destructuring
            errors.firstError = Object.keys(errors)[0]
            if (scroll) {
                window.scrollTo({
                    top: top.current.offsetTop - bdFirst ? 100 : 105,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        }

        return errors
    }
}

export const currencyList = [
    {
        value: 'USD',
        label: 'USD',
    },
    {
        value: 'EUR',
        label: 'EUR',
    },
]

export const gender = [
    {
        value: '1',
        label: 'Женский',
    },
    {
        value: '0',
        label: 'Мужской',
    },
]
/*
    {
        name_ru: 'Таджикский паспорт',
        name_en: 'Tajik passport',
        name_tj: 'Шиносномаи тоҷикӣ',
        code:  '0'
    },{
        name_ru: 'Таджикский загран. паспорт',
        name_en: 'Tajik foreign passport',
        name_tj: 'Шиносномаи хориҷии Тоҷикистон',
        code: '1'
    },{
        name_ru: 'Иностранный паспорт',
        name_en: 'Foreign passport',
        name_tj: 'Шиносномаи хориҷӣ',
        code: '2'
    }
     */
export const docTypes = [
    {
        value: '0',
        label: 'Таджикский паспорт',
    },
    {
        value: '1',
        label: 'Таджикский загран. паспорт',
    },
    {
        value: '2',
        label: 'Иностранный паспорт',
    },
]

const forbiddenTypes = ['0']
export const filterPassport = () => docTypes.filter(({ value }) => forbiddenTypes.indexOf(value) === -1)

export const CountryUnit = [
    {
        label_ru: 'Душанбе',
        label_en: 'DUSHANBE',
        label_tj: 'Душанбе',
        value: 'Душанбе',
    },
    {
        label_ru: 'РРП',
        label_en: 'DRS',
        label_tj: 'НТЧ',
        value: 'РРП',
    },
    {
        label_ru: 'СОГД',
        label_en: 'SUGHD',
        label_tj: 'Суғд',
        value: 'СОГД',
    },
    {
        label_ru: 'Хатлон',
        label_en: 'KHATLON',
        label_tj: 'ХАТЛОН',
        value: 'Хатлон',
    },
    {
        label_ru: 'ГБАО',
        label_en: 'GBAO',
        label_tj: 'ВМКБ',
        value: 'ГБАО',
    },
]

export const selectedOptions = [
    'covid19SumAssured',
    'flightDelaySumAssured',
    'baggageDelaySumAssured',
    'baggageLossSumAssured',
    'flatSumAssured',
    'accSumAssured',
]

export const optionsTravel = [
    { name: 'alcoholicOption', seq: 4, range: [], prem: 'alcoholicSumPrem', trans: 'alcoholicOption', hint: 'hint_optAlcohol', icon: 'alco' },
    // eslint-disable-next-line max-len
    { name: 'flightDelaySumAssured', seq: 6, range: createPremSum(2), prem: 'flightDelaySumPrem', trans: 'flightDelaOption', hint: 'hint_flightDelaOption', icon: 'airbus' },
    // eslint-disable-next-line max-len
    { name: 'baggageDelaySumAssured', seq: 7, range: createPremSum(2), prem: 'baggageDelaySumPrem', trans: 'baggageDelayOption', hint: 'hint_baggageDelayOption', icon: 'baggage' },
    { name: 'flatSumAssured', seq: 10, range: createPremSum(0), prem: 'flatSumPrem', trans: 'houseProtect', icon: 'house', hint: 'hint_houseProtect' },
    { name: 'seacreatureOption', seq: 9, range: [], prem: 'seacreatureSumPrem', trans: 'seacreatureOption', hint: 'hint_seaCreature', icon: 'sea' },
    // eslint-disable-next-line max-len
    { name: 'covid19SumAssured', seq: 1, range: createPremSum(3), prem: 'covid19SumPrem', trans: 'covid19Option', hint: 'hint_optCovid', icon: 'coronavirus' },
    { name: 'thingsSumAssured', seq: 5, range: createPremSum(1), prem: 'thingsSumPrem', trans: 'personalSwag', icon: 'bag', hint: 'hint_personalSwag' },
    { name: 'accSumAssured', seq: 11, range: createPremSum(0), prem: 'accSumPrem', trans: 'accident', icon: 'accident', hint: 'hint_accident' },
    { name: 'pregnantOption', seq: 2, range: [], prem: 'pregnantSumPrem', trans: 'pregnantOption', hint: 'hint_optPregnant', icon: 'pregnance' },
    { name: 'sunOption', seq: 3, range: [], prem: 'sunSumPrem', trans: 'sunOption', hint: 'hint_optSun', icon: 'sun' },
    // eslint-disable-next-line max-len
    { name: 'baggageLossSumAssured', seq: 8, range: createPremSum(1), prem: 'baggageLossSumPrem', trans: 'baggageLossOption', hint: 'hint_baggageLossOption', icon: 'baggage' },

    { name: 'sportOption', seq: 0, range: [], prem: 'sportSumPrem', trans: 'sportOption' },

    // { name: 'neighborSumAssured', range: createPremSum(5000, 500), prem: 'neighborSumPrem', trans: 'goodNeighbors', hint: 'hint_goodNeighbors' },
    // { name: 'poisoningOption', seq: 5, range: createPremSum(1000, 100), prem: 'poisoningSumPrem', trans: 'poisoningOption', hint: 'hint_optFood' },
]

export const initialValues = (state) => ({
    shortValidate: false,
    holderFullName: '',
    holderLastname: '',
    holderFirstname: '',
    holderPatronymic: '',
    holderPhone: '',
    holderDocType: '',
    holderSeries: '',
    holderIssueDate: '',
    holderDocNumber: '',
    holderEmail: '',
    holderBirthday: '',
    holderGender: state ? state.data.holderGender : '',
    holderAddressReg: '',
    holderOblProj: '',
    holderGorodProj: '',
    holderUlProj: '',
    holderDomProj: '',
    holderKvProj: '',
    holderPostalCodeReg: '',
    holderAddressFact: '',
    holderPostalCodeFact: '',
    holderAddressEqual: false,
    holderAsInsured: false,
    multi: state ? state.data.multi : 0,

    howOften: state ? state.data.howOften : 'one',

    country: state ? state.data.country : '',
    beginDate: state ? state.data.beginDate : '',
    endDate: state ? state.data.endDate : '',
    status: 0,
    las: [
        {
            uid: 1000,
            laLastname: '',
            laFirstname: '',
            laPatronic: '',
            laBirthday: state ? state.data.holderBirthday : moment().add(-12, 'year').format('YYYY-MM-DD'),
            laCitizenship: 'TJ',
            issueDate: '',
            // issueInstitution: '------',
            series: '',
            docType: '',
            docNumber: '',
            dateTo: '',
            gender: state ? state.data.holderGender : '',
        },
    ],
    mainSumAssured: 30000,
    mainSumPrem: 0,
    thingsSumAssured: 0,
    thingsSumPrem: 0,
    flatSumAssured: 0,
    flatSumPrem: 0,
    neighborSumAssured: 0,
    neighborSumPrem: 0,
    accSumAssured: 0,
    accSumPrem: 0,
    accSumPremManual: false,
    sumPrem: 0,

    sportOption: state ? state.data.sportOption : 0,
    sportSumPrem: 0,
    covid19SumAssured: 5000,
    covid19SumPrem: 0,
    pregnantOption: 0,
    pregnantSumPrem: 0,
    sunOption: 0,
    sunSumPrem: 0,
    alcoholicOption: 0,
    alcoholicSumPrem: 0,
    poisoningOption: 0,
    poisoningSumPrem: 0,
    sequence: (state && state.data.sportOption) ? [0] : [],
    flightDelaySumAssured: 0,
    flightDelayPrem: 0,
    baggageDelaySumAssured: 0,
    baggageDelayPrem: 0,
    baggageLossSumAssured: 0,
    baggageLossPrem: 0,
    seacreatureOption: 0,
    seacreaturePrem: 0,

    assuredFlatAddress: '',

    agree: false,
    dayCount: state ? state.data.dayCount : '',

    currency: 'USD',
    coupon: '',
    paymentOper: false,
})

export default {
    hints, fields, validate, initialValues,
}
