import React, { useContext, useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Layout from '../components/Layout'
import BlogItem from '../components/BlogItem'
import Pagination from '../components/base/Pagination'
import Loader from '../components/base/Loader'
// import BlogCategories from '../components/BlogCategories'
import MailSubs from '../components/MailSubs'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'

export default function Blog() {
    const [page, setPage] = useState(1)
    // const [category, setCategory] = useState(null)
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { t } = useTranslation('blog')

    const posts = useLoad({
        url: CMS.BLOG_POSTS,
        params: { page, pageSize: 12 },
    }, [page, cmsToken, language])

    return (
        <Layout>
            <div className="blog">
                <div className="blog__container">
                    <h2 className="blog__title">{t('bimaBlog')}</h2>
                </div>

                {/* <BlogCategories category={category} setCategory={setCategory} /> */}

                {!posts.loading ? (
                    <div>
                        {!isEmpty(posts.response ? posts.response.data : []) ? (
                            <div className="blog__container">
                                <div className="news-list__grid">
                                    {posts.response.data.map((item, index) => (
                                        <BlogItem key={item.id} item={item} index={index} />
                                    ))}
                                </div>

                                <Pagination
                                    countPages={posts.response.lastPage}
                                    page={page}
                                    setPage={setPage} />
                            </div>
                        ) : null}
                    </div>
                ) : <Loader centered />}

                <MailSubs />
            </div>
        </Layout>
    )
}
