import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SwiperSlider from '../common/SwiperSlider'
import { saleUrl } from '../../utils/urls'
import { secondSite } from '../../helpers/globals'

function BoxesProducts({ products, startScroll }) {
    const { t, i18n: { language } } = useTranslation('box')
    if (!products) return null
    startScroll()

    const slides = products.reduce((result, item) => {
        const slide = (
            <div className="main-popular__item">
                <div className="main-popular__image main-popular__image_vertical">
                    <img
                        className="main-popular__img main-popular__img_vertical"
                        src={item.mainImg}
                        alt="" />
                </div>
                <div className="slider-products__info">
                    <h3 className="slider-products__subtitle">{item.title}</h3>
                    <span
                        className="slider-products__text">
                        {item.shortDesc}
                    </span>
                    <div className="slider-products__links">
                        {(parseInt(item.isSale, 10) === 1)
                            && (
                                secondSite.indexOf(item.productCode) !== -1
                                    ? (
                                        <a href={saleUrl(item.slug, language)} className="slider-products__link request-link">
                                            {t('buy')}
                                        </a>
                                    )
                                    : (
                                        <NavLink to={`/boxes/order/${item.slug}`} className="slider-products__link request-link">
                                            {t('buy')}
                                        </NavLink>
                                    )
                            )}
                        <NavLink to={`/boxes/${item.slug}`}
                            className="slider-products__link info-link">
                            {t('more')}
                        </NavLink>
                    </div>
                </div>
            </div>
        )
        if (!item.seo) result.push(slide)
        return result
    }, [])

    return (
        <div className="boxes__container">
            <div className="boxes__products boxes_desktop">
                {
                    products.map((product) => {
                        if (!product.id) return null
                        return (
                            <div className="slider-products__item">
                                <div className="slider-products__image">
                                    <NavLink to={`/boxes/${product.slug}`}>
                                        <img src={product.mainImg} alt=""
                                            className="slider-products__img" />
                                    </NavLink>
                                </div>
                                <div className="slider-products__info">
                                    <NavLink to={`/boxes/${product.slug}`}>
                                        <h3 className="slider-products__subtitle">{product.title}</h3>
                                        <p className="slider-products__text"
                                            dangerouslySetInnerHTML={{ __html: product.shortDesc }} />
                                    </NavLink>
                                    <div className="slider-products__links">
                                        {(parseInt(product.isSale, 10) === 1
                                            && (
                                                secondSite.indexOf(product.productCode) === -1
                                                    ? (
                                                        <NavLink to={`/boxes/order/${product.slug}`}
                                                            className="slider-products__link request-link">
                                                            {t('buy')}
                                                        </NavLink>
                                                    ) : (
                                                        <a href={saleUrl(product.slug, language)} className="slider-products__link request-link">
                                                            {t('buy')}
                                                        </a>
                                                    )
                                            )
                                        )}
                                        <NavLink to={`/boxes/${product.slug}`}
                                            className="slider-products__link info-link">
                                            {t('more')}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="boxes_mobile">
                <SwiperSlider
                    className="slider-products"
                    loop items={slides}
                />
            </div>
        </div>
    )
}

export default BoxesProducts
