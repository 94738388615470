import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import logo from '../static/img/logo.svg'
// import marker from '../static/img/marker.svg'
import geo from '../static/img/svg/geolocation.svg'
import phone from '../static/img/phone-icon-header.svg'
import Loader from './base/Loader'
import Button from './common/Button'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { useModal } from '../hooks/modal'

import Login from './modals/Login'
import Registration from './modals/Registration'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import SelectLanguage from './common/SelectLanguage'
import SelectLanguageMob from './common/SelectLanguageMob'

export default function Header() {
    const { cmsToken } = useContext(AuthContext)
    const { category, setCategory, language } = useContext(Context)
    const { t } = useTranslation(['common', 'vacancy', 'blog'])

    const [showRegistration, hideRegistration] = useModal(
        <Registration
            className="popup__popup-registration"
            onClose={() => hideRegistration()}
        />,
    )

    const [showMobMenu, setShowMobMenu] = useState(false)

    const toggleMobMenu = () => {
        setShowMobMenu((menu) => !menu)
        document.querySelector('body').classList.toggle('open-menu')
    }

    const { listen } = useHistory()
    listen(() => {
        document.querySelector('body').classList.remove('open-menu')
        setShowMobMenu(false)
    })

    const url = window.location.pathname

    const [showLogin, hideLogin] = useModal(
        <Login
            className="popup__popup-login"
            showRegistration={showRegistration}
            onClose={() => hideLogin()}
        />,
    )

    const categories = [
        { title: t('clients'), id: 1, link: '/' },
        { title: t('business'), id: 2, link: '/jur' },
        { title: t('partners'), id: 3, link: '/partners' },
    ]

    useEffect(() => {
        if (url === '/') setCategory(1)
        else {
            const res = categories.some(({ link, id }) => {
                if (category !== false) return true
                if (link !== '/' && url.indexOf(link) !== -1) {
                    setCategory(id)
                    return true
                }
                return false
            })
            if (!res) setCategory(1)
        }
    }, [])

    let menuHeader
    const header = useLoad({ url: CMS.HEADER_DETAIL.replace('id', category) }, [
        category,
        cmsToken,
        language,
    ])

    const test = new RegExp(`^${url}$`)

    if (header && header.response) {
        if (header.response.map) {
            menuHeader = header.response.map((item) => {
                const link = item.link[0] === '/' ? item.link : `/${item.link}`
                return (
                    <li key={link}>
                        <NavLink
                            to={link}
                            key={item.id}
                            className="header__nav-item"
                        >
                            <p className={cn('header__nav-link',
                                `${test.test(link) ? 'header-menu-link_active' : ''}`)}>{item.title}
                            </p>
                        </NavLink>
                    </li>
                )
            })
        } else {
            const item = header.response
            const link = item.link[0] === '/' ? item.link : `/${item.link}`
            menuHeader = (
                <li>
                    <NavLink
                        to={link}
                        key={item.id}
                        className={cn(
                            'header__nav-item',
                            // eslint-disable-next-line max-len
                            `${
                                test.test(link) ? 'header-menu-link_active' : ''
                            }`,
                        )}
                    >
                        <p className="header__nav-link">
                            {item.title || 'Коробочные продукты'}
                        </p>
                    </NavLink>
                </li>
            )
        }
    }

    // const openMobileMenu = () => {
    //     setShowMobMenu(true)
    // }

    // const closeMobileMenu = () => {
    //     setShowMobMenu(false)
    // }

    return (
        <>
            <div className="header-area">
                <div className="header">
                    <div className="header__container">
                        <div className="header__top">
                            <div className="header__left">
                                <NavLink to="/" className="header-top__link">
                                    <img
                                        className="header__logo"
                                        src={logo}
                                        alt="Logo: BIMA Insurance"
                                    />
                                </NavLink>

                                <nav className="header__top-menu">
                                    <ul className="header__top-menu-list">
                                        {categories.map((item) => (
                                            <li
                                                key={item.id}
                                                onClick={() => setCategory(item.id)}
                                                className="header__top-menu_item"
                                            >
                                                <NavLink
                                                    to={item.link}
                                                    className={cn('header__top-menu-link', {
                                                    // eslint-disable-next-line max-len
                                                        'header__top-menu-link_active': item.id === category,
                                                    })}
                                                >
                                                    {item.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>

                            <div className="header__right">
                                <NavLink to="/losses/" className="header__case">
                                    {t('registerTask')}
                                </NavLink>

                                <NavLink className="header__geo" to="/offices">
                                    <img
                                        className="header__geo-icon"
                                        src={geo}
                                        alt="Icon: geomarker"
                                    />
                                    {t('offices')}
                                </NavLink>

                                <div className="header__tel">
                                    <img
                                        className="header__tel-icon"
                                        src={phone}
                                        alt="Icon: Call us"
                                    />

                                    <a href="tel:5511">
                                        <p className="header__number">5511</p>
                                    </a>
                                </div>

                                <SelectLanguage />
                            </div>
                        </div>

                        <div className="header__bottom">
                            {header.response && !header.loading ? (
                                <nav className="header__nav">
                                    {!isEmpty(header.response) ? (
                                        <ul className="header__nav-list">{menuHeader}</ul>
                                    ) : null}
                                </nav>
                            ) : (
                                <Loader />
                            )}
                            <Button
                                onClick={showLogin}
                                text={t('enterText')}
                                className="header__bottom-button button"
                            />
                        </div>
                    </div>
                </div>

                <div className="header-mobile">
                    <div className="header-mobile__container">
                        <nav className="nav">
                            { showMobMenu && (
                                <div className="nav__menu-container">
                                    <NavLink to="/" className="header-top__link align__center">
                                        <img
                                            className="header__logo big_logo"
                                            src={logo}
                                            alt="Logo: BIMA Insurance"
                                        />
                                    </NavLink>
                                    <SelectLanguageMob />
                                    <div className="nav__header">
                                        <div className="header__tel">
                                            <img
                                                className="header__tel-icon"
                                                src={phone}
                                                alt="Icon: Call us"
                                            />

                                            <a href="tel:5511">
                                                <p className="header__number">5511</p>
                                            </a>
                                        </div>
                                    </div>
                                    <ul className="nav__menu">
                                        <li className="nav__menu-item">
                                            <NavLink to="/losses/" className="nav__menu-title">
                                                {t('registerTask')}
                                            </NavLink>
                                        </li>
                                        <li className="nav__menu-item">
                                            <NavLink className="nav__menu-title" to="/offices">
                                                {t('offices')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div className="nav__footer">
                                        {categories.map((item) => (
                                            <div
                                                key={item.id}
                                                onClick={() => setCategory(item.id)}
                                                className={cn('nav__link', {
                                                    nav__link_active: item.id === category
                                                    && (url.indexOf('/blog') === -1 && url.indexOf('/vacancies') === -1),
                                                })}
                                            >
                                                <NavLink to={item.link}>
                                                    {item.title}
                                                </NavLink>

                                            </div>
                                        ))}

                                        {header.response && !header.loading && !isEmpty(header.response) ? (
                                            <ul className="nav__link-mobile">{menuHeader}</ul>
                                        ) : (
                                            <Loader />
                                        )}

                                        <div className={cn('nav__link', {
                                            nav__link_active: url === '/blog',
                                        })}>
                                            <NavLink to="/blog">
                                                {t('blog:blog')}
                                            </NavLink>
                                        </div>

                                        <div className={cn('nav__link', {
                                            nav__link_active: url === '/vacancies',
                                        })}>
                                            <NavLink to="/vacancies">
                                                {t('vacancy:vacancy')}
                                            </NavLink>
                                        </div>

                                    </div>
                                </div>
                            )}
                            <div className="nav__hamburger-menu">
                                <button className="nav__bar-wrapper" onClick={toggleMobMenu}>
                                    <div className="nav__bar" />
                                </button>
                            </div>
                            { showMobMenu && (<div className="overlay__hamburger" onClick={toggleMobMenu} />)}
                        </nav>
                        <NavLink to="/" className="header-top__link align__center">
                            <img
                                className="header__logo"
                                src={logo} style={{ width: '100%', textAlign: 'left' }}
                                alt="Logo: BIMA Insurance"
                            />
                        </NavLink>

                        <div className="header-right-block">
                            <Button
                                onClick={showLogin}
                                text={t('enterText')}
                                className="header__bottom-button button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
