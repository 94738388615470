export default {
    registred: 'Зарегистрировано',
    registerEvent: 'Регистрация страхового случая',
    fullName: 'ФИО застрахованного',
    fullNamePH: 'ФИО',
    policyNumber: 'Номер полиса',
    policyNumberPH: 'Номер полиса',
    email: 'Электронная почта',
    phone: 'Телефон',
    note: 'Опишите обстоятельства события',
    maxFiles: 'Максимум 10 файлов',
    notePH: 'Описание',
    submit: 'Подтвердить',
    ok: 'OK',
}
