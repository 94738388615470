import React from 'react'
import Select from 'react-select'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

export default function MultiSelect({ name, options, className, onChange, required, ...attributes }) {
    const { t } = useTranslation('travel')

    return (
        <Field name={name}>
            {({ form, field }) => (
                <Select
                    isMulti
                    className={className}
                    onBlur={form.setFieldTouched}
                    value={field.value}
                    options={options}
                    {...attributes}
                    required={required}
                    placeholder={t('chooseCountry')}
                    onChange={async (values) => {
                        await form.setFieldValue(name, values)
                        window.setTimeout(onChange, 500)
                    }}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#fef2cd',
                            primary: '#fbbe07',
                        },
                    })} />
            )}
        </Field>

    )
}
