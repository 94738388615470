import React from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import close from '../../static/icons/close.svg'

export default function Modal({ isActive, children, onClose, className }) {
    if (isActive) {
        document.querySelector('.page__content').classList.add('page__content__modal_active')
    }

    return createPortal((
        <div className={cn('popup', { popup_active: isActive }, className)}>
            <div
                style={{ padding: '20px' }}
                onClick={() => {
                    onClose()
                    document.querySelector('.page__content').classList.remove('page__content__modal_active')
                }}
                className="popup__close">
                <img src={close} alt="Close" />
            </div>

            {children}
        </div>
    ), document.querySelector('#modals-root'))
}
