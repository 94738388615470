import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.scss'

// import arrowPrev from '../../static/img/arrow-prev.svg'
// import arrowNext from '../../static/img/arrow-next.svg'

// function SamplePrevArrow({ style, onClick }) {
//     return (
//         <button
//             className="main-banner__button main-banner__button_prev"
//             style={{ ...style }}
//             onClick={onClick}
//         >
//             <img src={arrowPrev} alt="<" className="main-banner__arrow main-banner__arrow_prev" />
//         </button>
//     )
// }
//
// function SampleNextArrow({ style, onClick }) {
//     return (
//         <button
//             className="main-banner__button main-banner__button_next"
//             style={{ ...style }}
//             onClick={onClick}
//         >
//             <img src={arrowNext} alt=">" className="main-banner__arrow main-banner__arrow_prev" />
//         </button>
//     )
// }

export default function SwiperSlider({
    className,
    cssMode = true,
    mousewheel = true,
    loop = true,
    rewind = true,
    slidesPerView = 'auto',
    spaceBetween = 16,
    initialSlide = 2,
    speed = 8000,
    autoplay = {
        delay: 1,
        disableOnInteraction: false,
    },
    // freeMode = true,
    centeredSlides = true,
    breakpoints = {
        480: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
            slidesPerView: 3,
            spaceBetween: 16,
        },
    },
    items = [],
    ...props
}) {
    const settings = {
        className,
        cssMode,
        mousewheel,
        loop,
        speed,
        autoplay,
        rewind,
        slidesPerView,
        spaceBetween,
        initialSlide,
        centeredSlides,
        breakpoints,
        ...props,
    }

    return (
        <Swiper {...settings}>
            {
                items.map((item) => (
                    <SwiperSlide key={item.id} style={{ width: '80%' }}>
                        {item}
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}
