export default {
    buy: 'Buy',
    toBuy: 'Go to payment',
    more: 'Learn more',
    insuredSum: 'Sum insured',
    validaty: 'Insurance period',
    insuranceCost: 'Insurance cost',
    insuranceCostCoupon: 'Insurance cost including coupon',
    insuranceCostActiveCoupon: 'Cost with coupon applied:',
    loading: 'Loading...',
    activation: 'Box activation',
    buyBox: 'Buy box',
    boxProducts: 'Boxed Products',
    policyData: 'Policy data',
    policyNumber: 'Policy number',
    policyCode: 'Policy code',
    boxProductBuy: 'Boxed product arrangment',
    holderLastName: 'Surname',
    holderFirstName: 'Name',
    holderSecondName: 'Father name',
    holderLastNamePH: 'Ivanov',
    holderFirstNamePH: 'Ivan',
    holderSecondNamePH: 'Ivanovich',
    holderBirthDate: 'Date of Birth',
    holderPhone: 'Telephone',
    holderEmail: 'E-mail address',
    holderGender: 'Gender',
    holderCountry: 'Residence',
    couponError: 'Invalid promo code entered',
    coupon: 'Promo code',
    couponApply: 'Apply promo code',

    box: 'boxed',
    holderIssueInstitution: 'Passport issued',
    holderIssueDate: 'Date of issue of passport',
    holderDocSeries: 'Passport Series',
    holderDocNumber: 'Passport ID',
    periodName: 'months',
    offerProducts: 'Financial products',
    showPassword: 'show',
    closePassword: 'close',
}
