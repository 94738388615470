import { salesSite } from '../helpers/globals'

export const cmsDomain = process.env.REACT_APP_CMS_URL
export const restDomain = process.env.REACT_APP_BACKEND_URL
    || `${window.location.protocol}//${window.location.hostname}`
export const domain = restDomain.endsWith('/') ? restDomain.substr(0, restDomain.length - 1) : restDomain

export const CMS = {
    AUTH: `${cmsDomain}/api/login`,

    BLOG_CATS: `${cmsDomain}/api/cats`,
    BLOG_POSTS: `${cmsDomain}/api/blog_cat`,
    BLOG_POST: `${cmsDomain}/api/blogs/{slug}`,

    PARTNERS_CAT: `${cmsDomain}/api/partners_cat`,
    PARTNERS: `${cmsDomain}/api/partners`,
    PARTNER: `${cmsDomain}/api/partners/{id}`,

    BOX_CATS: `${cmsDomain}/api/box_cats`,
    ALL_BOXES: `${cmsDomain}/api/all_boxes?page={page}&page_size={size}&category={category}&boxed=1`,
    ALL_BOXES_ALL: `${cmsDomain}/api/all_boxes?page={page}&page_size={size}&category={category}`,
    TRAVEL_BANNER: `${cmsDomain}/api/slidesbyslug/{slug}`,
    ALL_BOXES_С: `${cmsDomain}/api/all_boxes?page={page}&page_size={size}&is_partner=1`,
    DIRECT_BOX: `${cmsDomain}/api/box/{slug}`,
    BOX_SEO: `${cmsDomain}/api/box_seo/`,

    BOX_CATS_UR: `${cmsDomain}/api/box_cats_jur`,
    ALL_BOXES_UR: `${cmsDomain}/api/all_boxes_jur?page={page}&page_size={size}&category={category}`,
    DIRECT_BOX_UR: `${cmsDomain}/api/box_jur/{slug}`,

    VACANCIES: `${cmsDomain}/api/vacs`,
    VACANCIES_ALL: `${cmsDomain}/api/all_vacs`,
    VACANCY: `${cmsDomain}/api/vacs/{slug}`,
    SEND_RESUME: `${cmsDomain}/api/send_resume`,

    MAIL_SUBS: `${cmsDomain}/api/mail_subs`,

    SLIDES_BY_SLUG: `${cmsDomain}/api/slidesbyslug/{slug}`,
    SLIDES_BY_SLUG_UR: `${cmsDomain}/api/slidesbyslug_jur/{slug}`,
    HEADERS: `${cmsDomain}/api/headers`,
    HEADER_DETAIL: `${cmsDomain}/api/headers/id`,
    FOOTERS: `${cmsDomain}/api/footers`,
    FOOTER_DETAIL: `${cmsDomain}/api/footers/id`,

    OFFICES: `${cmsDomain}/api/all_ofices`,

    POLITICS: `${cmsDomain}/api/politics`,
    RULES: `${cmsDomain}/api/rules`,
    DOCS: `${cmsDomain}/api/documents`,
}

export const REST = {
    LOGIN: {
        AUTH: `${domain}/v1/user/auth/`,
        AUTH_REFRESH: `${domain}/v1/user/auth/refresh/`,
    },
    REGISTER: {
        SMS: `${domain}/v1/user/sms/`,
        PROFILE: `${domain}/v1/user/profile/`,
        USER: `${domain}/v1/user/`,
        DOCS: `${domain}/v1/user/docs/`,
        ADDRESS: `${domain}/v1/address/`,
        DOCS_DETAIL: `${domain}/v1/user/docs/id/`,
        ADDRESS_DETAIL: `${domain}/v1/address/id/`,
    },
    TRAVEL: {
        ALL: `${domain}/v1/travel/`,
        DETAIL: `${domain}/v1/travel/id/`,
        CALC: `${domain}/v1/travel/calc/`,
        DETAIL_UPD: `${domain}/v1/travel/id/upd/`,
    },
    CAR: {
        MARKS: `${domain}/v1/pack/nsi/atm/`,
        MODELS: `${domain}/v1/pack/nsi/atm/{product_id}/`,
        SUM: `${domain}/v1/pack/nsi/atm/{model_id}/sum/`,
        YEAR: `${domain}/v1/pack/nsi/atm/{model_id}/year/`,
        CALC: `${domain}/api/v1/car/v2/calc/`,
        SAVE: `${domain}/api/v1/car/v2/`,
    },
    CAR_ECI: {
        CALC: `${domain}/api/v1/car/v3/calc/`,
        SAVE: `${domain}/api/v1/car/v3/`,
    },
    BOX: {
        ADD: `${domain}/v1/box/`,
        COUPON: `${domain}/v1/box/calc/`,
    },
    CLAIM: {
        SEND: `${domain}/v1/claim/`,
        FILES: `${domain}/v1/claim/{claim_id}/files/`,
    },
    CHANGE_PASS: `${domain}/v1/user/change_pass/`,
    POLICY_CHECK: `${domain}/v1/policy/check/`,
    BACK_CALL: `${domain}/v1/calls/`,
    PACK: {
        AUTH: `${domain}/v1/pack/auth/`,
    },
    PAYMENT: `${domain}/v1/payment/`,
    COUPON: `${domain}/v1/coupon/`,
}

export const saleUrl = (code, lang = 'ru') => `${salesSite}/sale/${code}/?lang=${lang}`
export const activationUrl = (code) => `${salesSite}/activation/${code}`
