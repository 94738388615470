import React from 'react'
import { useTranslation } from 'react-i18next'
import Loader from './base/Loader'

export default function PartnerTarget({ partner }) {
    const { t } = useTranslation('partners')
    if (!partner) return <Loader centered />

    const data = partner[0]

    return (
        <div className="partner">
            <div className="partner">
                <div className="partner__container">
                    <section className="partner__top">
                        <div className="img__wrapper">
                            <img className="partner__img" src={data.img}
                                alt="" />

                        </div>
                        <div className="partner__top-info">
                            <h1 className="partner__title">{data.title}</h1>
                            <p className="partner__text">
                                {data.description}
                            </p>
                            {/* <div className="partner__top-links">
                                {
                                    data.categories.map((item) => (
                                        <a className="partner__top-link"
                                            href="/">
                                            <svg width="21" height="22">
                                                <use
                                                    xlinkHref="#icon-partner-1" />
                                            </svg>
                                            {item.catName}
                                        </a>
                                    ))
                                }
                            </div> */}
                            <a className="partner__top-btn" type="button" href={data.link}>
                                {t('toSite')}
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
