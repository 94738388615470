import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import Layout from '../components/Layout'
import { usePostRequest } from '../hooks/request'
import { REST } from '../utils/urls'
import { VzrForm } from '../components/vzr/VzrForm'
import TravelBanner from '../components/travel/TravelBanner'
import Benefits from '../components/Benefits'
import wheel from '../static/img/services/wheel-icon.svg'
import cartIcon from '../static/img/services/cart-icon.svg'
import travelIcon from '../static/img/services/travel-icon.svg'
import { useModal } from '../hooks/modal'
import PolicySuccess from '../components/modals/PolicySuccess'
import PolicyCheck from '../components/modals/PolicyCheck'
import PolicyNotFound from '../components/modals/PolicyNotFound'
import VzrContextWrapper from '../contexts/VzrContext'
import Loader from '../components/base/Loader'

export default function Vzr() {
    console.log(1)
    const { push } = useHistory()
    const calc = usePostRequest({ url: REST.TRAVEL.CALC })
    const travel = usePostRequest({ url: REST.TRAVEL.ALL })
    const payment = usePostRequest({ url: REST.PAYMENT })
    const { state } = useLocation()
    const { t } = useTranslation('travel', ['common'])
    const buy = useRef(null)
    const products = useRef(null)

    console.log(2)

    const [openInfoPopup, setOpenInfoPopup] = useState(false)
    // const [openPaymentPopup, setOpenPaymentPopup] = useState(false)
    const [openSpaTypePopup, setOpenSpaTypePopup] = useState(false)
    // const [formikData, setFormikData] = useState()
    // const createTravel = useRef()

    console.log(3)

    const [showPolicySuccessModal, hidePolicySuccessModal] = useModal(
        <PolicySuccess className="popup__popup-policy-success" push={push} hide={() => hidePolicySuccessModal()} />,
    )

    const [showPolicyNotFound, hidePolicyNotFound] = useModal(
        <PolicyNotFound className="popup__popup-policy-success" push={push} onClose={() => hidePolicyNotFound()} />,
    )

    const [showPolicyCheckModal] = useModal(
        <PolicyCheck
            className="popup__policy-check"
            onOpenSuccess={showPolicySuccessModal}
            onOpenError={showPolicyNotFound} />,
    )

    console.log(4)

    const benefits = [
        { onClick: showPolicyCheckModal, image: wheel, title: 'travel:checkPolicy' },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: buy.current.offsetTop - 260,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: cartIcon,
            title: 'travel:byuOnline',
        },
        {
            onClick: (el) => {
                el.preventDefault()
                window.scrollTo({
                    top: products.current.offsetTop - 220,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            image: travelIcon,
            title: 'travel:travelProducts',
        },
    ]

    // if (travel.loading || payment.loading) {
    //     return (
    //         <Layout>
    //             <Loader />
    //         </Layout>
    //     )
    // }
    console.log(5)

    return (
        <Layout>
            <div className="travel-one">
                <div className="travel-one__bg">
                    <VzrContextWrapper buy={buy} state={state} calc={calc} head={buy} travel={travel} payment={payment}
                        toggleInfoPopup={() => setOpenInfoPopup((value) => !value)}
                        // setFormikData={setFormikData} createTravel={createTravel}
                        // togglePaymentPopup={() => setOpenPaymentPopup((value) => !value)}
                        toggleSpaTypePopup={() => setOpenSpaTypePopup((value) => !value)}
                    >
                        {
                            (travel.loading || payment.loading)
                                ? <Loader />
                                : (
                                    <>
                                        <TravelBanner />
                                        <Benefits benefits={benefits} />
                                        <div className="travel-one__container" ref={buy}>
                                            <h3 className="travel-one__title">{t('header')}</h3>
                                        </div>
                                        <VzrForm />
                                    </>
                                )
                        }
                    </VzrContextWrapper>
                </div>
            </div>
            <div className={cn('travel-type__hint-popup', { 'travel-popup-active': openSpaTypePopup })}>
                <div className="travel-type__hint-info">
                    <div className="travel-type__hint-head">
                        <h4 className="travel-type__hint-title">Типы отдыха</h4>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setOpenSpaTypePopup((value) => !value)
                            }}
                            className="travel-type__hint-close">
                            <span />
                            <span />
                        </button>
                    </div>
                    <span className="travel-type__hint-type">Спокойный отдых</span>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item">Пляжный</li>
                        <li className="travel-type__hint-item">Экскурсионный</li>
                        <li className="travel-type__hint-item">Посещение аквапарков, бассейнов</li>
                        <li className="travel-type__hint-item">Велосипедные прогулки</li>
                    </ul>
                    <span className="travel-type__hint-type">Спортивный отдых</span>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item">
                            Сноубординг/горные лыжи на подготовленных трассах
                        </li>
                        <li className="travel-type__hint-item">Серфинг</li>
                        <li className="travel-type__hint-item">Рафтинг</li>
                        <li className="travel-type__hint-item">Парашют</li>
                        <li className="travel-type__hint-item">
                            Полеты на моторных и безмоторных летательных аппаратах
                        </li>
                        <li className="travel-type__hint-item">Автоспорт</li>
                        <li className="travel-type__hint-item">Мотоспорт</li>
                        <li className="travel-type__hint-item">Мотороллеры, мопеды и т.п.</li>
                    </ul>
                </div>
            </div>
            <div className={cn('travel-type__hint-popup aside-popup', { 'travel-popup-active': openInfoPopup })}>
                <div className="travel-type__hint-info">
                    <div className="travel-type__hint-head">
                        <h4 className="travel-type__hint-title aside-popup__title">Что входит в полис</h4>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setOpenInfoPopup((value) => !value)
                            }}
                            className="travel-type__hint-close"
                        >
                            <span />
                            <span />
                        </button>
                    </div>
                    <ul className="travel-type__hint-list">
                        <li className="travel-type__hint-item aside-popup__item">Коронавирус и стихийные бедствия</li>
                        <li className="travel-type__hint-item aside-popup__item">Амбулаторное лечение</li>
                        <li className="travel-type__hint-item aside-popup__item">Вызов врача на дом</li>
                        <li className="travel-type__hint-item aside-popup__item">Вызов санитарно-медицинской помощи</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы на услуги, назначенные лечащим врачом
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Диагностические и лабораторные исследования</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Приобретение медикаментов, назначенных лечащим врачом
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы на перевязочные средства и средства фиксации (гипс, бандаж)
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Пребывание и лечение в стационаре
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Консультации узких специалистов</li>
                        <li className="travel-type__hint-item aside-popup__item">Проведение экстренных операций</li>
                        <li className="travel-type__hint-item aside-popup__item">Экстренная стоматология</li>
                        <li className="travel-type__hint-item aside-popup__item">Транспортировка к врачу или в медицинское учреждение</li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Транспортировка до места проживания при условии отсутствия возможностей
                            для предоставления экстренной медицинской помощи на территории страхования
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Транспортировка до места проживания при условии отсутствия возможности
                            у Застрахованного по медицинским показаниям самостоятельно вернуться
                            к месту проживания по причине страхового случая
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Расходы по подготовке к репатриации и репатриация останков
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">
                            Проезд до места жительства после лечения в стационаре
                        </li>
                        <li className="travel-type__hint-item aside-popup__item">Наблюдение в стационаре</li>
                        <li className="travel-type__hint-item aside-popup__item">Возвращение несовершеннолетних детей</li>
                        <li className="travel-type__hint-item aside-popup__item">Визит третьего лица</li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}
