import React, { useContext } from 'react'
// import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import Loader from './base/Loader'
import AutoCarousel from './common/AutoCarousel'

export default function MainPartners() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)

    const { t } = useTranslation('home')
    const { response } = useLoad({ url: CMS.PARTNERS }, [cmsToken, language])

    if (!response) {
        return (
            <Loader centered />
        )
    }

    const partners = response

    return (
        <section className="main-partners">
            {/* <div className="main-partners__container">

                <div className="main-blog__list-item">
                    <NavLink to="/partners" className="main-blog__link">{t('allPartners')}</NavLink>
                </div>
            </div> */}
            <div className="main-partners__container">
                <NavLink to="/partners">
                    <h2 className="main-partners__title">{t('ourPartners')}</h2>
                </NavLink>

                <AutoCarousel partners={partners} />
            </div>

        </section>
    )
}
