import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

// import ru from '../../static/img/language/ru.svg'
// import en from '../../static/img/language/en.svg'
// import tj from '../../static/img/language/tj.svg'
import { Context } from '../../contexts/GlobalContext'

export default function SelectLanguage() {
    const { setLanguage } = useContext(Context)
    // const langFlags = { en, ru, tj }
    const { t } = useTranslation('common')

    const languageSelect = (code) => {
        setLanguage(code)
        i18n.changeLanguage(code)
    }

    return (
        <div className="header__flags dropdown">
            <div className="dropbtn">
                <div className={`flag__wrapper flag__wrapper_${i18n.language}`} />
            </div>
            <div className="header__flags_case dropdown-content">
                <div className="header__flags-string" onClick={() => languageSelect('ru')}>
                    <span className="header__lang-name">RU</span>
                    <span className="header__lang-name">{t('rus')}</span>
                    <div className="flag__wrapper flag__wrapper_ru" />
                </div>
                <div className="header__flags-string" onClick={() => languageSelect('tj')}>
                    <span className="header__lang-name">TJ</span>
                    <span className="header__lang-name">{t('tjs')}</span>
                    <div className="flag__wrapper flag__wrapper_tj" />
                </div>
                <div className="header__flags-string" onClick={() => languageSelect('en')}>
                    <span className="header__lang-name">EN</span>
                    <span className="header__lang-name">{t('eng')}</span>
                    <div className="flag__wrapper flag__wrapper_en" />
                </div>
            </div>
        </div>
    )
}
