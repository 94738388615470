import React from 'react'
import Carousel from '../common/Carousel'
import slide1 from '../../static/img/auto-insurance/slide-1.png'
import slide1Mob from '../../static/img/auto-insurance/slide-1-mob.png'

const img = [1, 2, 3]

function BoxInsurance({ empty = true }) {
    if (empty) {
        return (
            <></>
        )
    }

    return (
        <>
            <div className="box__container">
                <h3 className="box__title">
                    Приемущества (тут тоже нет)
                </h3>
            </div>
            <Carousel
                autoplay
                arrows={false}
                centerMode
                infinite
                slidesToShow={3}
                className="auto-insurance-slider__slider"
                focusOnSelect={false}>
                {
                    img.map(() => (
                        <div className="auto-insurance-slider__slider-item">
                            <h3 className="auto-insurance-slider__slider-title">Ущерб</h3>
                            <p className="auto-insurance-slider__slider-text">
                                Гуманизм, на первый взгляд, обретает гносеологический
                                континентально-европейский тип политической культуры.
                            </p>
                            <picture className="auto-insurance-slider__slider-image">
                                <source media="min-width: 1280px" srcSet={slide1} />
                                <img src={slide1Mob} alt="" />
                            </picture>
                        </div>
                    ))
                }
            </Carousel>
        </>
    )
}

export default BoxInsurance
