import React, { useEffect, useRef, useState } from 'react'
import { Field, useField } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import hintIcon from '../../static/img/svg/hint-icon.svg'
import { mergeRefs } from '../../helpers/globals'

export function DateInput({ name, label = null, className, placeholder, hint, onTouch,
    handleSubmit, error, errorTxt, onChange = () => {}, isDisabled, required, inputRef,
    max = moment().format('YYYY-MM-DD'), min = moment().add(-80, 'year').format('YYYY-MM-DD'),
    refLink }) {
    const { t } = useTranslation('common')
    const input = useRef()

    const [{ value }, { error: err }, { setValue }] = useField(name)
    const [lazyVal, setLazyVal] = useState(value || null)
    useEffect(() => {
        if (value || value === '') {
            const date = moment(value)
            if (!date.isValid() || date.isBefore(min) || date.isAfter(max)) {
                if (lazyVal !== null) {
                    window.setTimeout(() => {
                        setLazyVal('')
                        setValue('')
                        input.current.focus()
                        input.current.showPicker()
                    }, 200)
                }
            } else {
                setLazyVal(date.format('YYYY-MM-DD'))
            }
        }
    }, [value])

    return (
        <div className={className} ref={refLink}>
            <label className={cn('input__label', { 'label-valid': !error })}>
                {label}
                { required ? <span style={{ color: 'red', paddingLeft: '4px' }}>*</span> : null }
                {
                    hint
                        ? (
                            <span className="hint__icon">
                                <img src={hintIcon} alt="" width="16" height="16" />
                                <span className="hint__content">
                                    {hint}
                                </span>
                            </span>
                        ) : null
                }
            </label>

            <Field name={name}>
                {({ form }) => (
                    <input
                        className="input__input"
                        type="date" ref={mergeRefs(inputRef, input)}
                        // onFocus="(this.type='date')"
                        value={lazyVal}
                        disabled={isDisabled}
                        max={max} min={min}
                        onChange={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (onTouch) onTouch()
                            const date = moment(e.target.value)
                            if (!date.isValid() || date.isBefore(min) || date.isAfter(max)) {
                                setLazyVal(e.target.value)
                            } else if (!isDisabled) {
                                setLazyVal(e.target.value)
                                form.setFieldValue(name, e.target.value).then(() => {
                                    if (handleSubmit) return handleSubmit()
                                    return false
                                })
                                window.setTimeout(() => onChange(e), 0)
                            } else if (e.target.value === '') {
                                window.setTimeout(() => onChange(e), 0)
                            }
                        }}
                        onBlur={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (onTouch) onTouch()
                            form.setFieldValue(name, e.target.value)
                        }}
                        placeholder={placeholder} />
                )}
            </Field>
            {
                (error || err)
                    ? (
                        <div className="input_error">
                            <span
                                className="input_error-text">
                                { errorTxt || t('wrongInput') }
                            </span>
                        </div>
                    ) : null
            }
        </div>
    )
}
