import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import { isNumber } from 'lodash'

export default function CheckboxesOptions({ hint, label, options = [], icon = false, nameDesc = false, onChange = () => {} }) {
    const { values, setFieldValue } = useFormikContext()
    // eslint-disable-next-line no-empty-pattern
    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(0)
    const refSelect = useRef()

    const handleClickOutside = (event) => {
        if (open && refSelect.current && !refSelect.current.contains(event.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return (
        <div className="auto__option-row">
            <div className={cn('checkbox travel-option auto-option__box auto-option__box-top green', icon, { 'travel-option__checked': checked >= 1 })}>
                {
                    hint !== '' && (
                        <span className="hint__icon travel-option__hint-icon">
                            <span className="hint__icon-simbol">?</span>
                            <span className="hint__content travel-option__hint-content">
                                {hint}
                            </span>
                        </span>
                    )
                }
                <label className="travel-option__rules">
                    <div className="travel-option__title-box travel-pl">
                        <div className="travel-option__title">
                            <div className="travel-option__text-box">
                                <div className="travel-option__name auto__name">
                                    <p className="travel-option__text">
                                        {label}
                                    </p>
                                </div>
                                <span className="select__text-desc">{nameDesc}</span>
                            </div>
                        </div>
                    </div>
                    {
                        options.map(({ optionName, optionLabel, optionPrem }) => (
                            <div className="auto-option__list" onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                console.log(1, values[optionPrem])
                                const set = values[optionName] === 1
                                setFieldValue(optionName, set ? 0 : 1)
                                setChecked((val) => val + (set ? -1 : 1))
                                if (onChange) onChange()
                            }}>
                                <div className="auto-option__item">{optionLabel}</div>
                                <div className="auto-option__check">
                                    <div className="travel-option__price auto-option__price">
                                        {isNumber(values[optionPrem]) ? `${values[optionPrem]} TJS` : ''}
                                        <input className="checkbox__input" type="checkbox" checked={values[optionName]} />
                                        <span className="checkbox__span auto-option__span" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </label>
            </div>
        </div>
    )
}
