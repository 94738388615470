export default {
    registred: 'Registered',
    registerEvent: 'Registration of the insured event',
    fullName: 'Full name of the Insured person',
    fullNamePH: 'Full name',
    policyNumber: 'Policy number',
    policyNumberPH: 'Policy number',
    email: 'E-mail',
    phone: 'Tel.',
    note: 'Describe the circumstances / events',
    maxFiles: 'Maximum 10 files',
    notePH: 'Description',
    submit: 'Confirm',
    ok: 'OK',
}
