import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Benefits({ benefits }) {
    const { t } = useTranslation('common')

    return (
        <section className="main-services">
            <div className="main-services__container">
                <div className="main-services__grid">
                    {benefits.map((benefit) => (
                        <button
                            key={benefit.title}
                            onClick={benefit.onClick}
                            className="main-services__button"
                            type="button">
                            <img className="main-services__icon" src={benefit.image} alt="Icon" />
                            <p className="main-services__text">{t(benefit.title)}</p>
                        </button>
                    ))}
                </div>
            </div>
        </section>
    )
}
