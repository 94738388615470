import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { random } from 'lodash'

const removeLas = (up, down) => (diff, i, las) => {
    if (diff <= up && diff >= down) {
        las.splice(i, 1)
        return true
    }
    return false
}

export default function SelectPersons() {
    const { setFieldValue, values, handleSubmit } = useFormikContext()
    const [open, setOpen] = useState(false)
    const [lasCount, setLasCount] = useState(values.las.length)
    const { t } = useTranslation('travel')
    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    const types = useMemo(() => {
        const las = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
        }
        values.las.forEach(({ laBirthday }) => {
            const diff = moment().diff(moment(laBirthday), 'year')
            if (diff <= 69 && diff >= 4) las[0] += 1
            if (diff < 1 && diff >= 0) las[1] += 1
            if (diff <= 3 && diff >= 1) las[2] += 1
            if (diff <= 74 && diff >= 70) las[3] += 1
            if (diff >= 75 && diff <= 80) las[4] += 1
        })

        return las
    }, [lasCount])

    useEffect(() => {
        window.setTimeout(() => handleSubmit(), 500)
    }, [lasCount])

    const removePerson = (e, type) => {
        e.preventDefault()
        e.stopPropagation()

        if (lasCount <= 1) return

        const las = values.las.reverse()
        let remove
        switch (type) {
        case 0:
            remove = removeLas(69, 4)
            break

        case 1:
            remove = removeLas(1, 0)
            break

        case 2:
            remove = removeLas(3, 1)
            break

        case 3:
            remove = removeLas(74, 70)
            break

        default:
            remove = removeLas(80, 75)
            break
        }
        values.las.some(({ laBirthday }, i) => {
            const diff = moment().diff(moment(laBirthday), 'year')
            return remove(diff, i, las)
        })
        las.reverse()

        setFieldValue('las', las)
        setLasCount(las.length)
    }

    const addPerson = (e, type) => {
        const bd = moment()
        e.preventDefault()
        e.stopPropagation()

        if (lasCount >= 5) return

        switch (type) {
        case 0:
            bd.add(-5, 'year')
            break

        case 1:
            bd.add(-0, 'year')
            break
        case 2:
            bd.add(-2, 'year')
            break

        case 3:
            bd.add(-72, 'year')
            break

        default:
            bd.add(-76, 'year')
        }

        const { las } = values

        las.push({
            uid: random(100, 9999, true),
            laLastname: '',
            laFirstname: '',
            laPatronic: '',
            laBirthday: bd.format('YYYY-MM-DD'),
            laBirthdayTouch: false,
            laCitizenship: 'TJ',
            issueDate: null,
            // issueInstitution: '------',
            series: '',
            docType: '',
            docNumber: '',
            dateTo: '',
            gender: '',
            // sportOption: las[0].sportOption,
            // sportSumPrem: 0,
            // covid19Option: las[0].covid19Option,
            // covid19SumPrem: 0,
            // flightDelaySign: las[0].flightDelaySign,
            // flightDelaySumPrem: 0,
            // baggageDelaySign: las[0].baggageDelaySign,
            // baggageDelaySumPrem: 0,
            // baggageLossSign: las[0].baggageLossSign,
            // baggageLossSumPrem: 0,
            // pregnantOption: 0,
            // pregnantSumPrem: 0,
            // sunOption: 0,
            // sunSumPrem: 0,
            // alcoholicOption: 0,
            // alcoholicSumPrem: 0,
            // poisoningOption: 0,
            // poisoningSumPrem: 0,
            // sequence: las[0].sequence,
        })
        setFieldValue('las', las)

        setLasCount(las.length)
    }

    const personWord = useMemo(() => {
        switch (lasCount) {
        case 1:
            return t('insured0', { count: lasCount })
        case 5:
            return t('insured2', { count: lasCount })
        default:
            return t('insured1', { count: lasCount })
        }
    }, [lasCount])

    return (
        <div className="travel-one__form-row travel-one__person" ref={ref}>
            <div className="select select__travel-order travel-order__input_ful select__field">
                <label className={cn('select__label', { 'label-valid': lasCount >= 1 })}
                    onClick={() => setOpen((value) => !value)}
                >
                    {t('travelingWho')}
                </label>
                <div className="select__current" onClick={() => setOpen((value) => !value)}>
                    {personWord}
                </div>
                <div className={cn('select__list select__list-travel', { select__list_open: open })}>
                    <div className="select__item select__item-travel">
                        <span className="select__text-value">{t('travelingPeriod0')}</span>
                        <div className="select__counter">
                            <button
                                onClick={(e) => removePerson(e, 0)}
                                className={cn('counter-button counter__decrease', {
                                    'counter__decrease-inactive': types[0] === 0 || lasCount === 1,
                                })}
                            >
                                -
                            </button>
                            <span className="counter__value">
                                {types[0]}
                            </span>
                            <button
                                className={cn('counter-button counter__increase', {
                                    'counter__decrease-inactive': lasCount >= 5,
                                })}
                                onClick={(e) => addPerson(e, 0)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="select__item select__item-travel">
                        <span className="select__text-value">{t('travelingPeriod1')}</span>
                        <div className="select__counter">
                            <button
                                onClick={(e) => removePerson(e, 1)}
                                className={cn('counter-button counter__decrease', {
                                    'counter__decrease-inactive': types[1] === 0 || lasCount === 1,
                                })}
                            >
                                -
                            </button>
                            <span className="counter__value">
                                {types[1]}
                            </span>
                            <button
                                className={cn('counter-button counter__increase', {
                                    'counter__decrease-inactive': lasCount >= 5,
                                })}
                                onClick={(e) => addPerson(e, 1)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="select__item select__item-travel">
                        <span className="select__text-value">{t('travelingPeriod2')}</span>
                        <div className="select__counter">
                            <button
                                onClick={(e) => removePerson(e, 2)}
                                className={cn('counter-button counter__decrease', {
                                    'counter__decrease-inactive': types[2] === 0 || lasCount === 1,
                                })}
                            >
                                -
                            </button>
                            <span className="counter__value">
                                {types[2]}
                            </span>
                            <button
                                className={cn('counter-button counter__increase', {
                                    'counter__decrease-inactive': lasCount >= 5,
                                })}
                                onClick={(e) => addPerson(e, 2)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="select__item select__item-travel">
                        <span className="select__text-value">{t('travelingPeriod3')}</span>
                        <div className="select__counter">
                            <button
                                onClick={(e) => removePerson(e, 3)}
                                className={cn('counter-button counter__decrease', {
                                    'counter__decrease-inactive': types[3] === 0 || lasCount === 1,
                                })}
                            >
                                -
                            </button>
                            <span className="counter__value">
                                {types[3]}
                            </span>
                            <button
                                className={cn('counter-button counter__increase', {
                                    'counter__decrease-inactive': lasCount >= 5,
                                })}
                                onClick={(e) => addPerson(e, 3)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <div className="select__item select__item-travel">
                        <span className="select__text-value">{t('travelingPeriod4')}</span>
                        <div className="select__counter">
                            <button
                                onClick={(e) => removePerson(e, 4)}
                                className={cn('counter-button counter__decrease', {
                                    'counter__decrease-inactive': types[4] === 0 || lasCount === 1,
                                })}
                            >
                                -
                            </button>
                            <span className="counter__value">
                                {types[4]}
                            </span>
                            <button
                                className={cn('counter-button counter__increase', {
                                    'counter__decrease-inactive': lasCount >= 5,
                                })}
                                onClick={(e) => addPerson(e, 4)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
