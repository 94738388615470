export default {
    insuranceCase: 'Ҳодисаи суғуртавӣ',
    checkPolicy: 'Тафтиши полис',
    activatePolicy: 'Фаъол намудани полис',
    popularProducts: 'Маҳсулоти маъмул',
    box: 'Қуттигӣ',
    config: 'Танзимшаванда',
    ourPartners: 'Шарикони мо',
    allPartners: 'Ҳамаи шарикон',
    blogHeader: '#Bima.Blog',
    blogShowAll: 'Назар',
    buy: 'Харидан',
    request: 'Дархост',
    more: 'Муфассал',
    corporate: 'Корпоративӣ',
    inputNumber: 'Муайян намудани рақами полиси',
    checkButton: 'Тафтиш',
}
