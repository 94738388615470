import React from 'react'
import Layout from '../components/Layout'

function Ponimayu() {
    return (
        <Layout>
            <div className="ponimayu">
                <div className="main__container">
                    <h1 className="ponimayu__title">
                        Что такое Well-being программы и как они влияют на успех в
                        бизнесе?
                    </h1>
                    <div className="ponimayu__text">
                        В наше время забота о благополучии сотрудников становится важным
                        элементом успешного ведения бизнеса. Мировым трендом в этом
                        направлении стало внедрение работодателями Well-being программ.
                        Данные программы включают в себя набор мероприятий и инструментов,
                        направленных на улучшение физического и психологического
                        благополучия сотрудников компании.
                    </div>
                    <div className="ponimayu__img ponimayu__img--1" />
                    <div className="ponimayu__text">
                        Основная цель Well-being программ — это создание условий труда,
                        способствующих повышению мотивации, продуктивности и
                        удовлетворенности работников. Забота о здоровье и благополучии
                        сотрудников не только способствует улучшению их качества жизни, но
                        и снижает текучесть кадров, увеличивает лояльность к компании и
                        способствует построению позитивного имиджа работодателя.
                    </div>
                    <div className="ponimayu__img ponimayu__img--2" />
                    <div className="ponimayu__text">
                        Страховая организация «БИМА» впервые в Таджикистане запустила
                        Well-being продукт «Понимаю» для корпоративного сегмента в рамках
                        медицинского страхования. Теперь компании могут позаботиться о
                        благополучии своих сотрудников, повысить их эффективность,
                        уменьшить выгорание на рабочем месте, улучшить ментальное здоровье
                        и мотивировать на новые более высокие трудовые результаты.
                    </div>
                    <div className="ponimayu__img ponimayu__img--3" />
                    <div className="ponimayu__text">
                        Продукт «Понимаю» включает в себя психологическую поддержку,
                        экспертизу по личным финансам, консультации по здоровому образу
                        жизни, консультации по физической активности и практике
                        осознанности.
                    </div>
                    <div className="ponimayu__text">
                        Специалисты, привлеченные в рамках реализации продукта «Понимаю»,
                        соблюдают все этические принципы работы психолога, в первую
                        очередь – это конфиденциальность, ответственность и уважение к
                        клиенту.
                    </div>
                    <div className="ponimayu__text">
                        Организации, которые внедрили у себя Well-being программы,
                        утверждают, что это помогло им в создании более позитивной
                        корпоративной культуры, укреплении командного духа и повышении
                        конкурентоспособности бизнеса. Забота о сотрудниках — залог успеха
                        и процветания любой компании в долгосрочной перспективе.
                    </div>
                    <div className="ponimayu__text">
                        Получить консультацию по вопросам подключения и функциональности
                        продукта «Понимаю» можно обратившись в Отдел Добровольного
                        медицинского страхования.
                    </div>
                    <div className="ponimayu__text ponimayu__text--bold">
                        Ситора Хурсан
                    </div>
                    <div className="ponimayu__text">
                        Руководитель отдела ДМС <br />
                        e-mail:
                        <a href="mailto:skadyrova@bima.tj">
                            <p className="ponimayu__mail">skadyrova@bima.tj</p>
                        </a>
                        <br />
                        тел.:
                        <a href="tel:+992446015511">
                            <p className="ponimayu__number">+992 44 601 5511</p>
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Ponimayu
