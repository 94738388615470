import React from 'react'
import { useFormikContext } from 'formik'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export default function SelectSwitch({
    name,
    options,
    onChange,
    className,
    value,
    label = false,
    refLinlk,
}) {
    const { setFieldValue, values, handleSubmit, errors } = useFormikContext()
    const { t } = useTranslation('common')

    return (
        <div className={cn('', className, { input_error: errors[name] })} ref={refLinlk}>
            {
                label && (
                    <label className={cn('select__label', { 'label-valid': !errors[name] })}>
                        {label}
                        <span className="input_error-text">{t('incorrectlyFilled')}</span>
                    </label>
                )
            }
            <div className="travel-currency__box">
                {
                    options.map((item) => (
                        <button
                            key={item.value}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setFieldValue(name, item.value)
                                if (onChange) onChange(item.value, e)
                                window.setTimeout(() => handleSubmit(), 200)
                            }}
                            className={cn('currency__item', { currency__item_active: values[name] === item.value || value === item.value })}
                        >
                            {item.name || item.label}
                        </button>
                    ))
                }
            </div>
        </div>
    )
}
