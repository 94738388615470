export default {
    vacancy: 'Vacancies',
    sendResume: 'Send resume',
    sendedResume: 'Send resume',
    location: 'Location',
    salary: 'Salary',
    experience: 'Experience required',
    dateEnd: 'CV submission deadline',
    experienceOnPosition: 'Job experience',
    workSchedule: 'Work schedule',
    candidatesRequirements: 'Requirements for candidates',
    duties: 'Duties',
    conditions: 'Conditions',
    fullName: 'Full Name',
    fullNamePH: 'Alexey Petrovich Ivanov',
    phone: 'Tel.',
    email: 'Email',
    message: 'Message to HR department',
    submitResume: 'Respond',
    addFile: 'Upload file',
    addFileText: 'Upload files with CV, certificates, etc. ( max. 5 files, max.25 mb )',
    title: 'Upload your resume',
    subTitle: 'We review the candidates from our archive ',
}
