export function countryTransform(options, lang) {
    return options.map((item) => ({
        value: item.code2,
        label: item[`label_${lang}`] || item.label,
        name: item.name,
        WET: item.WET,
        SCU: item.SCU,
        CET: item.CET,
    }))
}

export function optionsTransform(options, lang) {
    if (!options) return []

    return options.map((item) => ({
        value: item.value,
        label: item[`name_${lang}`] || item[`label_${lang}`] || item.label,
        name: item.name || item[`label_${lang}`] || item.label,
    }))
}
