import React, { useContext } from 'react'
import { Context } from '../contexts/GlobalContext'

export function useModal(component) {
    const { setModalComponent } = useContext(Context)

    function hideModal() {
        setModalComponent(null)
    }

    function showModal(props) {
        const componentProps = { ...props, hideModal }
        const content = React.isValidElement(component)
            ? React.cloneElement(component, componentProps) : component(componentProps)
        setModalComponent(content)
    }

    return [showModal, hideModal]
}
