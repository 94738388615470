import React from 'react'
import Layout from '../components/Layout'
import RegisterLosses from '../components/RegisterLosses'

export default function Losses() {
    return (
        <Layout>
            <RegisterLosses />
        </Layout>
    )
}
