import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import Button from '../base/Button'
import { usePostRequest } from '../../hooks/request'
import { REST } from '../../utils/urls'
import logo from '../../static/img/logo.svg'

export default function PolicyCheck({ onOpenSuccess, onOpenError }) {
    const [show, setShow] = useState(false)
    const policyCheck = usePostRequest({ url: REST.POLICY_CHECK })

    const { t } = useTranslation('home')

    async function onCheckPolicy(data) {
        const { success, response, error } = await policyCheck.request({ data })

        if (success) {
            sessionStorage.setItem('policyInfo', JSON.stringify(response))
            onOpenSuccess()
            return
        }

        if (error && error.status === 422) {
            onOpenError()
        }
    }

    setTimeout(() => setShow(!show), 300000)

    return (
        <div className="popup__content">
            <div className="popup__wrapper-head">
                <img src={logo} className="footer__image" alt="" />

                <h4 className="popup__title">{t('checkPolicy')}</h4>
            </div>
            <Formik initialValues={{ policyNumber: '' }} onSubmit={onCheckPolicy}>
                {({ handleSubmit }) => (
                    <div className="popup-policy-check">

                        <div className="popup-policy-check__tab-content current">
                            <div className="popup-policy-check__text">{t('inputNumber')}</div>

                            <Field name="policyNumber" className="popup-policy-check__input" />
                        </div>

                        <Button
                            loading={policyCheck.loading}
                            onClick={handleSubmit}
                            className="popup-policy-check__button"
                            text={t('checkButton')} />
                    </div>
                )}
            </Formik>
        </div>
    )
}
