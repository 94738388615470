import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'
import FormTotal from './FormTotal'
import SelectScroll from '../base/SelectScroll'
import { AutoContext } from '../../contexts/AutoContext'
import Input from '../base/Input'
import { createPremSum, hints } from '../../helpers/travel'
import Checkbox from '../base/Checkbox'

export default function FormStep1() {
    // eslint-disable-next-line max-len
    const { marks, models, getModels, setAutoSum, carIssueYear: carIssueYearOpt, getYear, isEci, debouceHandleSubmit, costMonth, installmentPlan, setShortValidate } = useContext(AutoContext)

    const { t } = useTranslation('auto')
    const { setFieldValue, errors, handleSubmit, values } = useFormikContext()
    const itemsRef = useRef({})
    const [carTxt, setCarTxt] = useState(false)
    const carIssueYear = useMemo(() => (values.carModel ? carIssueYearOpt : []), [values.carModel])
    const carBodyType = useMemo(() => (Array(10).fill(0)
        .map((_, item) => ({
            value: item,
            name: t(`carBodyTypeLabel.${item}`),
        }))), [values.carModel])
    const carIssuePrem = useMemo(() => (values.carIssueYear ? createPremSum(31) : []), [values.carIssueYear])

    useEffect(() => {
        getYear('')
        window.setTimeout(() => handleSubmit(), 500)
    }, [])

    return (
        <section className="step step-1">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <div className="travel-one__form-row" style={{ marginBottom: 0 }}>
                        <a className="change__button auto__copy-button driver-button"
                            onClick={async (e) => {
                                e.preventDefault()
                                setCarTxt((val) => !val)
                                setFieldValue('carMark', null)
                                setFieldValue('carModel', null)
                                setFieldValue('markaTxt', null)
                                setFieldValue('modelTxt', null)
                                debouceHandleSubmit()
                            }}
                        >
                            {t('autoText')}
                        </a>
                    </div>
                    {!carTxt
                        ? (
                            <div className="travel-one__form-row">
                                <SelectScroll
                                    label={t('carMark')}
                                    name="carMark"
                                    error={errors.carMark}
                                    options={marks}
                                    defValue="--------"
                                    onChange={(value) => {
                                        setFieldValue('carMark', value)
                                        getModels(value)
                                        debouceHandleSubmit()
                                    }}
                                    filter
                                    className="select select__auto-order"
                                />
                                <SelectScroll
                                    label={t('carModel')}
                                    name="carModel"
                                    error={errors.carModel}
                                    options={models}
                                    defValue="--------"
                                    onChange={(value) => {
                                        setFieldValue('carModel', value)
                                        setFieldValue('carIssueYear', 0)
                                        getYear(value)
                                        setAutoSum([])
                                        setFieldValue('policeSumInsured', 0)
                                        debouceHandleSubmit()
                                    }}
                                    filter
                                    className="select select__auto-order"
                                />
                            </div>
                        ) : (
                            <div className="travel-one__form-row">
                                <Input label={t('carMark')}
                                    name="markaTxt"
                                    refLinlk={(el) => {
                                        itemsRef.current.vin = el
                                    }}
                                    error={errors.carMark}
                                    onChange={() => {
                                        debouceHandleSubmit()
                                    }}
                                    placeholder=""
                                    divClass="input input__required auto__input"
                                />
                                <Input label={t('carModel')}
                                    name="modelTxt"
                                    refLinlk={(el) => {
                                        itemsRef.current.vin = el
                                    }}
                                    error={errors.carModel}
                                    onChange={() => {
                                        debouceHandleSubmit()
                                    }}
                                    placeholder=""
                                    divClass="input input__required auto__input"
                                />
                            </div>
                        )}
                    <div className="travel-one__form-row">
                        <SelectScroll
                            label={t('carIssueYear')}
                            name="carIssueYear"
                            error={errors.carIssueYear}
                            options={carIssueYear}
                            defValue="--------"
                            onChange={(value) => {
                                setFieldValue('carIssueYear', value)
                                setFieldValue('carIssueYearDelta', moment().year() - value)
                                // if (values.carModel) getSum(values.carModel, value)
                                setAutoSum([])
                                setFieldValue('policeSumInsured', 0)
                                debouceHandleSubmit()
                            }}
                            filter
                            className="select select__auto-order"
                        />
                        {isEci && (
                            <SelectScroll
                                label={t('policeSumInsured')}
                                name="policeSumInsured"
                                error={errors.policeSumInsured}
                                options={carIssuePrem}
                                defValue="--------"
                                onChange={(value) => {
                                    setFieldValue('policeSumInsured', value)
                                    setShortValidate(true)
                                    debouceHandleSubmit()
                                }}
                                className="select select__auto-order"
                            />
                        )}
                    </div>
                    {
                        isEci ? (
                            <>
                                <div className="travel-one__form-row">
                                    <Checkbox
                                        name="calculateInstallments"
                                        hint={hints.calculateInstallments}
                                        className="travel-order__text checkbox auto-rules__check"
                                        label={t('calculateInstallments')}
                                        onChange={() => debouceHandleSubmit()}
                                    />
                                </div>
                                <div className="travel-one__form-row">
                                    <div className="installment-block">
                                        {
                                            values && values.calculateInstallments && installmentPlan.map(({
                                                date,
                                                percent,
                                            }, i) => (
                                                <div className={cn('installment-block__item', { 'first-pay': i === 0 })}>
                                                    <span className="installment-block__percent">{percent}%</span>
                                                    {
                                                        i === 0 ? (
                                                            <span className="installment-block__date">
                                                                {t('payDateNow')}
                                                            </span>
                                                        ) : (
                                                            <span className="installment-block__date">
                                                                {t('payDateTo', { to: date.format('DD.MM.YYYY') })}
                                                            </span>
                                                        )
                                                    }
                                                    <span className="installment-block__summ">{costMonth[1]} TJS</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="travel-one__form-row">
                                    <SelectScroll
                                        label={t('carBodyType')}
                                        name="carBodyType"
                                        error={errors.carBodyType}
                                        options={carBodyType}
                                        defValue="--------"
                                        onChange={(value) => {
                                            setFieldValue('carBodyType', value)
                                            debouceHandleSubmit()
                                        }}
                                        className="select select__auto-order"
                                    />
                                </div>
                            </>
                        ) : null
                    }
                    <div className="travel-one__form-row">
                        <Input label={t('vin')}
                            name="vin"
                            refLinlk={(el) => {
                                itemsRef.current.vin = el
                            }}
                            error={errors.vin}
                            onChange={() => debouceHandleSubmit()}
                            placeholder="X7LLSRB2HEH728327"
                            divClass="input input__required auto__input"
                        />
                        <Input label={t('bodyNumber')}
                            name="bodyNumber"
                            refLinlk={(el) => {
                                itemsRef.current.bodyNumber = el
                            }}
                            error={errors.bodyNumber}
                            onChange={() => debouceHandleSubmit()}
                            placeholder=""
                            divClass="input input__required auto__input"
                        />
                    </div>
                    <div style={{
                        color: '#c2c2c2',
                        fontSize: '8pt',
                    }}>
                        {t('noVin')}
                    </div>
                    <div className="travel-one__form-row">
                        <Input label={t('gosNomer')}
                            name="gosNomer"
                            refLinlk={(el) => {
                                itemsRef.current.gosNomer = el
                            }}
                            error={errors.gosNomer}
                            errTxt={errors.gosNomer === true ? null : t(errors.gosNomer)}
                            onChange={() => debouceHandleSubmit()}
                            placeholder="1234AA01"
                            divClass="input input__required auto__input"
                        />
                        <Input label={t('registrSvidet')}
                            name="registrSvidet"
                            error={errors.registrSvidet}
                            onChange={() => debouceHandleSubmit()}
                            refLinlk={(el) => {
                                itemsRef.current.registrSvidet = el
                            }}
                            placeholder="AA 1234567"
                            divClass="input input__required auto__input"
                        />
                    </div>
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
