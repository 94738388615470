import React from 'react'
import loader from '../../static/icons/loader.svg'

export default function Loader({ centered }) {
    return (
        <div style={centered ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'center' }}>
            <img src={loader} alt="Loader" />
        </div>
    )
}
