import moment from 'moment'
import filter from 'lodash/filter'

export const MONTHS = [
    { key: 'январь', title: 'Января' },
    { key: 'февраль', title: 'Февраля' },
    { key: 'март', title: 'Марта' },
    { key: 'апрель', title: 'Апреля' },
    { key: 'май', title: 'Мая' },
    { key: 'июнь', title: 'Июня' },
    { key: 'июль', title: 'Июля' },
    { key: 'август', title: 'Августа' },
    { key: 'сентябрь', title: 'Сентября' },
    { key: 'октябрь', title: 'Октября' },
    { key: 'ноябрь', title: 'Ноября' },
    { key: 'декабрь', title: 'Декабря' },
]

export function changeMonth(monthKey) {
    return filter(MONTHS, { key: monthKey })[0]
}

export function getYear(date = new Date()) {
    return moment(date).format('YYYY')
}

export function getMonth(date = new Date(), withName = false) {
    if (withName) return changeMonth(moment(date).format('MMMM')).title
    return moment(date).format('MM')
}

export function getDay(date = new Date()) {
    const day = moment(date).format('DD')
    return day
}

export function getHour(date = new Date()) {
    return moment(date).format('HH')
}

export function getMinute(date = new Date()) {
    return moment(date).format('mm')
}

export function getDate(date = new Date(), secondType = false) {
    if (secondType) {
        return `${getHour(date)}:${getMinute(date)},
        ${getDay(date)} ${getMonth(date, true).toLowerCase()} ${getYear(date)}`
    }
    return `${getDay(date)} / ${getMonth(date)} / ${getYear(date)}`
}

export function changeDate(date = new Date(), dayCount) {
    let response = moment(date)
    if (dayCount && dayCount > 1) {
        response = moment(date).add(Number(dayCount) - 1, 'day')
    }
    return response.format('YYYY-MM-DD')
}
