import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePostRequest } from '../../hooks/request'
import { REST } from '../../utils/urls'
import open from '../../static/img/svg/eye.svg'
import Loader from '../base/Loader'

function BoxesActivate() {
    const { t } = useTranslation(['box', 'common'])
    const [number, setNumber] = useState('')
    const [code, setCode] = useState('')
    const [type, setType] = React.useState(false)
    const [onLoad, setOnLoad] = useState(false)
    const onClick = () => { setType(!type) }

    const getUrl = usePostRequest({ url: REST.PACK.AUTH })

    const [errorMessage, setErrorMessage] = useState('')
    const sendRequest = async (el) => {
        setOnLoad(true)
        el.preventDefault()
        getUrl.request({ data: { policy_number: number, policy_code: code } })
            .then(({ response, error, success }) => {
                if (!success) {
                    setErrorMessage(error.data.message)
                } else {
                    let calcNumber = number
                    if (calcNumber.indexOf('ZAA1') !== -1) {
                        calcNumber = calcNumber.replace('ZAA1', '')
                    }
                    calcNumber = calcNumber.replace(/[^+\d]/g, '')
                    window.location = `${response.url}?policy_number=${calcNumber}&password=${code.replace(' ', '')}`
                }
                setOnLoad(false)
            })
    }

    return (
        <div className="boxes__container">
            <form className="boxes__form" method="post" action="/add" onSubmit={sendRequest}>
                <h3 className="boxes__form-title">{t('policyData')}</h3>
                <div className="activation__wrapper">
                    { errorMessage && (
                        <div style={{ color: '#A44' }}>
                            {JSON.stringify(errorMessage, null, 2)}
                        </div>
                    )}
                    <div className="boxes__form-items">
                        <div className="boxes__form-item">
                            <div className="input">
                                <label htmlFor="number" className="input__label">
                                    {t('policyNumber')}
                                    <span className="input_error-text">{t('common:incorrectlyFilled')}</span>
                                </label>
                                <input type="text" onChange={(el) => setNumber(el.target.value)}
                                    autoComplete="off" id="number"
                                    placeholder="0123456789" className="input__input activation__input" value={number} />
                            </div>
                        </div>
                    </div>
                    <div className="boxes__form-items">
                        <div className="boxes__form-item">
                            <div className="input">
                                <label htmlFor="code" className="input__label">
                                    {t('policyCode')}
                                    <span className="input_error-text">{t('common:incorrectlyFilled')}</span>
                                </label>
                                <div className="password__wrapper">
                                    <input type={type ? 'text' : 'password'} onChange={(el) => setCode(el.target.value)}
                                        id="number" autoComplete="off"
                                        placeholder="1233 4566 7989 4653" className="input__input activation__input" value={code} />
                                    <button className="open_hide" onClick={onClick}>
                                        <img src={open} alt="" width="16px" height="16px" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        onLoad ? (
                            <Loader />
                        ) : (
                            <a href="/" className="boxes__form-btn" onClick={sendRequest}>{t('common:continue')}</a>
                        )
                    }
                </div>
            </form>
        </div>
    )
}

export default BoxesActivate
