import React from 'react'
import Slider from 'react-slick'
import arrowPrev from '../../static/img/arrow-prev.svg'
import arrowNext from '../../static/img/arrow-next.svg'

function SamplePrevArrow({ style, onClick }) {
    return (
        <button
            className="main-banner__button main-banner__button_prev"
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={arrowPrev} alt="<" className="main-banner__arrow main-banner__arrow_prev" />
        </button>
    )
}

function SampleNextArrow({ style, onClick }) {
    return (
        <button
            className="main-banner__button main-banner__button_next"
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={arrowNext} alt=">" className="main-banner__arrow main-banner__arrow_prev" />
        </button>
    )
}

export default function Carousel({
    children,
    dots = false,
    className = 'slider',
    arrows = true,
    lazyLoad = true,
    nextArrow = <SampleNextArrow />,
    prevArrow = <SamplePrevArrow />,
    focusOnSelect = false,
    rtl = false,
    infinite = true,
    speed = 500,
    centerPadding = 0,
    centerMode = true,
    autoplay = true,
    autoplaySpeed = 2500,
    slidesToShow = 1,
    slidesToScroll = 1,
    pauseOnHover = true,
    responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: true,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
    ...props
}) {
    const settings = {
        dots,
        className,
        arrows,
        lazyLoad,
        focusOnSelect,
        rtl,
        infinite,
        speed,
        centerPadding,
        centerMode,
        prevArrow,
        autoplay,
        autoplaySpeed,
        slidesToShow,
        slidesToScroll,
        responsive,
        nextArrow,
        pauseOnHover,
        ...props,
    }

    return (
        <Slider {...settings}>
            {children}
        </Slider>
    )
}
