import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import MultiSelect from '../common/MultiSelect'
import { countryTransform } from '../../helpers/lang'
import hintIcon from '../../static/img/svg/hint-icon.svg'

export default function MultiSelectField({
    name,
    className,
    type,
    label,
    error,
    hint,
    placeholder,
    errTxt,
    isDisabled,
    options,
    required,
    onChange = () => null,
    ...attributes
}) {
    const { i18n, t } = useTranslation('common')

    const [{ value }] = useField(name)
    const [opts, setOpts] = useState(options)
    useEffect(() => {
        const stop = []
        if (value) {
            value.forEach((item) => {
                if (item.WET === '2') stop.push('WET')
                if (item.SCU === '2') stop.push('SCU')
                if (item.CET === '2') stop.push('CET')
            })
        }
        setOpts(options.filter((item) => {
            if (item.WET !== '0' && stop.indexOf('WET') !== -1) return false
            if (item.SCU !== '0' && stop.indexOf('SCU') !== -1) return false
            if (item.CET !== '0' && stop.indexOf('CET') !== -1) return false
            return true
        }))
    }, [value])

    return (
        <div className="input">
            {label ? (
                <label className={cn({ hint__label: hint, 'label-valid': !error }, 'input__label')} htmlFor={name}>
                    {label}
                    {
                        required
                            ? <span className="input__label_req" style={{ color: 'red', paddingLeft: '4px' }}>*</span>
                            : null
                    }
                    {
                        hint
                            ? (
                                <>
                                    <span className="hint__icon">
                                        <img src={hintIcon} alt="" width="16" height="16" />

                                        <span className="hint__content">
                                            {hint}
                                        </span>
                                    </span>
                                </>
                            ) : null
                    }
                    {
                        error
                            ? (
                                <div className="input_error">
                                    <span
                                        className="input_error-text">
                                        { errTxt || t('wrongInput') }
                                    </span>
                                </div>
                            ) : null
                    }
                </label>
            ) : null}

            <MultiSelect name="country" options={countryTransform(opts, i18n.language)}
                onChange={onChange} {...attributes} />
        </div>
    )
}
