import React from 'react'
import { useTranslation } from 'react-i18next'

function BoxesMenu({ cats = [], select, selected }) {
    const { t } = useTranslation('box')
    const setSelect = (el, slug) => {
        el.preventDefault()
        select(slug)
    }
    if (!cats) return null

    return (
        <div className="boxes__container">
            <h3 className="boxes__title">{t('boxProducts')}</h3>
            <div className="boxes__navigation">
                <div className="boxes__menu">
                    <ul className="boxes__list">
                        {
                            cats.length !== 0
                        && (
                            <li className="boxes__list-item">
                                <a href="/boxes/" className={`boxes__link ${selected === '' ? 'boxes__link_active' : ''}`}
                                    onClick={(el) => setSelect(el, '')}>
                                    Все
                                </a>
                            </li>
                        )
                        }
                        {
                            cats.map(({ title, slug }) => (
                                <li className="boxes__list-item" key={slug}>
                                    <a href={`/boxes/${slug}`}
                                        className={`boxes__link ${selected === slug ? 'boxes__link_active' : ''}`}
                                        onClick={((el) => setSelect(el, slug))}
                                    >
                                        {title}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BoxesMenu
