export default {
    insuranceCase: 'Insurance event',
    checkPolicy: 'Check policy',
    activatePolicy: 'Activate policy',
    popularProducts: 'Popular Products',
    box: 'Boxed',
    config: 'Configurable',
    ourPartners: 'Our partners',
    allPartners: 'All partners',
    blogHeader: '#Bima.Blog',
    blogShowAll: 'See all',
    buy: 'Buy',
    request: 'Submit application',
    more: 'Learn more',
    corporate: 'Сorporate',
    inputNumber: 'Provide policy number',
    checkButton: 'Check it',
}
