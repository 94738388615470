import React, { useContext } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Loader from '../components/base/Loader'
import { getDate } from '../utils/date'
import calendar from '../static/icons/calendar.svg'
import MailSubs from '../components/MailSubs'
import { AuthContext } from '../contexts/AuthContext'
import { Context } from '../contexts/GlobalContext'
import { useModal } from '../hooks/modal'
import BackCall from '../components/modals/BackCall'

export default function BlogDetail() {
    const { slug } = useParams()
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    const { t } = useTranslation(['blog', 'offices'])

    const [showBackCall] = useModal(
        <BackCall className="popup__policy-check" />,
    )

    const post = useLoad({ url: CMS.BLOG_POST.replace('{slug}', slug) }, [slug, cmsToken, language])
    const posts = useLoad({
        url: CMS.BLOG_POSTS,
        params: {
            category: post.response && post.response.data ? post.response.data.categories[0].catId : null,
            pageSize: 2,
        },
    }, [post.response, language])

    return (
        <Layout>
            {!post.loading ? (
                <div className="blog-article">
                    {post.response ? (
                        <div className="blog-article__container">
                            <div className="blog-article__banner">
                                <div className="news-item">
                                    <picture>
                                        <img
                                            className="news-item__img lazy"
                                            src={post.response.mainImg} alt="" />
                                    </picture>

                                    <div className="news-item__content">
                                        {/* {!isEmpty(post.response.categories) ? (
                                            <div className="news-item__tags">
                                                {post.response.categories.map((cat) => (
                                                    <span
                                                        key={cat.catId}
                                                        className="news-item__tags-links">
                                                        {cat.catName}
                                                    </span>
                                                ))}
                                            </div>
                                        ) : null} */}

                                        <div className="news-item__wrapper">
                                            <div className="news-item__text">
                                                {post.response.title}
                                            </div>

                                            <div className="news-item__footer">
                                                <div className="news-item__date">
                                                    <div className="news-item__date-icon">
                                                        <img src={calendar} alt="Calendar" />
                                                    </div>

                                                    {getDate(post.response.createdAt)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="blog-article__subtitle">{post.response.subTitle}</h3>

                            <div className="mb-5">
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description2 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description3 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description4 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description5 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description6 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description7 }} />
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: post.response.description8 }} />
                            </div>

                            <div className="blog-article__footer">
                                {!isEmpty(post.response.tags.split(',')) ? (
                                    <div className="blog-article__tags">
                                        {post.response.tags.split(',').map((tag) => (
                                            <div key={tag} className="blog-article__tag">#{tag}</div>
                                        ))}
                                    </div>
                                ) : null}
                                { post.response.active === 0 ? (
                                    <button className="offices__button"
                                        onClick={() => {
                                            showBackCall()
                                        }}
                                        type="button">
                                        {t('offices:callRequest')}
                                    </button>
                                ) : (
                                    <div className="blog-article__btns">
                                        {/* TO DO: RESEARCH HOW TO SHARE */}
                                        {/* <div className="blog-article__btn-share blog-article__btn-xs"> */}
                                        {/*    <svg width="20" height="20"> */}
                                        {/*        <use xlinkHref="#icon-share" /> */}
                                        {/*    </svg> */}
                                        {/* </div> */}

                                        {/* <div className="blog-article__btn-msg blog-article__btn-xs"> */}
                                        {/*    <svg width="20" height="20"> */}
                                        {/*        <use xlinkHref="#icon-msg" /> */}
                                        {/*    </svg> */}
                                        {/* </div> */}

                                        <NavLink className="blog-article__btn-footer" to="/blog">{t('toBlog')}</NavLink>
                                    </div>
                                )}
                            </div>

                            <div className="recommend">
                                <h1 className="recommend__title">{t('similarArticles')}</h1>

                                {!posts.loading ? (
                                    <div>
                                        {!isEmpty(posts.response ? posts.response.data : []) ? (
                                            <div className="recommend__items">
                                                {posts.response.data.map((item) => (
                                                    <NavLink
                                                        to={`/blog/${item.slug}`}
                                                        key={item.id}
                                                        className="news-item">
                                                        <picture>
                                                            <img className="news-item__img lazy"
                                                                src={item.mainImg} alt="" />
                                                        </picture>

                                                        <div className="news-item__content">
                                                            {/* <div className="news-item__tags">
                                                                {item.categories.map((cat) => (
                                                                    <span
                                                                        key={cat.catId}
                                                                        className="news-item__tags-links">
                                                                        {cat.catName}
                                                                    </span>
                                                                ))}
                                                            </div> */}

                                                            <div className="news-item__wrapper">
                                                                <div className="news-item__text">
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : <Loader centered />}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : <Loader centered />}
            <MailSubs />
        </Layout>
    )
}
