import React from 'react'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import pdfIcon from '../../static/img/icon-pdf.svg'
import jpgIcon from '../../static/img/icon-jpg.svg'
import addIcon from '../../static/icons/icon-add.svg'
import cross from '../../static/icons/cross.svg'

const trueFile = 'application/pdf'

export default function FileInput({
    files, setFiles, delFile,
}) {
    const { t } = useTranslation('common')

    return (
        <>
            <p className="registration-losses__label">
                {t('fileHeader')}
            </p>
            <div className="registration-losses__docs">
                {files.map(({ name, type, size }, i) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <div className="registration-losses__item">
                        <div className="input_img_title">
                            <div className="registration-losses__img">
                                <img
                                    src={type === trueFile ? pdfIcon : jpgIcon} alt="" />
                            </div>
                            <div className="registration-losses__link">
                                {name.substr(0, 22)}
                                <div className="registration-losses__link_thin">
                                    {round((size / 1024), 2)} Кб
                                </div>
                            </div>
                        </div>
                        <div className="registration-losses__btn-remove"
                            onClick={() => delFile(i)}
                        >
                            <img src={cross} alt="" />
                        </div>
                    </div>
                ))}
            </div>
            { files.length >= 10 && (
                <div style={{ marginBottom: '20px', color: 'Red' }}>
                    {t('maxFiles')}
                </div>
            )}
            <label className="registration-losses__btn-add">
                <img src={addIcon} alt="" />
                <input type="file" multiple onChange={setFiles} />
                {t('addFile')}
            </label>
        </>
    )
}
