import React, { useContext } from 'react'
// import { NavLink } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
import { useLoad } from '../../hooks/request'
import { CMS } from '../../utils/urls'
import { AuthContext } from '../../contexts/AuthContext'
import Loader from '../base/Loader'
import banner from '../../static/img/banner-bg.svg'
import { Context } from '../../contexts/GlobalContext'

export default function TravelBanner() {
    const { cmsToken } = useContext(AuthContext)
    const { language } = useContext(Context)
    // const { t } = useTranslation('travel')
    const { response } = useLoad({
        url: `${CMS.TRAVEL_BANNER
            .replace('{slug}', 'puteshestvie')
        }`,
    }, [cmsToken, language])
    if (!response) {
        return (
            <Loader centered />
        )
    }
    const data = response[0]

    return (
        <div className="travel-one__container">
            <div className="banner">
                <div className="banner__info">
                    <h2 className="banner__title">{ data.title }</h2>
                    <p className="banner__text">
                        {data.description}
                    </p>
                    <div className="banner__links">
                        {/* <NavLink to={`/boxes/order/${data.slug}`} className="banner__link request-link">
                            {t('buy')}
                        </NavLink> */}
                        {/* <NavLink to={`/boxes/${data.slug}`} className="main-banner__link info-link">
                            {t('more')}
                        </NavLink> */}
                    </div>
                </div>
                <picture className="banner__image">
                    <source media="(min-width: 1340px)" srcSet={data.mainImg} />
                    <img src={data.mainImg} alt="" />
                </picture>
                <img className="banner__bg" src={banner} alt="" />
            </div>
        </div>
    )
}
