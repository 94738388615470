import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormik } from 'formik'
import { withFormikDevtools } from 'formik-devtools-extension'
import moment from 'moment'
import { initialValues, optionsTravel, selectedOptions, validate } from '../helpers/travel'
import { useModal } from '../hooks/modal'
import { PaymentSelector } from '../components/modals/PaymentSelector'
import { Context } from './GlobalContext'

export const VzrContext = createContext()

function changeSequence(val, data, type) {
    const index = data.sequence.indexOf(val)
    if (!type) {
        data.sequence.splice(index, 1)
    } else if (index === -1) {
        data.sequence.push(val)
    }
}

export default function VzrContextWrapper({ children, state, buy, calc, head, payment, travel, toggleInfoPopup, toggleSpaTypePopup }) {
    const { t } = useTranslation('travel')
    const [cost, setCost] = useState(false)
    const [step, setStep] = useState(1)
    const [step3el, setStep3el] = useState()
    const [canCopy, setCanCopy] = useState(true)

    const [errorMsg, setErrorMsg] = useState([])
    const [errorCoupon, setErrorCoupon] = useState(false)

    const timer = useRef(null)
    const nextStep = () => {
        setStep((value) => value + 1)
    }
    const prevStep = () => {
        setStep((value) => value - 1)
    }

    const travelType = {
        0: t('oneTravel'),
        1: t('multiTravel'),
    }

    console.log(1)
    const { utmPartner } = useContext(Context)
    console.log(2)

    const formik = useFormik({
        initialValues: initialValues(state),
        onSubmit: (data, { resetForm, setFieldValue, validateForm }) => {
            setFieldValue('shortValidate', null)
            validateForm()
            window.clearTimeout(timer.current)
            setErrorMsg(false)
            setErrorCoupon(false)
            const country = []
            const countryName = []
            const { holderBirthday } = data
            if (data.country) data.country.forEach((item) => country.push(item.value))
            if (data.country) data.country.forEach((item) => countryName.push(item.label))
            // setValues({ ...data, country: countryName.join(', ') })

            timer.current = window.setTimeout(async () => {
                const { success, response, error } = await calc.request(
                    { data: { ...data, country: (country.join(',')), holderBirthday } },
                )
                if (success && !error) {
                    // setValues({ ...response, country: countryName.join(', ') })
                    resetForm({ values: { ...response, country: data.country } })
                    setFieldValue('shortValidate', null)
                    validateForm()
                    setCost(response.sumPrem)
                    if (response.errorMsg) {
                        setErrorMsg(response.errorMsg)
                        window.scrollTo({
                            top: head.current.offsetTop - 10,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }
                    if (response.couponErr) {
                        setErrorCoupon(response.couponErr)
                        // setFieldValue('coupon', null)
                    }
                }
            }, 1000)
        },
        validate: validate(buy, step),
    })
    withFormikDevtools(formik)

    const [useAutocomplete, setUseAutocomplete] = useState(true)
    const autocompleteOptions = () => new Promise((resolve) => {
        if (useAutocomplete) {
            setUseAutocomplete(false)

            optionsTravel.forEach(({ name, range, seq }) => {
                if (selectedOptions.indexOf(name) !== -1) {
                    const middle = Math.floor(range.length / 2)
                    let val = 1
                    if (range[middle]) {
                        val = range[middle].value
                    }
                    if (!(formik.values[name] > 0)) {
                        formik.setFieldValue(name, val)
                    }
                    changeSequence(seq, formik.values, true)
                }
            })

            window.setTimeout(() => resolve(), 200)
        }
    })

    const onCreateTravel = async function (data) {
        const country = []
        let paymentOper = 'dc'
        switch (formik.values.paymentOper) {
        case 'vaslpay':
            paymentOper = 'vaslpay'
            break
        case 'payler':
            paymentOper = 'payler'
            break
        default:
            paymentOper = 'dc'
        }
        if (data.country) data.country.forEach((item) => country.push(item.value))

        if (data.agree) {
            const post = { ...data, country: (country.join(',')) }
            post.partner = utmPartner
            // if (post.coupon === '') delete post.coupon
            // post.issue_date = moment(data.issueDate).format('YYYY-MM-DD')
            post.date_to = moment(data.dateTo).format('YYYY-MM-DD')
            if (post.las) {
                post.las.forEach((item, i) => {
                    post.las[i].laCitizenship = item.laCitizenship.value
                })
            }
            const { success, response } = await travel.request({ data: post })
            if (success) {
                const request = { data: { policy_id: response.id, policy_type: 'travel', payment_oper: paymentOper } }
                const answer = await payment.request(request)
                if (answer.success) window.location = answer.response.redirectUrl
            }
        }
        return false
    }

    useEffect(() => {
        window.setTimeout(() => formik.handleSubmit(), 2000)
    }, [])

    useEffect(() => {
        if (formik.values.paymentOper) window.setTimeout(() => showPayment(), 0)
    }, [formik.values.paymentOper])

    const [showPayment] = useModal(
        <PaymentSelector
            className="payment-popup" paymentOper={formik.values.paymentOper}
            setPaymentOper={(value) => {
                formik.setFieldValue('paymentOper', value)
            }}
            onCreateTravel={() => onCreateTravel(formik.values)}
        />,
    )

    useEffect(() => {
        if (formik.values.paymentOper) showPayment()
    }, [formik.values.paymentOper])

    return (
        <VzrContext.Provider value={{
            cost,
            setCost,
            step,
            setStep,
            nextStep,
            prevStep,
            changeSequence,
            travelType,
            formik,
            errorMsg,
            errorCoupon,
            onCreateTravel,
            toggleInfoPopup,
            toggleSpaTypePopup,
            autocompleteOptions,
            step3el,
            setStep3el,
            canCopy,
            setCanCopy,
            showPayment,
        }}>
            <FormikProvider value={formik}>
                {children}
            </FormikProvider>
        </VzrContext.Provider>
    )
}
