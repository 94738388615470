import React, { useContext, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import MultiSelectField from '../base/MultiSelectField'
import { currencyList, hints, spaType } from '../../helpers/travel'
import countries from '../../utils/countries.json'
import { RadioGroup } from '../base/Radio'
import { DateInput } from '../base/DateInput'
import Input from '../base/Input'
import { changeDate } from '../../utils/date'
import Checkbox from '../base/Checkbox'
import FormTotal from './FormTotal'
import { VzrContext } from '../../contexts/VzrContext'
import SelectList from '../common/SelectList'
import SelectSwitch from '../common/SelectSwitch'
import SelectPersons from '../common/SelectPersons'

const medical = [{ value: 30000, name: '30 000' }, { value: 50000, name: '50 000' }, { value: 75000, name: '75 000' }]

/*
<QrReader
    constraints={{ facingMode: 'environment' }}
    onResult={(result, error) => {
        if (result) {
            setData(JSON.stringify(result))
        }

        if (error) {
            console.info(error)
        }
    }}
    style={{ width: '100%', height: '50%' }}
/>
<p>{data}</p>
 */

export default function FormStep1() {
    const { setCost, changeSequence, toggleSpaTypePopup } = useContext(VzrContext)

    const { t } = useTranslation('travel')
    const { setFieldValue, errors, values, validateForm, handleSubmit } = useFormikContext()
    const firstCalendar = useRef()
    const secondCalendar = useRef()

    const showPregnant = useMemo(() => values.las.some(({ laBirthday }) => {
        const diff = moment().diff(moment(laBirthday), 'year')
        if (diff <= 69 && diff >= 4) return true
        return false
    }), [values.las])

    useEffect(() => {
        if (showPregnant === false) {
            setFieldValue('pregnantOption', 0)
            setFieldValue('sequence', [...values.sequence].filter((item) => item !== 2))
            window.setTimeout(() => handleSubmit(), 500)
        }
    }, [showPregnant])

    const minDate = () => moment(values.beginDate || moment()).add(2, 'days').format('YYYY-MM-DD')

    return (
        <section className="step step-1">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <div className="travel-one__form-row">
                        <MultiSelectField error={errors.country}
                            hint={hints.coutry} required
                            options={countries} name="country"
                            label={t('travelCountry')}
                            errTxt={t('chooseCountry')}
                            onChange={() => {
                                if (firstCalendar.current) {
                                    firstCalendar.current.focus()
                                    firstCalendar.current.showPicker()
                                }
                                handleSubmit()
                            }}
                        />
                    </div>
                    <RadioGroup name="howOften" className="travel-one__form-ranges">
                        <div className="travel-one__ranges-block">
                            <div className="ranges__right">
                                <Checkbox
                                    label={t('multiTravel')}
                                    name="multi"
                                    hint={hints.moreTrip}
                                    onChange={(e) => {
                                        const multi = values.multi === 1 ? 0 : 1
                                        setFieldValue('multi', multi)

                                        if (e) {
                                            const begin = moment(values.beginDate || new Date())
                                            let end = moment(values.endDate || new Date())
                                            let delta = end.diff(begin, 'days')
                                            if (multi !== 0) {
                                                end = moment.unix(begin.unix()).add(1, 'year')
                                                    .add('-1', 'day')
                                                delta = 89
                                            } else if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                end = moment.unix(begin.unix()).add(2, 'day')
                                                    .add('-1', 'day')
                                                delta = 1
                                            }
                                            setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                            setFieldValue('dayCount', delta + 1)
                                        } else {
                                            setFieldValue('endDate', '')
                                            if (secondCalendar.current) {
                                                secondCalendar.current.focus()
                                                secondCalendar.current.showPicker()
                                            }
                                        }

                                        window.setTimeout(() => handleSubmit(), 200)
                                    }}
                                    className="travel-one__form-range" />
                            </div>
                        </div>
                        <div className="ranges__error-block">
                            {
                                errors.multi && (
                                    <span style={{ color: '#eb5757', fontSize: '12px' }}>
                                        {t('wrongPoliceType')}
                                    </span>
                                )
                            }
                        </div>
                    </RadioGroup>
                    <div className="travel-one__form-row">
                        <DateInput
                            className="input travel-one__input-date" inputRef={firstCalendar}
                            name="beginDate" required error={errors.beginDate}
                            min={moment().format('YYYY-MM-DD')}
                            max={moment().add(4, 'year').format('YYYY-MM-DD')}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    setFieldValue('beginDate', '')
                                    return
                                }
                                const begin = moment(e.target.value)
                                let end = moment(values.endDate)
                                let delta = end.diff(begin, 'days')
                                if (values.multi && values.multi !== 0) {
                                    end = moment.unix(begin.unix()).add(1, 'year')
                                        .add('-1', 'day')
                                    setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                    delta = 89
                                } else if (begin.unix() > (end.unix() - 2 * 86400)) {
                                    end = moment.unix(begin.unix()).add(2, 'day')
                                        .add('-1', 'day')
                                    setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                    delta = 1
                                }
                                setFieldValue('dayCount', delta + 1)
                                window.setTimeout(() => handleSubmit(), 200)
                                if (secondCalendar.current) {
                                    secondCalendar.current.focus()
                                    secondCalendar.current.showPicker()
                                }
                            }}
                            label={t('departureDate')}
                            placeholder="ДД / ММ / ГГГГ" />
                        {
                            values.multi === 0
                                ? (
                                    <DateInput inputRef={secondCalendar}
                                        className="input travel-one__input-date"
                                        min={minDate()}
                                        max={moment().add(4, 'year').format('YYYY-MM-DD')}
                                        name="endDate" required error={errors.endDate}
                                        label={t('arrivalDate')}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFieldValue('endDate', '')
                                                return
                                            }
                                            const begin = moment(values.beginDate)
                                            let end = moment(e.target.value)
                                            if (begin.unix() > (end.unix() - 2 * 86400)) {
                                                end = moment.unix(begin.unix()).add(2, 'day')
                                                    .add('-1', 'day')
                                                setFieldValue('endDate', end.format('YYYY-MM-DD'))
                                            }
                                            const delta = end.diff(begin, 'days')
                                            setFieldValue('dayCount', delta + 1)
                                            window.setTimeout(() => handleSubmit(), 200)
                                        }}
                                        placeholder="ДД / ММ / ГГГГ" />
                                ) : (
                                    <Input
                                        isDisabled required error={errors.dayCount}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            window.setTimeout(() => {
                                                validateForm(values)
                                                setFieldValue('endDate',
                                                    changeDate(new Date(values.beginDate),
                                                        e.target.value))
                                                setFieldValue('dayCount', e.target.value)
                                                setCost(false)
                                            }, 0)
                                            window.setTimeout(() => handleSubmit(), 200)
                                        }}
                                        divClass="travel-one__input-date"
                                        name="dayCount"
                                        label={t('dayCount')}
                                        placeholder={t('dayCountPH')} />
                                )
                        }

                    </div>
                    <h3 className="travel-order__persons">
                        {t('policeConditions')}
                    </h3>
                    <SelectPersons />
                    <div className="travel-one__form-row">
                        <div className="select select__travel-order">
                            <SelectList
                                name="mainSumAssured" options={medical} label={t('medical')}
                                onChange={() => window.setTimeout(() => handleSubmit(), 200)}
                            />
                        </div>
                        <SelectSwitch options={currencyList} name="currency" className="travel-currency__wrap" />
                    </div>
                    <div className="travel-one__form-row">
                        <div className="travel-type__wrapp">
                            <SelectSwitch className="travel-type__box"
                                options={spaType} name="sportOption"
                                onChange={(value) => {
                                    setFieldValue('sportOption', value)
                                    changeSequence(0, values, value === 1)
                                }}
                            />
                            {(values.sportOption === 0) && (
                                <div className="travel-type__hint relax-hint">
                                    <span className="travel-type__hint-text">
                                        {t('spaType0Hint')}
                                    </span>
                                    <button className="travel-type__hint-link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            toggleSpaTypePopup()
                                        }}
                                    >
                                        {t('spaTypeHint')}
                                    </button>
                                </div>
                            )}
                            {(values.sportOption === 1) && (
                                <div className="travel-type__hint relax-hint">
                                    <span className="travel-type__hint-text">
                                        {t('spaType1Hint')}
                                    </span>
                                    <button className="travel-type__hint-link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            toggleSpaTypePopup()
                                        }}
                                    >
                                        {t('spaTypeHint')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <FormTotal />
            </div>
        </section>
    )
}
