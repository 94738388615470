import React from 'react'

export default function ServerError({ error }) {
    if (!error || !error.data) return null

    return (
        <div>
            {Object.keys(error.data).map((key) => (
                <span style={{ color: 'rgba(164,4,4,0.74)' }}>
                    {error.data[key]}
                </span>
            ))}
        </div>
    )
}
